import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { LoginStyle } from './Login-style';
// routes
// import SecondView from './views/login-view-2';
// import ThirdView from './views/login-view-3';
import MainLogin from './steps/Main-login';
import RecoverPass from './steps/Recover-password';
import { useSelector } from 'react-redux';
import { authSelectors } from '../../../../store/auth/selectors';

const Login = () => {
  const history = useHistory();

  const isNewEmail = useSelector(authSelectors.getErrorEmail());

  useEffect(() => {
    if (isNewEmail) history.push('/registro');
  }, [history, isNewEmail]);

  return (
    <LoginStyle>
      <Route exact path="/iniciar-sesion/" component={MainLogin} />

      {/* <Route exact path="/iniciar-sesion/" component={FirstView} /> */}
      <Route path="/iniciar-sesion/olvido-de-contraseña" component={RecoverPass} />
      {/* <Route render={() => <Redirect to="/" />} /> */}

    </LoginStyle>
  );
};

export default Login;