interface InterfaceErrorMessage {
  required: string;
  'invalid-format': string;
  'invalid-prefix': string;
  'invalid-email': string;
  'invalid-date': string;
  'invalid-postal-code': string;
  'should-be-under-14': string;
  'form-error': string;
  'server-error': string;
  'previous-docs': string;
  'insured-duplicate-birthdate': string;
  'invalid-insured-birthdate': string;
  'invalid-taker-birthdate': string;
  'taker-under-18': string;
  'invalid-address': string;
  'budget-not-found': string;
  'budget-without-acts': string;
  [key: string]: string;
}

export const ERROR_MESSAGES: InterfaceErrorMessage = {
  'invalid-postal-code': 'C.P. no válido',
  'should-be-under-14': 'Es ilegal no tener NIF con más de 14 años',
  'form-error': 'Revisa que has rellenado todo correctamente',
  'server-error': 'Ha ocurrido un fallo en el servidor',
  'previous-docs':
    'Le informamos que la contratación del actual asegurado no puede llevarse a cabo ya que posee documentos pendientes de firma.',
  'insured-duplicate-birthdate':
    'La contratación del presupuesto seleccionado no se puede llevar a cabo ya que se ha detectado un error en la fecha de nacimiento del asegurado en las simulaciones previas.',
  'invalid-insured-birthdate': 'El asegurado debe tener entre 12 y 80 años de edad.',
  'invalid-taker-birthdate': 'El tomador del seguro debe tener entre 18 y 80 años de edad.',
  'taker-under-18': 'El tomador del seguro debe ser mayor de edad.',
  'invalid-address': 'La dirección introducida no es válida, comprueba que los datos son correctos.',
  'budget-not-found': 'No se han encontrado datos para los IDs introducidos.',
  'budget-without-acts': 'El presupuesto seleccionado no dispone de actos asegurables.',
  'not-active' : 'Debe aceptarse el check',
  'images-not-exist' : 'No se ha ingresado ninguna imagen',
  'invalid-credit-card-number' : 'Número de tarjeta inválido',
  'invalid-cvv': 'CVV inválido',
  required: 'Campo obligatorio',
  'too-short-name': 'Nombre muy corto',
  'too-short-surname': 'Apellido muy corto',
  'too-short-password': 'La contraseña es demasiado corta, debe contener un mínimo de 8 caracteres',
  'too-short-tdc': 'Debe ingresar 4 dígitos',
  'too-short-description': 'La descripción es muy corta',
  'too-short-address': 'Dirección muy corta',
  'too-short-clabe': 'La cuenta CLABE debe ser de 18 dígitos',
  'too-heavy-file': 'El archivo debe pesar un máximo de 6MB',
  'too-long-turn': 'La descripción del giro comercial debe ser de máximo 40 caracteres',
  'too-short-cvv': 'El código de seguridad de la tarjeta debe ser de mínimo 3 dígitos',
  'too-short-cvv-mx': 'El código de seguridad de la tarjeta debe ser de mínimo 4 dígitos',
  'too-short-month': 'El mes y año debe ser de 2 dígitos.',
  'too-short-code': 'El código debe ser de 6 dígitos.',
  'no-match': 'La contraseña no coincide',
  'no-strong':
    'La contraseña es débil, debe contener al menos una mayúscula, una minúscula, un signo de puntuación y un número',
  'accept-terms': 'Debes aceptar los términos y condiciones.',
  'both-ine-images-needed': 'Debe agregar ambas imágenes, y estas deben ser de extención .png o .jpeg',
  'invalid-format': 'Formato no válido',
  'invalid-url': 'La url debe contener https://, ser valida y un mínimo de 2 letras',
  'invalid-name': 'El nombre y apellido no pueden contener símbolos o números',
  'invalid-rfc':
    'El número que ha ingresado no es válido, por favor indique un formato del siguiente tipo: PARJ970612PY8',
  'invalid-prefix': 'Prefijo no válido',
  'invalid-phone': 'Teléfono no válido, por favor indique un formato del siguiente tipo: +52 923659832',
  'invalid-email': 'Email no válido',
  'invalid-date': 'Fecha no válida',
  'invalid-password': 'La contraseña es insegura',
  'invalid-image': 'La extensión es erronea, solo se acepta .png, .jpeg',
  'invalid-pdf': 'La extensión es erronea, solo se acepta .pdf',
  'invalid-xml': 'La extensión es erronea, solo se acepta .xml',
  'invalid-nationality': 'Este campo no puede contener símbolos o números',
  'invalid-card': 'El número de tarjeta no es correcto.',
  'invalid-month': 'Mes no válido.',
  'invalid-year': 'Año no válido.',
  'email-not-found': 'No existe ese correo en nuestra base de datos',
  'error-ine': 'Asegurese de que los archivos que esten subidos sean de formato png/jpg y que no superen un máximo de 6MB en tamaño',
  'invalid-date-rfc': 'La fecha de nacimiento no coincide con el RFC',
};
