import { Dispatch } from 'redux';

import { CardActions, CardActionsTypes, IUserCards } from './types';
import { ILoadingActionsTypes, ILoadingActions } from '../loader/types';
import { LightpayApi, IFormatError } from '../restclient';
import { ICard } from '@aluxion-lightpay/interfaces';
import CardStateContainer from '../../components/cards/card-state/card-state-style';

interface IServerResponseCard {
  data: IUserCards;
}

interface IServerResponseCardAdded {
  data: ICard;
}

interface IServerResponseHasDebts {
  data: {
    hasDebts: boolean;
  }
}

const addCard = (id:number, cardForm: any, tokenCardId: string, duplicate: any) => async (dispatch: Dispatch<CardActionsTypes | ILoadingActionsTypes>) => {
  await dispatch({ type: ILoadingActions.LOADING });
  if(duplicate){
    console.log('ya está registrada')
    await dispatch({ type: ILoadingActions.LOADED });
  } else {
    console.log('no esta duplicada')
    try {
      const res = await LightpayApi.get<IServerResponseHasDebts>(`/users/${id}/has-debts`, 
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        }
      });
      await dispatch({ type: CardActions.ADD_DEBTS_DATA, payload: res.data.hasDebts });
      
      const deviceSessionId = await (window as any).OpenPay.deviceData?.setup();
      const response = await LightpayApi.post<IServerResponseCardAdded>(`/users/${id}/cards`, {
        cvv2: cardForm.cvv2,
        cardToken: tokenCardId,
        deviceSessionId,
      }, 
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        }
      });
      await dispatch({ type: CardActions.ADD_LASTCARD_DATA, payload: response.data });
  
      await dispatch({ type: ILoadingActions.LOADED });
    } catch(error){
      const formatError: IFormatError = LightpayApi.errorHandler(error);
      dispatch({ type: ILoadingActions.LOADED });
      throw formatError;
  }
  }
};

const getCardData = (id:number) => async (dispatch: Dispatch<CardActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const cardData = await LightpayApi.get<IServerResponseCard>(`/users/${id}/cards`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    });
    await dispatch({ type: CardActions.ADD_CARD_DATA, payload: cardData.data  });
    await dispatch({ type: ILoadingActions.LOADED });
   }catch(error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
   }
}

const addMainCard = (id: number | string, cardId:number) =>async (dispatch: Dispatch<CardActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.post(`/purchases/${id}/card/${cardId}`, '', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    });
    await dispatch({ type: ILoadingActions.LOADED });
  }catch(error){
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const deleteCard = (id:number, cardId:number) =>async (dispatch: Dispatch<CardActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.delete(`/users/${id}/cards/${cardId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    });
    const cardData = await LightpayApi.get<IServerResponseCard>(`/users/${id}/cards`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    });
    await dispatch({ type: CardActions.ADD_CARD_DATA, payload: cardData.data  });
    await dispatch({ type: ILoadingActions.LOADED });
  }catch(error){
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

export const cardActions = {
  addCard,
  getCardData,
  addMainCard,
  deleteCard
};
