import React from 'react';
import Accordion from '../../../../../components/accordion/accordion';
import { IPropsAnswers } from '../../../../../utils/utils';

const HowToUse: React.FC<IPropsAnswers> = (props) => {
  const { onClick } = props;
  return (
    <div className="help-content">
      <div className="help-content-return" onClick={ () => onClick() }>
        <div><i className="arrow"></i></div>
        <p>Cómo usar Slightpay</p>
      </div>
      <div className="help-section-box">
        <Accordion title="Quiero comprar con Slightpay - ¿Cómo me registro?" className="help-item">
          <p className="help-text">

            <p>
              Para comprar con Slightpay debes registrar una cuenta. Para registrarte crea una cuenta en www.slightpay.com, configura 
              tu cuenta con los datos solicitados y podrás realizar más compras con Slightpay simplemente ingresando sus datos de 
              inicio de sesión al momento de pagar.
            </p>

            <p>
              Cuando hagas una compra en una de las tiendas que se encuentran en el Directorio de la tiendas, elije Slightpay como tu 
              método de pago al momento de pagar. Si es la primera vez que utilizas Slightpay, deberás crear una cuenta y proporcionar 
              tus datos personales y de pago para completar tu compra
            </p>

            <p>
              Para ser elegible para usar la característica de plazos, debe:<br></br><br></br>
              <ul>
                <li>Ser una persona mayor de 18 años</li>
                <li>Ser residente de Mexico</li>
                <li>Ser capaz de celebrar un contrato legalmente vinculante</li>
                <li>Tener una dirección de correo electrónico y un número de teléfono móvil válidos y verificables</li>
                <li>Proporcionar una dirección de entrega válida en México</li>
                <li>Estar autorizado para utilizar el Método de Pago provisto</li>
              </ul>
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Cuándo se realizará mi primer pago?" className="help-item">
          <p className="help-text">
            <p>
              Pagarás el primer 25% del valor de tu carrito en el momento de realizar la compra con Slightpay. En el momento de la compra 
              se cobrará un 5% por el uso de plataforma. 
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Cómo funcionan las cuotas por pospago?" className="help-item">
          <p className="help-text">
            <p>
              Las cuotas de Slightpay les permiten a los comercios afiliados ofrecer planes de cuotas a sus clientes en línea,
               para pagar una compra en cuatro cuotas iguales, cada 2 semanas.

            </p>

            <p>
              Simplemente llena tu carrito de compra en una de las tiendas dentro de nuestro Directorio de Tiendas y elije Slightpay 
              como método de pago al momento de pagar. Los compradores primerizos deben crear una cuenta y registrar un método de pago 
              como de costumbre, los compradores que regresan simplemente inician sesión para pagar su compra en cuotas. ¡Es súper fácil!

            </p>

            <p>
              Después de la confirmación de compra, el comercio le enviará las mercancías. En cualquier momento, puede iniciar sesión en 
              su cuenta de Slightpay para ver su calendario de pagos y realizar un pago antes de la fecha de vencimiento. De lo contrario, 
              generaremos un cargo automático a su tarjeta de débito o crédito cada 2 semanas.

            </p>

            <p>
              Es importante tener en cuenta: Slightpay no aprueba el 100% de las solicitudes. Nuestra filosofía y compromiso es 
              apoyar el gasto responsable.
            </p>

            <p>
              Al determinar qué órdenes se aprueban, consideramos una serie de factores diferentes. Como ejemplo, cuanto más tiempo 
              hayas sido comprador utilizando cuotas a través de Slightpay y cuantas más órdenes hayas pagado con éxito, más 
              probabilidades tendrá de gastar más.
            </p>

            <p>Cosas para considerar:</p>

            <p>
              <ul>
                <li>
                  ¿Hay fondos suficientes en tu tarjeta (en general, buscamos ver el 25% del valor del pedido disponible para gastar)?
                </li>
                <li>
                  La compra mínima es de 500 pesos.
                </li>
                <li>
                  La cantidad de tiempo que has utilizado Slightpay (el sistema de validación es más estricto en las primeras 6 semanas).
                </li>
                <li>
                  Autorización de acceso a modo lectura de cuenta bancaria.
                </li>
                <li>
                  Score crediticio.
                </li>
                <li>
                  Agregar una tarjeta de crédito puede aumentar el monto autorizado (no es una garantía).
                </li>
                <li>
                  El número de pedidos que tienes actualmente "abierto" con nosotros (no es una garantía).
                </li>
              </ul>
            </p>

            <p>
              Sabemos que puede ser frustrante no saber exactamente cuánto puedes gastar cada vez que uses Slightpay o cuántos pedidos 
              activos puedes tener, buscamos que nuestras reglas y el proceso de aprobación ayudan a Slightpay a ofrecer un servicio 
              personalizado a cada uno de nuestros clientes.
            </p>

          </p>
        </Accordion>

        <Accordion title="¿Quién puede Slightpay?" className="help-item">
          <p className="help-text">
            <p>
              Las cuotas de Slightpay son un servicio de pago ofrecido por los comercios en línea en México. Es un servicio para compradores 
              que:
            </p>

            <p>
              <ul>
                <li>
                  Viven en México.

                </li>
                <li>
                  Tienen al menos 18 años de edad.
                </li>
                <li>
                  Son capaces de celebrar un contrato legalmente vinculante.

                </li>
                <li>
                  Tenen una dirección de correo electrónico y un número de teléfono móvil válidos y verificables.

                </li>
                <li>
                  Proporcionan una dirección de entrega válida en México.

                </li>
                <li>
                  Estan autorizado para usar la tarjeta de crédito o débito emitida en México para realizar la compra.

                </li>
                <li>
                  La compra mínima es de $500 MXN y máxima de $5,000 MXN.
                </li>
              </ul>
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Dónde puedo usar Slightpay para pagar en 4 cuotas?" className="help-item">
          <p className="help-text">
            <p>
              El servicio de pago en 4 cuotas sin intereses, pronto serán ofrecidas por muchos e-commerces en México. 
              Para conocer una lista de las tiendas disponible, visita nuestro Directorio de Tiendas. Constantemente estamos 
              sumando nuevos comercios a la lista, así que no dejes de seguir visitando nuestro sitio web.
            </p>

            <p>
              Si deseas que las cuotas de Slightpay estén disponibles en tus en tiendas en  línea favoritas, envíales un correo 
              electrónico para informarles cuánto te gustaría poder comprar usando el servicio de Slightpay.
            </p>

            <p>
              Recuerda: el gasto mínimo es de $500 pesos.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Cuál es el costo del servicio?" className="help-item">
          <p className="help-text">
            <p>
              El servicio de Slightpay no genera intereses, únicamente se cobra una comisión mínima por aplazar el pago de tus compras 
              hasta 45 días equivalente al 5% del valor de la compra.
            </p>

            <p>
              Aquellos compradores que se retrasen en el pago de sus cuotas, si sus pagos programados se procesan sin éxito y, 
              después de ser notificado, no inicia sesión en su cuenta de Slightpay para realizar su pago a través de un método diferente, 
              generan un cargo por mora. 
            </p>

            <p>
              Los Cargos por Mora para los usuarios en México tienen un límite máximo del 45% del valor del pedido. Es importante tener en 
              cuenta que nuestro ticket mínimo de compra es de $500 pesos.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Slightpay es seguro?" className="help-item">
          <p className="help-text">
            <p>
              Entendemos lo importante que es la seguridad, por lo que nos aseguramos de que su información personal esté protegida. 
              Slightpay es una organización proveedora de servicios que cumple con la certificación <span>PCI DSS Nivel 1</span>.

            </p>

            <p>
              <span>PCI DSS</span> es un conjunto integral de requisitos creado por el Consejo de Normas de Seguridad de la Industria de Tarjetas de 
              Pago para mejorar la seguridad de los datos de los titulares de tarjetas y garantizar el manejo y almacenamiento seguro de 
              información y datos confidenciales de tarjetas de crédito.
            </p>

            <p>
              Este es el nivel más alto de seguridad en la industria de pagos.
            </p>
          </p>
        </Accordion>
        
        <Accordion title="¿Slightpay no hace todo su dinero de las tarifas atrasadas?" className="help-item">
          <p className="help-text">
            <p>
              Los pagos atrasados son malos para nuestro negocio porque perseguirlos cuesta lo mas de lo que recuperamos, y si un 
              cliente no hace un pago, no puede comprar nada más con nosotros hasta que se liquide su cuenta. Es por eso que hacemos 
              todo lo posible para asegurarnos de que solo compre lo que pueda pagar y evitar perder cualquier pago, incluyendo:
            </p>

            <p>
              <ul>
                <li>
                  Los Cargos por Mora son bajos y están limitados para que nunca puedan subir más de un 45%. 
                </li>
                <li>
                  No generan intereses.
                </li>
                <li>
                  Dejamos de ofrecer nuestro servicio una vez que no se haya realizado un pago, para que no se endeuden más.
                </li>
                <li>
                  Utilizamos tecnología patentada para evitar que las personas compren demasiado.
                </li>
              </ul>
            </p>

            <p>
              Por eso, Slightpay es una excelente manera de planificar, reservar, comprar y pagar.
            </p>
          </p>
        </Accordion>

      </div>
    </div>
  );
};

export default HowToUse;