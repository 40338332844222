import styled from 'styled-components/macro';

interface IAuthStyle {
  login?: boolean;
}

export const AuthStyle = styled.div<IAuthStyle>`
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  .login {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 0;
    margin-left: 50%;
    .login-container {
      /* ${props => (props.login ? `width: 27.6875rem;` : `width: 20.5625rem;`)} */
      /* text-align: center; */

      max-width: 440px;
      width: 100%;
      margin: 120px auto 80px;
      @media screen and (max-width: 1145px) {
        width: 20.5625rem;
      }
    }
    .main-title {
      margin-bottom: 6.875rem;
      display: flex;
      justify-content: center;
    }
    .login-form {
      /* height: calc(100vh); */
      display: flex;
      flex-direction: column;

      .form-button {
        margin-top: 30px;
      }
      .form-p {
        text-align: left;
        font-weight: 300;

        span {
          color: #1a4ccd;
          font-weight: 500;
          button {
            font-size: 1rem;
            font-weight: 500;
            &:after {
              content: '';
              position: absolute;
              width: 7rem;
              height: 1px;
              background: #1a4ccd;
              bottom: -2px;
              left: 6%;
            }
          }
        }
        .bold {
          color: #01030c;
          font-weight: 500;
        }
      }
      .form-input {
        margin-top: 2.6rem;
      }
      .button-underline {
        position: relative;
        color: #1a4ccd;
        font-size: 0.875rem;
        margin-top: 2.625rem;
        border: 0;
        background-color: transparent;
        &_main{
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 1145px) {
      .main-title {
        margin-bottom: 3rem;
      }
    }

  }
  @media  (max-width: 768px) {
      .login { 
        margin-left: 0%;
        .login-container {
          margin: 120px 20px 80px;
        }
      }
  }
`;
