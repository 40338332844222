import React from 'react';

import LoaderContainer from './loader-style';

const Loader: React.FC<{}> = () => {
  return (
    <LoaderContainer>
      <div className="loader-animation">
        <div className="big-letter-container">
          <span className="big-letter up">slight</span>
          <span className="big-letter down">pay</span>
          <p>Cargando...</p>
        </div>
      </div>
    </LoaderContainer>
  );
};

export default Loader;

