import React from 'react'
import HelpSectionBoxContainer from './help-section-box-style'
import { icons } from '../../assets/themes'

interface HelpSectionBoxProps {
  labelText?: string
  link?: string
  onClick?: () => void;
}

const HelpSectionBox: React.FC<HelpSectionBoxProps> = (
  props: HelpSectionBoxProps
) => {
  const { onClick } = props;
  return (
    <HelpSectionBoxContainer>
      <p className="help-section-box-title">{props.labelText}</p>

      <div className="help-section-box-icon" onClick={ onClick && onClick }>
        <img src={icons.fetchArrowRightBlue} alt="fetch right arrow " />
      </div>
    </HelpSectionBoxContainer>
  )
}

export default HelpSectionBox
