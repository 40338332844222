import styled from 'styled-components/macro';

export const PaginationStyles = styled.div`
  display: flex;
  align-items: baseline;
  ul {
    display: flex;
    button {
      color: ${props => props.theme.colors.secondary};
      &.active {
        font-weight: bold;
        color: ${props => props.theme.colors.primary};
      }
    }
  }
  .page-link {
    border: 0;
    background-color: transparent;
    font-size: 0.75rem;
    padding: 0 24px 0 24px;
    &.disable {
      pointer-events: none;
      opacity: 0.4;
    }
    &:last-child {
      padding: 0 24px 0 0;
    }
    &:hover {
      color: ${props => props.theme.colors.onBackground};
    }
  }
  .page-item {
    color: #01030c;
    font-size: 0.75rem;
    font-weight: 500;
  }
`;
