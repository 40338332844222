import React from 'react';
import Accordion from '../../../../../components/accordion/accordion';
import { IPropsAnswers } from '../../../../../utils/utils';

const Pagos: React.FC<IPropsAnswers> = (props) => {
  const { onClick } = props;
  return (
    <div className="help-content">
      <div className="help-content-return" onClick={ () => onClick() }>
        <div><i className="arrow"></i></div>
        <p>Pagos</p>
      </div>
      <div className="help-section-box">
        <Accordion title="¿Qué pasa si no puedo hacer un pago?" className="help-item">
          <p className="help-text">
            <p>
              Slightpay se compromete a asegurarse que cumplas con tu plan de pagos, es por eso que cada solicitud de compra lleva 
              un proceso de validación antes de aprobar. Con esta validación, nos asegurarnos que te será posible cumplir con el plan 
              de pagos. Adicionalmente siempre te enviaremos recordatorios cuando una cuota está por vencer.
            </p>
            <p>
              Si aprobamos una compra y presentas algún problema para realizar tu pago, (¡a todos nos puede pasar!), Te estaremos 
              contactando de inmediato para ver si podemos ayudarte. Si estás enfrentando dificultades, tenemos una política que 
              nos permite trabajar contigo para volver a encaminarte a tus cuotas pendientes.
            </p>
            <p>
              Por otro lado, es importante mencionar que tan pronto como te retrases con algún pago, inmediatamente impediremos 
              realizar más compras con Slightpay. Esto se debe a nuestro compromiso con nuestros clientes para fomentar el gasto y 
              compras responsables. 
            </p>
            <p>
              Tendrás hasta las 14:00 horas del día siguiente para hacer el pago de tu cuota correspondiente a la fecha y luego 
              incurrirás en un Cargo por Mora por un pago no realizado. Éste será por un monto inicial de $100 pesos  (pueden ser 3, 4, 7 
              días según el monto de la compra, el plazo se debería calcular y presentar al usuario al momento de decidir la compra.
            </p>
            <p>
              Para proteger a nuestros usuarios, los cargos por mora tienen un límite y nunca superarán el 45% del precio del artículo que 
              compraste y la cantidad de cargos por mora nunca superará el monto pendiente de pago. Es decir, si el pago parcial pendiente 
              es de $200 pesos, no se realizarán más de 2 cargos por mora. Esto significa que podemos evitar que las personas se aprovechen 
              deliberadamente de Slightpay y evitar generar más dificultades financieras a nuestros clientes. Con esto ofrecemos un 
              incentivo para que los usuarios se atengan a su plan de pagos, y que nunca pierdan el control de su dinero.
            </p>
            <p>
              Es importante que sepas que estaremos informando periódicamente sobre el comportamiento de tus pagos retrasados a 
              instituciones que almacenan información crediticia, y esto puede afectar a futuras aplicaciones a financiamientos.          
            </p>
          </p>
        </Accordion>

        <Accordion title="¿El pago anticipado afecta mi cuenta?" className="help-item">
          <p className="help-text">
            <p>
              No, no afecta. Puedes pagar todas tus cuotas por adelantado, pero cada pago debe ser una cuota a la vez. De esta manera, 
              siempre sabrás cuánto está pendiente y mantener el control de su dinero. Con el tiempo, nuestro sistema conocerá su capacidad 
              de pago y con cada pago que realices a tiempo te ayudará a aumentar tus límites de gasto autorizados.
            </p>
            <p>
              Recuerda, que si no deseas iniciar sesión en tu cuenta Slightpay para pagar tus cuotas, nuestro sistema cobrará automáticamente 
              el dinero de la tarjeta ocuenta registrada, en la fecha de vencimiento. Solo debes asegurarte que tu cuenta o tarjeta cuente 
              con el dinero suficiente disponible para cubrir esa cuota.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Cuándo se realizará mi primer pago?" className="help-item">
          <p className="help-text">
            <p>
              El pago de la primera cuota, se genera en el momento que realices tu compra. El pago es parte del proceso de verificación 
              para que tu compra sea autorizada. 
            </p>

            <p>
              Este pago será equivalente al 25% del valor total del pedido. 
            </p>

            <p>
              Antes de confirmar tu pedido, verás el plan de pagos claramente.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Por qué me han cobrado un Cargo por Mora?" className="help-item">
          <p className="help-text">
            <p>
              Se ha generado un Cargo por Mora debido a que te retrasaste en uno de tus pagos programados.
              Puedes realizar el pago correspondiente iniciando sesión en su cuenta o siguiendo el enlace en uno 
              de los mensajes de notificación que recibiste por parte de Slightpay.
            </p>

            <p>
              Si te enfrentas a dificultades financieras y te preocupa no poder realizar sus próximos pagos, escríbenos aquí. 
              (link pagos@slightpay.com)
            </p>

            <p>
              Queremos que te pongas al corriente lo antes posible y, desde luego, no queremos que tengas más problemas financieros. 
              Por eso, si tienes un pago vencido, no podrás comprar nada más con Slightpay hasta que tu cuenta se regularice.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Qué pasa si mi tarjeta se pierde o es robada?" className="help-item">
          <p className="help-text">
            <p>
              En primer lugar, podemos ayudarte.
            </p>

            <p>
              Si debes realizar un pago con nosotros, podemos programar el cobro automático de cualquier tarjeta alternativa almacenada en 
              tu cuenta. Si tu tarjeta perdida o robada es la única en nuestro sistema pero cuentas con otra tarjeta, puedes agregarla y 
              realizar tu pago pendiente de esa.
            </p>

            <p>
              Puedes actualizar tus detalles de pago en línea iniciando sesión en "Mi cuenta" y luego 
              "Agregar método de pago" en la sección "Mi cartera"
            </p>

            <p>
              Puedes seleccionar como "Tarjeta Preferida" si deseas usar esta para futuras transacciones. Alternativamente, puede hacer 
              clic en "Pagar Cuota". Una vez que hayas recuperado tu tarjeta o hayas recibido una nueva, puedes actualizar los detalles 
              en tu Cuenta de Slightpay, siguiendo las instrucciones anteriores.
            </p>

            <p>
              Si está por llegar una cuota pero no tienes ninguna forma de pago alternativa, pónte en contacto con nosotros aquí 
              (pagos@slightpay.com) e intentaremos resolver algo.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Cuentan con sistema de pagos automáticos?" className="help-item">
          <p className="help-text">
            <p>
              Para facilitar el apego a tu plan de pagos, Slightpay ofrece pagos automáticos.
            </p>

            <p>
              Esto significa que se realiza automáticamente el cargo del próximo pago de tu tarjeta preferida en la fecha definida en 
              el Plan de Pagos. Siempre te enviaremos recordatorios de pago antes de la fecha de vencimiento de la cuota, por lo que 
              te recomendamos asegurar de que haya suficiente saldo en tu cuenta o tarjeta para cubrir esa cuota, nosotros haremos el resto.

            </p>

            <p>
              Si un pago automático falla, trataremos de cobrar esa cuota de cualquier otra tarjeta que puedas tener registrada en tu cuenta. 
              Si aún así, no podemos cobrar o si no tienes otras tarjetas guardadas en tu cuenta, nos comunicaremos contigo de inmediato y 
              tendrás hasta las 17:00 hrs del día siguiente para realizar el pago.
            </p>

            <p>
              Si tu pago aún está pendiente, se cobrará un Cargo por Mora de $100, lo cual no nos gusta. Así que e preocupa no poder hacer 
              un pago, escríbanos aquí (pagos@slightpay.com) e intentaremos ordenar algo. 
            </p>

            <p>
              Alternativamente, siempre puedes hacer los pagos manualmente a tiempo o antes, haciendo clic en el botón PAGAR Cuota en tu 
              cuenta.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Qué hago si falla el pago automático?" className="help-item">
          <p className="help-text">
        
            <p>
              Estarás recibiendo un SMS y un correo electrónico para informarte que un pago automático ha fallado. Sabemos lo ocupado que 
              puedes estar, por lo que siempre tendrás hasta las 17:00 horas del día siguiente para realizar un pago atrasado antes de 
              recibir un Cargo por Mora.
            </p>

            <p>
              Para realizar un pago vencido, puedes iniciar sesión en tu cuenta y hacer clic en PAGAR Cuota junto a tu "pago vencido" en 
              tu "calendario de pagos"; simplemente sigue las indicaciones. Si te preocupa que no podrás hacer un pago, contáctanos lo 
              antes posible e intentaremos resolverlo.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Como puedo pagar?" className="help-item">
          <p className="help-text">
            <p>
              Slightpay acepta pagos con tarjetas de débito Mastercard o Visa y de crédito Mastercard, Visa y AMEX que se hayan emitido 
              en México.

            </p>

            <p>
              Hay dos formas básicas de pagar con estas tarjetas:<br></br>
              Lo más conveniente es nuestro sistema de pagos automáticos. Simplemente ingrese a tu cuenta y "Agrega un método de pago" 
              en la sección de "Mi Cartera"

            </p>

            <p>
              Cuando tengas que pagar una cuota, te estaremos notificando para que puedas asegurarte de que haya suficiente saldo en 
              tu cuenta para cubrirla. Alternativamente, siempre puedes hacer los pagos a tiempo o antes seleccionando el botón PAGAR 
              Cuota desde tu cuenta. 
            </p>

            <p>
              Es importante tener en cuenta que no podemos aceptar pagos con transferencia bancaria, o prepago.
            </p>
          </p>
        </Accordion>
        
        <Accordion title="¿Por qué veo 2 pagos en mi cuenta?" className="help-item">
          <p className="help-text">
            <p>
              En ocasiones, realizamos una verificación de autorización previa en la que retenemos temporalmente una pequeña cantidad de 
              tu cuenta, hasta el valor de tu primer pago (más 1 centavo) para compras en línea y hasta el 25% (más 1 centavo) del valor 
              del código de barras en la tienda para compras en la tienda para asegurarnos de que tu tarjeta nominada funciona correctamente.
            </p>

            <p>
              Una vez que se aprueba este cargo, le indicamos al banco que anule la transacción. A veces puede aparecer en tu cuenta como 
              un segundo pago.
            </p>

            <p>
              No te preocupes, no es un segundo pago y se resolverá en tu cuenta en breve, si tienes algún problema, contáctenos aquí.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Qué tarjetas aceptan?" className="help-item">
          <p className="help-text">
            <p>
              Slightpay acepta tarjetas de crédito y débito Mastercard, Visa y AMEX emitidas en México. No aceptamos tarjetas de prepago ni 
              tarjetas extranjeras.
            </p>

            <p>
              Es importante tener en cuenta que no aceptamos pagos mediante transferencias bancarias de pagos SPEI.
            </p>
          </p>
        </Accordion>
      </div>
    </div>
  );
};

export default Pagos;