import styled from 'styled-components/macro';

const UpdatePhoneContainer = styled.div`
    display: flex;
    align-items: center; 
    justify-content: center; 
    min-height: calc(100vh - 80px); 

    .update-phone-wrapper {
        max-width: 443px;
        width: 100%; 
    }

    .update-phone-text {
        font-family: "Gilroy";
        font-size: 16px;
        line-height: 24px;
        color: #01030C;
        margin-bottom: 25px; 
    }

    .update-phone-form {
        width: 100%; 
        margin-bottom: 53px; 
    }

    .update-phone-row {
        display: flex; 
        align-items: flex-end; 
        justify-content: center; 
    }

    .prefix {
        width: calc((50% - 20px) / 2);
        margin-right: 20px; 
    }

    .phone {
        width: calc((150% - 20px) / 2);
    }

    .update-phone-buttons {
        display: flex; 
        align-items: center; 
        justify-content: center; 

        .btn-cancel {
            width: calc((100% - 20px) / 2);
            margin-right: 20px; 
        }

        .btn-confirm {
            width: calc((100% - 20px) / 2);
        }
    }
`

export default UpdatePhoneContainer; 