import styled from 'styled-components/macro';

const AttachInputContainer = styled.div`
  /* position: relative; */
  width: 100%;
  height: 144px;

  &.error {
    #canvas {
      background-color: #faebf1;

      &:hover {
        background-color: #faebf1;
      }

      &:focus {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        outline: 0;
      }

      .icon-text {
        color: #e49cbb;
      }
    }

    small {
      color: #e49cbb;
    }
  }

  &.disabled {
  }

  &.complete {
  }

  &.normal {
    #canvas {
      background-color: #f2f2f3;

      &:hover {
        background-color: #e2e2e2;
      }

      &:focus {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        outline: 0;
      }

      .icon-text {
        color: #b3b3b6;
      }
    }

    small {
      color: #1a4ccd;
    }
  }

  label {
    padding-bottom: 10px;
    font-size: 12px;
    color: #b3b3b6;
    letter-spacing: 1px;
  }

  #canvas {
    position: relative;
    background-color: #f2f2f3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color ease 0.3s;

    .thumnail {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      img {
        opacity: 0.2;
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      background-color: #e2e2e2;
    }

    &:focus {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      outline: 0;
    }

    .icon-img-container {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      z-index: 2;
    }

    .icon-text {
      color: #b3b3b6;
      font-size: 14px;
    }

    input {
      display: none;
      opacity: 0;
    }

    img {
      width: 50px;
    }
  }

  .download_button {
    margin-top: 20px;
    border: 0;
    background-color: transparent;
    color: blue;
  }

  small {
    font-size: 12px;
    font-weight: 200;
    margin-top: 40px;
  }
`;

export default AttachInputContainer;
