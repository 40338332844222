import styled from 'styled-components/macro';

const UpdateAddressContainer = styled.div`
    width: 100%;    
    display: flex; 
    align-items: center; 
    justify-content: center; 
    flex-direction: column; 
    /* min-height: 100vh;  */
    background: #F2F2F3;
    padding-bottom: 80px;
    padding-top: 80px;
 
    .credit-card-duplicated{
        background: rgba(255,0,0,0.5);
        color: white;
        padding: 5px;
        margin-bottom: 15px;
        margin-top: 10px;
        text-align: center;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
      }

    .update-address-wrapper {
        max-width: 443px;
        width: 100%; 
    }

    .update-address-title {
        width: 100%;
        font-family: "Gilroy";
        font-size: 20px;
        line-height: 26px;
        color: #01030C;
        margin-bottom: 20px; 

        span {
            color: #1A4CCD;
        }
    }

    .update-address-form {

    }

    .update-address-row {
        display: flex; 
        align-items: flex-end; 
    }

    .update-address-col {
        width: 100%;
        margin-right: 20px; 

        &:last-child {
            margin-right: 0;
        }

        &.col-2 {
            width: calc((100% - 20px) / 2);
        }
    }

    .update-address-buttons {
        display: flex; 
        align-items: center; 
        justify-content: center; 

        .btn-cancel {
            width: calc((100% - 20px) / 2);
            margin-right: 20px; 
        }

        .btn-confirm {
            width: calc((100% - 20px) / 2);
        }
    }

    @media(max-width: 650px) {
        .update-address-wrapper {
            width: 90%; 
        }

        .update-address-row {
            flex-direction: column; 
        }

        .update-address-col {
            margin-right: 0; 

            &.col-2 {
                width: 100%; 
                flex-direction: column;  
                margin-bottom: 35px;

            }
        }

    }
    
`   

export default UpdateAddressContainer; 