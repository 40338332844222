import { IAuthState } from './types';

export const authSelectors = {
  getMe: () => (state: { auth: IAuthState }) => state.auth.user,
  getLoginError: () => (state: { auth: IAuthState }) => state.auth.loggingError,
  getEmail: () => (state: { auth: IAuthState }) => state.auth.registerForm?.email,
  getErrorEmail: () => (state: { auth: IAuthState }) => state.auth.checkEmailError,
  getIneStatus: () => (state: { auth: IAuthState }) => state.auth.registerForm?.legalData?.ine,
  getIneModalStatus: () => (state: { auth: IAuthState }) => state.auth.registerForm?.ineModal?.isOpen,
  getRegisterFormBasicData: () => (state: { auth: IAuthState }) => state.auth.registerForm?.basicData,
  getRegisterFormLegalData: () => (state: { auth: IAuthState }) => state.auth.registerForm?.legalData,
  // getRegisterFormExtraData: () => (state: { auth: IAuthState }) => state.auth.registerForm?.extraData,
  getRegisterFormComercialData: () => (state: { auth: IAuthState }) => state.auth.registerForm?.comercialData,
  getCurrentJWT: () => (state: { auth: IAuthState }) => state.auth.token,
  getRegisterError: () => (state: { auth: IAuthState }) => state.auth.registerError,
  getUserCommerce: () => (state: { auth: IAuthState }) => state.auth.user?.commerce,
  getPassword:() => (state: { auth: IAuthState }) => state.auth.registerForm?.basicData?.password,
  getCurrentUserEmailStatus: () => (state: { auth: IAuthState }) => state.auth.user?.emailVerified,
  getScoringStatus: () => (state: { auth: IAuthState }) => state.auth.scoringResult, 
};