import jwt_decode from 'jwt-decode';
import { IFile } from '@aluxion-lightpay/interfaces';

interface IJWT {
  id: number;
  iat: number;
  exp: number;
}

export interface IPropsAnswers {
  onClick: () => void;
}

const transformFile = async (url: string, name: string, type: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], name, {
    type,
  });
  return file;
};

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const urlToFile = (dataurl: string, filename: any) => {
  const arr = dataurl.split(',');
  const expMatch = arr[0].match(/:(.*?);/);
  const mime = expMatch ? expMatch[1] : '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const isExpiredJWT = (jwt: string) => {
  if (!jwt) return true;
  const decodedJWT = jwt_decode(jwt) as IJWT;

  const expiredSeconds = decodedJWT.exp * 1000; // Transform seconds to miliseconds
  const expiredDate = new Date(expiredSeconds);

  return expiredDate < new Date();
};

const formatMoney = (num:number) => {
  return Number(num).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })
}

const instanceOfA = (object: any): object is IFile => {
  return true;
};

const translatePurchasePartitionState = (status: string) => {
  let translatedStatus
  
  switch (status) {
    case 'DRAFT':
      translatedStatus = 'PREPARANDO';
      break;
    case 'SUCCESSFUL':
      translatedStatus = 'COMPLETADA';
      break;
    case 'CANCELED':
      translatedStatus = 'CANCELADO';
      break;
    case 'CANCELED_PENDING':
      translatedStatus = 'CANCELADO PENDIENTE';
      break;
    case 'EXPIRED':
      translatedStatus = 'EXPIRADA';
      break;
    case 'PENDING':
      translatedStatus = 'EN PROCESO';
      break;
    case 'CHARGEBACK_REJECTED':
      translatedStatus = 'CONTRACARGO RECHAZADO';
      break;
    case 'CHARGEBACK_ACCEPTED':
      translatedStatus = 'CONTRACARGO ACEPTADO';
      break;
    case 'REFUNDED_TOTALLY':
      translatedStatus = 'DEVOLUCIÓN TOTAL';
      break;
    case 'REFUNDED_PARTIALLY':
      translatedStatus = 'DEVOLUCIÓN PARCIAL';
      break;
    default:
      break;
  }

  return translatedStatus;
}

export { transformFile, toBase64, urlToFile, isExpiredJWT, formatMoney, instanceOfA, translatePurchasePartitionState };  

