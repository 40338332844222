import React from 'react'
import { useHistory } from 'react-router-dom'
import CardBasicAsideContainer from './card-basic-aside-style'
import { CardBasicAsideProps } from './card-basic-aside-types'

const CardBasicAside: React.FC<CardBasicAsideProps> = (
  props: CardBasicAsideProps
) => {
  const history = useHistory()
  const { icon, title, description, url } = props

  const handleUrl = () => {
    history.push(url ? url : "")
  }

  return (
    <CardBasicAsideContainer className="card" onClick={handleUrl}>
      <div className="card-basic-aside-icon">
        <img src={icon} alt="Card Icon" />
      </div>

      <div className="card-basic-aside-content">
        <div className="card-basic-aside-title">
          <h3>{title}</h3>
        </div>

        <div className="card-basic-aside-description">
          <p>{description}</p>
        </div>
      </div>
    </CardBasicAsideContainer>
  )
}

export default CardBasicAside
