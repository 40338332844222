import styled from 'styled-components/macro';

const TableSimpleContainer = styled.div`
  table {
    width: 100%;
    table-layout: fixed;

    thead,
    tr {
      td {
        text-align: left;
        word-wrap: break-word;
        vertical-align: middle;
      }
    }
  }

  &.minimalLight {
    table {
      border-collapse: separate;
      border-spacing: 0 20px;

      tr {
        td,
        th {
          font-size: 16px;
          color: #01030c;
          background: #fff;
          font-weight: 100;
          padding: 20px;
          text-align: left;

          &:first-child {
            padding-left: 40px;
          }

          &:last-child {
            padding-right: 40px;
            text-align: right;
          }
        }
      }

      tr th {
        color: #01030c;
        font-weight: normal;
      }
    }

    .wrapper-icons {
      display: flex;
      justify-content: flex-end;
    }
  }

  &.simple {
    table {
      display: flex;
      flex-direction: column;

      tr {
        display: flex;
        flex-direction: wrap;

        td,
        th {
          font-size: 14px;
          line-height: 24px;
          color: #b3b3b6;
          padding: 4px;
          text-align: right;

          &:first-child {
            flex-grow: 1;
            text-align: left;
          }
        }

        &:last-child td {
          color: #34353d;
          font-size: 16px;
        }
      }
    }
  }
`;

export default TableSimpleContainer;
