// NEW USER'S CARD  
import { isValidCard, isValidNumber, isValidName, isValidSelect } from "../../../utils/validation";

export const  CARD_NUMBER_INPUT_NAME = 'cardNumber';
export const CARD_CVV_INPUT_INPUT = 'cvv2';
export const CARD_EXP_MONTH_INPUT = 'expirationMonth';
export const CARD_EXP_YEAR_INPUT = 'expirationYear';
export const CARD_HOLDERNAME_INPUT = 'holderName'
export const CARD_ADDRESS_CITY_INPUT = 'city';
export const CARD_ADDRESS_PC_INPUT = 'postalCode';
export const CARD_ADDRESS_STATE_INPUT = 'state'
export const CARD_ADDRESS_LINE1_INPUT = 'line1';
export const CARD_ADDRESS_LINE2_INPUT = 'line2';
export const CARD_ADDRESS_LINE3_INPUT = 'line3';

export const getNewCardInputMetadata = (inputName: string, extraValidation: any = {}, isItMX: boolean = false ) => {
  const inputsArray: any = {
    [CARD_NUMBER_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidCard: (value: any) => isValidCard(value),
      },
    },
    [CARD_CVV_INPUT_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: isItMX ? 4 : 3, message: isItMX ? 'too-short-cvv-mx' : 'too-short-cvv' },
      maxLength: { value: 4, message: 'too-short-cvv' },
      validate: {
        isValidNumber: (value: any) => isValidNumber(value),
      },
    },
    [CARD_EXP_MONTH_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: 2, message: 'too-short-month' },
      validate: extraValidation,
    },
    [CARD_EXP_YEAR_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: 2, message: 'too-short-month' },
      validate: extraValidation,
    },
    [CARD_HOLDERNAME_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-name' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [CARD_ADDRESS_CITY_INPUT]: {
      required: { value: true, message: 'required' },
      minLength: { value: 3, message: 'too-short-name' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [CARD_ADDRESS_PC_INPUT]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidNumber: (value: any) => isValidNumber(value),
      },
    },
    [CARD_ADDRESS_STATE_INPUT]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidSelect: (value: any) => isValidSelect(value),
      },
    },
    [CARD_ADDRESS_LINE1_INPUT]: {
      required: { value: true, message: 'required' },
    },
    [CARD_ADDRESS_LINE2_INPUT]: {
    },
    [CARD_ADDRESS_LINE3_INPUT]: {
    },
  };
  return inputsArray[inputName];
};