import cn from 'classnames';
import React, { Component } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { MainTableContainer } from './main-table-style';
import { BodyText } from '../components-text/body-text/body-text';
import TableAvatar from './main-table-components/avatar/table-avatar';
import NoContentTable from '../no-content-table/no-content-table';

interface IMainTableProps extends RouteComponentProps {
  rows: any[];
  columns: any[];
  orderBy?: (column: string, active: boolean) => void;
  keysRow: string[];
  rowKeyLink?: string;
  rowLink?: string;
  className?: string;
  emptyStateMessage?: string;
  fixedLeft?: boolean;
  fixedRight?: boolean;
  fixedBoth?: boolean;
  noContentText?: string;
}

interface IMainTableState {
  activeColumnHead: IActiveObject;
}

interface IActiveObject {
  [key: string]: any;
}

class MainTable extends Component<IMainTableProps, IMainTableState> {
  public state: IMainTableState = {
    activeColumnHead: {},
  };

  public handleClickOnColumnHead(key: string) {
    const { orderBy } = this.props;
    const { activeColumnHead } = this.state;

    if (activeColumnHead[key] === true) {
      activeColumnHead[key] = undefined;
    } else if (activeColumnHead[key] === false) {
      activeColumnHead[key] = true;
    } else {
      activeColumnHead[key] = false;
    }
    if (orderBy) {
      orderBy(key, activeColumnHead[key]);
    }
    this.setState({ activeColumnHead });
  }
  public renderCellTable(item: any) {
    switch (item.key) {
      case 'highlighted':
        return <p className="start-row">{item.text}</p>;
      case 'email':
        return <a href={`mailto:${item.text}`}>{item.text}</a>;
      case 'phone':
        return <a href={`tel:${item.text}`}>{item.text}</a>;
      case 'avatar':
        return <TableAvatar img={item.img} />;
      case 'date':
        return (
          <div>
            <p>
              {item.text}
              <span>{item.span}</span>
            </p>
          </div>
        );
      case 'icon':
        return (
          <div className="start-row" onClick={item.click ? item.click : ()=>{}}>
            {item.img}
            {item.text ? <span>{item.text}</span> : ''}
          </div>
        );
      case 'icon-right':
        return (
          <div className="start-row" onClick={item.click ? item.click : () =>{}}>
            {item.text ? <span>{item.text}</span> : ''}
            {item.img}
          </div>
        );
      case 'profile':
        return (
          <div className="profile">
            <TableAvatar img={item.img} />
            <BodyText textType="p-14">
              <p>{item.text}</p>
            </BodyText>
          </div>
        );
      case 'action':
        return (
          <div className="profile">
            <div className="profile__action">
                {item.text}
                <span onClick={item.click ? item.click : ''}>{item.span}</span>
            </div>
          </div>
        );
      case 'payment':
        return (
          <div className="payments">
            <BodyText textType="p-14">
              <p>{item.text}</p>
              <span>{item.span}</span>
            </BodyText>            
          </div>
        );
      default:
        return (
          <BodyText textType="p-14">
            <p>{item.text}</p>
          </BodyText>
        );
    }
  }

  public renderHeadTable(item: any) {
    switch (item.key) {
      case 'highlighted':
        return (
          <BodyText textType="p-16b">
            <p>{item.text}</p>
          </BodyText>
        );
      case 'break':
        return (
          <div>
            <p>
              {item.text}
              <span>{item.span}</span>
            </p>
          </div>
        );
      default:
        return <p>{item.text}</p>;
    }
  }

  public render() {
    const {
      rows,
      columns,
      keysRow,
      rowLink,
      rowKeyLink,
      className,
      fixedLeft,
      fixedRight,
      fixedBoth,
      noContentText,
      // emptyStateMessage,
    } = this.props;
    const { activeColumnHead } = this.state;
    // const emptyMessage =
    //   emptyStateMessage ||
    //   "¡Oops! No hemos encontrado ningún resultado, prueba con otra búsqueda";

    const mainTableContainerClass: any = {
      'fixed-left': fixedLeft,
      'fixed-right': fixedRight,
      'fixed-booth': fixedBoth,
    };

    if (className) {
      mainTableContainerClass[className] = className;
    }

    return (
      <>
        {rows.length < 1 ? (
          <NoContentTable text={noContentText || 'No hay resultados'} />
        ) : (
          <MainTableContainer className={cn(mainTableContainerClass)}>
            <div className="main-table-column left">
              <div className="main-table-column-head">{this.renderHeadTable(columns[0])}</div>
              <div className="main-table-column-scroll">
                {rows.map((row, index) => (
                  <div className="main-table-column-row" key={`row-${index}`}>
                    {this.renderCellTable(row[keysRow[0]])}
                  </div>
                ))}
              </div>
            </div>
            <div className="main-table-wrapper-scroll">
              <table>
                <tbody>
                  <tr>
                    <td className="main-table-wrapper-reset">
                      <table className="main-table-head">
                        <tbody>
                          <tr>
                            {columns.map((column, index) => (
                              <th
                                key={`column-${index}`}
                                onClick={() => column.filter && this.handleClickOnColumnHead(keysRow[index])}
                                className={`
                                  ${column.filter ? 'pointer' : ''}
                                  ${columns[index] && columns[index].className} 
                                  ${
                                    column.filter && activeColumnHead[keysRow[index]] !== undefined
                                      ? `filter ${activeColumnHead[keysRow[index]] ? 'active' : ''}`
                                      : ''
                                  }
                                  `}
                              >
                                {this.renderHeadTable(column)}
                              </th>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                <tr>
                  <td>
                     <div className="main-table-wrapper-scroll-vertical">
                      {/* <div className="main-table-body"> */}
                      {/* <Scrollbars
                            renderTrackHorizontal={propsTrackHorizontal => (
                              <div
                                {...propsTrackHorizontal}
                                className="track-horizontal"
                              />
                            )}
                            renderThumbHorizontal={propsThumbHorizontal => (
                              <div
                                {...propsThumbHorizontal}
                                className="thumb-horizontal"
                              />
                            )}
                            style={{ height: "100%" }}
                            autoHide={false}
                            autoHideTimeout={200}
                            autoHideDuration={200}
                            autoHeight={true}
                            autoHeightMin={60}
                            autoHeightMax={400}
                            hideTracksWhenNotNeeded={true}
                          > */}
                      <table>
                        <tbody>
                          {rows.map((row, index) => (
                            <tr
                              className="main-table-row"
                              key={`row-${index}`}
                            >
                              {keysRow.map((key, indexKey) => (
                                <td
                                  key={`td-${indexKey}`}
                                  className={`main-table-row-cell ${row[key] && row[key].className}`}
                                  onClick={() => {
                                    row.onClick && row[key].key !== 'action' && row.onClick();
                                    row[key].key !== 'action' && this.props.history.push(row.link);
                                  }}
                                >
                                  {rowLink && row[key].key !== 'link' && row[key].key !== 'button' ? (
                                    <NavLink to={`${rowLink}${rowKeyLink ? `/${row[rowKeyLink].text}` : ''}`}>
                                      {this.renderCellTable(row[key])}
                                    </NavLink>
                                  ) : (
                                    this.renderCellTable(row[key])
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* </Scrollbars> */}
                      </div>  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="main-table-column right">
              <div className="main-table-column-head">{this.renderHeadTable(columns[columns.length - 1])}</div>
              <div className="main-table-column-scroll">
                {rows.map((row, index) => (
                  <div className="main-table-column-row" key={`row-${index}`}>
                    {this.renderCellTable(row[keysRow[keysRow.length - 1]])}
                  </div>
                ))}
              </div>
            </div>
          </MainTableContainer>
        )}
      </>
    );
  }
}

export default withRouter(MainTable);
