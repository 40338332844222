import styled from 'styled-components/macro';

const ProfileAsideRespContainer = styled.nav`
    

    .profile-aside-resp-list {
        z-index: 2; 
        width: 100%; 
        position: fixed; 
        top: 60px;
        left: 0;         
        background: #01030C;     
        max-height: 52px; 
        overflow: hidden; 
        transition: 0.5s all ease-in-out;  

        &.active {
            max-height: 154px; 
        }

    }

    .profile-aside-resp-element {
        display: flex; 
        align-items: center; 
        justify-content: flex-start;
        padding-left: 25px; 
        padding-right: 30px; 
        height: 52px; 
        cursor: pointer; 

        .element-text {
            font-family: "Gilroy";
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #808185;
        }
        
        &.active-element {
            cursor: text;
            justify-content: space-between;

            .element-text {
                color: #fff;
            }

            .element-icon {
                cursor: pointer; 
                width: 12px; 
                height: 12px; 
                display: flex;
                align-items: center;

                .less-element {
                    height: 2px; 
                    width: 12px; 
                    background: #fff;
                    transition: 0.5s all ease-in-out;
                }

                img {
                    width: 100%; 
                    height: auto; 
                    transition: 0.5s all ease-in-out;

                }
            }
        }

        
    }
`;

export default ProfileAsideRespContainer; 