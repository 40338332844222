import React from 'react'
import { Link } from 'react-router-dom'

//Components
import MyWalletWithoutCardContainer from './my-wallet-without-card-style'
import MainButton from '../../../../components/main-button/main-button'

//Constant
import { images } from '../../../../assets/images/images'

const MyWalletWithoutCard: React.FC<any> = (props: any) => {
  return (
    <MyWalletWithoutCardContainer>
      <div id="my-wallet">
        <div className="image-container">
          <img src={images.myWalletNoCard} alt="no card" />
        </div>
        <div className="text-container">
          <p>
            <b>¡Oops... sin una tarjeta vinculada</b> no podrás disfrutar de los
            beneficios de comprar en línea con Slightpay!
          </p>
        </div>
        <div className="button-container">
          <Link to="/mi-cartera/agregar-tarjeta">
            <MainButton type="primary" text="Vincular nueva tarjeta" />
          </Link>
        </div>
      </div>
    </MyWalletWithoutCardContainer>
  )
}

export default MyWalletWithoutCard
