import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { SERVICE_URL } from '../constants/env';
import { store } from '..';
import { toastActions } from './toasts/actions';
import { authActions } from './auth/actions';
import { handleReasonsError } from '../utils/errorHandler';

export interface IFormatError {
  message: string;
  statusCode?: number;
  code?: string;
  reasons?: string[];
}

export const CURRENT_PLATFORM = 'pagador';
class Api {
  private req: AxiosInstance;

  constructor() {
    this.req = axios.create({ baseURL: SERVICE_URL.url, timeout: 300000 });
  }

  public get<T>(url: string, params: AxiosRequestConfig = {}): Promise<T> {
    return this.req.get(url, params);
  }

  public post<T>(url: string, body: any, params: AxiosRequestConfig = {}): Promise<T> {
    return this.req.post(url, body, params);
  }

  public put<T>(url: string, body: any, params: AxiosRequestConfig = {}): Promise<T> {
    return this.req.put(url, body, params);
  }

  public patch<T>(url: string, body: any, params: AxiosRequestConfig = {}): Promise<T> {
    return this.req.patch(url, body, params);
  }

  public delete<T>(url: string, config: AxiosRequestConfig = {}): Promise<T> {
    return this.req.delete(url, config);
  }

  public setJWT(jwt: string) {
    this.req.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  }

  public setCookie(cookie: any) {
    this.req.defaults.headers.common.Cookie = cookie;
  }

  public errorHandler(error: any, type?:'LOGIN' | 'FORGOT' | 'CHECK' | 'REGISTER'): IFormatError {
    if (error.response) {
      console.log(error, 'el error que obtuve...')
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      type ? store.dispatch<any>(
        toastActions.error( authActions.handleCustomErrors(type , error.response.data.code)  || 'Ocurrió un error inesperado. Intente más tarde.'),
      ):
        handleReasonsError(error.response.data)
      return {
        message: error.response.data.message,
        statusCode: error.response.data.statusCode,
        code: error.response.data.code,
        reasons: error.response.data.reasons,
      };
    }else if(error.message === 'EMAIL_EXIST' || 'RFC_EXIST' || 'EMAIL_NOT_VERIFIED' || 'EMAIL_NO_VALID' || 'RFC_NO_VALID' ) {
      if(type){
        store.dispatch<any>(
          toastActions.error( authActions.handleCustomErrors(type , error.message)  || 'Ocurrió un error inesperado. Intente más tarde.'),
        )
      }

    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      return {
        message: error.request.statusText,
      };
    }
    // Something happened in setting up the request and triggered an Error
    return {
      message: error.message,
    };
  }
}

export const LightpayApi = new Api();
