import styled from 'styled-components';

const CardStateContainer = styled.div`
  display: flex;
  padding: 32px 30px 42px;
  background: white;
  box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
  border-radius: 3px;

  /* ACEPT STATE  */

  /* .card-state-img{
    width:59px;
    height: 59px;
    border-radius: 100%;
    margin-right: 19px;
    background: #4FB2C6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-state-icon-aproved{
    width: 40px;
  }

  .card-state-info{
      .card-state-title{
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #B3B3B6;
      font-family: Gilroy;
      
    }

    .card-state-text{
      font-size: 24px;
      line-height: 28px;
      color: #4FB2C6;
      font-family: Gilroy;
      font-weight: 400;
    }
  } */

  /* DENIED STATE */

  /* .card-state-img{
    width:59px;
    height: 59px;
    border-radius: 100%;
    margin-right: 19px;
    background: #CA3976;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-state-icon-aproved{
    width: 40px;
  }

  .card-state-info{
      .card-state-title{
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #B3B3B6;
      font-family: Gilroy;
      
    }

    .card-state-text{
      font-size: 24px;
      line-height: 28px;
      color: #CA3976;
      font-family: Gilroy;
      font-weight: 400;
    }
  }  */

  /* RETURN STATE */

  /* .card-state-img{
    width:59px;
    height: 59px;
    border-radius: 100%;
    margin-right: 19px;
    background: #8CA5E6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-state-icon-aproved{
    width: 40px;
  }

  .card-state-info{
      .card-state-title{
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #B3B3B6;
      font-family: Gilroy;
      
    }

    .card-state-text{
      font-size: 24px;
      line-height: 28px;
      color: #8CA5E6;
      font-family: Gilroy;
      font-weight: 400;
    }
  }  */

  /* CHARGUEBACK STATE */

  .card-state-img {
    width: 59px;
    height: 59px;
    min-width: 59px;
    min-height: 59px;
    border-radius: 100%;
    margin-right: 19px;
    background: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    .card-state-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.Aproved {
      background: #4fb2c6;
    }

    &.Denied {
      background: #ca3976;
    }

    &.Return {
      background: #8ca5e6;
    }

    &.Waiting {
      background: #ffbb36;
    }

    &.Prepending {
      background: #daf9ff;
    }
  }

  .card-state-icon-aproved {
    width: 40px;
  }

  .card-state-info {
    .card-state-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #b3b3b6;
      font-family: Gilroy;
    }

    .card-state-text {
      font-size: 24px;
      line-height: 28px;
      color: #b3b3b6;
      font-family: Gilroy;
      font-weight: 400;

      &.Aproved {
        color: #4fb2c6;
      }

      &.Denied {
        color: #ca3976;
      }

      &.Return {
        color: #8ca5e6;
      }

      &.Waiting {
        color: #ffbb36;
      }

      &.Prepending {
        color: #11c6ffb8;;
      }
    }
  }
`;

export default CardStateContainer;
