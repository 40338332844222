import styled from 'styled-components';

const LoaderContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.99);
  color: #424242;
  z-index: 99999999;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-animation {
    width: 180px;
    height: 180px;
    position: absolute;

    .big-letter-container {
      position: relative;
      top: -110px;
      left: 4px;
      text-align: center;

      .big-letter {
        font-size: 22px;
        position: relative;
        font-weight: bold;
        text-align: center;
        &.up {
          top: -3px;
        }
        &.down {
          top: 3px;
        }
      }
    }
  }

  .loader-animation:before {
    content: '';
    display: block;
    width: 164px;
    height: 164px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #1a4ccd;
    border-color: #1a4ccd transparent #1a4ccd transparent;
    animation: loader-animation 1.3s linear infinite;
  }

  @keyframes loader-animation {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.4;
    }
    100% {
      transform: rotate(360deg);
      opacity: 1;
    }
  }
`;

export default LoaderContainer;
