import { images } from '../assets/images/images'
export const dataCommerce = [
  {
    img: images.twoFeet,
    alt: "Opciones para comprar en línea sin TDC",
    title: "Cómo pagar sin tarjeta de crédito",
    textImg: "Two Feet Under",
    linkSite: "https://twofeetundr.com/"
  },
      {
        img: images.alanika,
        alt: "Plataforma de pagos para compras en línea",
        title: "Compras en línea sin tarjeta de crédito",
        textImg: "Alanika",
        linkSite: "https://www.alanika.com.mx/"
      },
      {
        img: images.millie,
        alt: "Opciones para comprar en línea sin TDC",
        title: "Cómo pagar sin tarjeta de crédito",
        textImg: "Millié Jewelry",
        linkSite: "https://www.milliejewelry.com?utm_source=slightpay"
      },
      {
        img: images.monasita,
        alt: "Alternativas para pagar online",
        title: "Páginas para comprar en línea sin tarjeta de crédito",
        textImg: "Monasita",
        linkSite: "https://monasita.com.mx/"
      },
      {
        img: images.arteCuadrado,
        alt: "Alternativas para pagar online",
        title: "Páginas para comprar en línea sin tarjeta de crédito",
        textImg: "Arte Cuadrado",
        linkSite: "https://artecuadradomx.com/?v=cb211d643684"
      },
      {
        img: images.boh,
        alt: "Opciones para comprar en línea sin TDC",
        title: "Cómo pagar sin tarjeta de crédito",
        textImg: "Boh Shoes",
        linkSite: "https://bohshoes.com/"
      },
 

      {
        img: images.that,
        alt: "Alternativas para pagar online",
        title: "Páginas para comprar en línea sin tarjeta de crédito",
        textImg: "That Style",
        linkSite: "https://thatstyle.mx/"
      },
      {
        img: images.baker,
        alt: "Plataforma de pagos para compras en línea",
        title: "Compras en línea sin tarjeta de crédito",
        textImg: "3325 Bakery House",
        linkSite: "https://3325bakeryhouse.com/"
      },
      {
        img: images.terra,
        alt: "Alternativas para pagar online",
        title: "Páginas para comprar en línea sin tarjeta de crédito",
        textImg: "Terra Refill",
        linkSite: "https://terrarefill.com/"
      },
      {
        img: images.amorpropio,
        alt: "Pagar a cuotas compras en línea",
        title: "Comprar en internet sin TDC",
        textImg: "Amor propio",
        linkSite: "https://amorpropiomx.com/"
      },
      {
        img: images.rhinostore,
        alt: "Pagar a cuotas compras en línea",
        title: "Comprar en internet sin TDC",
        textImg: "Rhinostore",
        linkSite: "https://rhinostoreoficial.mitiendanube.com"
      },
      {
        img: images.mintRose,
        alt: "Pagar a cuotas compras en línea",
        title: "Comprar en internet sin TDC",
        textImg: "Mint & Rose",
        linkSite: "https://mrmexico.com.mx/"
      },
      {
        img: images.onParty,
        alt: "Pagar a cuotas compras en línea",
        title: "Comprar en internet sin TDC",
        textImg: "On Party",
        linkSite: "https://onsideparty.myshopify.com/"
      },
      {
        img: images.pink,
        alt: "Pagar a cuotas compras en línea",
        title: "Comprar en internet sin TDC",
        textImg: "Pink Papaya",
        linkSite: "https://www.pinkpapaya.mx/"
      }
     
]
