import moment from 'moment'
import React, { useDebugValue, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { icons } from '../../assets/icons/icons'
import { images } from '../../assets/themes'
import { transactionActions } from '../../store/transaction/actions'
import { transactionSelector } from '../../store/transaction/selector'
import { formatMoney, instanceOfA } from '../../utils/utils'
import Accordion from '../accordion/accordion'
import BuyDetail from '../buy-detail/buy-detail'
import BuyDues from '../buy-dues/buy-dues'
import CardDetailHeaderInfo from '../cards/card-detail-header-info/card-detail-header-info'
import CardState from '../cards/card-state/card-state'
import CardStatistics from '../cards/card-statistics/card-statistics'
import CardUser from '../cards/card-user/card-user'
import SelectBox from '../form-components/select-box/select-box'
import { SVGIcon } from '../svg-icon/svg-icon'
import { TransactionLayoutStyle } from './transaction-layout-style'
import BuyLatePayments from '../buy-late-payments/buy-late-payments'
import { cardActions } from '../../store/card/actions'
import { authSelectors } from '../../store/auth/selectors'
import { IUser, ICard } from '@aluxion-lightpay/interfaces'
import { cardSelector } from '../../store/card/selectors'
import { isNumeric } from '../../utils/validation'
import CardPayMethod from '../cards/card-pay-method/card-pay-method'
import useModal from '../../hooks/useModal/use-modal'
import ModalInfo from '../modals/modal-info/modal-info'
import ModalSuccess from '../modals/modal-success/modal-success'
import ModalToPaidManual from '../modals/modal-to-paid/modal-to-paid-manual'
import ModalToSelectPaymentMethod from '../../components/modals/modal-to-paid/modal-select-payment'
import ModalErrorCard from '../modals/modal-error/modal-error'
import ModalErrorCustom from '../modals/modal-error/modal-error-custom'

interface IModalsData {
  shopName: string;
  prize: string;
  fee: string | number;
  date: string;
  card?: ICard;
  onClick: (value: any) => void;
  purchaseId: number;
  feeId: number;
  products: any;
}

const LAST_PAYMENT = 3

const TransactionLayout: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const selectedPurchase = useSelector(transactionSelector.getSellDetails());
  const latePayments = useSelector(transactionSelector.getAllLatePayments());
  const totalAmountLatePayments = useSelector(transactionSelector.getPurchaseLatePaymentsAmount());
  const { id: IdUser } = useSelector(authSelectors.getMe()) as IUser;
  const cards = useSelector(cardSelector.getCardData());
  const [currentCard, setCurrentCard] = useState<ICard | null>(null);
  const { openModal, closeModal, isModal } = useModal();
  const [warningKeysData, setWarningData] = useState<IModalsData | null>(null);
  const [selectedCardId, setSelectedCard] = useState<number | null>();
  const [paymentState, setPaymentState] = useState<any>({})
  const [version, setVersion] = useState<number>(0)
  const [title, setTitle] = useState<string>('')
  const [walletScreen, setWalletScreen] = useState<boolean>(false)

  const getPendingPaymentPartitions = (partitions: any) => {
    let totalAmount = 0

    partitions.map((partition: any) => {
      if (partition.status === 'PENDING' || partition.status === 'PAYMENT_REJECTED') totalAmount = totalAmount + partition.amount
    })

    return formatMoney(totalAmount)
  }

  const payAdvancementPurchase = async (purchaseId: number, cardId: number) => {
    try {
      await Promise.all([
        dispatch(transactionActions.payPurchasePendingTotal(purchaseId, cardId)),
      ]);
      openModal('paymentSuccessful');
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error) {
      console.log(error)
      handleErrorModals(error)
    }
  }

  //const paymentsBNPL = selectedPurchase?.payments.filter(value => value.paymentIndex != 0);
  //console.log(paymentsBNPL)

  const handleErrorModals = (error: any) => {
    if (error?.code === 'OPENPAY_EXCEPTION') {
      setTitle(error?.message)
      openModal('error');
    } else if (error?.reasons[0]?.validation === 'OpenPay regreso un error') {
      setVersion(1)
      openModal('error-custom');
    } else if (error?.reasons[0]?.validation === 'Tiene 4 intentos de OpenPay') {
      setVersion(2)
      openModal('error-custom');
    } else if (error?.reasons[0]?.validation === 'No puede adelantar cuotas') {
      setVersion(3)
      openModal('error-custom');
    } else {
      openModal('error');
    }
  }

  const selectedCard = async (currentCardId: number) => {
    setSelectedCard(currentCardId);
  }

  useEffect(() => {
    const getData = async () => {
      // @ts-ignore 
      if (id) {
        await Promise.all([
          dispatch(transactionActions.getPaymentDetails(id)),
          dispatch(transactionActions.getPurchaseLatePaymentsAmount(id)),
          IdUser && dispatch(cardActions.getCardData(IdUser)),
        ]);
      }

    }
    getData()
  }, [IdUser, dispatch, id]);

  useEffect(() => {
    setCurrentCard(selectedPurchase?.payments[LAST_PAYMENT]?.card || cards[0]);
  }, [cards, selectedPurchase])

  useEffect(() => {
    if (selectedPurchase) {
      setPaymentState(selectedPurchase)
      setWarningData({
        shopName: selectedPurchase?.commerce.name,
        fee: selectedPurchase?.actualPaymentIndex + 1,
        prize: getPendingPaymentPartitions(selectedPurchase?.payments),
        date: moment(selectedPurchase?.createdAt).format('L'),
        card: selectedPurchase?.payments[0].card,
        onClick: (cardId: number) => {
          payAdvancementPurchase(selectedPurchase?.id, cardId)
        },
        purchaseId: selectedPurchase?.id,
        feeId: selectedPurchase?.payments[selectedPurchase?.actualPaymentIndex]?.id,
        products: selectedPurchase?.products
      })
    }
  }, [selectedPurchase])

  console.log(selectedPurchase?.paymentDiscountCode?.idDiscountCode, '----------------------- descuento')

  const shippingFee =
    selectedPurchase?.paymentDiscountCode?.idDiscountCode === 1 ?
      '$0'
      :
      selectedPurchase ?
        `+ $${selectedPurchase.shippingFee}`
        :
        '30.00'

  const amount =
    selectedPurchase?.paymentDiscountCode?.idDiscountCode === 1 ?
      formatMoney(selectedPurchase.subtotal)
      :
      selectedPurchase && selectedPurchase?.paymentDiscountCode?.idDiscountCode > 1 ?
        formatMoney(selectedPurchase?.subtotal - selectedPurchase?.shippingFee + selectedPurchase?.paymentDiscountCode?.amount)
        :
        `${selectedPurchase ? formatMoney(selectedPurchase.subtotal - selectedPurchase.shippingFee) : '300.00'} `

  const tableDataTest: any[] = [
    [
      'Gastos de envío',
      shippingFee
    ],
    [
      'Monto',
      amount
    ],
    [
      'Total compras',
      selectedPurchase ?
        selectedPurchase?.type === "OneShot" ? formatMoney(selectedPurchase?.totalOneShot) : formatMoney(selectedPurchase?.totalAmount) :
        "30.00"
    ]
  ]

  if (selectedPurchase?.discountCode?.id > 1) {
    tableDataTest.splice(1, 0,
      ['Código de descuento: ', `${selectedPurchase?.discountCode?.code}`],
      ['Descuento aplicado', `-${formatMoney(selectedPurchase?.paymentDiscountCode?.amount)}`]);
  }
  if (selectedPurchase?.type !== "OneShot") {
    tableDataTest.splice(1, 0,
      ['Comision', `+${formatMoney(selectedPurchase ? selectedPurchase?.userFee + selectedPurchase?.userFeeIva : 30)}`]);
  }

  const dummyData: any[] = [
    { name: 'Sabrosa Cotton Teclado', amount: 681, quantity: 1 },
    { name: 'Increíble Granito Zapatos', amount: 901, quantity: 5 },
    { name: 'Refinado Metal Queso', amount: 187, quantity: 1 },
    { name: 'Increíble Fresco Sombrero', amount: 771, quantity: 3 }
  ]

  let text = ''
  let status: 'Prepending' | 'Denied' | 'Waiting' | 'Return' | 'Aproved' = 'Aproved'
  let img: JSX.Element = (
    <SVGIcon icon={icons.okIcon} color="#FFFFFF" scale="1.7" />
  )

  if (selectedPurchase) {
    switch (selectedPurchase.status) {
      case 'SUCCESSFUL':
        text = 'completada'
        status = 'Aproved'
        img = <SVGIcon icon={icons.okIcon} color="#FFFFFF" scale="1.7" />
        break
      case 'CANCELED':
        text = 'cancelado'
        status = 'Denied'
        img = <SVGIcon icon={icons.rejectedIcon} color="#FFFFFF" scale="1.7" />
        break
      case 'CANCELED_PENDING':
        text = 'cancelado'
        status = 'Denied'
        img = <SVGIcon icon={icons.rejectedIcon} color="#FFFFFF" scale="1.7" />
        break
      case 'DRAFT':
        text = 'preparando'
        status = 'Waiting'
        img = <SVGIcon icon={icons.draftIconSVG} color="#FFFFFF" scale="1.7" />
        break
      case 'EXPIRED':
        text = 'expirada'
        status = 'Return'
        img = <SVGIcon icon={icons.expiredIcon} scale="1.7" />
        break
      case 'PENDING':
        text = 'exitosa'
        status = 'Waiting'
        img = (
          <SVGIcon icon={icons.inProgressIcon} color="#FFFFFF" scale="1.7" />
        )
        break
      case 'CHARGEBACK_REJECTED':
        text = 'contracargo'
        status = 'Denied'
        img = (
          <SVGIcon icon={icons.chargeBackIcon} color="#FFFFFF" scale="1.7" />
        )
        break
      case 'CHARGEBACK_ACCEPTED':
        text = 'contracargo'
        status = 'Aproved'
        img = (
          <SVGIcon icon={icons.chargeBackIcon} color="#FFFFFF" scale="1.7" />
        )
        break
      case 'CHARGEBACK_PENDING':
        text = 'contracargo'
        status = 'Waiting'
        img = (
          <SVGIcon icon={icons.chargeBackIcon} color="#FFFFFF" scale="1.7" />
        )
        break
      case 'REFUNDED_TOTALLY':
        text = 'devolución'
        status = 'Aproved'
        img = <SVGIcon icon={icons.refundIcon} color="#FFFFFF" scale="1.7" />
        break
      case 'REFUNDED_PENDING':
        text = 'devolución'
        status = 'Waiting'
        img = <SVGIcon icon={icons.refundIcon} color="#FFFFFF" scale="1.7" />
        break
      case 'REFUNDED_PARTIALLY':
        text = 'devolución'
        status = 'Return'
        img = <SVGIcon icon={icons.refundIcon} color="#FFFFFF" scale="1.7" />
        break
      case 'INDEBTED':
        text = 'incobrable'
        status = 'Waiting'
        img = <SVGIcon icon={icons.refundIcon} color="#FFFFFF" scale="1.7" />
        break
      case 'PREPENDING':
        text = 'En validacion';
        status = 'Prepending';
        img = <SVGIcon icon={icons.clockIcon} color="#11c6ffb8" scale="1" />;
        break;
    }
  }

  // CARDS

  const getStringCardNumberDetailed = (brand: string, lastDigits: string, expirationDate: string) => {
    return `Tarjeta ${brand.toUpperCase()} **** ${lastDigits} - ${expirationDate}`
  }

  const getSelectCardNumber = (cardData: ICard[]) => {
    let resp: { [key: number]: string } = {};
    cardData.forEach((card) => {
      resp[card.id] = getStringCardNumberDetailed(card.brand, card.lastDigits, card.expirationDate)
    });
    return resp
  }

  const handleChange = (cardId: number) => {
    const card = cards.filter(card => card.id === cardId);
    if (card) {
      setCurrentCard(card[0]);
      const hasDebts = selectedPurchase?.payments.some(payment =>
        payment.status === 'PAYMENT_REJECTED' || payment.status === 'PENDING'
      );
      hasDebts && openModal('setDefaultCard');
    }
  }

  const setDefaultCard = async () => {
    try {
      if (id) {
        // tslint:disable-next-line: no-unused-expression
        currentCard && await dispatch(cardActions.addMainCard(id, currentCard.id));
      }
      openModal('successCardChange');
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <>
      <TransactionLayoutStyle>
        <div className="transaction-left">
          <div className="transaction-item" style={{ position: 'relative' }}>
            <CardState
              state={status}
              text={text.toUpperCase() || 'ACEPTADA'}
              subText="Operación"
              icon={img}
            />
            {
              status === "Waiting" &&
              <div style={{
                position: 'absolute',
                bottom: '10px',
                right: '15px',
              }}>
                <span onClick={async () => {
                  openModal('selectPaymentMethod')
                }} style={{ cursor: 'pointer', marginLeft: '20px', color: '#1A4CCD', fontWeight: 500 }}>PAGAR TODAS LAS CUOTAS</span>
              </div>
            }
          </div>
          {totalAmountLatePayments !== 0 && (
            <div className="transaction-item">
              <CardStatistics
                overtitle="Deudas pendientes de cargos por mora"
                title={`${formatMoney(totalAmountLatePayments || 0)}`}
                // icon={<SVGIcon icon={iconInfo} />}
                // infoText="periodo anterior"
                className="settlement-item"
                danger
              />
            </div>
          )}

          <div className="transaction-item">
            <CardUser
              img={
                selectedPurchase?.commerce?.user
                  ? (instanceOfA(selectedPurchase.commerce.user.image) &&
                    selectedPurchase.commerce.user.image?.url) ||
                  images.defaultProfileImage
                  : images.defaultProfileImage
              }
              title={`${selectedPurchase?.commerce
                ? selectedPurchase.commerce.name
                  ? selectedPurchase.commerce.name
                  : '---'
                : 'Lucas'
                }`}

              infoTelephone={''}
            // id={selectedPurchase?.commerce.id || 6498498}
            />
          </div>
        </div>
        <div className="transaction-right">
          <div className="transaction-item">
            <CardDetailHeaderInfo
              // status={processStatus ? processStatus : 'resolved'}
              id={selectedPurchase?.id.toString() || '444234'}
              date={
                moment(selectedPurchase?.createdAt).format('L') || '12/06/2019'
              }
              hour={
                moment(selectedPurchase?.createdAt).format('h:mm a') ||
                '12:43AM'
              }
            />
          </div>
          <div className="transaction-item">
            <div className="transaction-item__wrapper">
              <BuyDetail
                title="El título de la sección"
                inventory__data={
                  selectedPurchase ? selectedPurchase.products : dummyData
                }
                table__data={tableDataTest}
              />
            </div>
          </div>

          <div className="transaction-item">
            {selectedPurchase?.type !== "OneShot" &&
              <Accordion title="Plan de pago">
                <BuyDues
                  payments={selectedPurchase?.payments?.filter(value => {
                    if (!selectedPurchase?.type && !value.type && value.paymentIndex !== 0) {
                      return value
                    } else if (selectedPurchase?.type === value.type && value.status !== 'CANCELED') return value
                  }) || null}
                // payments={selectedPurchase?.payments.filter(value => value.paymentIndex != 0) || null}
                />
              </Accordion>
            }
          </div>

          <div className="transaction-item">
            <Accordion title="Historial de moras">
              <BuyLatePayments
                latePayments={latePayments?.items || []}
                count={latePayments?.count || 0}
                purchaseId={id ? typeof id === 'string' ? parseInt(id, 10) : id : 0}
                commerceName={selectedPurchase?.commerce?.name || ''}
                handleModalError={handleErrorModals}
              />
            </Accordion>
          </div>

          {currentCard && cards.length !== 0 && (
            <div className="transaction-item">
              <Accordion title="Método de pago">
                <SelectBox
                  name="credit-card"
                  options={getSelectCardNumber(cards)}
                  onChange={(id) => {
                    if (isNumeric(id)) handleChange(Number(id))
                  }}
                />

                <CardPayMethod
                  lastDigits={currentCard.lastDigits}
                  bankName={currentCard.bankName}
                  dateEnd={currentCard.expirationDate}
                  cardType={currentCard.type}
                  cardBrand={currentCard.brand}
                />
              </Accordion>
            </div>
          )}
        </div>
      </TransactionLayoutStyle>

      {/* MODALS */}
      <ModalToPaidManual
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'confirmPaymentPurchase') || false,
          onClose: closeModal,
        }}
        logo={icons.slightpayLogo}
        shopName={warningKeysData?.shopName || ''}
        prize={warningKeysData?.prize || ''}
        fee={`${warningKeysData?.fee}/4`}
        date={warningKeysData?.date || ''}
        card={warningKeysData?.card!}
        onClick={warningKeysData?.onClick}
        purchaseId={warningKeysData?.purchaseId}
        feeId={warningKeysData?.feeId}
        setDefaultCard={selectedCard}
        payPurchase
        products={warningKeysData?.products}
      />

      <ModalToSelectPaymentMethod
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'selectPaymentMethod') || false,
          onClose: () => {
            closeModal()
            setWalletScreen(false)
          }
        }}
        setWalletScreen={setWalletScreen}
        walletScreen={walletScreen}
        onClick={() => {
          openModal('confirmPaymentPurchase')
        }}
        purchase={paymentState}
      />

      <ModalSuccess
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'paymentSuccessful') || false,
          onClose: closeModal,
        }}
      >
        Se ha liquidado <span>su compra con éxito.</span>
      </ModalSuccess>

      <ModalInfo
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'setDefaultCard') || false,
          onClose: closeModal,
        }}
        title={'¿Desea que las cuotas pendientes se paguen con la tarjeta seleccionada?'}
        text=""
        buttons={[
          {
            text: 'No',
            type: 'ghost',
            onClick: closeModal,
          },
          {
            text: 'Sí',
            type: 'primary',
            onClick: setDefaultCard,
          },
        ]}
      />
      <ModalSuccess
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'successCardChange') || false,
          onClose: closeModal,
        }}
        text="el próximo pago se hará con su nueva tarjeta."
      >
        Se ha cambiado <span>su tarjeta predeterminada.</span>
      </ModalSuccess>

      <ModalErrorCard
        title={title}
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'error') || false,
        }}
      />

      <ModalErrorCustom
        version={version}
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'error-custom') || false,
        }}
      />

    </>

  )
}

export default TransactionLayout
