import { Dispatch } from 'redux';

import { ILoadingActions, ILoadingActionsTypes } from './types';

const loading = () => async (dispatch: Dispatch<ILoadingActionsTypes>) => {
  dispatch({ type: ILoadingActions.LOADING });
};

const loaded = () => async (dispatch: Dispatch<ILoadingActionsTypes>) => {
  dispatch({ type: ILoadingActions.LOADED });
};

export const loaderActions = {
  loading,
  loaded,
};
