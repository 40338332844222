import styled from 'styled-components';

export const NoContentTableStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #cccdce;
`;
