const latePaymentsStatus = {
  SUCCESSFUL: 'Exitosa',
  PENDING: 'Pendiente',
  PAYMENT_REJECTED: 'Pago Fallido',
};

const statuses = {
  DRAFT: 'Preparando',
  EXPIRED: 'Expirada',
  PENDING:'Por cobrar',
  PAYMENT_REJECTED: 'Pago Fallido',
  SUCCESSFUL: 'Exitoso',
  CANCELED_PENDING: 'Candelado',
  CANCELED: 'Cancelado',
  CHARGEBACK_PENDING: 'contracargo',
  CHARGEBACK_ACCEPTED: 'contracargo',
  CHARGEBACK_REJECTED: 'contracargo',
  REFUNDED_PENDING: 'devolución',
  REFUNDED_TOTALLY: 'devolución',
  REFUNDED_PARTIALLY: 'devolución',
  INDEBTED: 'incobrable,'
};

export { latePaymentsStatus, statuses };