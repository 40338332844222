import styled from 'styled-components/macro'
import sizes from '../../../assets/sizes/sizes'

// Crear una clase mas arriba y heredar ?? opcion TODO
const DesktopHeaderContainer = styled.div`
  width: 100%;
  height: ${sizes.navbar.desktop.height}px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  z-index: 999;
  box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
  background: white;



  #nav-logo {
    max-width: 0;
    .img-container {
      position: relative;
    }

  }

  #nav-actions {
    display: flex;
    align-items: center;

    .nav-opts {
      display: flex;
      align-items: center;
      margin-right: 1px;
      border-right: 1px solid lightgray;
      padding-right: 30px;

      a, div {
        color: #b3b3b6;
        margin-left: 40px;
        &:hover {
          color: #1a4ccd;
        }
      }

      &__item {
        cursor: pointer;
      }

      a.active {
        color: #1a4ccd;
      }
    }

    #nav-toolbar {
      display: flex;
      align-items: center;

      .img-container {
        width: 35px;
        cursor: pointer;
      }

      #img-profile  {
        border-radius: 100%;
        width: 45px;
        height: 45px;
        background: gray;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow: hidden;
        margin-left: 20px;
        cursor: pointer;

        img {
          background-size: cover;
          background-position: 100%;
        }
        .invalid-finerio{
          background: red;
          width: 16px;
          height: 16px;
          position: absolute;
          z-index: 500;
          color: white;
          border-radius: 9999px;
          font-size: 12px;
          text-align: center;
          align-items: center;
        }
        .approved-finerio{
          background: green;
          width: 16px;
          height: 16px;
          position: absolute;
          z-index: 500;
          color: white;
          border-radius: 9999px;
          font-size: 12px;
          text-align: center;
          align-items: center;
        }
      }
    }
  }
`

export { DesktopHeaderContainer }
