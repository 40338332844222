import styled from 'styled-components/macro';
import { images } from '../../../../assets/themes';

const ProfileHelpContainer = styled.article`
    width: 80%;

    .help-banner {
        display: flex;  
        flex-direction: column; 
        justify-content: center; 
        align-items: center; 
        background: url(${images.helpBanner}) center;
        background-size: cover;
        background-position-y: 25%; 
        width: 100%;
        height: 221px; 
        margin-bottom: 24px;
    }

    .help-title {
        font-family: "Gilroy";
        font-size: 30px;
        line-height: 36px;
        color: #FFFFFF;
        width: 80%;
        text-align: left; 
        margin-bottom: 31px; 
    }

    .help-searcher-container {
        width: 80%;
        display: flex; 
        align-items: flex-end; 
    }

    .category-select {
        width: calc((100% - 20px) / 2);
        margin-right: 20px; 
        .complete {
            .select-box-main-field {
                border-bottom: 1px solid white; 
                p {
                    color: white; 
                }
            } 
        } 
    }

    .search-category-input {
        width: calc((100% - 20px) / 2);

        .input {
            color: white; 
            border-bottom: 1px solid white; 

            &::placeholder {
                color: white;
            }
        }
    }

    .help-section-box {
        margin-bottom: 12px; 

        &:last-child {
            margin-bottom: 0; 
        }
    }

    .help-filter {
        display: none; 
    }

    .help-content {
        margin-bottom: 40px;
    }

    .help-item {
        padding: 16px 27px;
        margin-bottom: 12px;
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        .help-text {
            font-size: 14px;
            line-height: 22px;
            color: #808185;
            p {
                margin-bottom: 10px;
                li {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    margin-left:30px;
                    
                    &:before {
                        position: absolute;
                        content: '';
                        border-radius: 100%;
                        width: 6px;
                        height: 6px;
                        left: -12px;
                        top: 8px;
                        background-color: #1a4ccd;
                    }
                }

                span {
                    font-weight: bold;
                }
            
            }
        }
    }

    .help-content-return {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 23px;

        p {
            font-size: 30px;
            line-height: 36px;
            display: flex;
            align-items: center;
            margin-left:18px;
        }
    }

    .arrow {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }
    

    @media (max-width: 768px) {
        width: 90%; 
        margin-top: 200px; 

        .help-banner {
            flex-direction: column; 
            background: none; 
            display: none; 

        }

        .help-title {
            display: none; 
        }

        .help-searcher-container {
            flex-direction: column; 
        }

        .help-filter {
            display: flex; 
            flex-direction: column; 
            width: 100%; 
            margin-bottom: 20px; 
        }

        .help-filter-topbar {
            display: flex; 
            align-items: center; 
            justify-content: flex-start; 
        }

        .help-filter-icon {
            width: 16px; 
            height: 14px; 
            margin-right: 8px; 
            cursor: pointer; 

            img {
                width: 100%; 
                height: auto; 
            }
        }

        .help-filter-title {
            color: #B3B3B6;
        }
        
    }
`;

export default ProfileHelpContainer; 