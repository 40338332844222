import React, { useState, FunctionComponent } from 'react'
import PaymentTableWrapperContainer from './payment-table-wrapper-style'
import { icons } from '../../../assets/themes'

interface PaymentTableWrapperProps {
  title?: string;
  changeOrder?: (orderBy: 'asc' | 'desc') => void;
  tables?: JSX.Element[];
  tabs?: string[];
  onClick?: (e:any) => void;
}

const useTabs = () => {
  const [selectedTable, setSelectedTable] = useState(0)

  const changeSelectedTable = (index: number) => {
    setSelectedTable(index)
  }

  return {
    selectedTable,
    changeSelectedTable
  }
}

const PaymentTableWrapper: React.FC<PaymentTableWrapperProps> = (
  props: PaymentTableWrapperProps
) => {
  const { title, tabs, changeOrder, tables, onClick } = props
  const { selectedTable, changeSelectedTable } = useTabs()
  return (
    <PaymentTableWrapperContainer>
      <div className="table-topbar">
        {title && (
          <div className="table-title">
            <h2>{title}</h2>
          </div>
        )}

        {/* <div className="order-container">
          <div className="order-label">
            <p>Ordenar</p>
          </div>
          <div className="arrows-container">
            <div className="up-arrow">
              <img src={icons.tableArrowUp} alt="up-arrow" />
            </div>
            <div className="down-arrow">
              <img src={icons.tableArrowDown} alt="up-arrow" />
            </div>
          </div>
        </div> */}

        {tabs && (
          <nav className="tabs-nav">
            <ul className="tabs-list">
              {tabs.map((tabName: string, index: number) => (
                <li
                  className={`tab ${selectedTable === index ? 'selected' : ''}`}
                  onClick={() => {changeSelectedTable(index); onClick && onClick(tabName)}}
                  key={index}
                >
                  <p>{tabName}</p>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>

      {tables && (
        <div className="payment-table-wrapper-content">
          {tables[selectedTable]}
        </div>
      )}
    </PaymentTableWrapperContainer>
  )
}

export default PaymentTableWrapper
