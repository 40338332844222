import React, { useState } from 'react';
import { MainTableHeaderStyle } from './main-table-header-style';
import HeadingText from '../../components-text/heading-text/heading-text';
import InputBox from '../../form-components/input-box/input-box';
import MainButton from '../../main-button/main-button';
import { SVGIcon } from '../../svg-icon/svg-icon';
import { icons } from '../../../assets/icons/icons';

interface IMainTableHeaderProps {
  title?: string;
  children?: any;
  buttons?: any;
  mainButton?: boolean;
  onClick?: () => void | {};
  searchFunction?: (e?: any) => void;
  placerholder?: string;
  onAnyKeyPress?: boolean;
  noFilters?:boolean;
}
const MainTableHeader: React.FC<IMainTableHeaderProps> = (props: IMainTableHeaderProps) => {
  const { title, children, buttons, searchFunction, mainButton, onClick, placerholder, onAnyKeyPress, noFilters } = props;
  const [openFilters, setOpenFilters] = useState('');
  
  return (
    <MainTableHeaderStyle>
      <div className="main-table-header-top">
        <div className="main-table-header-title">
          {title && (
            <HeadingText as="p" textType="h3">
              {title}
            </HeadingText>
          )}
          {mainButton && (
            <div className="main-table-header-title_button">
              <MainButton type="primary" text="Generar Operación" onClick={onClick} />
            </div>
          )}
        </div>

        <div className="main-table-header-actions">
          {buttons && (
            <div className="main-table-header-actions-buttons">
              {buttons.map((item: any, index: number) => (
                <div
                  className="main-table-header-actions-buttons__item"
                  key={`main-table-button-${index}`}
                  onClick={() => (item.onClick ? item.onClick : '')}
                >
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          )}
          {
            !noFilters &&
            <>
              <div
                className={`main-table-header-actions-search ${openFilters === 'search' ? 'active' : ''}`}
                onClick={() => setOpenFilters(openFilters === 'search' ? '' : 'search')}
              >
                <SVGIcon icon={icons.searchIcon} color={openFilters === 'search' ? '#000000' : '#CCCDCE'} />
              </div>
              {children && (
                <div
                  className={`main-table-header-actions-filters ${openFilters === 'search' ? 'active' : ''}`}
                  onClick={() => setOpenFilters(openFilters === 'filters' ? '' : 'filters')}
                >
                  <SVGIcon icon={icons.filtersIcon} color={openFilters === 'filters' ? '#000000' : '#CCCDCE'} />
                </div>
              )}

            </>
          }

        </div>
      </div>
      <div className={`main-table-header-filters ${openFilters !== '' ? 'active' : ''}`}>
        {openFilters === 'search' ? (
          <div className="main-table-header-filters__search">
            {searchFunction && (
              <InputBox
                name="search"
                placeholder={placerholder ? placerholder : 'Introduzca un email'}
                onKeyPressEvent={searchFunction}
                onAnyKeyPress={onAnyKeyPress}
              />
            )}
          </div>
        ) : (
          <div className="main-table-header-filters__row">{children}</div>
        )}
      </div>
    </MainTableHeaderStyle>
  );
};
export default MainTableHeader;
