import styled from 'styled-components';

export const CircleStatStyle = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;

circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  background-color:transparent;
}

.text {
  position: absolute;
  line-height: 26px;
  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #34353D;
    font-size: 21px;
    span {
      color: #CA3976; 
      font-size: 20px;
      font-weight: 500;
      p {
        color: #CA3976; 
        font-size: 18px;
        font-weight: bold;

      }
    }
  }
}

.circle-progress {
  position: absolute;
  top : 0;
  left: 0;
}

`