import React, { useEffect, useState } from 'react';
import ProfileAsideContainer from './profile-aside-style';
import { Link } from 'react-router-dom';

const isActive = (page: string) => {
    switch(page) {
        case 'personal-data': {
            if(window.location.href.indexOf('/datos-personales') !== -1) {
                return 'active'
            } else{
                return '';
            }
        }

        case 'config': {
            if(window.location.href.indexOf('/configuracion') !== -1) {
                return 'active'
            } else{
                return '';
            }
        }

        case 'help': {
            if(window.location.href.indexOf('/ayuda') !== -1) {
                return 'active'
            } else{
                return '';
            }
        }

        case 'score': {
            return 'active'
        }

        default : 
            return '';
        
    }
}   

const ProfileAside: React.FC = () => {
    return (
        <ProfileAsideContainer>
            <nav className="profile-aside-menu">
                <ul className="profile-aside-list">
                    <Link to="/perfil/datos-personales">
                        <li className={`profile-aside-element ${isActive('personal-data')}`}>
                            <p className="element-text">Datos personales</p>          
                        </li>
                    </Link>

                    {/* <Link to="/perfil/configuracion">
                        <li className={`profile-aside-element ${isActive('config')}`}>
                            <p className="element-text">Configuración de la cuenta</p>
                        </li>
                    </Link> */}

                    <Link to="/perfil/ayuda">
                        <li className={`profile-aside-element ${isActive('help')}`}>
                            <p className="element-text">Ayuda</p>
                        </li>
                    </Link>

                </ul>
            </nav>
        </ProfileAsideContainer>
        
    )
}

export default ProfileAside; 