import { IBaseAction } from '../base-action.interface';
import { IPurchase, IPaymentPartition, ILatePaymentFee } from '@aluxion-lightpay/interfaces';
import { IPurchaseClients, IPaymentPartitionClients, ILatePaymentFeeClients } from '../../interfaces/purchaseclients.types'

export enum TransactionActions {
  PURCHADES_ADD_DATA = 'PURCHADES_ADD_DATA',
  PAYMENTS_ADD_DATA = 'PAYMENTS_ADD_DATA',
  DETAILS_PURCHASES_ADD_DATA = 'DETAILS_PURCHASES_ADD_DATA',
  ADD_COMPLETED_PAYMENTS_DATA = 'ADD_APPROVED_PAYMENTS_DATA',
  ADD_SELECTED_CARD_PURCHASES = 'ADD_SELECTED_CARD_PURCHASES',
  ADD_LATEPAYMENT_DATA = 'ADD_LATEPAYMENT_DATA',
  ADD_LATEPAYMENTS_OBJECT_DATA = 'ADD_LATEPAYMENTS_OBJECT_DATA',
  ADD_PURCHASE_LATEPAYMENTS_AMOUNT_DATA = 'ADD_PURCHASE_LATEPAYMENTS_AMOUNT_DATA',
}

export interface ITransactionPurchase {
  items: IPurchaseClients[];
  count: number;
}

export interface ITransactionPayment {
  items: IPaymentPartitionClients[];
  count: number;
}

export interface IAllLatePayments { 
  items: ILatePaymentFeeClients[];
  count: number;
}

export interface ITransactionState {
  readonly purchases: Partial<ITransactionPurchase> | null;
  readonly purchaseDetails: IPurchaseClients | null;
  readonly payments: ITransactionPayment | null;
  readonly completedPayments: ITransactionPayment | null;
  readonly selectedCardPurchases: Partial<ITransactionPurchase> | null;
  readonly totalAmountLatePayments: number | null;
  readonly latePayments: IAllLatePayments | null;
  readonly purchaseTotalLatePaymentsAmount: number | null;
}

export interface ITransactionReducer {
  (state: ITransactionState, action: TrasactionActionsTypes): ITransactionState;
}

// Action Interfaces
export interface ITransactionSellsData extends IBaseAction {
  type: TransactionActions.PURCHADES_ADD_DATA;
  payload: ITransactionPurchase | null;
}

export interface ITransactionCardData extends IBaseAction {
  type: TransactionActions.ADD_SELECTED_CARD_PURCHASES;
  payload: ITransactionPurchase | null;
}

export interface IDetailsSellData extends IBaseAction {
  type: TransactionActions.DETAILS_PURCHASES_ADD_DATA;
  payload: IPurchaseClients | null;
}

export interface IPaymentsData extends IBaseAction {
  type: TransactionActions.PAYMENTS_ADD_DATA;
  payload: ITransactionPayment | null;
}

export interface ICompletedPaymentsData extends IBaseAction {
  type: TransactionActions.ADD_COMPLETED_PAYMENTS_DATA;
  payload: ITransactionPayment | null;
}

export interface ILatePaymentData extends IBaseAction {
  type: TransactionActions.ADD_LATEPAYMENT_DATA;
  payload: number | null;
}

export interface IAllLatePaymentData extends IBaseAction {
  type: TransactionActions.ADD_LATEPAYMENTS_OBJECT_DATA;
  payload: IAllLatePayments | null;
}

export interface IPurchaseLatePaymentAmountData extends IBaseAction {
  type: TransactionActions.ADD_PURCHASE_LATEPAYMENTS_AMOUNT_DATA;
  payload: number | null;
}

export type TrasactionActionsTypes = 
  ITransactionSellsData 
| IDetailsSellData 
| IPaymentsData 
| ICompletedPaymentsData 
| ITransactionCardData
| ILatePaymentData
| IAllLatePaymentData
| IPurchaseLatePaymentAmountData;