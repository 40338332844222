import React from 'react';
import Accordion from '../../../../../components/accordion/accordion';
import { IPropsAnswers } from '../../../../../utils/utils';

const Verification: React.FC<IPropsAnswers> = (props) => {
  const { onClick } = props;
  return (
    <div className="help-content">
      <div className="help-content-return" onClick={ () => onClick() }>
        <div><i className="arrow"></i></div>
        <p>Verificación de Identidad</p>
      </div>
      <div className="help-section-box">
        <Accordion title="¿Cómo y por qué verificamos tu identidad?" className="help-item">
          <p className="help-text">
            <p>
              En Slightpay, siempre estamos trabajando para que el uso de nuestra plataforma sea lo más segura posible para todos. 
              Es por eso que, antes de comprar con nosotros, todos los clientes y comercios deben confirmar su identidad (ID).
              Hacemos esto como parte de nuestro proceso de registro. En algunos casos, es posible que el sistema te solicite 
              información adicional (como el número de pasaporte o identificación oficial) para que podamos verificar su identidad, 
              lo que haremos a través de un servicio de verificación de terceros. No hay nada que debas hacer a menos que se lo pidamos. 
              Este proceso nos ayudará a prevenir el fraude y garantizará que todas las personas que usan el servicio tengan 18 años o más.
            </p>
          </p>
        </Accordion>
      </div>
    </div>
  );
};

export default Verification;