import React, { useState } from 'react';
import { icons } from '../../assets/themes'
// import SucessIcon from '../../assets/icons/Success/success-icon';
// import ErrorIcon from '../../assets/icons/Error/error-icon';
// import WarningIcon from '../../assets/icons/Warning/warning-icon';

// Styles
import { CustomAlertContainer } from './alert-style';

// Types
import { ICustomAlertProps } from './alert-types';
import { SVGIcon } from '../svg-icon/svg-icon';

const getImageBaseOnType = (type: string) => {
  let url;
  switch (type) {
    case 'error':
      url = icons.errorRed;
      break;
    case 'warning':
      url = icons.warningYellow;
      break;
    case 'success':
      url = icons.tickGreen;
      break;
    default:
      url = '';
      break;
  }
  return url;
};

const CustomAlert: React.FC<ICustomAlertProps> = (props: ICustomAlertProps) => {
  const { onClick, link, text, type, showIcon } = props;
  const [showLink, setShowLink] = useState(true);
  const imageUrl = getImageBaseOnType(type.trim());
  const handleClick = () => {
    /* eslint-disable */
    if (onClick) {
      onClick()
      setShowLink(false);
    }
  };
  return (
    <CustomAlertContainer className={type}>
      <div id="custom-alert-icon-box" className={showIcon ? '' : 'hidden'}>
        <SVGIcon icon={imageUrl} scale='1' />
      </div>
      <div>
        {text}{' '}
        {link && showLink && (
          <a href="#" onClick={handleClick}>
            {link}
          </a>
        )}
      </div>
    </CustomAlertContainer>
  );
};

export default CustomAlert;
