import React from 'react';
import { useHistory } from 'react-router-dom';
import { ICheck } from './Check-type';
import InputBox from '../../../../components/form-components/input-box/input-box';
import MainButton from '../../../../components/main-button/main-button';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../store/auth/actions';
import { useForm } from 'react-hook-form';
import { CHECK_EMAIL_INPUT_NAME, getCheckEmailInputMetadata } from '../Auth-inputs-names';
import { getCheckEmailFormErrors } from '../Auth-inputs-validations';
import { authSelectors } from '../../../../store/auth/selectors';

const Check: React.FC<ICheck> = () => {
  const history = useHistory();
  const checkEmail = useSelector(authSelectors.getEmail());

  // This should be refactored
  const getFormDefaultValues = () => {
    if (!checkEmail) {
      return {};
    }
    const formData = {
      [CHECK_EMAIL_INPUT_NAME]: checkEmail,
    };
    return formData;
  };
  // End refactoring

  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({ defaultValues: getFormDefaultValues() });

  const handleForm = async (data: any) => {
    try {
      await dispatch(authActions.checkEmail(data[CHECK_EMAIL_INPUT_NAME]));
      history.push('/iniciar-sesion');
    } catch (error) {
      if(error.message !== 'EMAIL_NO_VALID') {
        await dispatch(authActions.registerEmail(data[CHECK_EMAIL_INPUT_NAME]));
        history.push('/registro/informacion-basica');
      }
    }
  };

  return (
    <>
      <InputBox
        labelText="Correo Electrónico"
        name={CHECK_EMAIL_INPUT_NAME}
        inputRef={register(getCheckEmailInputMetadata(CHECK_EMAIL_INPUT_NAME))}
        errorCode={getCheckEmailFormErrors(errors, CHECK_EMAIL_INPUT_NAME)}
        onKeyPress={handleSubmit(handleForm)}
      />
      <MainButton className="form-button" type="primary" text="Siguiente" onClick={handleSubmit(handleForm)}/>
    </>
  );
};

export default Check;
