import React, { useState, useEffect } from 'react'
import Scrollbars from 'react-custom-scrollbars'

// Constants
import { ERROR_MESSAGES } from '../../../constants/errorMessages'

// Themes
import { icons } from '../../../assets/themes'

// Styles
import { SelectBoxContainer } from './select-box-style'

// Types
import { SelectBoxProps, UseSelectType } from './select-box-types'

// Components
import BodyText from '../../components-text/body-text/body-text'
import ErrorText from '../../components-text/error-text/error-text'

const useSelect: UseSelectType = (
  initialValue: string,
  props: SelectBoxProps
) => {
  const [value, setValue] = useState(initialValue)
  const [showList, setShowList] = useState(false)

  const handleClickOutside = (event: Event) => {
    if (showList) {
      setShowList(false)
      setValue(value)
    }
  }

  const handleElementClick = (show: boolean, val: string) => {
    if (props.optionsArray) {
      setValue(val)
    } else if (props.options) {
      setValue(props.options[val])
    }

    setShowList(show)

    if (props.onChange) {
      props.onChange(val, props.name)
    }
  }

  return {
    value,
    showList,
    handleElementClick,
    handleClickOutside
  }
}

const SelectBox: React.FC<SelectBoxProps> = (props: SelectBoxProps) => {
  const {
    className,
    disabled,
    errorCode,
    // name,
    labelText,
    options,
    optionsArray,
    required,
    value,
    whiteIcon
  } = props

  const actions = useSelect(value || 'Seleccionar', props)

  useEffect(() => {
    window.addEventListener('click', (e: any) => actions.handleClickOutside(e))
    return () => {
      window.removeEventListener('click', (e: any) =>
        actions.handleClickOutside(e)
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const errorText = errorCode ? ERROR_MESSAGES[errorCode] : ''

  return (
    <SelectBoxContainer
      className={`${errorCode ? 'error' : ''} ${className} ${
        actions.showList ? 'show-list' : ''
      } ${disabled ? 'disabled' : ''} ${required ? 'required' : ''} ${
        errorCode ? 'error' : ''
      } ${actions.value !== '' ? 'complete' : ''}`}
    >
      <div className="select-box-topbar">
        <div className="select-box-topbar-label">
          <BodyText>
            <p>{labelText}</p>
          </BodyText>
        </div>
      </div>

      <div
        className="select-box-main"
        onClick={() =>
          actions.handleElementClick(!actions.showList, actions.value)
        }
      >
        <div className="select-box-main-field">
          <BodyText>
            <p>{actions.value}</p>
          </BodyText>
        </div>
        <div className="select-box-icon">
          <img
            src={disabled ? icons.arrowDownGrey : (whiteIcon ? icons.arrowDownWhite : icons.arrowDownBlue)}
            alt="down arrow"
          />
        </div>
      </div>
      <ul className="select-box-main-list">
        <Scrollbars
          renderTrackVertical={props => (
            <div {...props} className="track-vertical" />
          )}
          renderThumbVertical={props => (
            <div {...props} className="thumb-vertical" />
          )}
          style={{ width: '100%' }}
          autoHide={true}
          autoHideTimeout={200}
          autoHideDuration={200}
          autoHeight={true}
          autoHeightMin={60}
          autoHeightMax={200}
        >
          {optionsArray &&
            optionsArray.map((option: string, index) => (
              <li
                key={index}
                onClick={() => actions.handleElementClick(false, option)}
              >
                <BodyText>{option}</BodyText>
              </li>
            ))}

          {options &&
            Object.keys(options).map((key: string, index) => (
              <li
                key={index}
                onClick={() => actions.handleElementClick(false, key)}
              >
                <BodyText>{options[key]}</BodyText>
              </li>
            ))}
        </Scrollbars>
      </ul>

      <div className="select-box-error">
        <ErrorText>{errorCode && errorText}</ErrorText>
      </div>
    </SelectBoxContainer>
  )
}

export default SelectBox
