import React, { useState } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'

import Profile from '../containers/ligthpay-app/profile/profile'
import ProfilePersonalData from '../containers/ligthpay-app/profile/profile-personal-data/profile-personal-data'

//Components
import Home from '../containers/ligthpay-app/home/home'
import MyActivity from '../containers/ligthpay-app/my-activity/my-activity'
import ProfileHelp from '../containers/ligthpay-app/profile/profile-help/profile-help'
import UpdateAddress from '../containers/ligthpay-app/profile/profile-personal-data/update-address/update-address'

// Wallet
import MyWallet from '../containers/ligthpay-app/my-wallet/my-wallet'
import UpdatePhone from '../containers/ligthpay-app/profile/profile-personal-data/update-phone/update-phone'
import MyActivityPaymentSchedule from '../containers/ligthpay-app/my-activity/payment-schedule/payment-schedule'
import MyActivityPaymentRecord from '../containers/ligthpay-app/my-activity/payments-record/payment-record'
import ConfirmEmail from '../containers/authorization/auth/signup/steps/ConfirmEmail'
import { useSelector, useDispatch } from 'react-redux'
import { authSelectors } from '../store/auth/selectors'
import CustomAlert from '../components/custom-alerts/alert'
import { authActions } from '../store/auth/actions'
import Notfound from '../containers/ligthpay-app/404/Not-found'
import TransactionLayout from '../components/transaction-layout/transaction-layout'
import ScrollToTop from '../utils/scrollToTop'
import TermsAndConditions from '../containers/legal/terms'
import Privacy from '../containers/legal/privacy'
import CommerceSection from '../components/shopSection/commerceSection'

const LightpayAppRoutes: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isEmailVerified = useSelector(authSelectors.getCurrentUserEmailStatus());
  const [verifyEmailModalData, setVerifyEmailModalData] = useState({
    text: `
    Nos hemos dado cuenta que tu correo no esta verificado, 
    hazle click al siguiente enlace para reenviar el correo.
    `,
    type: 'warning',
  }); 
  const sendVerifyEmail = async () => {
    try {
      await dispatch(authActions.requestVerifyEmail());

      setVerifyEmailModalData({
        text: `
        Hemos enviado el correo con éxito. Luego de confirmarlo recuerda recargar esta página para que los cambios hagan efecto
        `,
        type: 'success',
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* {!isEmailVerified && (
        <CustomAlert
          type={`${verifyEmailModalData.type} ${location.pathname.indexOf("/perfil") < 0 ? "" : "profile"}`}
          text={verifyEmailModalData.text}
          showIcon
          link="Reenviar correo de verificación"
          onClick={sendVerifyEmail}
        />
      )} */}
      <ScrollToTop />
      <Switch>
        <Route exact path="/" render={props => <Home {...props} />} />
        <Route exact path="/home/:paymentId?/:feeId?/" render={props => <Home {...props} />} />
        <Route exact path="/detalle-operacion/:id" component={TransactionLayout} />
        <Route path="/mi-actividad" render={props => <MyActivity {...props} />} />
        <Route
          exact
          path="/mi-actividad/agenda-de-pagos"
          render={props => <MyActivityPaymentSchedule {...props} />}
        />

        <Route
          exact
          path="/mi-actividad/historial-de-pagos"
          render={props => <MyActivityPaymentRecord {...props} />}
        />

        <Route
          exact
          path="/perfil/datos-personales"
          render={props => (
            <Profile>
              <ProfilePersonalData {...props} />
            </Profile>
          )}
        />

        <Route
          path="/perfil/datos-personales/añadir-direccion"
          render={props => <UpdateAddress {...props} />}
        />

        <Route
          path="/perfil/datos-personales/actualizar-telefono"
          component={UpdatePhone}
        />

        <Route
          path="/perfil/ayuda"
          render={props => (
            <Profile>
              <ProfileHelp {...props} />
            </Profile>
          )}
        />
        <Route exact path="/comercios" component={CommerceSection} />
        <Route exact path="/mi-cartera" component={MyWallet} />
        <Route path="/mi-cartera/agregar-tarjeta" render={props => <UpdateAddress {...props} />} />

        <Route exact path="/registro/verificar-email/:token" component={ConfirmEmail} />
        {/* LEGAL TERMS */}
        <Route exact path="/terminos-y-condiciones" component={TermsAndConditions} />
        <Route exact path="/politica-de-privacidad" component={Privacy} />
        
        <Route path="/not-found" component={Notfound} />
        <Route render={() => <Redirect to="/not-found" />} />

      </Switch>
    </>
  )
}

export default LightpayAppRoutes
