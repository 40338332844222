import React from 'react';
import UpdatePhoneContainer from './update-phone-style';
import MainButton from '../../../../../components/main-button/main-button';
import InputBox from '../../../../../components/form-components/input-box/input-box';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../../../../store/auth/selectors';
import { IUser } from '@aluxion-lightpay/interfaces';
import { PHONE_NUMBER_INPUT_NAME, getPersonalDataInputMetadata } from '../../../../authorization/auth/Auth-inputs-names';
import { getLegalDataFormErrors } from '../../../../authorization/auth/Auth-inputs-validations';
import { modalActions } from '../../../../../store/modal/actions';
import { modalSelector } from '../../../../../store/modal/selectors';
import ModalErrorCard from '../../../../../components/modals/modal-error/modal-error';
import { authActions } from '../../../../../store/auth/actions';
import ModalCode from '../../../../../components/modals/modal-code/modal-code';

const UpdatePhone: React.FC = () => {
    const dispatch = useDispatch();
    const isModal = useSelector(modalSelector.isShowing());
    const { id } = useSelector(authSelectors.getMe()) as IUser;
    const history = useHistory();
    const { handleSubmit, errors, control } = useForm();

    const openModal = async (id: number | string) => {
        await dispatch(modalActions.show(id));
      };
    
    const closeModal = async () => {
        await dispatch(modalActions.hide());
    };

    const handleForm = async (data:any) => {
        try {
            await dispatch(authActions.updatePhoneNumber(data[PHONE_NUMBER_INPUT_NAME], id));
            openModal('SmsCode')
        }catch(error) {
            console.log(error);
            openModal('error');
        }
    }

    return(
        <UpdatePhoneContainer>
            <div className="update-phone-wrapper">
                <p className="update-phone-text">
                    Indícanos tu número de celular.
                </p>

                <div className="update-phone-form">
                    <div className="update-phone-row">
                        <Controller
                            as={
                                <InputBox
                                    className="col-sm-12 form-input"
                                    initialValue="+52" 
                                    labelText="Nº de Télefono"
                                    name={PHONE_NUMBER_INPUT_NAME}
                                    errorCode={getLegalDataFormErrors(errors, PHONE_NUMBER_INPUT_NAME)}
                                    cleaveFormat={{
                                    phone: true,
                                    // phoneRegionCode: 'MX',
                                    }}
                                    placeholder="+52"
                                    onKeyPress={handleSubmit(handleForm)}
                            />
                            }
                            name={PHONE_NUMBER_INPUT_NAME}
                            control={control}
                            rules={getPersonalDataInputMetadata(PHONE_NUMBER_INPUT_NAME)}
                        />
                    </div>
                </div>

                <div className="update-phone-buttons">
                    <div className="btn-cancel">
                        <Link to="/perfil/datos-personales">
                            <MainButton type="secondary" text="Cancelar"/>
                        </Link>
                    </div>
                    <div className="btn-confirm">
                        <MainButton type="primary" text="Confirmar" onClick={handleSubmit(handleForm)}/>
                    </div>
                </div>
            </div>
            {/* MODALS */}

            <ModalCode 
                modalConfig={{
                    active: (isModal?.show && isModal?.idModal === 'SmsCode') || false,
                }}
                onClick={() => history.push('/perfil/datos-personales')}
            />

            <ModalErrorCard
                modalConfig={{
                    onClose: closeModal,
                    active: (isModal?.show && isModal?.idModal === 'error') || false,
                }}
            />
        </UpdatePhoneContainer>
    )
}

export default UpdatePhone; 