import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { icons } from '../../../assets/themes'
import { AuthStyle } from './Auth-style';
import GridMasonry from '../../../components/grid-masonry/grid-masonry';
import { images } from '../../../assets/themes';
import { SVGIcon } from '../../../components/svg-icon/svg-icon';

import Check from './check/Check';
import Login from './login/Login';
import Signup from './signup/Signup';

const Auth = () => {
  const loginImages = [
    images.login1,
    images.login2,
    images.login3,
    images.login5,
    images.login6,
  ];

  return (
    <AuthStyle>
      <GridMasonry title="Bienvenido" arrayImages={loginImages} />
      {/* <LittleMasonry/> */}
      <div className="login">
        <div className="login-container">
          <div className="main-title">
            <SVGIcon icon={icons.slightpayLogo} scale='2.5' color='black'/>
          </div>
          <div className="login-form">
            <Route exact path="/" component={Check} />
            <Route path="/iniciar-sesion" component={Login} />
            <Route path="/registro" component={Signup} />
            {/* <Redirect to="/" /> */}
          </div>
        </div>
      </div>
    </AuthStyle>
  );
};

export default Auth;