import styled from 'styled-components/macro';

const ProfileAsideContainer = styled.aside`
    position: fixed;
    top: 115px; 
    left: 0; 
    height: 100%;
    width: 323px; 
    padding-left: 42px;
    padding-right: 20px; 

    .profile-aside-menu {
        height: 100%; 
        width: 100%; 

        .profile-aside-list {
            height: 100%; 
            width: 100%; 
            display: flex; 
            flex-direction: column; 
            align-items: center; 
            justify-content: flex-start;
            
            a {
                width: 100%;
            }

            .profile-aside-element {
                cursor: pointer; 
                width: 100%; 
                height: 65px; 
                display: flex; 
                align-items: center; 
                justify-content: center; 
                background: #FFFFFF;
                box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
                border-radius: 3px;
                margin-bottom: 12px; 

                .element-text {
                    font-family: "Gilroy";
                    font-size: 16px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    color: #01030C;
                }

                &.active {
                    background: #01030C;

                    .element-text {
                        color: #fff;
                    }
                }
            }
        }
    }
`;  

export default ProfileAsideContainer; 