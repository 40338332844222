import React, { useState } from 'react';

// Styles
import { DobleImageAttachContainer } from './doble-image-attach-style';

// Types
import { IDobleImageAttachProps, IUseDobleImageAttach, IDefaultAttachImagesObjType } from './doble-image-attach-types';

// Extra components
import AttachFileInput from '../attach-input/attach-input';
import MainButton from '../../../main-button/main-button';

const useDobleAttach: IUseDobleImageAttach = (props: IDobleImageAttachProps) => {
  const defaultAttachImagesObj: IDefaultAttachImagesObjType = {
    ImgIneFront: {},
    ImgIneBack: {},
  };
  const [attachImages, setAttachImages] = useState(defaultAttachImagesObj); // Maybe pass a initState ?

  const onFirstImageAdded = (list: Array<any>) => {
    // const reader = new FileReader();

    // reader.onloadend = () => {
    //   const blob = typeof reader.result == 'string' ? reader.result.split(';') : ' ';
    //   const mime = blob[0];
    //   const base64 = blob[1];
    //   defaultAttachImagesObj.ImgIneFront.Mime = mime;
    //   defaultAttachImagesObj.ImgIneFront.Base64 = base64;
    //   setAttachImages(defaultAttachImagesObj);
    // };
    // reader.readAsDataURL(list[0]);

    defaultAttachImagesObj.ImgIneFront.fakeUrl = list[0];
    setAttachImages(defaultAttachImagesObj);
  };
  const onSecondImageAdded = (list: Array<any>) => {
    // const reader = new FileReader();

    // reader.onloadend = () => {
    //   const blob = typeof reader.result == 'string' ? reader.result.split(';') : ' ';
    //   const mime = blob[0];
    //   const base64 = blob[1];
    //   defaultAttachImagesObj.ImgIneBack.Mime = mime;
    //   defaultAttachImagesObj.ImgIneBack.Base64 = base64;
    //   setAttachImages(defaultAttachImagesObj);
    // };
    // reader.readAsDataURL(list[0]);

    defaultAttachImagesObj.ImgIneBack.fakeUrl = list[0];
    setAttachImages(defaultAttachImagesObj);
  };
  const onImagesAdded = () => {
    props.onImagesAdded(defaultAttachImagesObj);
  };

  return {
    attachImages,
    onFirstImageAdded,
    onSecondImageAdded,
    onImagesAdded,
  };
};

const DobleImageAttach: React.FC<IDobleImageAttachProps> = (props: IDobleImageAttachProps) => {
  const actions = useDobleAttach(props);

  return (
    <DobleImageAttachContainer>
      <h1 className="attach-h1">
        Adjunta las fotografías de la cara frontal y posterior de tu INE en el espacio correspondiente.
      </h1>

      <div className="attach-container">
        <AttachFileInput
          name="test-input-file"
          textTitle="INE (CARA FRONTAL)"
          textIcon="Añadir cara frontal"
          textHelp=""
          type="normal"
          multi={false}
          onFilesAdded={actions.onFirstImageAdded}
        />
      </div>
      <div className="attach-container">
        <AttachFileInput
          name="test-input-file-2"
          textTitle="INE (CARA POSTERIOR)"
          textIcon="Añadir cara posterior"
          textHelp=""
          type="normal"
          multi={false}
          onFilesAdded={actions.onSecondImageAdded}
        />
      </div>
      <div className="add-files">
        <MainButton className="attach-button" text="Aceptar" type="primary" onClick={actions.onImagesAdded} />
      </div>
    </DobleImageAttachContainer>
  );
};

export default DobleImageAttach;
