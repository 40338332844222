import React from 'react';

// Styles
import InventoryItemContainer from './inventory-item-style';

// Types
import { IInventoryItemProps } from './inventory-item-types';

const InventoryItem: React.FC<IInventoryItemProps> = (props: IInventoryItemProps) => {
  const { className, image, title, infoQuantity, infoPrice } = props;

  return (
    <InventoryItemContainer className={className}>
      {image && (
        <div className="img">
          <img src={image} alt="Inventory Item" />
        </div>
      )}

      <div className="wrapper-info">
        <div className="wrapper-product">
          <div className="name">{title}</div>
          <div className="quantity">x {infoQuantity}</div>
        </div>
        <div className="price">{infoPrice}</div>
      </div>
    </InventoryItemContainer>
  );
};

export default InventoryItem;
