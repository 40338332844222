import React, { useState, useEffect } from 'react'
import ModalToPaidContainer from './modal-to-paid-style'
import MainButton from '../../main-button/main-button'
import { ModalToPaidProps } from './modal-to-paid-types'
import ModalContainer from '../modal-container/modal-container'
import SelectBox from '../../form-components/select-box/select-box'
import { ICard } from '@aluxion-lightpay/interfaces'
import { useSelector, useDispatch } from 'react-redux'
import { cardSelector } from '../../../store/card/selectors'
import useUserCards from '../../../hooks/useWallet/useUserCards'
import { isNumeric } from '../../../utils/validation'
import { transactionActions } from '../../../store/transaction/actions'
import { transactionSelector } from '../../../store/transaction/selector'
import { formatMoney } from '../../../utils/utils'

const ModalToPaidManual: React.FC<ModalToPaidProps> = (props: ModalToPaidProps) => {
  const dispatch = useDispatch();
  const { logo, shopName, prize, fee, modalConfig, onClick, card, date, purchaseId, feeId, setDefaultCard, payPurchase, products } = props
  const [currentCard, setCurrentCard] = useState<ICard>(card);
  const { getCard } = useUserCards();
  const latePaymentAmount = useSelector(transactionSelector.getLatePaymentAmount());
  const cards = useSelector(cardSelector.getCardData());
  const getStringCardNumber = (card: ICard) => {
    if(!card){
      return "Selecciona una tarjeta..."
    } else {
      return `Tarjeta ${card?.brand.toUpperCase()} **** ${card?.lastDigits}`
    }
  }

  const getSelectCardNumber = (cardData: ICard[]) => {
    let resp: { [key: number]: string } = {};
    cardData.forEach((card) => {
      resp[card.id] = getStringCardNumber(card)
    })
    return resp
  }

  const handleCardChange = async (cardId: number) => {
    const card = getCard(cardId)
    setCurrentCard(card);
  }

  const handleBuy = async () => {
    try {
      onClick && currentCard && onClick(currentCard?.id);
      setDefaultCard(currentCard?.id)
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        if (purchaseId && feeId)
          await dispatch(transactionActions.getTotalLateFeeAmount(purchaseId, feeId));
        setCurrentCard(card);

      } catch (error) {
        console.error(error);
      }
    }
    getData();
  }, [card, dispatch, feeId, purchaseId])

  return (
    <ModalContainer {...modalConfig} type="light">
      <ModalToPaidContainer>
        <div className="modal-to-paid-description">
          <p>{payPurchase ? '¿Quieres adelantar el pago de esta compra?' : '¿Quieres adelantar el pago de esta cuota?'}</p>
        </div>
        <div className="modal-to-paid-content">
          <div className="modal-to-paid-topbar">
            <div className="modal-to-paid-logo">
              <img src={logo} alt="" />
            </div>
            <div className="modal-to-paid-topbar-info">
              <div className="purchase">
                <p>
                  Compra en <span>{shopName}</span>
                </p>
              </div>
            </div>
          </div>

          {
            payPurchase &&
            <div className="modal-to-paid-row">
              <div style={{ width: '100%' }}>
                <div className="modal-to-paid-label">
                  <p>Detalles de la compra</p>
                </div>
                {products && products.map((product: any, key: number) => {
                  let marginBottom = products.length-1 !== key ? '20px' : '0'
                  return (
                    <div className="fee" style={{ marginBottom }}>
                      <div className="fee-icon" />
                      <p className="fee-text modal-to-paid-text">{product.name}</p>
                      <p className="fee-text modal-to-paid-text"><span style={{ fontSize: '14px', lineHeight: '22px', color: '#01030c', marginBottom: '5px', fontWeight: 400 }}>Cantidad: </span>{product.quantity}</p>
                      <p className="fee-text modal-to-paid-text"><span style={{ fontSize: '14px', lineHeight: '22px', color: '#01030c', marginBottom: '5px', fontWeight: 400 }}>Costo: </span>{formatMoney(product.amount)}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          }

          <div className="modal-to-paid-row">
            {payPurchase ?
              <>
                <div style={{ width: '100%' }}>
                  <div className="modal-to-paid-label">
                    <p>Total pendiente</p>
                  </div>

                  <div className="fee">
                    <div className="fee-icon" />
                    <p className="fee-text modal-to-paid-text">{prize}</p>
                  </div>
                </div>
              </> :
              <>
                <div className="modal-to-paid-fee-number">
                  <div className="modal-to-paid-label">
                    <p>Nº de cuota</p>
                  </div>

                  <div className="fee">
                    <div className="fee-icon" />
                    <p className="fee-text modal-to-paid-text">{fee}</p>
                  </div>
                </div>

                <div className="modal-to-paid-paid-date">
                  <div className="modal-to-paid-label">
                    <p>Fecha pago</p>
                  </div>

                  <div className="paid-date modal-to-paid-text">
                    <p>{date}</p>
                  </div>
                </div>
              </>
            }

          </div>

          <div className="modal-to-paid-column">
            <div className="payment-method">
              <div className="modal-to-paid-label">Método de pago</div>
              <div className="payment-method-text modal-to-paid-text">
                  <SelectBox
                    name="current-card"
                    value={getStringCardNumber(card)}
                    options={getSelectCardNumber(cards)}
                    onChange={(id) => {
                      if (isNumeric(id)) handleCardChange(Number(id))
                    }}
                  />

              </div>
            </div>
            <div className="payment-method-icon">
              <img alt="" />
            </div>
          </div>
        </div>

        <div className="modal-to-paid-buttons">
          <div className="btn-cancel">
            <MainButton type="secondary" text="Cancelar" onClick={modalConfig.onClose} />
          </div>

          <div className="btn-yes">
            <MainButton type="primary" text="Sí" onClick={handleBuy} />
          </div>
        </div>
      </ModalToPaidContainer >

    </ModalContainer >
  )
}

export default ModalToPaidManual
