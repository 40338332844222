import React from 'react';
import Accordion from '../../../../../components/accordion/accordion';
import { IPropsAnswers } from '../../../../../utils/utils';

const LoginQuestions: React.FC<IPropsAnswers> = (props) => {
  const { onClick } = props;
  return (
    <div className="help-content">
      <div className="help-content-return" onClick={ () => onClick() }>
        <div><i className="arrow"></i></div>
        <p>Login</p>
      </div>
      <div className="help-section-box">
        <Accordion title="¿Por qué no puedo iniciar sesión en mi cuenta de Slightpay?" className="help-item">
          <p className="help-text">
            <p>
              Deberás crear una contraseña para acceder a tu cuenta en el sitio web de Slightpay. Esto lo puedes hacer dando clic 
              en el enlace de un correo electrónico que recibes después de crear tu cuenta, realizar tu primera compra, o dando clic 
              en "¿Olvide mi contraseña?" al intentar iniciar sesión en Slightpay.
            </p>
            <p>
              Si estás utilizando un dispositivo móvil y tienes problemas para iniciar sesión, intenta lo siguiente:
            </p>
            <p>
              <ul>
                <li>
                  Asegúrate de tener una conexión a Internet sólida y estable (WiFi o móvil).
                </li>
                <li>
                  Actualiza tu dispositivo con la versión más reciente del sistema operativo y el navegador.
                </li>
                <li>
                  Cierra cualquier navegador existente y reinicia.
                </li>
                <li>
                  Si tienes varias pestañas abiertas, ciérrelas todas y reinicia tu navegador.
                </li>
                <li>
                  No usar el modo de navegación privada / incógnito.
                </li>
              </ul>
            </p>
            <p>
              Recuerda: Tu contraseña debe tener al menos 8 caracteres y debe contener una combinación de letras, números y símbolos.
            </p>
          </p>
        </Accordion>

        <Accordion title="Mi cuenta ha sido desactivada" className="help-item">
          <p className="help-text">
            <p>
              Si tu cuenta se ha inhabilitado, restablece tu contraseña seleccionando "Olvide mi contraseña" en la página de inicio de 
              sesión y se te enviará un correo electrónico. Sigue las indicaciones y tu cuenta se activará una vez más. Deja tiempo para 
              recibir el correo electrónico y asegúrete de revisar tu buzón de SPAM / JUNTA en caso de que haya ingresado en esa carpeta. 
              Ten cuidado: si lo haces demasiadas veces en un corto período de tiempo, volverás a desactivar tu cuenta.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Por qué no he recibido el correo electrónico para restablecer mi contraseña?" className="help-item">
          <p className="help-text">
            <p>
              Asegúrate de estar usando el mismo correo electrónico que ingresaste para crear tu cuenta: si olvidaste qué correo era, 
              verifica en todas las bandejas de entrada de tus correos electrónicos la confirmación de creación de cuenta que hayas 
              recibido de nosotros o del comercio.
            </p>
            <p>
              Revisa tus carpetas de spam / basura.
            </p>
            <p>
              ¿Todavía no puedes entrar? Ponte en contacto con nosotros y lo resolvemos.
            </p>
          </p>
        </Accordion>

      </div>
    </div>
  );
};

export default LoginQuestions;