import styled from 'styled-components';

interface CardPayMethodStyleProps {
  options?: boolean;
}

export const CardPayMethodStyle = styled.div<CardPayMethodStyleProps>`
  display: flex;
  ${ props => props.options && 'border-top: 1px solid #e6e6e7' };
  padding-top: 35px;
  padding-bottom: 35px;

  .card-pay-method-wrapper {
    margin-right: 20px;
    flex: auto;
  }
  .card-pay-method-subtitle {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #1a4ccd;
    margin-bottom: 5px;
    &.active {
      &:hover {
        cursor: pointer;  
        color: #4FB2C6;
      }
    }
  }
  .card-pay-method-title {
    font-size: 24px;
    line-height: 29px;
    color: #01030c;
    font-weight: 600;
    margin-bottom: 7px;
  }
  .card-pay-method-date {
    font-size: 14px;
    line-height: 22px;
    color: #01030c;
    margin-bottom: 21px;
  }
  .card-pay-method-item {
    margin-bottom: 23px;
    &:last-child {
      margin-bottom: 0px;
    }
    &__title {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 500;
      color: #b3b3b6;
      margin-bottom: 4px;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      color: #01030c;
      font-weight: 300;
    }
  }
  .card-pay-method-card {
    &__delete {
      margin-top: 30px;
      letter-spacing: 1px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      color:#CA3976;
      &:hover {
        cursor: pointer
      }
    }
  }

  @media (max-width: 1300px) {
    flex-direction: column;

    .card-pay-method-wrapper {
      margin-bottom: 32px;
    }
  }
`;
