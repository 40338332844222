import styled from 'styled-components/macro';


const MyWalletWithoutCardContainer = styled.div`
    display: flex;
    justify-content: center;

    #my-wallet {
        margin-top: 80px;

        .image-container {
            width: 300px;
            position: relative;
            margin: 0 auto;
            margin-bottom: 40px;
            img {
                width: 100%;
            }
        }   

        .text-container {
            text-align: center;
            max-width: 500px;
            margin-bottom: 40px;
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;

            b {
                font-weight: bold;
            }
        }

        .button-container {
            margin: 0 auto;
            margin-bottom: 50px;
            max-width: 250px;
        }

    }

    @media (max-width: 576px) {
        #my-wallet {
            padding: 0 25px;

            .text-container {
                font-size: 16px;
            }
        }
    }
`

export default MyWalletWithoutCardContainer; 