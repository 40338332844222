import styled from 'styled-components/macro';
import sizes from '../../assets/sizes/sizes';

const BaseLayoutContainer = styled.div`
    overflow: auto;
    padding-left: 40px;
    padding-right: 40px;
    /* min-height: calc(100vh - ${sizes.navbar.desktop.height}px);

    @media(max-width: ${sizes.navbar.screen.sm}px) {
        min-height: calc(100vh - ${sizes.navbar.mobile.height}px);
    } */



    @media(max-width: 992px) { 
        padding-left: 20px;
        padding-right: 20px;
    }

    @media(max-width: 576px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`;

export default BaseLayoutContainer;