import styled from 'styled-components';

export const TableAvatarStyle = styled.div`
  border-radius: 100%;
  min-height: 37px;
  min-width: 37px;
  height: 37px;
  width: 37px;
  background: #f4f6fc;
  overflow: hidden;
  margin-left: 30px;
  img {
    width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
  }
`;
