export const currentEnvironment = 'master' //process.env.REACT_APP_ENV as string;
export const PUBLIC_KEY='pk_ac05d9443e914ee1b6d88f781fe2290e';
export const OPENPAY_MERCHANTE_ID='mabarczrcfhon9l8b1dz';
export const OPENPAY_SANDBOX=false;
export const googleAnalyticsKey = 'UA-175440665-1';
export const facebookPixelKey = '340936073703925';

interface IServices {
  url: string;
}

const SERVICES: IServices = {
  url: ''
};

switch (currentEnvironment) {
  // case 'staging':
  //   SERVICES.url = 'https://api-staging.slightpay.com/';
  //   break;

  case 'master':
    SERVICES.url = 'https://api.slightpay.com/'
    break;

  // case 'develop':
  // default:
  //   // SERVICES.url = 'https://api-staging.slightpay.com/';
  //   SERVICES.url = 'http://localhost:3000/'
}

export const SERVICE_URL = SERVICES;
