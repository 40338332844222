import styled, { keyframes } from 'styled-components/macro';

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const MainTableContainer = styled.div`
  text-align: left;
  width: 100%;
  display: flex;
  overflow: hidden;

  &.fixed-left {
    .main-table-head {
      tr {
        th  {
          &:first-child {
            display: none;
          }
        }
      }
    }
    .main-table-column {
      &.left {
        display: block;
      }
    }
    .main-table-row {
      .main-table-row-cell {
        &:first-child {
          display: none;
        }
      }
    }
  }
  &.fixed-right {
    .main-table-head {
      tr {
        th  {
          &:last-child {
            display: none;
          }
        }
      }
    }
    .main-table-column {
      &.right {
        display: block;
      }
    }
    .main-table-row {
      .main-table-row-cell {
        &:last-child {
          display: none;
        }
      }
    }
  }
  &.fixed-booth {
    .main-table-head {
      tr {
        th  {
          &:first-child {
            display: none;
          }
          &:last-child {
            display: none;
          }
        }
      }
    }
    .main-table-column {
      display: block;
    }
    .main-table-row {
      .main-table-row-cell {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: none;
        }
      }
    }
  }

  /*  hide scrolls  */
  /* & > *::-webkit-scrollbar {
    display: none;
  } */

  /* Hide scrollbar for IE and Edge */
  /* & > * {
    -ms-overflow-style: none;
  } */

  .main-table-column {
    max-height: 100%;
    box-shadow: 10px 0px 16px rgba(0, 0, 0, 0.1), -6px 0px 6px rgba(0, 0, 0, 0.06);
    z-index: 9;
    display: none;
  }
  .main-table-column-scroll {
    overflow-y: scroll;
    height: 40vh;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .main-table-wrapper-scroll-vertical {
    tr {
      width: 100%;
      display: inline-table;
    }
    /* &::-webkit-scrollbar {
      display: none;
    } */
  }
  .main-table-wrapper-scroll {
    z-index: 2;
    overflow-x: scroll;
    width: 100%;
    /* overflow-y: hidden; */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  table {
    width: 100%;
    height: 60px;
    &.main-table-head {
    }
  }

  .main-table-column-head {
    padding: 12px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    background: white;
    margin-bottom: 12px;
  }
  .main-table-head {
    background: white;
    margin-bottom: 12px;
    display: inline-table;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
    th {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 48px;
      min-height: 48px;
      max-height: 48px;
      vertical-align: middle;

      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
        overflow: hidden;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 22px;
      }

      &.pointer {
        cursor: pointer;
      }

      &.filter {
        cursor: pointer;
        p {
          padding-right: 30px;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            transition: 0.3s all ease-in-out;
          }
        }

        &.active {
          p {
            &:after {
              transform: translateY(-50%) rotate(-180deg);
            }
          }
        }
      }
    }
  }

  .main-table-column-row {
    padding: 8px 16px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    align-items: center;
    background: white;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
    margin-bottom: 12px;
  }

  .action {
    opacity: 0;
    cursor: pointer;
  }

  .main-table-row  {
    /* cursor: pointer; */
    transition: 0.15s all ease-in-out;
    background: white;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    width: 100%;
    align-items: center;
    display: flex;
    white-space: nowrap;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
    margin-bottom: 12px;

    .main-table-row-cell {
      flex: auto;
      vertical-align: middle;
      margin-left: 8px;
      text-overflow: ellipsis;
      padding-right: 12px;
      a  {
        font-size: 14px;
        line-height: 22px;
      }
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
        overflow: hidden;
      }
      &:last-child {
        margin-left: 0px;
      }
      > div {
        display: flex;
        align-items: center;
        &.table-icon-container {
          padding: 0;
        }
        &.hire {
          cursor: pointer;
        }
      }
    }

    .table-icon-item {
      a {
        padding: 25px 16px;
      }

      button {
        padding: 25px 16px;
        -webkit-appearance: none;
        background: none;
        border: none;
        cursor: pointer;
      }
    }

    &:hover {
      box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.1);
      cursor: pointer;
      > .action {
        animation: ${appear} 0.5s forwards ease-in-out;
      }
    }
  }

  /* Components */
  .main-table-single-check {
    width: 56px;
    background: #f1f1f1;
  }
  .main-table-button {
  }

  .main-table-body {
    overflow-y: auto;
  }

  .selectors {
    min-width: 56px;
    max-width: 56px;
    width: 56px;
  }
  .avatar {
    min-width: 82px;
    max-width: 82px;
    width: 82px;
  }
  .buttons {
    min-width: 85px;
    max-width: 85px;
    width: 85px;
    padding: 0 8px;
  }

  .table-col-1-5 {
    width: calc(85px * 0.5);
    max-width: calc(85px * 0.5);
    min-width: calc(85px * 0.5);
    overflow: hidden;
  }

  .table-col-1 {
    width: calc(85px * 1);
    max-width: calc(85px * 1);
    min-width: calc(85px * 1);
    overflow: hidden;
  }

  .table-col-2 {
    width: calc(85px * 2);
    max-width: calc(85px * 2);
    min-width: calc(85px * 2);
    overflow: hidden;
  }

  .table-col-3 {
    width: calc(85px * 3);
    max-width: calc(85px * 3);
    min-width: calc(85px * 3);
    overflow: hidden;
  }

  .table-col-4 {
    width: calc(85px * 4);
    max-width: calc(85px * 4);
    min-width: calc(85px * 4);
    overflow: hidden;
  }

  .profile {
    div {
      position: relative;
      p {
        margin-left: 20px;
      }
      div {
        margin: 0;
        /* &:first-child{
          position: absolute;
            left: -5%;
        } */
      }
    }
    &__action {
      span {
        margin-left: 20px;
        color: #1A4CCD;
        font-weight: 500;
      }
    }
  }

  .start {
    /* text-align: center; */
    position: relative;
    p {
      padding-left: 25px;
      word-break: break-all;
    }

    &_row {
      .start-row {
        display: flex;
        padding-left: 25px;
        img {
          width: auto;
        }
        span {
          margin-left: 15px;
        }
      }
    }
  }

  .end {
    p {
      padding-right: 25px;
    }
    &_row {
      padding-right: 25px;
    }
  }

  .date {
    p {
      span {
        color: #cccdce;
        margin-left: 10px;
      }
    }
  }

  .break {
    span {
      padding: 1px 8px;
      &::before {
        content: '';
        display: block;
      }
    }
  }

  .payment {
    color:#CA3976;
    font-weight: 500;
    &.active {
      color: #1A4CCD;
    }
  }

  .deposit {
    color: #4fb2c6;
  }

  .waiting {
    color: #ca3976;
  }

  .canceled {
    color: #B3B3B6;
    &.cross {
      text-decoration: line-through;
    }
  }

  @media screen and (max-width: 1440px) {
    .table-col-1 {
      width: calc(85px * 1.5);
      max-width: calc(85px * 1.5);
      min-width: calc(85px * 1.5);
    }
  }
`;
