import styled from 'styled-components/macro';
import { images } from '../../../../assets/themes';

const ProfilePersonalDataContainer = styled.article`

    .profile-section {
        width: 80%; 
        box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
        border-radius: 3px;
        background: #fff;
        margin-bottom: 20px; 
        

        &:last-child {
            margin-bottom: 0; 
        }

        &.personal-data-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
        }
        
        &.address-list-container {
            padding: 37px 50px; 
            box-sizing: border-box; 

        }

    }
    .finerio-btn{
        cursor: pointer; 
        width: 70%; 
        height: 50px; 
        display: flex; 
        align-items: center; 
        justify-content: center; 
        background: black;
        box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
        border-radius: 3px;
        margin-bottom: 12px;
        color: white;
    }

    .user-banner {
        width: 100%; 
    }

    .personal-data-info {
        position: relative; 
        bottom: 20px; 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%; 
    }

    .user-icon {
        /* border-radius: 100%; 
        width: 123px; 
        height: 123px; 
        background: gray; 
        display: flex; 
        flex-direction: column; 
        justify-content: flex-end; 
        overflow: hidden; 
        margin-bottom: 22px;  */

        .edit-user-icon {
            border-radius: 100%;
            width: 123px;
            height: 123px;
            background: #737373;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            overflow: hidden;
            margin-bottom: 22px;
            cursor: pointer;
            &:hover {
                p {
                    background: rgba(1, 3, 12, 0.6);
                }
            }
            img {
                background-size: cover;
                background-position: 100%;
            }
            p {
                background: rgba(1, 3, 12, 0.3);
                width: 100%;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 11px;
                line-height: 20px;
                color: #ffffff;
                transition: 0.3s ease-in-out all;
            }
        }

        
    }

    .user-name-container {
        display: flex; 
        align-items: center; 
        justify-content: center; 
        flex-direction: column; 
    }

    .user-name {
        font-family: "Gilroy";
        font-size: 24px;
        line-height: 29px;
        color: #01030C;
    }

    .edit-user-name-link {
        cursor: pointer; 
        font-family: "Gilroy";
        font-size: 14px;
        line-height: 17px;
        color: #1A4CCD;
        margin-bottom: 50px; 
    }

    .user-important-info {
        width: 90%; 
        display: flex; 
        align-items: center; 
        justify-content: center; 
    }

    .user-important-info-wrapper {
        display: flex;         
        flex-direction: column; 
        border-right: 1px solid #E6E6E7;
        padding-right: 22px; 
        width: calc(50% - 44px);

        &:last-child {
            border-right: 1px solid transparent; 
            border-right: 1px solid transparent;
            margin-right: 0;
            padding-left: 22px;
        }
    }

    .user-important-info-element {
        display: flex; 
        position: relative;
        align-items: center; 
        justify-content: space-between; 
        margin-bottom: 20px;
        
        &:last-child {
            margin-bottom: 0; 
        }

        .user-important-info-link {
            position: absolute;
            right: -50px;
            opacity: 0;
        }

        &:hover {
            .user-important-info-link {
                position: absolute;
                right: -50px;
                opacity: 1;
            }   
        }
    }

    .user-important-info-label {
        font-family: "Gilroy";
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #B3B3B6;
        /* width: 50%; */

    }

    .edit-phone-icon {
        cursor: pointer; 
    }

    .user-important-info-data {
        font-family: "Gilroy";
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #B3B3B6;
        /* width: 50%; */

        &.email {
            text-align: end;
        }
    }  


    .address-list-topbar {
        padding-bottom: 15px; 
        /* border-bottom: 1px solid #E6E6E7; */
        display: flex; 
        align-items: center; 
        justify-content: space-between; 
        margin-bottom: 20px; 

        .address-list-title {
            font-family: "Gilroy";
            font-size: 20px;
            line-height: 26px;
            color: #000000;
        }

        .add-address-icon {
            cursor: pointer; 
            width: 12px; 
            height: 12px; 

            img {
                width: 100%; 
                height: auto; 
            }
        }

    }

    .address-element {
        margin-bottom: 15px;
        
        &:last-child {
            margin-bottom: 0; 
        }
    }

    @media(max-width: 1200px) {
        .user-important-info {
            width: 100%; 
            flex-direction: column; 
            padding-left: 31px; 
        }

        .user-important-info-wrapper {
            flex-direction: column; 
            width: 100%; 

            &:first-child {
                border:0;
                margin-bottom: 20px; 
            }

            &:last-child {
                padding-left: 0; 
            }
        }
    }

    @media(max-width: 1000px) {
        .address-list-container {
            &.profile-section {
                padding: 31px; 
            }
        }
    }

    @media(max-width: 768px) {
        width: 90%;
        .user-name {
            padding: 20px;
        }
        .profile-section {
            margin-right: 0;
            width: 100%;

            &.personal-data-container {
                margin-top: 213px;

            }
        }
        
        .user-banner {
            display: none; 
        }
    }

`;

export default ProfilePersonalDataContainer; 