import React, { useState, useEffect } from 'react';
import ModalUpdateAvatarContainer from './modal-update-avatar-style';
import { IUseInputFileParams, IModalAvatarProps } from './modal-update-avatar-types';
import { icons } from '../../../assets/themes';
import MainButton from '../../main-button/main-button';
import AvatarEditor from 'react-avatar-editor';
import { SVGIcon } from '../../svg-icon/svg-icon';
import ModalContainer from '../modal-container/modal-container';
// import { ModalContext } from '../../../../../context/modal/modal-context'

const useFileInput = (params: IUseInputFileParams) => {
  const [isFocus, setIsFocus] = useState(false); // Maybe pass a initState ?
  const [image, setImage] = useState<File | null>();

  const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  /* Open File dialog
   *  Simulate click in hidden file input to open filesystem dialog
   */

  const handleOpenFileDialog = (): void => {
    if (params.disabled) return;

    fileInputRef.current && fileInputRef.current.click();
  };

  const filesToArray = (list: FileList, multi: boolean | undefined): Array<File | null> => {
    const array = [];

    const limit = multi ? list.length : 1;

    for (let i = 0; i < limit; i++) {
      array.push(list.item(i));
    }

    return array;
  };

  /* On filed Added hanlder
   * Get files added, transform the in an array a then trigger onFilesAdded fn
   */
  const handleOnFilesAdded = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    if (params.disabled) return;

    if (evt.currentTarget.files) {
      const files = filesToArray(evt.currentTarget.files, params.multi);

      // Workaround for typescript error (https://bit.ly/2lHr9fI)
      // Move to separate fn
      const notNullFiles: Array<File> = [];
      files.forEach(elem => {
        // We remove the posible null files in files array
        if (elem) notNullFiles.push(elem);
      });

      setImage(notNullFiles[0]);

      params.onFilesAdded && params.onFilesAdded(notNullFiles);
    }
  };

  /*  On drag over canvas hanlder
   *
   */
  const handleOnDragOver = (evt: React.DragEvent<HTMLDivElement>): void => {
    evt.preventDefault();
    if (params.disabled) return;

    setIsFocus(true);
  };

  /* On drag leave canvas hanlder
   *
   */
  const handleOnDragLeave = (evt: React.DragEvent<HTMLDivElement>): void => {
    evt.preventDefault();
    if (params.disabled) return;

    setIsFocus(false);
  };

  /* On drag leave canvas hanlder
   *  Detect and add files to array
   */

  const handleOnDrop = (evt: React.DragEvent<HTMLDivElement>): void => {
    evt.preventDefault();
    if (params.disabled) return;

    if (params.onFilesAdded) {
      const files = filesToArray(evt.dataTransfer.files, params.multi);

      // Workaround for typescript error (https://bit.ly/2lHr9fI)
      // Move to separate fn
      const notNullFiles: Array<File> = [];
      files.forEach(elem => {
        // We remove the posible null files in files array
        if (elem) notNullFiles.push(elem);
      });

      setImage(notNullFiles[0]);

      params.onFilesAdded(notNullFiles);
    }

    setIsFocus(false);
  };

  const resetImage = () => {
    setImage(null);
  };

  return {
    isFocus,
    fileInputRef,
    handleOpenFileDialog,
    handleOnFilesAdded,
    handleOnDragOver,
    handleOnDragLeave,
    handleOnDrop,
    resetImage,
    image,
  };
};

const onFilesAdded = () => {
  console.log('file added');
};

const ModalUpdateAvatar: React.FC<IModalAvatarProps> = props => {
  const { modalConfig, onClick } = props;
  let editor: any = null;
  const {
    isFocus,
    fileInputRef,
    handleOpenFileDialog,
    handleOnFilesAdded,
    handleOnDragOver,
    handleOnDragLeave,
    handleOnDrop,
    resetImage,
    image,
  } = useFileInput({
    onFilesAdded,
  });

  useEffect(() => {
    resetImage();
  },[modalConfig.active])  // eslint-disable-line

  const setEditorRef = (editorRef: any) => {
    editor = editorRef;
  };

  const onImage = async () => {
    if (editor && onClick) {
      const canvas = editor.getImageScaledToCanvas().toDataURL();
      const res = await fetch(canvas);
      const blob = await res.blob();
      // console.log(window.URL.createObjectURL(blob))
      onClick(blob);
    }
  };

  return (
    <ModalContainer {...modalConfig}>
      <ModalUpdateAvatarContainer>
        <p className="modal-update-avatar-title">Arrastra una foto de perfil aquí</p>

        <div
          className="avatar-editor-container"
          onDragOver={handleOnDragOver}
          onDragLeave={handleOnDragLeave}
          onDrop={handleOnDrop}
          onClick={handleOpenFileDialog}
        >
          {!image ? (
            <div className="no-image-container">
              <input
                name="image"
                ref={fileInputRef}
                className="hidden-file-ipnut"
                type="file"
                multiple
                onChange={handleOnFilesAdded}
              />

              <div className="no-image-icon">
                <SVGIcon icon={icons.noImage} scale="1" />
              </div>
            </div>
          ) : (
            <AvatarEditor
              ref={setEditorRef}
              image={image}
              border={[50, 10]}
              height={200}
              width={200}
              borderRadius={1000}
              color={[1, 2, 12, 0.6]} // RGBA
              rotate={0}
              scale={1.2}
            />
          )}
        </div>

        <p className="modal-update-avatar-text">o súbela desde tu computadora</p>

        <div className="modal-update-avatar-buttons">
          <div className="btn-cancel">
            <MainButton type="secondary" text="Cancelar" onClick={modalConfig.onClose} />
          </div>

          <div className="btn-accept">
            <MainButton type="primary" text="Aceptar" onClick={onImage} />
          </div>
        </div>
      </ModalUpdateAvatarContainer>
    </ModalContainer>
  );
};

export default ModalUpdateAvatar;
