import styled, { css } from 'styled-components/macro';
import { weights } from '../../../assets/themes';

interface LabelTextProps {
  color?: string;
}

export const LabelText = styled.div<LabelTextProps>`
  font-family: 'gilroy';
  font-size: 12px;
  line-height: 14px;
  font-weight: ${weights.semibold};
  letter-spacing: 1px;
  text-transform: uppercase;
  ${props => css`
    color: ${props.color};
  `}
  
`;
