import styled from 'styled-components/macro'
import { images } from '../../../../assets/images/images'

const MyWalletWithCardContainer = styled.div`
  position: relative;
  .main-table-header-top {
    margin-bottom:30px;
    position: absolute;
    right: 80px;
    top: 300px;
  }
  .heroblock {
    position: relative;
    width: 100%;
    height: 350px;
    background-image: url(${images.myWalletHeroblock});
    background-size: cover;
    background-position: 15% 0px;
    background-repeat: no-repeat;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    .heroblock-select-input {
      margin-top: 150px;

      max-width: 490px;
      width: 100%;
      padding: 0 20px;

      p {
        color: white !important;
      }
    }
    .heroblock-nav {
      display: flex;
      width: 100%;
      justify-content: space-between;
      color: white;
      margin-bottom: 37px;

      .nav-opts {
        display: flex;
        font-size: 30px;

        a {
          opacity: 0.4;
          padding: 0 20px;
          border-left: 1px solid gray;
        }

        a.first {
          padding: 0px 0px;
          padding-right: 20px;
          border-left: 0px solid gray;
        }

        a.active {
          opacity: 1;
          font-size: 30px;
        }
      }
    }
  }

  .filters-opt {
    display: flex;
    align-items: center;
    cursor: pointer;

    & .mobile {
      display: none;
    }

    p {
      font-size: 14px;
      margin-right: 10px;
    }
  }
  .filters-opt.mobile {
    display: none;
  }

  .heroblock-nav,
  .content-table {
    padding: 0 80px;
  }

  .content-table {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;

    #wallet-card-data {
      flex-grow: 1;
    }

    #wallet-sidebar {
      flex-shrink: 0;
    }
  }

  #creditcard-info,
  #fixed-action {
    display: none;
    z-index: 8;
  }

  #creditcard-info {
    background-color: white;

    & > div {
      position: relative;
      margin-top: -100px;
    }

    .card-actions {
      border-bottom: 0px;

      .image-container {
        display: flex;
        justify-content: center;
      }
    }

    .card {
      margin-top: 100px;
      box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
      padding-bottom: 30px;
    }
  }

  .card {
    margin-bottom: 25px;
    .card-text {
      .card-actions {
        margin-top: 10px;
        margin-left: -5px;
        border-bottom: 1px solid #e6e6e7;
        padding-bottom: 10px;
        justify-content: space-between;

        & > div {
          display: flex;
        }

        .image-container {
          img {
            cursor: pointer;
          }
        }
      }

      #billing-address {
        margin-top: 35px;
        line-height: 22px;
        font-size: 14px;

        span {
          display: block;
        }

        .bold {
          font-weight: 700;
          margin-bottom: 5px;
        }
      }
    }
  }


  .pagination {
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .pagination-count {
      margin: 0px;
      color: ${props => props.theme.colors.secondary};
      font-size: 0.75rem;
      font-weight: 400;
    }
  }

  @media (max-width: 992px) {
    .heroblock-nav,
    .content-table {
      padding: 0 45px;
    }

    .content-table {
      flex-direction: column;
      margin-bottom: 60px;
    }

    // #wallet-sidebar {
    //   display: none;
    // }

    #creditcard-info,
    #fixed-action {
      display: block;
    }

    #fixed-action {
      position: fixed;
      bottom: 0px;
      left: 0px;
      width: 100%;
    }
    .main-table-header-top {
      right: 30px
    }

  }

  .wallet-table {
    width: 100%;
    /* position: relative; */
    overflow: auto;
    margin-bottom: 40px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none;
    }

  }

  @media (max-width: 768px) {
    .heroblock-nav,
    .content-table {
      padding: 0 30px;
    }
    .heroblock {
      .heroblock-nav {
        .nav-opts {
          a {
          }
        }

        .filters-opt {
          display: none;
        }
      }
    }

    .filters-opt {
      &.mobile {
        display: flex;
        margin-bottom: 25px;
        color: #b3b3b6;
      }

      img {
        width: 18px;
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 576px) {
    .heroblock {
      height: 280px;
      background-position: center;

      background-image: url(${images.myWalletHeroblockMobil});
      background-size: cover;
      background-position: 15% 0px;

      .heroblock-select-input {
        margin-top: 70px;
      }
    }
    .heroblock-nav,
    .content-table {
      padding: 0 15px;
    }

    .main-table-header-top {
      top: 230px;
      right: 15px
    }
    .content-table {
    }
  }
`

export default MyWalletWithCardContainer
