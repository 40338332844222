import React from 'react';
import { Route, Switch } from 'react-router-dom';

//Components 
import MyActivityPaymentSchedule from '../containers/ligthpay-app/my-activity/payment-schedule/payment-schedule';
import MyActivityPaymentRecord from '../containers/ligthpay-app/my-activity/payments-record/payment-record';

const MyActivityRoutes: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/mi-actividad/agenda-de-pagos" render={(props) => <MyActivityPaymentSchedule {...props}></MyActivityPaymentSchedule>}/>
            <Route exact path="/mi-actividad/historial-de-pagos" render={(props) => <MyActivityPaymentRecord {...props}></MyActivityPaymentRecord>}/>
        </Switch>
    )
}

export default MyActivityRoutes;