import React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

interface ISVGIcon {
  icon: string;
  color?: string;
  scale?: string;
}

interface ISVGIconStyle {
  color?: string;
  scale?: string;
}

const SVGIconStyle = styled.div<ISVGIconStyle>`
  svg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${props =>
    props.color &&
    css`
      path {
        fill: ${props.color};
      }
      circle {
        fill: ${props.color};
      }
      transform: scale(${props.scale}, ${props.scale});
    `}
`;

export const SVGIcon: React.FC<ISVGIcon> = (props: ISVGIcon) => {
  const { icon, color, scale } = props;
  return (
    <SVGIconStyle color={color} scale={scale}>
      <ReactSVG src={icon} />
    </SVGIconStyle>
  );
};
