import React from 'react';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';

//Components
import MyActivityContainer from './my-activity-style';

//Constants
import {icons} from '../../../assets/icons/icons';

//Routes
import MyActivityRoutes from '../../../navigation/my-activity-routes';


const MyActivity: React.FC<any> = (props: any) => {

    return(
        <MyActivityContainer>

            <div>
                <div id="heroblock" >
                    <div id="heroblock-nav">
                        <div id="nav-opts">
                            <NavLink to='/mi-actividad/agenda-de-pagos' className="first" activeClassName='active'>Agenda de pagos</NavLink>
                            <NavLink to='/mi-actividad/historial-de-pagos' activeClassName='active'>Historial</NavLink>
                        </div>
               
                        {/* <div id="filters-opt">
                            <p>FILTRAR</p>
                            <img src={icons.helpFilter} alt='card filter button'/>
                        </div> */}

                    </div>
                </div>
            </div>

            <div className="content-table">
                <MyActivityRoutes></MyActivityRoutes>
            </div>
        </MyActivityContainer>
    )
}

export default MyActivity;