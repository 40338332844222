import styled from 'styled-components';

export const ZeroContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .zero-content-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 18px;
  }
  .zero-content-top {
    margin-bottom: 40px;
    text-align: center;
  }
  .zero-content-title {
    margin: 0 auto;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    max-width: 320px;
    color: #01030c;
    span {
      color: #1a4ccd;
    }
  }
  .zero-content-text {
    margin-top: 24px;
    max-width: 600px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    span {
      font-weight: bold;
    }
  }
  .zero-content-img {
  }
`;
