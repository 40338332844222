import styled from 'styled-components/macro';

//Components
import BaseLayoutContainer from '../../../components/layout/basicLayoutContainer';

const MyWalletContainer = styled<any>(BaseLayoutContainer as any)`
    padding-left: 0px !important;
    padding-right: 0px !important;
`

export default MyWalletContainer; 