import styled, { css } from 'styled-components/macro';

export interface IHeadingTextProps {
  children: any;
  className?: string;
  color?: string;
  textType?: string;
}

const controlTypographyDesktop = (prop?: string) => {
  let style = '';

  switch (prop) {
    case 'h1':
      style = `
      font-size: 36px;
      line-height: 44px;
        `;
      break;
    case 'h3':
      style = `
      font-size: 24px;
      line-height: 29px;
      font-weight: 600;
        `;
      break;
    case 'h5':
      style = `
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
        `;
      break;

    default:
      style = `
        font-size: 14px;
      `;
  }
  return style;
};

export const HeadingText = styled.div<IHeadingTextProps>`
  font-family: 'gilroy';
  ${(props: IHeadingTextProps) => controlTypographyDesktop(props.textType)};

  ${(props: IHeadingTextProps) => css`
    color: ${props.color};
  `}
`;

HeadingText.defaultProps = {
  children: null
}

export default HeadingText;
