import React from 'react';
import BuyDuesContainer from './buy-dues-style';
import { IBuyDuesProps } from './buy-dues-types';
import { SVGIcon } from '../svg-icon/svg-icon';
import { icons } from '../../assets/themes';
import CircleStat from '../circle-stat/circle-stat';
import moment from 'moment';
import { formatMoney } from '../../utils/utils';
import { statuses } from '../../constants/constants';

const BuyDues: React.FC<IBuyDuesProps> = (props: IBuyDuesProps) => {
  const { className, title, isolated, payments } = props;

  return (
    <BuyDuesContainer className={`${className} ${isolated ? 'isolated' : ''}`}>
      {isolated && (
        <div className="heading">
          <div className="title">{title}</div>
          <div className="icon">
            <SVGIcon icon={icons.infoIcon} color="blue" scale="1.5"/>
          </div>
        </div>
      )}

      <div className="wrapper-items">
        {payments && payments.map((payment, index: any) =>{
          return (
            <div className={`wrapper-info ${payment.status === 'CANCELED' && 'disabled'}`}>
              <div className="due">
                { payment.status === 'CANCELED' ?
                <SVGIcon icon={icons.rejectedIcon} color="#B3B3B6" scale="1.5"/>
                :
                <CircleStat 
                  radius={17} 
                  stroke={3} 
                  progress={((index + 1) * 100) / Number(payment.type ? payment?.type?.substr(0, 1) : 4)}
                  fill={payment.status === 'SUCCESSFUL' ? '#1A4CCD' :  '#CA3976' }
                />
                }
 
                <p>Cuota {payment.paymentIndex}/{Number(payment.type ? payment?.type?.substr(0, 1) : 4)}</p>
              </div>
              <div className="date">{moment(payment.frontendDate ? payment.frontendDate : payment.dueDate).format('L')}</div>
              <div>{statuses[payment.status]}</div>
              <div
                  className={`price ${
                    payment.status === 'SUCCESSFUL' ? 'successful' : payment.status === 'CANCELED' ? 'disabled' : ''
                  }`}
                >
                  {formatMoney(payment.amount)}
                </div>
          </div>
          );
        })}
      </div>
    </BuyDuesContainer>
  );
};

export default BuyDues;
