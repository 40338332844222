import React from "react";
import { CircleStatStyle } from "./circle-stat-style";

interface ICircleStatProps {
  radius: number;
  stroke: number;
  progress: number;
  fill?: string;
  text?: string;
  span?: string;
  status?: string;
}

const CircleStat: React.FC<ICircleStatProps> = ({
  radius,
  stroke,
  progress,
  fill,
  text,
  span,
  status,
}) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <CircleStatStyle>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke="#B3B3B6"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset: 0 }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          />
      </svg>
      { text && span &&
        <div className="text">
          <p>
            {text}
            <span>
              {span}<br/>
              { status === 'CANCELED' && <p>CANCELADA</p>}
            </span>
          </p>
        </div>
      }
      <div className="circle-progress">
        <svg height={radius * 2} width={radius * 2}>
          <circle
            stroke={fill ? fill : '#1A4CCD'}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            style={{ strokeDashoffset }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
      </div>
    </CircleStatStyle>
  );
};

export default CircleStat;
