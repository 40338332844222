import React from 'react';
import HomeOptionsContainer from './home-options-style';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelectors } from '../../../../store/auth/selectors';
import { IUser } from '@aluxion-lightpay/interfaces';


const HomeOptions: React.FC = () => {
    const { firstName, lastName } = useSelector(authSelectors.getMe()) as IUser;

    return(
        <HomeOptionsContainer id="home-options">
            <div id="home-options-main-text">
                <h1>Hola, <span className="highlight">{`${firstName} ${lastName}`}</span></h1>
                <p>Compra lo que te gusta y empieza a pagarlo a tu manera.</p>
            </div>
            <div id="home-options-images">
                <div className="image one">
                    <div id="aviable-stores" className="image-box">
                        {/* <div className="caption">
                            <div>
                                <h3>Tiendas Disponibles</h3>
                            </div>
                            <div>
                                <h3>+</h3>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="image two">
                    {/* <Link to="/perfil/configuracion"> */}
                        <div id="notifications-config" className="image-box">
                            {/* <div className="caption">
                                <div>
                                    <h3>Configura tus notificaciones</h3>
                                </div>
                                <div>
                                    <h3>+</h3>
                                </div>
                            </div> */}
                        </div>
                    {/* </Link> */}

                    <Link to="/mi-cartera">
                        <div id="add-new-payments" className="image-box">
                            <div className="caption">
                                <div>
                                    <h3>Añade nuevas tarjetas para tus compras</h3>
                                </div>
                                <div>
                                    <h3>+</h3>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </HomeOptionsContainer>
    )
}

export default HomeOptions;