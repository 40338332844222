import styled from "styled-components";

export const ModalErrorStyle = styled.div`
  max-width: 680px;
  .modal-error-title {
    font-size: 25px;
    line-height: 22px;
    text-align: center;
    color: #1a4ccd;
    margin-bottom: 12px;
    font-weight: 600;
  }

  .modal-error-text {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #34353d;
    font-weight: 300;
  }
`;
