import styled from 'styled-components/macro';
import { IMainButtonContainerProps } from './main-button-types';
import { buttonColors } from '../../assets/colors/button-colors/button-colors';

const MainButtonContainer = styled.button<IMainButtonContainerProps>`
  cursor: pointer;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;

  &.primary {
    background: ${buttonColors.primary.default};
    border: 1px solid ${buttonColors.primary.default};
    color: white;

    &:hover {
      border: 1px solid ${buttonColors.primary.hover};
      background: ${buttonColors.primary.hover};
      color: grey;
    }

    &.disabled {
      border: 1px solid ${buttonColors.primary.disabled};
      background: ${buttonColors.primary.disabled};
      cursor: initial;
      &:hover {
        color: white;
      }
    }
  }

  &.secondary {
    background: ${buttonColors.secondary.default};
    border: 1px solid ${buttonColors.secondary.text};

    .main-button-text {
      color: ${buttonColors.secondary.text};
    }

    &:hover {
      border: 1px solid ${buttonColors.secondary.hover};

      .main-button-text {
        color: ${buttonColors.secondary.hover};
      }
    }

    &.disabled {
      border: 1px solid ${buttonColors.secondary.disabled};

      .main-button-text {
        color: ${buttonColors.secondary.disabled};
      }
    }
  }
  &.ghost {
    border: 1px solid ${buttonColors.secondary.text};
    box-shadow: none;
    .main-button-text {
      color: ${buttonColors.secondary.text};
    }

    &:hover {
      border: 1px solid ${buttonColors.secondary.hover};

      .main-button-text {
        color: ${buttonColors.secondary.hover};
      }
    }

    &.disabled {
      border: 1px solid ${buttonColors.secondary.disabled};

      .main-button-text {
        color: ${buttonColors.secondary.disabled};
      }
    }
  }
  &.basic {
    background: ${buttonColors.secondary.default};
    border: 0px solid ${buttonColors.secondary.text};
    box-shadow: none;
    display: inline;
    .main-button-text {
      color: ${buttonColors.secondary.text};
    }

    &:hover {
      border: 0px solid ${buttonColors.secondary.hover};

      .main-button-text {
        color: ${buttonColors.secondary.hover};
      }
    }

    &.disabled {
      border: 0px solid ${buttonColors.secondary.disabled};

      .main-button-text {
        color: ${buttonColors.secondary.disabled};
      }
    }
  }

  &.tertiary {
    display: block;
    background: transparent;
    border: none;
    box-shadow: none;
    border-radius: 0;
    text-align: left;
    font-weight: bold;

    .main-button-text {
      color: ${ props => props.tertiaryColor ? props.tertiaryColor : buttonColors.secondary.text};
    }

    &:hover {
      .main-button-text {
        color: ${buttonColors.secondary.hover};
      }
    }

    &.disabled {
      .main-button-text {
        color: ${buttonColors.secondary.disabled};
      }
    }
  }

  &.error {
    background: ${buttonColors.error.default};
    border: 1px solid ${buttonColors.error.default};

    &:hover {
      background: ${buttonColors.error.hover};
      border: 1px solid ${buttonColors.error.hover};
    }

    &.disabled {
      border: 1px solid ${buttonColors.primary.disabled};
      background: ${buttonColors.primary.disabled};
    }
  }

  &.alert {
    background: ${buttonColors.alert.default};
    border: 1px solid ${buttonColors.alert.default};

    &:hover {
      background: ${buttonColors.alert.hover};
      border: 1px solid ${buttonColors.alert.hover};
    }

    &.disabled {
      border: 1px solid ${buttonColors.primary.disabled};
      background: ${buttonColors.primary.disabled};
    }
  }

  &.success {
    background: ${buttonColors.success.default};
    border: 1px solid ${buttonColors.success.default};

    &:hover {
      background: ${buttonColors.success.hover};
      border: 1px solid ${buttonColors.success.hover};
    }

    &.disabled {
      border: 1px solid ${buttonColors.primary.disabled};
      background: ${buttonColors.primary.disabled};
    }
  }

  .main-button-text {
    font-family: 'Gilroy';
    font-size: 16px;
    line-height: 19px;
    color: 'white';
  }
`;

export default MainButtonContainer;
