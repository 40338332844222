import React from "react";
import { useHistory } from 'react-router-dom';
import MainButton from "../../main-button/main-button";
import PasswordBox from "../../form-components/password-box/password-box";
import ModalContainer from "../modal-container/modal-container";
import { ModalCodeStyle } from "./modal-code-style";
import { IModalCodeProps } from './modal-code-types'
import { useForm } from "react-hook-form";
import { CODE_NUMBER_INPUT_NAME, getCodeNumberInputMetadata } from "../../../containers/authorization/auth/Auth-inputs-names";
import { getCodeNumberDataFormErrors } from "../../../containers/authorization/auth/Auth-inputs-validations";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth/actions";
import { modalActions } from "../../../store/modal/actions";
import amplitude from 'amplitude-js'


const ModalCode: React.FC<IModalCodeProps> = ({ modalConfig, onClick }) => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };

  const handleData = async (data:any) => {
    try {
      await dispatch(authActions.checkPhone(data[CODE_NUMBER_INPUT_NAME]));
      amplitude.getInstance().logEvent('phone verified');
      closeModal();
      history.push('/registro/finalizado')
      // onClick && onClick();
    }catch (error) {
      amplitude.getInstance().logEvent('error encountered', {'error_type': 'account', 'error_reason': JSON.stringify(error)});
      console.log(error)
    }
  }

  const handleCodeSent = async (data:any) => {
    try {
      await dispatch(authActions.getCodePhone());
    }catch(error){
      console.log(error)
    }
  }

  return (
    <ModalContainer {...modalConfig}>
      <ModalCodeStyle>
        <div className="modal-code-title">
          <span>¡Bienvenido a SlightPay!</span>
          <p>
            Te hemos enviado un código de verificación por SMS, por favor,
            introdúcelo a continuación:
          </p>
        </div>

        <div className="modal-code-text">
          <p>
            Además, en tu email te espera un link para que puedas acceder a tu
            cuenta de SlightPay.
          </p>
        </div>
        <div className="modal-code-input">
          <PasswordBox
            name={CODE_NUMBER_INPUT_NAME}
            labelText="código de verificación"
            onKeyPress={handleSubmit(handleData)}
            inputRef={register(
              getCodeNumberInputMetadata(CODE_NUMBER_INPUT_NAME)
            )}
            errorCode={getCodeNumberDataFormErrors(errors, CODE_NUMBER_INPUT_NAME)}
          />
        </div>
        <div className="modal-code-send">
          <div className="modal-code-send__text">
            <p>¿No te ha llegado el código? </p>
          </div>
          <div
            className="modal-code-send__button"
            onClick={handleCodeSent}
          >
            <p>Reenviar código</p>
          </div>
        </div>
        <div className="modal-code-button">
          <MainButton
            text="Verificar y registrar"
            type="primary"
            onClick={handleSubmit(handleData)}
          />
        </div>
      </ModalCodeStyle>
    </ModalContainer>
  );
};
export default ModalCode;
