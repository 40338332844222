import styled from 'styled-components/macro';

const InventoryItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  .img {
    margin-right: 16px;

    img {
      width: 64px;
      height: 64px;
    }
  }

  .wrapper-info {
    flex-grow: 1;
    display: flex;
    flex-direction: wrap;

    .wrapper-product {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        font-size: 14px;
        line-height: 22px;
        color: #1a1c24;
      }

      .quantity {
        font-size: 16px;
        line-height: 24px;
        color: #b3b3b6;
      }
    }

    .price {
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      color: #1a1c24;
    }
  }
`;

export default InventoryItemContainer;
