import styled from 'styled-components/macro';
import cardBase from '../card-base-style';

interface ICardStatisticsContainerTypes {
  lost?: boolean;
}

const CardStatisticsContainer = styled<any>(cardBase)<ICardStatisticsContainerTypes>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .wrapper-overtitle {
    display: flex;
    flex-direction: wrap;
    position: relative;

    .overtitle {
      font-size: 18px;
      line-height: 22px;
      font-weight: bold;
      color: ${props => props.theme.colors.onSurface};
      padding-right: 32px;
    }

    .icon {
      width: 24px;
      height: 24px;
      align-self: flex-end;
      position: absolute;
      right: 0;
      top: -8px;

      svg {
        cursor: pointer;
      }
    }
  }

  .title {
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    color: ${props => props.theme.colors.onSurface};
    margin: 8px 0;

    &.danger {
      color: #CA3976
    }
  }

  .info {
    color: ${props => (props.lost ? '#CA3976' : '#4fb2c6')};

    .infoNumber {
      font-size: 18px;
      line-height: 26px;
      margin-right: 8px;
    }

    .infoText {
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }
`;

export default CardStatisticsContainer;
