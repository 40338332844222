import { useDispatch, useSelector} from "react-redux";
import { paymentSidebarActions } from "../../store/paymentSidebar/actions";
import { paymentSidebarSelector } from "../../store/paymentSidebar/selectors";
import { useHistory } from "react-router-dom";

const useMainInfoSidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSidebarOpen = useSelector(paymentSidebarSelector.isOpen());
  const isSidebarLoading = useSelector(paymentSidebarSelector.isLoading());
  const selectedPurchase = useSelector(paymentSidebarSelector.getCurrentPurchased());

  const openMainInfoSidebar = async (id: number | string) => {
    try {
      dispatch(paymentSidebarActions.openSidebar());
      await dispatch(paymentSidebarActions.getCurrentPurchasedData(id));
    }catch (error) {
      console.error(error);
    }
  }

  const closeMainInfoSidebar = () => {
    dispatch(paymentSidebarActions.closeSidebar());
    history.push('/');
  }

  const isMainInfoSidebarOpen = () => {
    return isSidebarOpen
  }

  const isMainInfoSidebarLoading = () => {
    return isSidebarLoading
  }

  return {
    openMainInfoSidebar,
    closeMainInfoSidebar,
    isMainInfoSidebarOpen,
    isMainInfoSidebarLoading,
    selectedPurchase
  }
}


export default useMainInfoSidebar;