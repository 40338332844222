import React from 'react';
import { GridMasonryStyle } from './grid-masonry-style';
import { IGridMansoryProps } from './grid-masonry-types';

const GridMasonry: React.FC<IGridMansoryProps> = (props: IGridMansoryProps) => {
  const { title, subtitle, arrayImages } = props;
  return (
    <GridMasonryStyle>
      <div className="masonry">
        {arrayImages.map((img: string, index: number) => {
          return (
            <div className={`d${index} item`} key={index}>
              <img src={img} alt="" />
            </div>
          );
        })}
        <div className="blackSquare item">
          <p>
            <span>
              {subtitle}
              <br />
            </span>
            {title}
          </p>
        </div>
      </div>
    </GridMasonryStyle>
  );
};

export default GridMasonry;
