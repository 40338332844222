import moment from 'moment';
import 'moment/locale/es.js';
import React, { Component } from 'react';
import { CalendarItemContainer } from './calendar-item-style';
import { icons } from '../../assets/themes'
import { isValidDate } from '../../utils/validation';
import { capitalizeFirstLetter, copyOf } from '../../utils/date';

moment.locale('es');
interface ICalendarItemProps {
  onSelect: (value: string, closeCalendar: boolean, secondValue?: string) => void;
  initialValue?: string;
  forbidFutureDates?: boolean;
  forbidPastDates?: boolean;
  rangeSelect?: boolean;
  minAge?: number;
  maxAge?: number;
  className?: string;
  multiSelect?: boolean;
  onChangeMulti?: (values: string[]) => void;
}

class CalendarItem extends Component<ICalendarItemProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showYears: false,
      showMonths: false,
      rangeSelectStep: 0,
      currentDate: moment(),
      secondDate: props.rangeSelect ? moment() : null,
      auxDate: moment(),
      multiValues: [],
    };
  }

  public componentDidMount() {
    const { currentDate } = this.state;
    const { minAge } = this.props;

    if (minAge) {
      const startDate = moment(currentDate).subtract(minAge, 'years');
      this.setState({ currentDate: startDate, auxDate: startDate });
    }
  }

  public componentDidUpdate() {
    const { currentDate } = this.state;
    const { initialValue, rangeSelect } = this.props;

    if (!rangeSelect && initialValue && isValidDate(initialValue)) {
      const initialDate = moment(initialValue, 'DD[/]MM[/]YYYY').toDate();
      if (initialDate.getTime() !== currentDate.getTime()) {
        if (rangeSelect) {
          this.setState({ currentDate: initialDate, secondDate: initialDate });
        } else {
          this.setState({ currentDate: initialDate });
        }
      }
    }
  }

  public isAllowSelect(date: any) {
    const { forbidFutureDates, minAge, forbidPastDates } = this.props;
    if (minAge && Math.floor(moment().diff(date, 'years', true)) < minAge) {
      return false;
    }
    // if (forbidFutureDates && dateFns.isFuture(date)) {
    //   return false;
    // }
    // if (forbidPastDates && dateFns.isPast(date)) {
    //   return false;
    // }
    return true;
  }

  public renderHeader() {
    const dateFormatMonth = 'MMMM';
    const dateFormatYear = 'YYYY';

    return (
      <div className="calendar-header">
        {/* Current Month */}
        <div className="calendar-header__date">
          <div className="current-month">
            <p onClick={() => this.setState({ showMonths: true })}>
              {capitalizeFirstLetter(moment(this.state.auxDate).format(dateFormatMonth))}
            </p>
            {/* <div className="pick-arrow top">
            <img src={images.arrowUpSvg} alt="Elegir mes" onClick={() => this.prevMonth()} />
            </div>
            <div className="pick-arrow bottom">
            <img src={images.arrowDownSvg} alt="Elegir mes" onClick={() => this.nextMonth()} />
          </div> */}
          </div>
          {/* Current Year */}
          <div className="current-year">
            <p onClick={() => this.setState({ showYears: true })}>
              {capitalizeFirstLetter(moment(this.state.auxDate).format(dateFormatYear))}
            </p>
            {/* <div className="pick-arrow top" onClick={() => this.nextYear()}> */}
            {/* al hacer click, meter clase active a .year-select */}
            {/* <img src={images.arrowUpSvg} alt="Elegir año" />
          </div>
        <div className="pick-arrow bottom" onClick={() => this.prevYear()}> */}
            {/* al hacer click, meter clase active a .year-select */}
            {/* <img src={images.arrowDownSvg} alt="Elegir año" />
          </div> */}
          </div>
        </div>
        {/* Swipe Month */}
        <div className="calendar-arrow-container">
          {/* Arrow Left */}
          <div className="arrows arrow-left" onClick={() => this.prevMonth()}>
            <img src={icons.calendarIcon} alt="" />
          </div>
          {/* Arrow Right */}
          <div className="arrows arrow-right" onClick={() => this.nextMonth()}>
            <img src={icons.calendarIcon} alt="" />
          </div>
        </div>
      </div>
    );
  }

  public renderDaysHeader() {
    const dateFormat = 'ddd';
    const days = [];
    // "L","M","X","J","V","S","D"
    const startDate = moment(this.state.auxDate).startOf('week');

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="calendar-days-day" key={i}>
          <div>
            <p>
              {moment(startDate)
                .add(i, 'days')
                .format(dateFormat)}
            </p>
          </div>
        </div>,
      );
    }
    return <div className="calendar-days">{days}</div>;
  }

  public renderCells() {
    const { rangeSelect, multiSelect } = this.props;
    const { currentDate, secondDate, rangeSelectStep, auxDate, multiValues } = this.state;
    const monthStart = moment(auxDate).startOf('month');
    const monthEnd = moment(monthStart).endOf('month');
    const startDate = moment(monthStart).startOf('week');
    const endDate = moment(monthEnd).endOf('week');
    const dateFormat = 'DD';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';
    let multiSelected = false;
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = moment(day).format(dateFormat);
        const cloneDay = moment(day).clone();
        if (multiSelect) {
          multiSelected = false;
          multiValues.forEach((multiValue: string) => {
            if (!multiSelected) {
              multiSelected = moment(day).isSame(moment(multiValue), 'day');
            }
          });
        }
        days.push(
          // selected
          // last
          // added-hours
          // closed
          // absence
          // national
          // municipal
          // other
          <div
            className={`col cell 
              ${
                !moment(day).isSame(monthStart, 'month')
                  ? ' disabled'
                  : !this.isAllowSelect(day)
                  ? ' no-allow'
                  : multiSelected
                  ? 'selected'
                  : currentDate &&
                    !multiSelect &&
                    ((rangeSelect && rangeSelectStep !== 0) || !rangeSelect) &&
                    (moment(day).isSame(currentDate, 'date') || (secondDate && moment(day).isSame(secondDate, 'date')))
                  ? ' selected'
                  : (rangeSelect &&
                      currentDate &&
                      secondDate &&
                      moment(currentDate).isAfter(day, 'day') &&
                      moment(secondDate).isBefore(day, 'day')) ||
                    (moment(currentDate).isBefore(day, 'day') &&
                      moment(secondDate).isAfter(day, 'date') &&
                      !multiSelect)
                  ? ' in-selected'
                  : ' '
              }
            `}
            key={moment(day).format('DD-MM-YYYY')}
            onClick={() => this.onDateClick(cloneDay)}
          >
            {/* Days */}
            <div>{formattedDate}</div>
          </div>,
        );
        day = moment(day).add(1, 'day');
      }
      rows.push(
        <div className="row" key={moment(day).format('DD-MM-YYYY')}>
          {days}
        </div>,
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  public renderSelectYear() {
    const { minAge, maxAge } = this.props;
    const { auxDate } = this.state;
    const currentYear = new Date().getFullYear();
    const maxYear = minAge ? currentYear - minAge : 2050;
    const minYear = maxYear - (maxAge || 150);
    const years = [];
    for (let i = maxYear; i > minYear; i--) {
      const child = [<p key={i}>{i}</p>];
      years.push(
        <li
          className={`${!this.isAllowSelect(auxDate) ? 'no-allow' : ''}`}
          key={i}
          onClick={() => {
            const selected = moment(auxDate).set('year', i);
            this.onDateClick(selected, true);
          }}
        >
          {child}
        </li>,
      );
    }

    const { showYears } = this.state;

    return (
      <div className={`year-select ${showYears ? 'active' : ''}`}>
        <div className="year-select-close" onClick={() => this.setState({ showYears: false })}>
          <img src={icons.arrowDownGrey} alt="cerrar seleccion de año" />
        </div>
        <ul>{years}</ul>
      </div>
    );
  }

  public renderSelectMonth() {
    const { showMonths, auxDate } = this.state;
    let tempDate = moment().set('month', 0);
    const rows = [];
    let months = [];
    for (let i = 0; i < 12; i++) {
      const monthDate = moment(auxDate).set('month', i);
      months.push(
        <div
          className={`select-month-container-item ${!this.isAllowSelect(monthDate) ? 'no-allow' : ''}`}
          key={i}
          onClick={() => {
            const selected = moment(auxDate).set('month', i);
            this.onDateClick(selected, true);
          }}
        >
          <p>{moment(tempDate).format('MMMM')}</p>
        </div>,
      );
      tempDate = moment(tempDate).add('month', 1);

      if ((i + 1) % 3 === 0) {
        rows.push(
          <div className="select-month-container-row" key={i}>
            {months}
          </div>,
        );
        months = [];
      }
    }

    return <div className={`select-month-container ${showMonths ? 'active' : ''}`}>{rows}</div>;
  }

  public handleMultipleOnSelect(closeCalendar: boolean) {
    const { currentDate, secondDate } = this.state;
    const { onSelect } = this.props;
    if (moment(currentDate).isBefore(secondDate)) {
      onSelect(
        moment(currentDate).format('DD[/]MM[/]YYYY'),
        closeCalendar,
        moment(secondDate).format('DD[/]MM[/]YYYY'),
      );
    } else {
      onSelect(
        moment(secondDate).format('DD[/]MM[/]YYYY'),
        closeCalendar,
        moment(currentDate).format('DD[/]MM[/]YYYY'),
      );
    }
  }

  public onDateClick(date: any, auxDate?: boolean) {
    const { onSelect, rangeSelect, multiSelect, onChangeMulti } = this.props;
    const { rangeSelectStep, multiValues } = this.state;
    if (auxDate) {
      this.setState({
        auxDate: date,
        showMonths: false,
        showYears: false,
      });
    } else if (rangeSelect) {
      if (rangeSelectStep === 0) {
        this.setState(
          {
            rangeSelectStep: 1,
            currentDate: date,
            secondDate: date,
            showMonths: false,
            showYears: false,
          },
          () => {
            this.handleMultipleOnSelect(false);
          },
        );
      } else if (rangeSelectStep === 1) {
        this.setState(
          {
            rangeSelectStep: 2,
            secondDate: date,
            showMonths: false,
            showYears: false,
          },
          () => {
            this.handleMultipleOnSelect(true);
          },
        );
      } else {
        this.setState({
          rangeSelectStep: 0,
          currentDate: new Date(),
          secondDate: new Date(),
          showMonths: false,
          showYears: false,
        });
      }
    } else if (multiSelect && onChangeMulti) {
      let newMultiValues = copyOf(multiValues);
      const isoDate = date.toISOString();
      if (newMultiValues.includes(isoDate)) {
        newMultiValues = newMultiValues.filter((val: string) => val !== isoDate);
      } else newMultiValues.push(isoDate);
      this.setState({ multiValues: newMultiValues, showMonths: false, showYears: false }, () => {
        onChangeMulti(newMultiValues);
      });
    } else if (this.isAllowSelect(date)) {
      this.setState(
        {
          selectedCurrentDate: true,
          currentDate: date,
          showMonths: false,
          showYears: false,
        },
        () => {
          onSelect(moment(this.state.currentDate).format('DD[/]MM[/]YYYY'), true);
        },
      );
    }
  }

  public nextMonth() {
    const nextMonth = moment(this.state.auxDate).add('month', 1);

    if (this.isAllowSelect(nextMonth)) {
      this.setState(
        {
          auxDate: nextMonth,
        },
        () => {
          // onSelect(dateFns.format(this.state.currentDate, 'DD[/]MM[/]YYYY'), false);
        },
      );
    }
  }

  public prevMonth() {
    const prevMonth = moment(this.state.auxDate).subtract('month', 1);

    if (this.isAllowSelect(prevMonth)) {
      this.setState(
        {
          auxDate: prevMonth,
        },
        () => {
          // onSelect(dateFns.format(this.state.currentDate, 'DD[/]MM[/]YYYY'), false);
        },
      );
    }
  }

  public nextYear = () => {
    const { onSelect } = this.props;
    const nextYear = moment(this.state.auxDate).add('year', 1);

    if (this.isAllowSelect(nextYear)) {
      this.setState(
        {
          auxDate: nextYear,
        },
        () => {
          onSelect(moment(this.state.auxDate).format('DD[/]MM[/]YYYY'), false);
        },
      );
    }
  };

  public prevYear = () => {
    const { onSelect } = this.props;
    const prevYear = moment(this.state.auxDate).subtract('year', 1);

    if (this.isAllowSelect(prevYear)) {
      this.setState(
        {
          auxDate: prevYear,
        },
        () => {
          onSelect(moment(this.state.auxDate).format('DD[/]MM[/]YYYY'), false);
        },
      );
    }
  };

  public render() {
    const { rangeSelect, className } = this.props;
    return (
      <CalendarItemContainer className={`${rangeSelect ? 'range' : ''} ${className}`}>
        <div className="calendar">
          {this.renderHeader()}
          {this.renderDaysHeader()}
          {this.renderCells()}
          {this.renderSelectYear()}
          {this.renderSelectMonth()}
        </div>
        <div className="calendar-legend">
          <div className="calendar-legend-item national">
            <div className="calendar-legend-item-color" />
            <div className="calendar-legend-item-text">
              <p>Festivo nacional</p>
            </div>
          </div>
          <div className="calendar-legend-item municipal">
            <div className="calendar-legend-item-color" />
            <div className="calendar-legend-item-text">
              <p>Festivo municipal</p>
            </div>
          </div>
          <div className="calendar-legend-item other">
            <div className="calendar-legend-item-color" />
            <div className="calendar-legend-item-text">
              <p>Otros tipos de cierres</p>
            </div>
          </div>
        </div>
      </CalendarItemContainer>
    );
  }
}

export default CalendarItem;
