import React from 'react';
import CreditCard from '../credit-card/credit-card';
import { CardPayMethodStyle } from './card-pay-method-style';
import { ICardPayMethodProps } from './card-pay-method-types';
import { useSelector } from 'react-redux';
import { transactionSelector } from '../../../store/transaction/selector';
import { ITransactionPurchase } from '../../../store/transaction/types';

const CardPayMethod: React.FC<ICardPayMethodProps> = props => {
  const { dateEnd, lastDigits, bankName, cardType, cardBrand, onClick, onDelete, cardSelected, options, status } = props;
  const { items } = useSelector(transactionSelector.getTransactionDataTable()) as ITransactionPurchase;

  const handleClick = () => {
    onClick &&
    cardSelected !== items[0]?.payments[3].card?.id &&
    onClick();
  }
  
  const handleDelete = () => {
    onDelete && onDelete();
  }

  let text = ''
  if(status) {
    switch (status) {
      case 'SUCCESSFUL':
        text = 'exitoso';
        break;
      case 'CANCELED':
        text = 'cancelado';
        break;
      case 'CANCELED_PENDING':
        text = 'cancelado';
        break;
      case 'DRAFT':
        text = 'preparando';
        break;
      case 'EXPIRED':
        text = 'expirada';
        break;
      case 'PENDING':
        text = 'en proceso';
        break;
      case 'CHARGEBACK_REJECTED':
        text = 'contracargo';
        break;
      case 'CHARGEBACK_ACCEPTED':
        text = 'contracargo';
        break;
      case 'CHARGEBACK_PENDING':
        text = 'contracargo';
        break;
      case 'REFUNDED_TOTALLY':
        text = 'devolución';
        break;
      case 'REFUNDED_PENDING':
        text = 'devolución';
        break;
      case 'REFUNDED_PARTIALLY':
        text = 'devolución';
        break;
      case 'INDEBTED':
        text = 'incobrable';
        break;
    }
  }

  return (
    <CardPayMethodStyle options={options}>
      <div className="card-pay-method-wrapper">
          {Array.isArray(items) && items.length !== 0 && options &&
          <div 
            className={`card-pay-method-subtitle ${cardSelected !== items[0]?.payments[3].card?.id && 'active'}`} 
            onClick={handleClick}
          >
              <p> {cardSelected === items[0]?.payments[3].card?.id ? 'TARJETA PREDETERMINADA' : 'SELECCIONAR COMO TARJETA PREDETERMINADA' }</p>
          </div>
          }
        <div className="card-pay-method-title">
          <p>
            {cardBrand.toUpperCase()} **** {lastDigits}
          </p>
        </div>
        <div className="card-pay-method-date">
          <p>{dateEnd}</p>
        </div>
        <div className="card-pay-method-item">
          <div className="card-pay-method-item__title">
            <p>banco emisor</p>
          </div>
          <div className="card-pay-method-item__text">
            <p>{bankName}</p>
          </div>
        </div>
        <div className="card-pay-method-item">
          <div className="card-pay-method-item__title">
            <p>tipo</p>
          </div>
          <div className="card-pay-method-item__text">
            <p>{cardType === 'debit' ? 'Débito' : 'Crédito'}</p>
          </div>
        </div>
        {
          status && 
          <div className="card-pay-method-item">
            <div className="card-pay-method-item__title">
              <p>ESTATUS DE PAGO</p>
            </div>
            <div className="card-pay-method-item__text">
              <p>{text.toUpperCase()}</p>
            </div>
          </div>
        }

      </div>
      <div className="card-pay-method-card">
        <CreditCard alias={bankName} validDate={dateEnd} lastFourDigits={lastDigits} type={cardBrand}/>
        {
          options &&
            <div className="card-pay-method-card__delete" onClick={handleDelete}>
              <div className="card-pay-method-item__delete-title">
                <p>ELIMINAR TARJETA</p>
              </div>
            </div>
        }

      </div>
    </CardPayMethodStyle>
  );
};

export default CardPayMethod;
