import React from 'react';
import CardImageShadow from '../cards/card-image-shadow/card-image-shadow';
import { ZeroContentStyle } from './zero-content-style';
import { images } from '../../assets/images/images';
import { IMainButtonProps } from '../main-button/main-button-types';
import MainButton from '../main-button/main-button';

interface IZeroContent {
  icon?: any;
  children?: any;
  text?: any;
  image: string;
  button?: IMainButtonProps;
  shadowColor?: string;
  email?: string;
}

const ZeroContent: React.FC<IZeroContent> = ({ image, children, icon, text, button, shadowColor, email }) => {
  return (
    <ZeroContentStyle>
      <div className="zero-content-icon">{icon}</div>
      <div className="zero-content-top">
        <div className="zero-content-title">
          <p>{children}</p>
        </div>
        {text && (
          <div className="zero-content-text">
            <p>{text}</p>
          </div>
        )}
        {email && (
          <div className="zero-content-text">
            <p>
              Puedes escribirnos a <span>{email}</span> con tu problema y te contestaremos lo antes posible.
            </p>
          </div>
        )}
      </div>
      <div className="zero-content-img">
        <CardImageShadow image={image ? image : images.image404} shadowColor={shadowColor} />
      </div>
      {button && (
        <div className="zero-content-button">
          <MainButton {...button} />
        </div>
      )}
    </ZeroContentStyle>
  );
};

export default ZeroContent;
