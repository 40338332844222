import { NestDataObject } from "react-hook-form";

// FOR NEW CREDIT CARD
export const getNewCardFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  if (inputErrors[inputName]?.type === 'isValidSelect') return 'required';
  if (inputErrors[inputName]?.type === 'isValidNumber') return 'invalid-format';
  if (inputErrors[inputName]?.type === 'invalidMonth') return 'invalid-month';
  if (inputErrors[inputName]?.type === 'invalidYear') return 'invalid-year';
  if (inputErrors[inputName]?.type === 'isValidCard') return 'invalid-card';

  

  return inputErrors[inputName]?.message;
};