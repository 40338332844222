import React from 'react';
import CreditCardContainer from './credit-card-style';
import { icons } from '../../../assets/themes';
import { CreditCardProps } from './credit-card-types';



const CreditCard: React.FC<CreditCardProps> = (props: CreditCardProps) => {
    const {
        alias, 
        lastFourDigits, 
        validDate,
        type
    } = props; 

    return (
        <CreditCardContainer>
            <div className="credit-card-topbar">
                <div className="alias">
                    <p>{ alias }</p>
                </div>

                <div className="visa-icon">
                    { type === 'visa' ? (
                        <img src={icons.creditCardVisa} alt="credicard type"/>
                    ) : type === 'mastercard' ? (
                        <img src={icons.mastercardIcon} alt="credicard type"/>
                    ) :  <img src={icons.amexIcon} alt="credicard type"/>
                }
                    
                </div>
            </div>

            <div className="credit-card-number">

                <div className="private-number">
                    <p>****</p>
                </div>

                <div className="private-number">
                    <p>****</p>
                </div>

                <div className="private-number">
                    <p>****</p>
                </div>

                <div className="last-digits">
                    <p>{ lastFourDigits } </p>
                </div>

            </div>

            <div className="credit-card-bottom">
                <div className="valid-date-label">
                    <p>VÁLIDA HASTA</p>
                </div>

                <div className="valid-date">
                    { validDate }
                </div>
            </div>
        </CreditCardContainer>
    )
}   

export default CreditCard; 