import styled from 'styled-components/macro';
import cardBase from '../card-base-style';

const CardUserContainer = styled<any>(cardBase)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .img {
    width: 90px;
    height: 90px;
    margin-bottom: 12px;
    border-radius: 100%;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 100%;
    }
  }

  .title {
    font-size: 20px;
    line-height: 26px;
    color: #34353d;
    font-weight: 600;
  }

  .info_email {
    font-size: 16px;
    line-height: 22px;
    color: #b3b3b6;
    font-weight: 100;
    margin: 2.5px 0;
  }

  .info_telephone {
    font-size: 16px;
    line-height: 22px;
    margin: 2.5px 0;
  }

  .button {
    font-size: 20px;
    color: #1a4ccd;
    margin: 20px 0;
    /* cursor: pointer; */
    font-weight: 600;
  }
`;

export default CardUserContainer;
