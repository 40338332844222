import styled from 'styled-components/macro';


const MyActivityPaymentScheduleContainer = styled.div`
  .payments-schedule-content {
    position: relative;

    .main-table-header-top {
      position: absolute;
      right: 0px;
      top: -85px;
    }
  }

  .no-data-container {
    text-align: center;
    margin-top: 80px;
  }

  .zero-content-title {
    margin: 30px auto;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    max-width: 320px;
    color: #01030c;
    text-align: center;
    span {
      color: #1a4ccd;
    }
  }

  .pagination {
    margin-top: 15px;   
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .pagination-count {
      margin: 0px;
      color: ${props => props.theme.colors.secondary};
      font-size: 0.75rem;
      font-weight: 400;
    }
  }

`;

export default MyActivityPaymentScheduleContainer;