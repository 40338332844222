import styled from 'styled-components';

export const LatePaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  box-shadow: none;
  border-radius: 0px;

  .wrapper-items {
    width: 100%;
    display: block;
    border-bottom: 1px solid #e6e6e7;
    border-top: 1px solid #e6e6e7;
    overflow: hidden;
    overflow-x: auto;

    .wrapper-info { 
      display: grid;
      grid-template-columns: 100px repeat(7, 180px);
      grid-template-rows: 30px;
      
      margin: 20px 0;
      align-items: center;

      &.light {
        color: #B3B3B6;
      }

      .card {
        word-break: break-all;
        font-size: 12px;
      }

      .status {
        color: #CA3976;
        &.success {
          color: #1A4CCD;
        }
      }

      .button {
        text-align: center;
      }

      .price {
        line-height: 22px;
        text-align: right;
        color: #CA3976;
        font-weight: 500;
        &.successful {
          color: #1A4CCD
        }
      }
    }

  }

  .pagination {
    margin-top: 15px;   
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .pagination-count {
      margin: 0px;
      color: ${props => props.theme.colors.secondary};
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
`;