import React, { useState, useEffect, useCallback } from 'react';
import UpdateAddressContainer from './update-address-style';
import InputBox from '../../../../../components/form-components/input-box/input-box';
import MainButton from '../../../../../components/main-button/main-button';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FormGridRow } from '../../../../../components/form-grid-row/form-grid-row';
import {
    CARD_NUMBER_INPUT_NAME,
    getNewCardInputMetadata,
    CARD_HOLDERNAME_INPUT,
    CARD_CVV_INPUT_INPUT,
    CARD_EXP_MONTH_INPUT,
    CARD_EXP_YEAR_INPUT,
    CARD_ADDRESS_LINE1_INPUT,
    CARD_ADDRESS_PC_INPUT,
    CARD_ADDRESS_LINE2_INPUT,
    CARD_ADDRESS_CITY_INPUT,
    CARD_ADDRESS_STATE_INPUT
} from '../../profile-personal-inputs-names';
import { getNewCardFormErrors } from '../../profile-personal-inputs-validation';
import SelectBox from '../../../../../components/form-components/select-box/select-box';
import { STATES, IUser } from '@aluxion-lightpay/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../../../../store/auth/selectors';
import ModalErrorCard from '../../../../../components/modals/modal-error/modal-error';
import { isValidNumber } from '../../../../../utils/validation';
import { loaderActions } from '../../../../../store/loader/actions';
import ModalInfo from '../../../../../components/modals/modal-info/modal-info';
import { transactionActions } from '../../../../../store/transaction/actions';
import useModal from '../../../../../hooks/useModal/use-modal';
import { cardActions } from '../../../../../store/card/actions';
import { cardSelector } from '../../../../../store/card/selectors';
import { CardActions } from '../../../../../store/card/types';
import moment from 'moment';
import amplitude from 'amplitude-js'
import ModalErrorCustom from '../../../../../components/modals/modal-error/modal-error-custom';

interface IModalDataType {
    title: string;
    onClick: () => void;
}

const UpdateAddress: React.FC<any> = (props: any) => {
    const { register, handleSubmit, errors, control, watch } = useForm();
    const { id } = useSelector(authSelectors.getMe()) as IUser;
    const dispatch = useDispatch();
    const cardNumberData = watch(CARD_NUMBER_INPUT_NAME);
    const [modalData, setModalData] = useState<IModalDataType>();
    const history = useHistory();
    const { isModal, openModal, closeModal } = useModal();
    const newCard = useSelector(cardSelector.getLastCardData());
    const hasDebts = useSelector(cardSelector.hasDebts());
    const cards = useSelector(cardSelector.getCardData());
    const [version, setVersion] = useState<number>(0)
    const [title, setTitle] = useState<string>('')
    const [cardsData, setCardsData] = useState<any>([])
    const [cardValidation, setCardValidation] = useState<boolean>(false)
    let maxlen: number = 30;

    const validateMonth = (value: string) => {
        if (!isValidNumber(value)) return false
        if (parseInt(value) > 12) {
            return false
        }
    }

    const validateYear = (value: string) => {
        if (!isValidNumber(value)) return false
        if (parseInt(value) < parseInt(moment().format('YY'))) {
            return false;
        }
        if (parseInt(value) > parseInt(moment().format('YY')) + 19) {
            return false;
        }
    }

    const cleanData = useCallback(async () => {
        dispatch({ type: CardActions.ADD_DEBTS_DATA, payload: null });
        dispatch({ type: CardActions.ADD_LASTCARD_DATA, payload: null });
        closeModal();
        history.push('/mi-cartera');
    }, [closeModal, dispatch, history])

    useEffect(() => {
        const handleDebts = async () => {
            try {
                await dispatch(transactionActions.payAllDebts(newCard?.id!));
                cleanData();
            } catch (error) {
                console.log(error);
                if (error?.code === 'OPENPAY_EXCEPTION') {
                    setTitle(error?.message)
                    openModal('error');
                } else if (error?.reasons[0]?.validation === 'OpenPay regreso un error') {
                    setVersion(1)
                    openModal('error-custom');
                } else if (error?.reasons[0]?.validation === 'Tiene 4 intentos de OpenPay') {
                    setVersion(2)
                    openModal('error-custom');
                } else if (error?.reasons[0]?.validation === 'No puede adelantar cuotas') {
                    setVersion(3)
                    openModal('error-custom');
                  } else {
                    openModal('error');
                }
            }
        }

        if (newCard && hasDebts) {
            setModalData({
                title: '¿Deseas pagar todas las deudas pendientes con esta tarjeta?',
                onClick: handleDebts,
            })

            openModal('payDebts');
        } else if (newCard && !hasDebts) {
            dispatch({ type: CardActions.ADD_LASTCARD_DATA, payload: null });
            history.push('/mi-cartera');
        }

    }, [newCard])
    // cleanData, dispatch, hasDebts, history, isModal, newCard, openModal

    useEffect(() => {
        amplitude.getInstance().logEvent('add card to wallet');
        console.log(cards)
    }, [])

    const handleForm = async (data: any) => {
        let cardData: any = {
            address: {
                city: data[CARD_ADDRESS_CITY_INPUT],
                line1: data[CARD_ADDRESS_LINE1_INPUT],
                postalCode: data[CARD_ADDRESS_PC_INPUT],
                state: data[CARD_ADDRESS_STATE_INPUT],
            },
            cardNumber: data[CARD_NUMBER_INPUT_NAME].replace(/\s/g, ''),
            cvv2: data[CARD_CVV_INPUT_INPUT],
            expirationMonth: parseInt(data[CARD_EXP_MONTH_INPUT]),
            expirationYear: parseInt(data[CARD_EXP_YEAR_INPUT]),
            holderName: data[CARD_HOLDERNAME_INPUT],
        };
        if (data[CARD_ADDRESS_LINE2_INPUT]) {
            cardData = {
                ...cardData,
                address: {
                    ...cardData.address,
                    line2: data[CARD_ADDRESS_LINE2_INPUT]
                }
            }
        }
        let lastDigits = cardData.cardNumber.slice(-4)
        let duplicate: boolean = false;
        cards.map(item => {
        if(item.lastDigits === lastDigits){
            setCardValidation(true)
            console.log('Tarjeta duplicada')
            duplicate = true;
        }      
        })
        try {
            dispatch(loaderActions.loading());
            (window as any).OpenPay.token.create({
                expiration_month: cardData.expirationMonth,
                expiration_year: cardData.expirationYear,
                cvv2: cardData.cvv2,
                card_number: cardData.cardNumber,
                holder_name: cardData.holderName,
                address: {
                    country_code: "MX",
                    city: cardData.address.city,
                    postal_code: cardData.address.postalCode,
                    line1: cardData.address.line1,
                    state: cardData.address.state,
                }

            }, async (response: any) => {
                await dispatch(cardActions.addCard(id, cardData, response.data.id, duplicate));
                amplitude.getInstance().logEvent('payment card info added to wallet', { payment_method: response });
            },
                (error: any) => {
                    amplitude.getInstance().logEvent('error encountered', { 'error_type': 'payment card info', 'error_reason': JSON.stringify(error) });
                    dispatch(loaderActions.loaded());
                    openModal('errorBank');
                }
            );

        } catch (error) {
            amplitude.getInstance().logEvent('error encountered', { 'error_type': 'payment card info', 'error_reason': JSON.stringify(error) });
            console.log(error);
            openModal('error');
        }

    };



    return (
        <UpdateAddressContainer>
            <div className="update-address-wrapper">
                <h1 className="update-address-title">
                    Indíquenos los <span>datos de la tarjeta</span>
                </h1>
                <div className="new-credit-card-form">
                    <FormGridRow pattern="1fr">
                        <Controller
                            as={
                                <InputBox
                                    labelText="Nº de tarjeta"
                                    name={CARD_NUMBER_INPUT_NAME}
                                    // inputRef={register(getNewCardInputMetadata(CARD_NUMBER_INPUT_NAME))}
                                    errorCode={getNewCardFormErrors(errors, CARD_NUMBER_INPUT_NAME)}
                                    cleaveFormat={{
                                        creditCard: true,
                                    }}
                                />
                            }
                            name={CARD_NUMBER_INPUT_NAME}
                            control={control}
                            rules={getNewCardInputMetadata(CARD_NUMBER_INPUT_NAME)}
                        />

                    </FormGridRow>
                    <FormGridRow pattern="1fr">
                        <InputBox
                            labelText="Nombre del titular"
                            name={CARD_HOLDERNAME_INPUT}
                            inputRef={register(getNewCardInputMetadata(CARD_HOLDERNAME_INPUT))}
                            errorCode={getNewCardFormErrors(errors, CARD_HOLDERNAME_INPUT)}
                        />
                    </FormGridRow>
                    <FormGridRow pattern="1fr 1fr 1fr">
                        <InputBox
                            labelText="cvv"
                            name={CARD_CVV_INPUT_INPUT}
                            inputRef={register(getNewCardInputMetadata(CARD_CVV_INPUT_INPUT, {},
                                cardNumberData ? cardNumberData.substring(0, 2) === '34' || cardNumberData.substring(0, 2) === '37' ? true : false : false
                            ))}
                            errorCode={getNewCardFormErrors(errors, CARD_CVV_INPUT_INPUT)}
                            maxLength={cardNumberData ? cardNumberData.substring(0, 2) === '34' || cardNumberData.substring(0, 2) === '37' ? 4 : 3 : 3}
                        />
                        <Controller
                            as={
                                <InputBox
                                    labelText="mm"
                                    name={CARD_EXP_MONTH_INPUT}
                                    errorCode={getNewCardFormErrors(errors, CARD_EXP_MONTH_INPUT)}
                                    cleaveFormat={{
                                        date: true,
                                        datePattern: ['m'],
                                    }}
                                />
                            }
                            name={CARD_EXP_MONTH_INPUT}
                            control={control}
                            rules={getNewCardInputMetadata(CARD_EXP_MONTH_INPUT, { invalidMonth: validateMonth })}
                        />

                        {/* <InputBox
                            labelText="yy"
                            name={CARD_EXP_YEAR_INPUT}
                            inputRef={register(getNewCardInputMetadata(CARD_EXP_YEAR_INPUT, {invalidYear: validateYear }))}
                            errorCode={getNewCardFormErrors(errors, CARD_EXP_YEAR_INPUT)}
                            maxLength={2}
                        /> */}
                        <Controller
                            as={
                                <InputBox
                                    labelText="yy"
                                    name={CARD_EXP_YEAR_INPUT}
                                    errorCode={getNewCardFormErrors(errors, CARD_EXP_YEAR_INPUT)}
                                    cleaveFormat={{
                                        numeral: true,
                                        blocks: [2],
                                    }}
                                    maxLength={2}
                                />
                            }
                            name={CARD_EXP_YEAR_INPUT}
                            control={control}
                            rules={getNewCardInputMetadata(CARD_EXP_YEAR_INPUT, { invalidYear: validateYear })}
                        />

                    </FormGridRow>
                    <div className="modal-adress-title">
                        <h1 className="update-address-title">
                            Indíquenos la <span>dirección asociada a esta tarjeta</span>
                        </h1>
                    </div>
                    <div className="modal-adress-form">
                        <FormGridRow pattern="1fr">
                            <InputBox
                                labelText="Calle y número"
                                name={CARD_ADDRESS_LINE1_INPUT}
                                inputRef={register(getNewCardInputMetadata(CARD_ADDRESS_LINE1_INPUT))}
                                errorCode={getNewCardFormErrors(errors, CARD_ADDRESS_LINE1_INPUT)}
                                maxLength={maxlen}
                            />
                        </FormGridRow>
                        <FormGridRow pattern="1fr 1fr" patternResponsive="1fr">
                            <InputBox
                                labelText="CP"
                                name={CARD_ADDRESS_PC_INPUT}
                                inputRef={register(getNewCardInputMetadata(CARD_ADDRESS_PC_INPUT))}
                                errorCode={getNewCardFormErrors(errors, CARD_ADDRESS_PC_INPUT)}
                            />
                            <InputBox
                                labelText="Colonia (opcional)"
                                name={CARD_ADDRESS_LINE2_INPUT}
                                inputRef={register(getNewCardInputMetadata(CARD_ADDRESS_LINE2_INPUT))}
                                errorCode={getNewCardFormErrors(errors, CARD_ADDRESS_LINE2_INPUT)}
                            />
                        </FormGridRow>
                        <FormGridRow pattern="1fr 1fr" patternResponsive="1fr">
                            <InputBox
                                labelText="Ciudad"
                                name={CARD_ADDRESS_CITY_INPUT}
                                inputRef={register(getNewCardInputMetadata(CARD_ADDRESS_CITY_INPUT))}
                                errorCode={getNewCardFormErrors(errors, CARD_ADDRESS_CITY_INPUT)}
                            />
                            <Controller
                                as={
                                    <SelectBox
                                        name={CARD_ADDRESS_STATE_INPUT + 'inner'}
                                        labelText="Estado"
                                        optionsArray={STATES}
                                        errorCode={getNewCardFormErrors(errors, CARD_ADDRESS_STATE_INPUT)}
                                    />
                                }
                                name={CARD_ADDRESS_STATE_INPUT}
                                control={control}
                                rules={getNewCardInputMetadata(CARD_ADDRESS_STATE_INPUT)}
                            />
                        </FormGridRow>
                    </div>
                </div>
                {cardValidation &&
                <div className="credit-card-duplicated">
                    <p>Parece que esta tarjeta ya ha sido agregada, prueba agregando otra.</p>
                </div>
                }
                <div className="update-address-buttons">
                    <div className="btn-cancel">
                        <Link to='/mi-cartera'>
                            <MainButton type="secondary" text="Cancelar" />
                        </Link>
                    </div>

                    <div className="btn-confirm">
                        <MainButton type="primary" text="Confirmar" onClick={handleSubmit(handleForm)} />
                    </div>
                </div>
            </div>
            <ModalErrorCard
                title={title}
                modalConfig={{
                    onClose: () => {
                        closeModal();
                        openModal('payDebts');
                    },
                    active: (isModal?.show && isModal?.idModal === 'error') || false,
                }}
            />

            <ModalErrorCard
                modalConfig={{
                    onClose: closeModal,
                    active: (isModal?.show && isModal?.idModal === 'errorBank') || false,
                }}
                title='Por favor, pongase en contacto con su banco.'
            />

            <ModalErrorCustom
                version={version}
                modalConfig={{
                    onClose: () => {
                        closeModal();
                        openModal('payDebts');
                    },
                    active: (isModal?.show && isModal?.idModal === 'error-custom') || false,
                }}
            />

            <ModalInfo
                modalConfig={{
                    active: (isModal?.show && isModal?.idModal === 'payDebts') || false,
                    onClose: () => cleanData(),
                }}
                title={modalData?.title || ''}
                text=""
                buttons={[
                    {
                        text: 'No',
                        type: 'ghost',
                        onClick: () => cleanData(),
                    },
                    {
                        text: 'Sí',
                        type: 'primary',
                        onClick: () => modalData?.onClick(),
                    },
                ]}
            />


        </UpdateAddressContainer>
    )
}

export default UpdateAddress;