import React, { useEffect, useState,useCallback } from "react";
import { FormGridRow } from "../../form-grid-row/form-grid-row";
import InputBox from "../../form-components/input-box/input-box";
import { IModalUserInfoProps } from './modal-modify-user-info-types';
import MainButton from "../../main-button/main-button";
import ModalContainer from "../modal-container/modal-container-userinfo";
import { ModalAdressStyle } from "./modal-modify-user-info-style";
import SelectBox from "../../form-components/select-box/select-box";
import CalendarBox from "../../../components/calendar-box";
import { toastActions } from '../../../store/toasts/actions';
import pug from "../../../assets/images/files/pugv2.png";
import {
  AUTO_LOAN_INPUT_NAME,
  BIRTHDATE_INPUT_NAME,
  CITY_INPUT_NAME,
  COLONY_INPUT_NAME,
  CP_INPUT_NAME,
  FIRST_NAME_INPUT_NAME,
  getRegisterInputMetadata,
  INE_INPUT_NAME,
  LAST_NAME_INPUT_NAME,
  SECOND_LAST_NAME_INPUT_NAME,
  LIGHTPAY_TERMS_POLICY_INPUT_NAME,
  MORTGAGE_LOAN_INPUT_NAME,
  OWNS_TDC_INPUT_NAME,
  PASSWORD_INPUT_NAME,
  REPEAT_PASSWORD_INPUT_NAME,
  RFC_INPUT_NAME,
  STATE_INPUT_NAME,
  STREET_INPUT_NAME,
  TDC_INPUT_NAME,
  CONFIRM_PASSWORD_INPUT_NAME,
  PHONE_NUMBER_INPUT_NAME
} from "../../../containers/authorization/auth/Auth-inputs-names";
import { useForm, Controller } from "react-hook-form";
import { STATES } from "@aluxion-lightpay/interfaces";
import { useSelector, useDispatch, useStore } from 'react-redux';
import { authSelectors } from "../../../store/auth/selectors";
import { modalActions } from "../../../store/modal/actions";
import useModal from "../../../hooks/useModal/use-modal";
import { isValidNumber } from "../../../utils/validation";
import moment from 'moment';
import { loaderActions } from "../../../store/loader/actions";
import { getRegisterFormErrors } from "../../../containers/authorization/auth/Auth-inputs-validations";
import { IRegisterForm } from "../../../store/auth/types";
import { selectCity } from "../../../utils/selectCity";
import { authActions } from "../../../store/auth/actions";
import { IPurchase } from "@aluxion-lightpay/interfaces";

const ModalModifyUserData: React.FC<IModalUserInfoProps> = (props: IModalUserInfoProps) => {
  const { modalConfig, onClick, times, idCard } = props
  const dispatch = useDispatch();
  const me: any = useSelector(authSelectors.getMe());
  // const  id  = 234567;
  const { register, handleSubmit, errors, control, watch, setValue } = useForm();
  const stateSelected = watch(STATE_INPUT_NAME);
  const municipalitySelect = selectCity(stateSelected);
  // const { isModal, openModal, closeModal } = useModal();
  const [disabledButton, setDisabledButton] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const store = useStore();
  let [hasErrorRFC, setHasErrorRFC] = useState<string>('')
  let [colonies, setColonies] = useState<any>([]);
  let maxlen: number = 30;
  const cpInput = watch(CP_INPUT_NAME) as string;

  let callBackCP = useCallback(async (cp = '0') => {
    if (cp.length === 5) {
      let result = await authActions.getDataPostalCode(cp);

      let arrayColony: any = []
      result.map((colony: any) => {
        arrayColony.push(colony?.NomAsentamiento);
      });

      console.log(arrayColony, 'enviado a setColonies')
      setColonies(arrayColony)

      setValue(STATE_INPUT_NAME, result[0]?.CatMunicipio?.CatEstado?.NomEstado);
      setValue(CITY_INPUT_NAME, result[0]?.CatMunicipio?.NomMunicipio);
      if (arrayColony.length === 0) {
        console.log('si es 0')
        setDisabled(false);
      } else {
        setDisabled(true);
        setValue(STATE_INPUT_NAME, result[0]?.CatMunicipio?.CatEstado?.NomEstado);
        setValue(CITY_INPUT_NAME, result[0]?.CatMunicipio?.NomMunicipio);
  
      }
    } 
  }, []);

  useEffect(() => {
    callBackCP(cpInput)
  }, [cpInput]);

  useEffect(() => {
    console.log(me, 'informacion del usuario')
    setValue(CP_INPUT_NAME, me?.address?.zipCode);
  }, [])

  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };

  const validateMonth = (value: string) => {
    if (!isValidNumber(value)) return false
    if (parseInt(value) > 12) {
      return false
    }
  }

  const validateYear = (value: string) => {
    if (!isValidNumber(value)) return false
    if (parseInt(value) < parseInt(moment().format('YY'))) {
      return false;
    }
    if (parseInt(value) > parseInt(moment().format('YY')) + 19) {
      return false;
    }
  }

  const handleForm = async (data: any) => {
    let flag = false

    // DEFINE SCHEMA TO FORMS
    console.log(data[CONFIRM_PASSWORD_INPUT_NAME]);
    const registerFormData = {
      name: data[FIRST_NAME_INPUT_NAME],
      surname: data[LAST_NAME_INPUT_NAME],
      secondSurname: data[SECOND_LAST_NAME_INPUT_NAME],
      rfc: data[RFC_INPUT_NAME],
      cp: data[CP_INPUT_NAME],
      colony: data[COLONY_INPUT_NAME],
      municipality: data[CITY_INPUT_NAME],
      street: data[STREET_INPUT_NAME],
      state: data[STATE_INPUT_NAME],
    };

    try {
      setHasErrorRFC('')
      let rfc = data[RFC_INPUT_NAME]
      let dateRfc = rfc.substr(4, 6)
      let year = Number(dateRfc.substr(0, 2)) <= 99 && Number(dateRfc.substr(0, 2)) >= 21 ? String(1900 + Number(dateRfc.substr(0, 2))) : String(2000 + Number(dateRfc.substr(0, 2)))
      let month = dateRfc.substr(2, 2)
      let day = dateRfc.substr(4, 2)

      let arrayBirth = data[BIRTHDATE_INPUT_NAME].split('/')

      if (year !== arrayBirth[2]) throw new Error('La fecha de nacimiento no coincide con el RFC');
      if (month !== arrayBirth[1]) throw new Error('La fecha de nacimiento no coincide con el RFC');
      if (day !== arrayBirth[0]) throw new Error('La fecha de nacimiento no coincide con el RFC');

      console.log(data[RFC_INPUT_NAME], data[BIRTHDATE_INPUT_NAME])

      let flag = false

      // DEFINE SCHEMA TO FORMS
      console.log(data[CONFIRM_PASSWORD_INPUT_NAME]);
      const registerFormData = {
        name: data[FIRST_NAME_INPUT_NAME],
        surname: data[LAST_NAME_INPUT_NAME],
        secondSurname: data[SECOND_LAST_NAME_INPUT_NAME],
        rfc: data[RFC_INPUT_NAME],
        cp: data[CP_INPUT_NAME],
        colony: data[COLONY_INPUT_NAME],
        municipality: data[CITY_INPUT_NAME],
        street: data[STREET_INPUT_NAME],
        state: data[STATE_INPUT_NAME],
        birthDate: data[BIRTHDATE_INPUT_NAME]
      };
  
      try {
        await dispatch(authActions.updateUser(registerFormData));
        // modalConfig?.onClose && modalConfig.onClose();
        setDisabledButton(true)
        await dispatch(authActions.sendBuroDeCredito());
        // await dispatch(paymentActions.confirmPurchase( idCard, purchasesData.id ))
        // openModal('successBuy')
  
      } catch (error) {
        console.log(error);
        // Here we would have
        // modalConfig?.onClose && modalConfig.onClose();
      }

    } catch (error) {
      console.log(error, 'el error del handle')
      setHasErrorRFC('invalid-date-rfc')
    }
  }

  const checkData = (value: any, type: any, obj: any = null) => {
    obj ?
    value !== me?.[obj]?.[type] && setDisabledButton(false)
    : value !== me?.[type] && setDisabledButton(false)
  }

  return (
    <ModalContainer {...modalConfig}>
      <div>
        <img src={pug} style={{ width: '100%', padding: '20px' }} />
        <div className='error-title'>¡Oops... alguno de tus datos personales no es correcto!<div style={{ fontWeight: 'initial' }}> Por favor revísalos nuevamente y da click en Actualizar Información</div></div>
      </div>
      <ModalAdressStyle>
        <div className="new-credit-card-form">
          <p style={{ color: '#1A4CCD', fontSize: '18px' }}>¡Ingresa tu Nombre Completo, no usar abreviaturas o símbolos, y en tu dirección sólo Calle y Número!</p>
          <div className="new-credit-card-title">
            <p style={{ marginBottom: '10px', marginTop: '25px', fontSize: '18px' }}>Datos personales</p>
          </div>

          <div className="new-credit-card-form">
            <FormGridRow pattern="1fr 1fr" patternResponsive="1fr">
              <InputBox
                labelText="Nombre"
                name={FIRST_NAME_INPUT_NAME}
                inputRef={register(
                  getRegisterInputMetadata(FIRST_NAME_INPUT_NAME)
                )}
                errorCode={getRegisterFormErrors(errors, FIRST_NAME_INPUT_NAME)}
                value={me?.firstName}
                onChange={(obj: any) => { checkData(obj.currentTarget.value, 'firstName') }}
              />
              <InputBox
                labelText="Apellido Paterno"
                name={LAST_NAME_INPUT_NAME}
                inputRef={register(
                  getRegisterInputMetadata(LAST_NAME_INPUT_NAME)
                )}
                errorCode={getRegisterFormErrors(errors, LAST_NAME_INPUT_NAME)}
                value={me?.lastName}
                onChange={(obj: any) => { checkData(obj.currentTarget.value, 'lastName') }}
              />
              <InputBox
                labelText="Apellido Materno"
                name={SECOND_LAST_NAME_INPUT_NAME}
                inputRef={register(
                  getRegisterInputMetadata(SECOND_LAST_NAME_INPUT_NAME)
                )}
                errorCode={getRegisterFormErrors(errors, SECOND_LAST_NAME_INPUT_NAME)}
                value={me?.secondLastName}
                onChange={(obj: any) => { checkData(obj.currentTarget.value, 'secondLastName') }}
              />
              <Controller
                as={
                  <CalendarBox
                    onChange={value => {
                      setDisabledButton(false)
                    }}
                    className="col-sm-12 form-input"
                    initialValue={`${me?.birthDate?.substr(0,10).split('-').reverse()[0]}/${me?.birthDate?.substr(0,10).split('-').reverse()[1]}/${me?.birthDate?.substr(0,10).split('-').reverse()[2]}`}
                    labelText="Fecha de nacimiento"
                    placeholder="día/mes/año"
                    minAge={18}
                    errorCode={getRegisterFormErrors(
                      errors,
                      BIRTHDATE_INPUT_NAME
                    )}
                  />
                }
                defaultValue={`${me?.birthDate?.substr(0,10).split('-').reverse()[0]}/${me?.birthDate?.substr(0,10).split('-').reverse()[1]}/${me?.birthDate?.substr(0,10).split('-').reverse()[2]}`}
                onChange={data => {
                  setDisabledButton(false)
                  return data[0];
                }}
                name={BIRTHDATE_INPUT_NAME}
                control={control}
                rules={getRegisterInputMetadata(BIRTHDATE_INPUT_NAME)}
              />
            </FormGridRow>
            <FormGridRow pattern="1fr">
              <InputBox
                labelText="RFC"
                name={RFC_INPUT_NAME}
                inputRef={register(getRegisterInputMetadata(RFC_INPUT_NAME))}
                errorCode={getRegisterFormErrors(errors, RFC_INPUT_NAME) || hasErrorRFC}
                value={me?.rfc}
                onChange={(obj: any) => { checkData(obj.currentTarget.value, 'rfc') }}
              />
            </FormGridRow>
          </div>
          <div className="new-credit-card-title">
            <p style={{ marginBottom: '10px', fontSize: '18px' }}>Dirección</p>
          </div>
          <div className="new-credit-card-form">
            <FormGridRow pattern="1fr">
              <InputBox
                labelText="Calle y Número"
                name={STREET_INPUT_NAME}
                inputRef={register(getRegisterInputMetadata(STREET_INPUT_NAME))}
                errorCode={getRegisterFormErrors(errors, STREET_INPUT_NAME)}
                value={me?.address?.street}
                onChange={(obj: any) => { checkData(obj.currentTarget.value, 'street', 'address') }}
                maxLength={maxlen}
              />
            </FormGridRow>
            <FormGridRow pattern="1fr 1fr" patternResponsive="1fr">
              <InputBox
                labelText="CP"
                name={CP_INPUT_NAME}
                inputRef={register(getRegisterInputMetadata(CP_INPUT_NAME))}
                errorCode={getRegisterFormErrors(errors, CP_INPUT_NAME)}
                onChange={(obj: any) => { checkData(obj.currentTarget.value, 'zipCode', 'address') }}
              />
              {disabled ?
                <Controller
                  as={
                    <SelectBox
                      name={COLONY_INPUT_NAME + 'inner'}
                      optionsArray={colonies}
                      labelText="Colonia"
                      disabled={!cpInput}
                    />
                  }
                  name={COLONY_INPUT_NAME}
                  control={control}
                /> :
                <InputBox
                  labelText="Colonia"
                  name={COLONY_INPUT_NAME}
                  inputRef={register(getRegisterInputMetadata(COLONY_INPUT_NAME))}
                  errorCode={getRegisterFormErrors(errors, COLONY_INPUT_NAME)}
                />}
            </FormGridRow>
            <FormGridRow pattern="1fr 1fr" patternResponsive="1fr">
            {disabled ?
                <InputBox
                  labelText="estado"
                  name={STATE_INPUT_NAME}
                  inputRef={register(getRegisterInputMetadata(STATE_INPUT_NAME))}
                  errorCode={getRegisterFormErrors(errors, STATE_INPUT_NAME)}
                  disabled
                /> :
                <Controller
                  as={
                    <SelectBox
                      name={STATE_INPUT_NAME + "inner"}
                      labelText="Estado"
                      optionsArray={STATES}
                      errorCode={getRegisterFormErrors(errors, STATE_INPUT_NAME)}
                    />
                  }
                  name={STATE_INPUT_NAME}
                  control={control}
                  rules={getRegisterInputMetadata(STATE_INPUT_NAME)}
                />
              }

              {
                disabled ?
                  <InputBox
                    name={CITY_INPUT_NAME}
                    labelText={'Municipio'}
                    inputRef={register(getRegisterInputMetadata(CITY_INPUT_NAME))}
                    errorCode={getRegisterFormErrors(errors, CITY_INPUT_NAME)}
                    disabled
                  />
                  :
                  <Controller
                    as={
                      <SelectBox
                        name={CITY_INPUT_NAME + "inner"}
                        labelText={
                          stateSelected === "Ciudad de México"
                            ? "DELEGACIÓN / MUNICIPIO"
                            : "Municipio"
                        }
                        optionsArray={
                          municipalitySelect &&
                          municipalitySelect.sort((a: string, b: string) =>
                            a.localeCompare(b)
                          )
                        }
                        errorCode={getRegisterFormErrors(errors, CITY_INPUT_NAME)}
                        disabled={
                          stateSelected === undefined ||
                          stateSelected === "Seleccionar"
                        }
                      />
                    }
                    name={CITY_INPUT_NAME}
                    control={control}
                    rules={getRegisterInputMetadata(CITY_INPUT_NAME)}
                  />
              }
              
            </FormGridRow>
          </div>
          <div className="new-credit-card-title">
          </div>
          <div className="new-credit-card-form">
            <div className="new-credit-card-button">
              <MainButton
                className="form-button"
                type="primary"
                text="Actualizar informacion"
                onClick={handleSubmit(handleForm)}
                disabled={disabledButton}
              />
            </div>
            {
            times >= 2 &&
            <>
              <p style={{ color: '#1A4CCD', marginBottom: '20px', marginTop: '55px' }}>Si tu información es correcta, da click en Continuar</p>
              <div className="new-credit-card-form">
                <div className="new-credit-card-button">
                  <MainButton
                    className="form-button"
                    type="primary"
                    text="Continuar"
                    onClick={() => {
                      localStorage.setItem('buro_validation', 'true')
                      onClick()
                    }
                  }
                  />
                </div>
              </div>
            </>
          }
          </div>
        </div>
      </ModalAdressStyle>
    </ModalContainer>
  );
};
export default ModalModifyUserData;
