import React from 'react';
import WalletAsideContainer from './wallet-aside-style';

//Components
import MainButton from '../../../components/main-button/main-button';
import { Link } from 'react-router-dom';


//Constants
import CreditCard from '../../cards/credit-card/credit-card';
import CreditCardInfo from '../../cards/credit-card-info/credit-card-info';
import { ICard } from '@aluxion-lightpay/interfaces';

interface IWalletAsideProps {
    card: ICard;
    currentCardStatus: boolean;
    deleteCard: () => void;
    setDefaultCard: () => void;
}

const WalletAside: React.FC<IWalletAsideProps> = (props: IWalletAsideProps) => {
    const {card, deleteCard, setDefaultCard, currentCardStatus} = props;
    return (
        <WalletAsideContainer>

            <div className="wallet-aside-credit-card">
                <CreditCard
                    alias={card.bankName}
                    lastFourDigits={card.lastDigits}
                    validDate={card.expirationDate}
                    type={card.brand}
                /> 
            </div>
            
            <div className="wallet-aside-credit-card-info">
                <CreditCardInfo
                    lastDigits={card.lastDigits} 
                    validDate={card.expirationDate} 
                    street={card.address.street1} 
                    location={card.address.city + ' - ' + card.address.state} 
                    type={card.brand}
                    deleteCard={deleteCard}
                    setDefaultCard={setDefaultCard}
                    currentCardStatus={currentCardStatus}
                />
            </div>

            <div>
                <Link to='/mi-cartera/agregar-tarjeta'>
                    <MainButton type='primary' text='Añadir nueva tarjeta'></MainButton>
                </Link>
            </div>
        </WalletAsideContainer>
    )
}

export default WalletAside;