import React, { useState, useEffect } from 'react';
import { PaginationStyles } from './pagination-style';
import { IPaginationProps } from './pagination-types';

const Pagination: React.FC<IPaginationProps> = (props: IPaginationProps) => {
  const [currentPage, setPage] = useState(1);
  const [copyFilter, setCopy] = useState();
  const { totalItems, pageLimit, nextPage, onPageChanged, reset } = props;

  const nextPageToView = nextPage ? Math.max(0, Math.min(nextPage, 2)) : 1;
  const totalPages = Math.ceil(totalItems / pageLimit);
  const paginationData = {
    currentPage,
    totalPages,
    pageLimit,
    totalItems,
  };

  useEffect(() => {
    onPageChanged(paginationData); // eslint-disable-line    
  }, [currentPage, copyFilter]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-line
    if (reset && reset !== copyFilter) {
      setCopy(reset);
      setPage(1);
    }
  },[reset]); // eslint-disable-line

  const LEFT_PAGE = '...';
  const RIGHT_PAGE = '...';

  const range = (from: number, to: number, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  const fetchPageNumbers = () => {
    const totalNumbers = nextPageToView * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - nextPageToView;
      const rightBound = currentPage + nextPageToView;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const gotoPage = (page: any) => {
    if (page !== '...' && page !== 0) {
      const newCurrentPage = Math.max(0, Math.min(page, totalPages));
      setPage(newCurrentPage);
    }
  };

  const handleMoveLeft = () => {
    gotoPage(currentPage - 1);
  };

  const handleMoveRight = () => {
    gotoPage(currentPage + 1);
  };

  const pages = fetchPageNumbers();
  return (
    <PaginationStyles>
      {totalItems !== 0 && (
        <>
          <div className="page-item">
            <button className={`page-link ${currentPage === 1 ? 'disable' : ''}`} onClick={handleMoveLeft}>
              <span className={`sr-only `}>PREV</span>
            </button>
          </div>
          <ul className="pagination">
            {pages.map((page, index) => {
              return (
                <li key={index} className="page-number">
                  <button
                    className={`page-link ${currentPage === page ? 'active' : ''}`}
                    onClick={() => gotoPage(page)}
                  >
                    {page}
                  </button>
                </li>
              );
            })}
          </ul>
          <div className="page-item">
            <button className={`page-link ${currentPage === totalPages ? 'disable' : ''}`} onClick={handleMoveRight}>
              <span className="sr-only">NEXT</span>
            </button>
          </div>
        </>
      )}
    </PaginationStyles>
  );
};

export default Pagination;
