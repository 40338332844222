import styled from 'styled-components/macro';

const ModalContainer = styled.div`
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    background: rgba(242, 242, 243, 0.99);
    z-index: 99999; 
    

    .btn-close {
        cursor: pointer; 
        position: absolute; 
        right: 59px; 
        top: 59px; 
        width: 14px; 
        height: 14px; 
        display: flex;
        z-index:9; 
    }

    .modal-content {
        padding: 59px;
        height: 100%; 
        display: flex; 
        align-items: center; 
        justify-content: center;  
    }
`;


export default ModalContainer; 