import styled from 'styled-components/macro'

const ModalProfileEditNameContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 430px;
  width: 100%;

  .edit-name-modal-text {
    font-family: 'Gilroy';
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #01030c;
    margin-bottom: 20px;
  }

  .edit-name-modal-list {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .edit-name-modal-element {
    width: 100%;
    border-bottom: 1px solid #e6e6e7;
    height: 75px;
    display: flex;
    align-items: center;

    &:first-child {
      border-top: 1px solid #e6e6e7;
    }
  }

  .radio {
    cursor: pointer;
    border-radius: 100%;
    padding: 2px;
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    margin-right: 21px;
    border: 1px solid #1a4ccd;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #1a4ccd;
      min-width: 6px;
      min-height: 6px;
      max-width: 6px;
      max-height: 6px;
    }
  }

  .radio-label {
    .radio-label-title {
      margin-bottom: 7px;
      font-family: 'Gilroy';
      font-size: 14px;
      line-height: 22px;
      color: #01030c;
    }

    .radio-label-description {
      font-family: 'Gilroy';
      font-size: 14px;
      line-height: 22px;
      color: #808185;
    }
  }

  .edit-name-modal-buttons {
    display: flex;
    align-items: center;
    width: 100%;

    .btn-cancel {
      width: calc((100% - 20px) / 2);
      margin-right: 20px;
    }

    .btn-accept {
      width: calc((100% - 20px) / 2);
    }
  }
`

export default ModalProfileEditNameContainer
