import styled from 'styled-components/macro';

//Components
import BaseLayoutContainer from '../../../../components/layout/basicLayoutContainer';

//Images 
import {images} from '../../../../assets/images/images';


const HomeOptionsContainer = styled<any>(BaseLayoutContainer as any)`
    min-height: calc(100vh - 154px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; 
    padding: 35px;

    .highlight {
        color: #1A4CCD;
    }

    #home-options-main-text {
        text-align: center;

        h1 {
            font-size: 48px;
            font-weight: 500;
        }

        p {
            font-size: 21px;
            color: #808185;
            margin-top: 10px;
            font-weight: 300;
        }
    }

    #home-options-images {
        display: flex;
        margin-top: 30px;
        width: 100%;
        max-width: 900px;

        #aviable-stores,
        #notifications-config,
        #add-new-payments {
            background-size: cover;
            background-repeat: no-repeat;
        }

        #aviable-stores {
            background-image: url(${images.homeAviableStore});
        }
        #notifications-config {
            background-image: url(${images.homeNotiConfig});
        }
        #add-new-payments {
            background-image: url(${images.homeNewPayment});
        }
        
        h3 {
            font-size: 30px;
        }


        .image {
            flex: 5;

            .image-box {
                width: 100%;
                max-width: 440px; 
                background-color: lightgrey;

                display: flex;
                align-items: flex-end;
            }

            &.one {
                margin-right: 10px;
                .image-box {
                    height: 439px;
                }
            }

            &.two {
                margin-left: 10px;
                .image-box {
                    height: 212px;
                }
            }

            #notifications-config {
                margin-bottom: 15px;
            }

            .caption {
                color: white;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                padding: 0 25px; 
                width: 100%;
                padding-bottom: 20px;

                h3 {
                    max-width: 250px;
                }
            }
        }

    }

    @media (max-width: 768px) {

        #home-options-images {
            flex-flow: column;

            #aviable-stores {
                background-image: url(${images.homeAviableStoreMobile});
            }
            #notifications-config {
                background-image: url(${images.homeNotiConfigMobile});
            }
            #add-new-payments {
                background-image: url(${images.homeNewPaymentMobile});
            }

            .image {
                margin-left: 0px;
                margin-right: 0px;

                .image-box {
                    width: 100%;
                    max-width: none;

                    align-items: center;
                }
        
                &.one {
                    .image-box {
                        height: 212px;
                    }
                }

                &.one,
                &.two { 
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-bottom: 20px;
                }

                .caption {
                    align-items: center;
                    padding-bottom: 0px;
                }
            }
        }   

    }

    @media (max-width: 576px) {

        margin-top: 74px;

        #home-options-main-text {   
            h1 {
                font-size: 36px;
                font-weight: 500;
            }
    
            p {
                font-size: 16px;
                color: #808185;
                margin-top: 10px;
                font-weight: 300;
            }
        }       
    } 
    
    @media (max-width: 480px) {
        margin-top: 56px;

        #home-options-main-text {   
            padding: 0 20px;
            h1 {
                font-size: 24px;
                font-weight: 500;
            }
    
            p {
                font-size: 16px;
                color: #808185;
                margin-top: 10px;
                font-weight: 300;
            }
        }       

        #home-options-images {

            .image {
                &.one,
                &.two {
                    .image-box {
                        height: 156px;
                    }
                }

                .caption {
    
                    h3 {
                        font-size: 26px;
                        max-width: 215px;
                    }
                }
            }
        }
    }     
`;

export default HomeOptionsContainer;