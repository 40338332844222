import React from 'react';
import { LegalStyle } from './legal-styles';

const TermsAndConditions = () => {
  return (
    <LegalStyle>
      <div className="legal-title">
        <p>Términos</p>
        <p>y condiciones</p>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>1. Objetivo</p>
        </div>
        <div className="legal-text">
          <p>
            El presente acuerdo de voluntad es suscrito entre el Usuario y la sociedad YUBO, S.A.P.I. DE C.V. contiene
            los términos y condiciones de uso de los servicios ofertados a través del sitio web https://clientes.slightpay.com/
            y tendrá plenos efectos jurídicos, validez y fuerza obligatoria (en lo sucesivo referido como el “Acuerdo”).
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>2. Definiciones</p>
        </div>
        <div className="legal-text">
          <p>
            Salvo que sean definidos en otra parte del Acuerdo, los conceptos que a continuación se definentendrán el
            significado que aquí mismo se establece, que será igualmente aplicable a la forma singular o plural:
          </p>
          <p>
            <u>Comercio:</u> Las personas físicas o morales que tienen un convenio con Slightpay y que se enlistan en 
            el siguiente vínculo{' '} 
            <a href="https://clientes.slightpay.com/" target="_blank" rel="noopener noreferrer">
              https://clientes.slightpay.com/
            </a>
          </p>
          <p>
            <u>Comisiones:</u> La definida en la sección 10 del presente Acuerdo.
          </p>
          <p>
            <u>Claves de Acceso:</u> Datos únicos, compuestos por la clave de usuario y contraseña generados de manera
            secreta por el Usuario para crear su firma electrónica, que equivalen a su firma autógrafa al utilizar los
            Servicios de Slightpay.
          </p>
          <p>
            <u>Días Hábiles:</u> Los días de lunes a viernes exceptuando los días señalados en que las entidades
            financieras sujetas a la supervisión de la Comisión Nacional Bancaria y de Valores deberán cerrar sus
            puertas y suspender operaciones.
          </p>
          <p>
            <u>Slightpay:</u> La sociedad mercantil denominada YUBO, SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN DE CAPITAL
            VARIABLE, o sus siglas “S.A.P.I. DE C.V.”, debidamente constituida conforme a las leyes de los Estados
            Unidos Mexicanos.
          </p>
          <p>
            <u>Medios Electrónicos: </u>
            <ul>
              <li>Equipo de cómputo o dispositivo móvil;</li>
              <li>La red mundial de comunicaciones conocida como “Internet”.</li>
            </ul>
          </p>
          <p>
            <u>Mensaje de Datos:</u> La información generada, enviada, recibida o archivada por medios electrónicos,
            ópticos o cualquier otra tecnología.
          </p>
          <p>
            <u>Servicios:</u> A través de los servicios, el Usuario podrá -previa autorización de Slightpay- adquirir
            productos y/o servicios de los Comercios mediante el pago del precio en 4 (cuatro) cuotas más las Comisiones
            que en su caso se generen.
          </p>
          <p>
            <u>Sitio Web:</u> Es la página de internet https://clientes.slightpay.com/ y/o aplicaciones móviles con todas
            suscaracterísticas y elementos que lo componen, propiedad de Slightpay y que se puede acceder a través de
            los Medios Electrónicos mediante los cuales los Usuarios podrán acceder al uso de los Servicios.
          </p>
          <p>
            <u>Tarjeta de Crédito:</u> Medio de disposición emitida a favor del Usuario al amparo de un contrato de
            crédito en cuenta corriente celebrado por el Usuario con una institución de crédito o una sociedad
            financiera de objeto múltiple regulada, que mantenga vínculos patrimoniales con instituciones de crédito
            autorizadas para emitir tarjetas de crédito, en los Estados Unidos Mexicanos.
          </p>
          <p>
            <u>Tarjeta de Débito:</u> Medio de disposición de depósitos a la vista emitida a favor del Usuario al
            amparode un contrato celebrado por el Usuario con una institución de crédito en los Estados Unidos Mexicanos
            que permiten iniciar una instrucción de cargo a una cuenta del Usuario.
          </p>
          <p>
            <u>Usuario:</u> Toda aquella persona física, mayor de 18 (dieciocho) años de edad en los Estados Unidos
            Mexicanos, con personalidad y capacidad legal y de ejercicio, titular de una Tarjeta de Crédito o Tarjeta de
            Débito, y que no se encuentra impedida para visualizar, acceder y utilizar el Sitio Web a través de los
            Medios Electrónicos y que accede al uso de los Servicios.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>3. Aceptación del Acuerdo</p>
        </div>
        <div className="legal-text">
          <p>
            El acceso, visualización y uso de los Servicios a través del Sitio Web requiere la aceptación del Usuario al
            Acuerdo vigente por lo que el Usuario se adhiere y obliga plenamente y sin reservas al cumplimiento de los
            presentes términos y condiciones. Es responsabilidad del Usuario conocer, aceptar y cumplir el Acuerdo antes
            de acceder a los Servicios a través del Sitio Web. Al acceder, visualizar y utilizar los Servicios en el
            Sitio Web, el Usuario reconoce y acepta expresamente el Acuerdo, por lo que si no se encuentra conforme
            deberá de abstenerse de obtener sus Claves de Acceso, utilizar el Sitio Web así como los Servicios.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>4. Claves de Acceso</p>
        </div>
        <div className="legal-text">
          <p>
            Para solicitar y utilizar los Servicios disponibles en el Sitio Web, el Usuario deberá generar de manera
            secreta una clave de usuario que junto con su contraseña personal o “password” serán sus Claves de Acceso y
            serán los datos para la creación de su firma electrónica y lo identificarán como Usuario de Slightpay.
          </p>
          <p>
            El uso de las Claves de Acceso será exclusiva responsabilidad del Usuario, quien reconoce y acepta desde
            ahora como suyas todas las operaciones que celebre en el Sitio Web utilizando las Claves de Acceso, para
            todos los efectos legales a que haya lugar. El Usuario expresamente también reconoce y acepta el carácter
            personal e intransferible de las Claves de Acceso, así como su confidencialidad. El Usuario deberá tener
            asociado a sus Claves de Acceso un método de pago que podrá ser una Tarjeta de Crédito o una Tarjeta de
            Débito a su nombre.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>5. Datos y Documentos Personales</p>
        </div>
        <div className="legal-text">
          <p>
            Para utilizar los Servicios disponibles en el Sitio Web el Usuario deberá proporcionar la información y
            documentación requerida por Slightpay. El Usuario deberá proporcionarle a Slightpay, o a la persona física o
            moral que ésta designe, toda la información y documentación requerida por Slightpay y, en caso de no
            realizarlo, Slightpay no podrá ofrecerle los Servicios. El Usuario será responsable de validar, actualizar y
            complementar periódicamente su información y documentación en el Sitio Web o cuando le sea requerida por
            Slightpay a través de un Mensaje de Datos. Slightpay no será responsable de la imprecisión de los datos y
            documentos del Usuario, reservándose la facultad de requerir documentación comprobatoria. Los datos y
            documentos personales se procesan, encriptan y almacenan en servidores o medios magnéticos que mantienen
            altos estándares de seguridad y protección tanto física como tecnológica. Para mayor información sobre la
            privacidad de los datos y documentos personales y casos en los que será revelada la información personal, se
            puede consultar el Aviso de Privacidad.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>6. Veracidad en la Información y Documentación</p>
        </div>
        <div className="legal-text">
          <p>
            El Usuario acepta y reconoce que toda la información y documentación que ingrese al Sitio Web deberá ser
            veraz, por lo que el Usuario garantiza la autenticidad de los datos y documentos que le son solicitados para
            el uso de los Servicios. El Usuario autoriza a Slightpay para que directamente o a través de terceros,
            realice cualquier investigación que considere necesaria para corroborar la veracidad de la información y
            documentación proporcionada por el Usuario. Dentro de esta investigación se podrá incluir solicitarle mayor
            información o documentación, requerir que proporcione el número de Registro Federal de Contribuyentes (RFC)
            o la Clave Única del Registro de Población (CURP).
          </p>
          <p>
            Slightpay podrá de tiempo en tiempo verificar la veracidad de la información y documentación proporcionada
            requiriéndole que confirme la titularidad del correo electrónico o número telefónico proporcionado,
            solicitarle un reporte crediticio o comparar la información y documentación con bases de datos de terceros o
            a través de otras fuentes. Slightpay se reserva el derecho de cancelar, suspender o restringir la Clave de
            Usuario en el supuesto que no pueda corroborar la veracidad de la información y documentación a satisfacción
            de Slightpay. El Usuario será el responsable de las consecuencias, daños y/o perjuicios que pudiere
            ocasionar a Slightpay y a terceros así como de las acciones judiciales o administrativas que se pudieren
            ejercer en su contra debido al registro de datos y documentos falsos o que no esté autorizado por su
            propietario o titular para utilizarlos. Slightpay no se hará responsable por errores generales o
            tipográficos en que pudiera incurrir el Usuario o por negligencia al hacer uso del Sitio Web.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>7. Previsiones con respecto a la privacidad de los usuarios registrados</p>
        </div>
        <div className="legal-text">
          <p>
            Slightpay utilizará la información proporcionada por el Usuario en forma confidencial, por lo que no podrá
            difundirla o transmitirla salvo autorización expresa del Usuario o por requerimiento de la autoridad
            competente.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>8. Consentimiento para comunicaciones electrónicas</p>
        </div>
        <div className="legal-text">
          <p>
            Cada vez que utilice un Servicio de Slightpay o envíe un correo electrónico, mensaje de texto y otras
            comunicaciones de cualquier dispositivo, estará comunicándose electrónicamente con Slightpay. El Usuario
            acepta recibir comunicaciones de Slightpay electrónicamente en una variedad de formas, por ejemplo mediante
            los siguientes tipos de Mensajes de Datos: correo electrónico, mensajes de texto, notificaciones automáticas
            de nuestra app o mediante avisos y mensajes colocados en este Sitio Web o mediante los demás Servicios de
            Slightpay. El Usuario acepta que todos los contratos, avisos, mensajes, y otras notificaciones y
            comunicaciones que le enviemos por medios electrónicos satisfacen cualquier requisito legal de forma escrita
            y cuentan con plenos efectos legales y validez.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>9. Condiciones para el uso de los servicios</p>
        </div>
        <div className="legal-text">
          <p>
            Para que el Usuario pueda hacer uso de los Servicios, adicionalmente a la aceptación del presente Acuerdo,
            el Usuario debe cumplir, al menos, con los siguientes requisitos:
          </p>
          <p>
            <u>9.1</u> Tener al menos 18 (dieciocho) años de edad;
          </p>
          <p>
            <u>9.2</u> La Tarjeta de Crédito o Tarjeta de Débito se encuentre vigente;
          </p>
          <p>
            <u>9.3</u> Tener crédito disponible en la Tarjeta de Crédito o saldo disponible 
            en la Tarjeta de Débito que al menos sea igual al Cargo definido en la sección 
            “Funcionamiento de los servicios.” de los términos y condiciones de la compraventa 
            mismos que podrá consultar en el siguiente vínculo: www.slightpay.com/terminos-y-condiciones/.
          </p>
          <p>
            <u>9.4</u> Aceptar los términos y condiciones de la compraventa mismos que podrá consultar 
            en el siguiente vínculo: www.slightpay.com/terminos-y-condiciones/.
          </p>
          <p>
            Los Servicios solamente se pueden proporcionar a Tarjetas de Crédito o Tarjeta de Débito de las denominadas
            Visa, Mastercard o American Express. No se podrá dar de alta como Medio de Pago a tarjetas de prepago ni de
            Tarjetas de Crédito y/o Tarjetas de Débito emitidas por instituciones financieras extranjeras.
          </p>
          <p>
            No obstante lo anterior, el Usuario entiende y acepta que los Servicios que solicita a Slightpay podrán ser
            aceptados, restringidos o rechazados por Slightpay de acuerdo al análisis de la información proporcionada
            por el Usuario. En caso de restringirlos, rechazarlos o rehusarlos se le informará a la brevedad posible
            mediante un Mensaje de Datos sin ninguna responsabilidad para Slightpay.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>10. Comisión por uso de los servicios</p>
        </div>
        <div className="legal-text">
          <p>
            El uso de los Servicios se encuentra sujeto a determinadas comisiones conforme se detallan en la presente 
            sección y podrán ser actualizadas por Slightpay, con 30 (treinta) días naturales de anticipación a la fecha 
            prevista para que éstas surtan efectos, a través de un Mensaje de Datos conforme se describe en la sección “Definiciones”.  
            Sin perjuicio de lo anterior, el Usuario tendrá derecho a dar por terminada la prestación de los Servicios en caso de 
            no estar de acuerdo con los nuevos montos, sin que Slightpay pueda cobrarle cantidad adicional alguna por este hecho, 
            con excepción de los adeudos que ya se hubieren generado a la fecha en que el Usuario solicite dar por terminado el Servicio.  
            Para el uso de los Servicios, el Usuario se obliga a pagar a Slightpay una comisión del 5% (cinco por ciento) sobre 
            el monto o valor de cada producto y/o servicio adquirido a través de Slightpay.
          </p>
          <p>
            En caso que de acuerdo a la Ley del Impuesto al Valor Agregado, el Usuario deba pagar tal impuesto sobre la
            Comisión, el Usuario se obliga a pagar el impuesto citado juntamente con la Comisión.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-subtitle">
          <p>11. Subcontratación</p>
        </div>
        <div className="legal-text">
          <p>
            Con el fin de facilitar los Servicios, Slightpay podrá subcontratar total o parcialmente dichos Servicios
            con otra parte, a fin de poder proporcionar los Servicios.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-subtitle">
          <p>12. Cancelación</p>
        </div>
        <div className="legal-text">
          <p>
            El Usuario podrá cancelar, en todo momento, el uso de los Servicios mediante el envío de un Mensaje de Datos
            a través del Sitio Web dentro de los 2 (dos) días hábiles siguientes a la fecha de solicitud sin
            responsabilidad alguna, siempre y cuando el Usuario no haya utilizado o recibido los beneficios de los
            Servicios. En caso que el Usuario tenga un adeudo pendiente de pago, Slightpay no cancelará los Servicios
            solicitados hasta en tanto se liquida la totalidad del adeudo generado, sin perjuicio que Slightpay pueda
            restringir total o parcialmente los Servicios.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>13. Terminación</p>
        </div>
        <div className="legal-text">
          <p>
            El Usuario podrá solicitar a Slightpay, en todo momento, la terminación de los Servicios mediante el envío
            de un Mensaje de Datos a través del Sitio Web. Slightpay proporcionará al Usuario una referencia, código o
            constancia que identifique la solicitud de terminación que señale la fecha y hora de recepción. Slightpay no
            podrá cobrar comisión, cargo y cualquier monto por concepto de terminación, salvo que existan adeudos
            pendientes por cubrir por parte del Usuario, en cuyo caso, la terminación se verificará una vez se haya
            realizado el pago correspondiente.
          </p>
          <p>
            Slightpay deberá dar a conocer al Usuario el importe adeudado a más tardar dentro de un plazo de 10 (diez)
            Días Hábiles contados a partir de la fecha de la presentación de la solicitud de terminación. En caso de no
            existir adeudos pendiente, Slightpay dará por terminado el Acuerdo al Día Hábil siguiente a la fecha de
            presentación de la solicitud por parte del Usuario.
          </p>
          <p>
            Realizado el pago del adeudo pendiente por parte del Usuario a Slightpay, Slightpay pondrá a disposición del
            Usuario un documento que dé constancia del fin de la relación contractual, de la terminación de los
            Servicios y de la inexistencia de adeudos. Slightpay podrá dar por terminado el presente Acuerdo en
            cualquier momento mediante un Mensaje de Datos a través del Sitio Web.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>14. Responsabilidad del usuario</p>
        </div>
        <div className="legal-text">
          <p>
            El Usuario asume toda la responsabilidad de cualquier quebranto que pueda sufrir él o el titular de Tarjeta
            de Crédito, como consecuencia del uso indebido de las Claves de Acceso que se le asignen, ya sea por parte
            de terceras personas no autorizadas o por personas autorizadas por el Usuario, ya que dichos elementos de
            seguridad deben ser conocidos únicamente por el Usuario.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>15. Responsabilidad de Slightpay</p>
        </div>
        <div className="legal-text">
          <p>
            El Usuario acepta que Slightpay no será responsable en caso de que no pueda efectuar operaciones por
            desperfectos o por la suspensión temporal del Sitio Web. Así mismo, Slightpay no será responsable de manera
            alguna del incumplimiento de las instrucciones del Usuario cuando esto se deba a caso fortuito o fuerza
            mayor, o bien por fallas del sistema de cómputo de Slightpay o del Usuario, o por la interrupción en los
            sistemas de comunicación, ni será responsable cuando el Usuario o personas autorizadas por éste, cometan
            errores u omisiones, en los datos o instrucciones transmitidos a Slightpay a través del Sitio Web para la
            realización de alguna operación, ni será responsable de los daños que pudiera sufrir el Usuario en su
            patrimonio por el uso indebido de las Claves de Acceso que le sean asignadas por Slightpay. El Usuario
            acepta que Slightpay en ningún caso será responsable de las comisiones, cargos, intereses ordinarios,
            intereses moratorios, impuestos y demás accesorios que en su caso se generan a cargo del Usuario por la
            institución financiera emisora de la Tarjeta de Crédito o la Tarjeta de Débito debiendo en todo momento,
            indemnizar, defender y sacar en paz y a salvo Slightpay de dichas responsabilidades.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-subtitle">
          <p>16. Usos Permitidos</p>
        </div>
        <div className="legal-text">
          <p>
            El uso y aprovechamiento de los Servicios y del Sitio Web es exclusiva responsabilidad del Usuario, quien en
            todo caso deberá utilizarlos únicamente de acuerdo a las funcionalidades permitidas en el Sitio Web y a los
            usos autorizados por el presente Acuerdo. El Usuario se obliga en todo momento a hacer uso de los Servicios
            y del Sitio Web de modo tal que no atente contra las normas de uso y convivencia en los Medios Electrónicos,
            las leyes de los Estados Unidos Mexicanos, las buenas costumbres, la dignidad de la persona y los derechos
            de terceros. El Sitio Web es para el uso exclusivo del Usuario por lo que no podrá comercializar de manera
            alguna los Servicios obtenidos de manera directa o indirecta. Slightpay no se responsabiliza de que el
            material en el Sitio Web sea apropiado o esté disponible para su uso en otros lugares, estando prohibido su
            acceso donde su contenido sea ilegal.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>17. Prohibiciones del usuario</p>
        </div>
        <div className="legal-text">
          <p>
            El Usuario se compromete a hacer el uso adecuado de los Servicios que ofrece Slightpay a través del Sitio
            Web y a no emplearlos en actividades ilícitas. El Usuario no contará en ningún momento con el derecho de
            colocar hipervínculos en el Sitio Web, ni el derecho de utilizar los Servicios en otros sitios o páginas de
            internet, propias o de terceros, sin autorización expresa, previa y por escrito de Slightpay. Asimismo, el
            Usuario no tendrá el derecho de limitar o impedir a cualquier otro Usuario el uso del Sitio Web. El Usuario
            tendrá estrictamente prohibido interferir directa o indirectamente con las operaciones, bases de datos
            actividades y/o contenidos del Sitio Web y Slightpay. El Usuario se obliga a no proporcionar información y/o
            documentación que pertenezcan a otra persona; a no utilizar las Claves de Acceso que pertenezcan a otra
            persona y a no aperturar o utilizar múltiples Claves de Acceso.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>18. Exactitud de la información</p>
        </div>
        <div className="legal-text">
          <p>
            Slightpay no ofrece garantía alguna respecto de la exactitud de la información contenida en el Sitio Web por
            lo que no asume ninguna responsabilidad por lo erróneo en los Mensajes de Datos del Sitio Web o de los
            Servicios que pueda ofrecer. Tampoco está garantizada la obtención de ningún resultado o fin concreto, por
            lo que usted reconoce que el acceso y utilización del Sitio Web y de los Servicios es de su exclusiva
            responsabilidad. En consecuencia, Slightpay no será responsable de cualquier daño o perjuicio que sufra el
            Usuario a consecuencia de inexactitudes, errores tipográficos, técnicos y cambios o mejoras que se realicen
            periódicamente a los Servicios o al Sitio Web. Las recomendaciones y consejos obtenidos a través del Sitio
            Web son de naturaleza general, por lo que no deben tomarse en cuenta en la adopción de decisiones
            financieras del Usuario. El Usuario deberá consultar en cualquier Servicio a un profesional que pueda
            brindarle asesoría financiera de acuerdo con sus necesidades específicas.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-subtitle">
          <p>19. Derechos de autor y propiedad intelectual</p>
        </div>
        <div className="legal-text">
          <p>
            A exclusión de los documentos que Slightpay determine a través del Sitio Web que es posible su impresión,
            toda la información, documentación y marcas disponibles a través del Sitio Web, incluyendo de manera
            enunciativa más no limitativa la marca “Slightpay” y el dominio “slightpay.com” es propiedad de Slightpay o
            de terceros que está protegida por la Ley Federal del Derecho de Autor, Ley de la Propiedad Industrial y de
            otras leyes y disposiciones aplicables. Cualquier marca y aviso comercial que se muestran como parte del
            Sitio Web están registrados en favor de Slightpay o de terceros y se interpretará que nada en este Sitio Web
            otorga, por implicación, cualquier licencia o derecho para utilizar cualquier marca o aviso comercial en
            este Sitio Web por lo que no podrán ser difundidas, reproducidas, comercializadas o utilizadas en cualquier
            forma, total o parcialmente, de forma idéntica o que confunda en menor o mayor grado, por cualquier medio,
            sin autorización previa y por escrito del titular de los derechos correspondientes.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>20. Titularidad del sitio web</p>
        </div>
        <div className="legal-text">
          <p>
            El Usuario acepta y reconoce expresamente que Slightpay es el propietario y titular de los derechos, según
            corresponda, del Sitio Web que permitan hacer uso de los Servicios, por lo que sin el consentimiento de
            Slightpay, el Usuario no podrá transferir, divulgar, o dar un uso distinto total o parcialmente a la
            naturaleza del Sitio Web, en cuyo caso será responsable de los daños y perjuicios que se ocasionen debido al
            mal uso, a Slightpay o a terceros, y de las acciones judiciales o administrativas que en su caso resulten.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>21. Modificación del acuerdo</p>
        </div>
        <div className="legal-text">
          <p>
            Slightpay se reserva el derecho de modificar el presente Acuerdo cuando lo considere oportuno mediante una
            versión actualizada en el Sitio Web la cual entrará en vigor en el momento de su publicación. Si la versión
            modificada incluye un cambio sustancial, se informará al Usuario con al menos 30 (treinta) días naturales de
            anticipación a su entrada en vigor mediante la publicación de una notificación en el Sitio Web. El Usuario
            al acceder y utilizar el Sitio Web, manifiesta su acuerdo a estar legalmente sujeto a todos los términos y
            condiciones que contenga o a los que se hagan referencia. Es responsabilidad del Usuario verificar
            constantemente el Acuerdo. El uso continuo de los Servicios posteriormente a la modificación del Acuerdo
            constituye la aceptación de dichas modificaciones. Slightpay podrá notificar al Usuario cualquier
            modificación al Acuerdo mediante un Mensaje de Datos a través del Sitio Web o correo electrónico. En caso
            que el Usuario no esté de acuerdo con las modificaciones propuestas, podrá solicitar la terminación hasta 60
            (sesenta) días naturales después de la entrada en vigor de dichas modificaciones, sin responsabilidad alguna
            a su cargo, debiendo cubrir, en su caso, los adeudos que ya se hubieren generado a la fecha en que el
            Usuario solicite dar por terminados los Servicios. Los adeudos pendientes por parte del usuario deberán
            liquidarse conforme a las condiciones originalmente contratadas, salvo aquellos que se hayan realizado
            conforme a las modificaciones propuestas. Slightpay no podrá cobrar cantidad adicional alguna por la
            terminación de la prestación de los Servicios, con excepción de los adeudos que ya se hubieren generado a la
            fecha en que el usuario solicita dar por terminado el Servicio.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>22. Modificación del sitio web o los servicios</p>
        </div>
        <div className="legal-text">
          <p>
            Slightpay podrá en cualquier momento, ampliar, disminuir o modificar, en todo o en parte, temporal o
            permanentemente, sin necesidad de notificación previa al Usuario, las condiciones, características y
            alcances del Sitio Web o los Servicios que pone a disposición del Usuario.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>23. Cesión de derechos</p>
        </div>
        <div className="legal-text">
          <p>
            El Usuario tendrá prohibida la enajenación, cesión o transferencia total o parcial de las Claves de Acceso o
            los derechos y obligaciones derivados del presente Acuerdo salvo con el consentimiento previo y por escrito
            de Slightpay. Slightpay queda facultado para ceder, transmitir y negociar de cualquier forma los derechos y
            obligaciones derivados del presente Acuerdo.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-subtitle">
          <p>24. Suspeción de uso del Sitio Web</p>
        </div>
        <div className="legal-text">
          <p>
            Slightpay, a su propio juicio, se reserva el derecho de negar, restringir, suspender o condicionar sin
            previo aviso y en cualquier momento, temporal o permanentemente, los derechos para utilizar las Claves de
            Acceso y acceder al Sitio Web cuando de manera enunciativa más no limitativa el Usuario infringe el Acuerdo,
            realice el uso indebido del Sitio Web, ya sea porque su uso va en contra de la naturaleza de los Servicios,
            porque infrinja una ley, cuando cuente con elementos que le hagan presumir que las Claves de Acceso no están
            siendo utilizadas por el propio Usuario, por considerar que su uso puede dañar los intereses de otros
            Usuarios o de Slightpay. Lo anterior sin perjuicio de las responsabilidades y sanciones civiles,
            administrativas, fiscales, penales o de cualquier índole contenidas en la legislación aplicable.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-subtitle">
          <p>25. Vigencia del Sitio Web</p>
        </div>
        <div className="legal-text">
          <p>
            Sightpay podrá en cualquier momento, con previa notificación a través del Sitio Web mediante un Mensaje de
            Datos, o sin previa notificación, suspender temporal o permanentemente la disposición del Sitio Web a través
            de los Medios Electrónicos por cualquier causa o razón que Slightpay estime conveniente. Slightpay no se
            responsabiliza por la pérdida, daño o perjuicio, sin importar su alcance o naturaleza, que resulte de la
            falta de continuidad o disponibilidad operativa del Sitio Web.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-subtitle">
          <p>26. Garantias</p>
        </div>
        <div className="legal-text">
          <p>
            Sightpay no ofrece garantías respecto a los Servicios, mismos que pudieran no ser precisos, correctos o que
            cumplirán las expectativas del Usuario. Slightpay no garantiza ni asume responsabilidad de los productos o
            servicios de los Proveedores.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-subtitle">
          <p>27. Indemnización</p>
        </div>
        <div className="legal-text">
          <p>
            El Usuario acuerda en defender y sacar en paz y a salvo a Slightpay, a las empresas subsidiarias o afiliadas
            de Slightpay y a sus respectivos consejeros, funcionarios, empleados, representantes, apoderados o asesores,
            incluidos en este último caso, sin limitación alguna, abogados, contadores, consultores, financieros y
            cualquiera de los representantes de dichos asesores de cualquier responsabilidad, daño, obligación, demanda,
            sentencia, transacción, requerimiento, reclamación, procedimiento, juicio, gastos y/o costas de cualquier
            naturaleza, incluyendo los honorarios de abogados, que directa o indirectamente se hagan valer en su contra,
            como resultado de: (a) el acceso, uso o inhabilidad de usar las Claves de Acceso o los Servicios; (b) el
            incumplimiento o supuesto incumplimiento del presente Acuerdo; (c) la violación a los derechos de terceros,
            incluyendo de manera enunciativa mas no limitativa a la culpa o negligencia de los empleadors, funcionarios,
            representantes, mandatarios, comisionistas, apoderados o asesores del Usuario o la relación entre el Usuario
            y de terceros personas; (d) la violación a cualquier legislación aplicable; (e) la falta de veracidad en la
            información y/o documentación proporcionada por el Usuario, misma que deberá ser precisa, correcta y
            actualizada. El Usuario se obliga a no convenir o transaccionar sin el consentimiento previo y por escrito
            de Lighptay. Ésta obligación de defender y sacar en paz y a salvo subsistirá posteriormente a la terminación
            de los Servicios.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-subtitle">
          <p>28. Títulos de las Cláusulas</p>
        </div>
        <div className="legal-text">
          <p>
            Los títulos que aparecen en cada una de las cláusulas de este Acuerdo son exclusivamente para facilitar su
            lectura y, por consiguiente, no se considerará que definen, limitan o describen el contenido del mismo, ni
            para efectos de su interpretación o cumplimiento.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>29. Divisibilidad de las Cláusulas</p>
        </div>
        <div className="legal-text">
          <p>
            La nulidad o invalidez de alguna o varias de las cláusulas del presente Acuerdo no conllevará a la nulidad
            del mismo, subsistiendo el acuerdo de voluntades como si la cláusula o cláusulas que fueren declaradas nulas
            o inválidas no hubiesen sido puestas.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>30. Renuncias</p>
        </div>
        <div className="legal-text">
          <p>
            La omisión por parte de Slightpay en el ejercicio de los derechos previstos en este Acuerdo, en ningún caso
            tendrá el efecto de una renuncia a los mismos ni que el ejercicio singular o parcial por parte de Slightpay
            de cualquier derecho derivado de este instrumento excluye algún otro derecho, facultad o privilegio.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>31. Atención a usuarios</p>
        </div>
        <div className="legal-text">
          <p>
            Para efectos de consultas de saldo, transacciones, movimientos, aclaraciones, inconformidades o quejas,
            Slightpay pone a disposición del Usuario el Centro de Atención que tiene como correo electrónico
            <a href="mailto:hello@slightpay.com" rel="noopener noreferrer" target="_blank">
              hello@slightpay.com
            </a>{' '}
            con horario en Días Hábiles de 9:00 (nueve) a 18:00 (dieciocho) horas.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>32. Legislación Aplicable</p>
        </div>
        <div className="legal-text">
          <p>
            El presente Acuerdo se regirá por lo dispuesto por el Código Civil Federal de los Estados Unidos Mexicanos.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-subtitle">
          <p>33. Jurisdicción</p>
        </div>
        <div className="legal-text">
          <p>
            Para la interpretación, ejecución y cumplimiento de lo pactado en este Acuerdo, el Usuario se somete a la
            jurisdicción de los tribunales competentes de Monterrey, Nuevo León, Estados Unidos Mexicanos, renunciando
            expresamente a cualquier otro fuero que por razón de su domicilio presente o futuro o por cualquier otro
            motivo pudiera corresponderle.
          </p>
        </div>
      </div>

      <div className="legal-section">
        <div className="legal-last-update">
          <p>Fecha de última actualización: 18 de marzo de 2020.</p>
        </div>
      </div>
    </LegalStyle>
  );
};

export default TermsAndConditions;
