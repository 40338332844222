import { ITransactionState } from './types';

export const transactionSelector = {
  getTransactionDataTable: () => (state: { transactions: ITransactionState }) => state.transactions?.purchases,
  getSellDetails: () => (state: { transactions: ITransactionState }) => state.transactions?.purchaseDetails,
  getPaymentsData: () => (state: { transactions: ITransactionState }) => state.transactions?.payments,
  getCompletedPaymentsData: () => (state: { transactions: ITransactionState }) => state.transactions?.completedPayments,
  getSelectedCardPurchases: () => (state: { transactions: ITransactionState }) => state.transactions?.selectedCardPurchases,
  getLatePaymentAmount: () => (state: { transactions: ITransactionState }) => state.transactions?.totalAmountLatePayments,
  getAllLatePayments: () => (state: { transactions: ITransactionState }) => state.transactions?.latePayments,
  getPurchaseLatePaymentsAmount: () => (state: { transactions: ITransactionState }) => state.transactions?.purchaseTotalLatePaymentsAmount,
};
