import produce from 'immer';
import { TransactionActions, ITransactionState, ITransactionReducer } from './types';

// Move to other file
const checkForDataOnLocalstorage = (blockName: string): ITransactionState => {
  let tempState: ITransactionState;
  // Think a more abstract solution
  const defaultState: ITransactionState = {
    purchases: {
      items: [],
      count: 0,
    },
    purchaseDetails: null,
    payments: {
      items: [],
      count: 0,
    },
    completedPayments: {
      items: [],
      count: 0,
    },
    selectedCardPurchases: {
      items: [],
      count: 0,
    },
    totalAmountLatePayments: null,
    latePayments: {
      items: [],
      count: 0,
    },
    purchaseTotalLatePaymentsAmount: 0,
  };
  try {
    if (localStorage.getItem(blockName)) tempState = JSON.parse(localStorage.getItem(blockName) as string);
    else tempState = defaultState;
  } catch (error) {
    tempState = defaultState;
  }

  const initialState: ITransactionState = { ...tempState };
  return initialState;
};
// End move to other file

export const initialState: ITransactionState = checkForDataOnLocalstorage('TransactionState');

export const saveStoreInLocalstorage = (blockName: string, data: ITransactionState) => {
  localStorage.setItem(blockName, JSON.stringify(data));
};

const transactionReducer: ITransactionReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TransactionActions.PURCHADES_ADD_DATA:
        draft.purchases = action.payload;
        break;

      case TransactionActions.DETAILS_PURCHASES_ADD_DATA:
        draft.purchaseDetails = action.payload;
        break;

      case TransactionActions.PAYMENTS_ADD_DATA:
        draft.payments = action.payload;
        break;

      case TransactionActions.ADD_COMPLETED_PAYMENTS_DATA:
        draft.completedPayments = action.payload;
        break;

      case TransactionActions.ADD_SELECTED_CARD_PURCHASES:
        draft.selectedCardPurchases = action.payload;
        break;
      case TransactionActions.ADD_LATEPAYMENT_DATA:
        draft.totalAmountLatePayments = action.payload;
        break;
      case TransactionActions.ADD_LATEPAYMENTS_OBJECT_DATA:
        draft.latePayments = action.payload;
        break;
      case TransactionActions.ADD_PURCHASE_LATEPAYMENTS_AMOUNT_DATA:
        draft.purchaseTotalLatePaymentsAmount = action.payload;
        break;
      
    }
    saveStoreInLocalstorage('TransactionState', draft);
  });

export default transactionReducer;