interface BoxStyle {
    width?: number;
    height?: number;
}

interface ScreenBreakpoints {
    xs?: number;
    sm: number;
    md?: number;
    lg?: number;
}

interface SizesStyles {
    navbar : {
        screen: ScreenBreakpoints,
        desktop: BoxStyle,
        mobile: BoxStyle
    }
}

const sizes : SizesStyles = {
    navbar: {
        screen:{
            sm: 992
        },
        desktop: {
            height: 70
        },
        mobile: {
            height: 60
        }
    },
};

export default sizes;
  