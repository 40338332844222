export const icons: any = {
  addFavorite: require('./files/icon-favorite.svg'),
  addFavoriteSelected: require('./files/icon-favorite-selected.svg'),
  addTrash: require('./files/icon-trash.svg'),
  arrowDatepickerMonthBack: require('./files/arrow-datepicker-month-back.svg'),
  arrowDatepickerMonthNext: require('./files/arrow-datepicker-month-next.svg'),
  arrowDownBlue: require('./files/icon-arrow-down-blue.svg'),
  arrowDownWhite: require('./files/icon-arrow-down-white.svg'),
  arrowDownGrey: require('./files/icon-arrow-down-grey.svg'),
  arrowDownLightBlue: require('./files/icon-arrow-down-light-blue.svg'),
  arrowLeftBlue: require('./files/icon-arrow-left-blue.svg'),
  arrowBackLeft: require('./files/arrow-back-icon.svg'),
  arrowBackLeftBlue: require('./files/icon-arrow-back-left-blue.svg'),
  arrowLeftGrey: require('./files/icon-arrow-left-grey.svg'),
  arrowRightBlue: require('./files/icon-arrow-right-blue.svg'),
  arrowUpLightBlue: require('./files/icon-arrow-up-light-blue.svg'),
  attachPlus: require('./files/attach-plus.svg'),
  bellBlue: require('./files/icon-bell-blue.svg'),
  bellPurple: require('./files/icon-bell-blue.svg'),
  bulletDone: require('./files/icon-bullet-done.svg'),
  btnModalClose: require('./files/btn-modal-close.svg'),
  cameraGrey: require('./files/camera-icon.svg'),
  calendarBlue: require('./files/icon-calendar-blue.svg'),
  calendarGrey: require('./files/icon-calendar-grey.svg'),
  calendarRed: require('./files/icon-calendar-red.svg'),
  cardBlue: require('./files/icon-card-blue.svg'),
  closeModal: require('./files/close-modal.svg'),
  closeHelpFilters: require('./files/close-help-filters.svg'),
  cardGrey: require('./files/icon-card-grey.svg'),
  creditCardVisa: require('./files/icon-visa-creditcard.svg'),
  check: require('./files/check-icon.svg'),
  errorRed: require('./files/icon-error-red.svg'),
  eyeBlue: require('./files/icon-eye-blue.svg'),
  eyeBlueCrossed: require('./files/icon-eye-blue-crossed.svg'),
  eyeBlueFill: require('./files/icon-eye-blue-fill.svg'),
  eyeGrey: require('./files/icon-eye-grey.svg'),
  eyeGreyCrossed: require('./files/icon-eye-grey-crossed.svg'),
  eyeLightBlue: require('./files/icon-eye-light-blue.svg'),
  eyeLightBlueCrossed: require('./files/icon-eye-light-blue-crossed.svg'),
  eyeLightGrey: require('./files/icon-eye-light-grey.svg'),
  eyeLightGreyCrossed: require('./files/icon-eye-light-grey-crossed.svg'),
  fetchArrowRightBlue: require('./files/fetch-arrow-right-blue.svg'),
  helpFilter: require('./files/help-filter-icon.svg'),
  homeCreditcard: require('./files/icon-home-credicard.svg'),
  homeClickcard: require('./files/icon-home-click.svg'),
  infoBlue: require('./files/icon-info-blue.svg'),
  infoGrey: require('./files/icon-info-grey.svg'),
  slightpayLogo: require('./files/logo-full-black.svg'),
  logoNavbarDesktop: require('./files/logo-navbar-desktop.svg'),
  mexicoFlag: require('./files/mexico.svg'),
  myActivityMenuDots: require('./files/icon-my-activity-dots-menu.svg'),
  myActivityMenuClose: require('./files/icon-my-activity-close-menu.svg'),
  myActivityArrowRight: require('./files/icon-my-activity-arrow-right.svg'),
  myActivityEvents: require('./files/icon-my-activity-events.svg'),
  myActivityShoppingCart: require('./files/icon-my-activity-shopping-cart.svg'),
  myActivityCreditcard: require('./files/icon-my-activity-creditcard.svg'),
  navbarLogoutIcon: require('./files/icon-navbar-logout.svg'),
  navbarCloseIcon: require('./files/icon-navbar-close.svg'),
  navbarHamburgerIcon: require('./files/icon-navbar-hamburger.svg'),
  navbarNotiPending: require('./files/icon-navbar-notification-pending.svg'),
  noImage: require('./files/no-image-icon.svg'),
  noFav: require('./files/not-fav.svg'),
  fav: require('./files/fav.svg'),
  pencilBlue: require('./files/icon-pencil-blue.svg'),
  pencilGrey: require('./files/icon-pencil-grey.svg'),
  plusBlue: require('./files/plus-blue.svg'),
  power: require('./files/power.svg'),
  plusWhite: require('./files/plus-white.svg'),
  sliderRangeImage: require('./files/slider-image-range.svg'),
  tableArrowDown: require('./files/icon-table-arrow-down.svg'),
  tableArrowUp: require('./files/icon-table-arrow-up.svg'),
  tickGreen: require('./files/icon-tick-green.svg'),
  tickBlueIcon: require('./files/tick-blue-icon.svg'),
  tickGreyIcon: require('./files/tick-grey-icon.svg'),
  trashBlue: require('./files/trash-blue.svg'),
  warningYellow: require('./files/icon-warning-yellow.svg'),
  filtersIcon: require('./files/icon-filters.svg'),
  searchIcon: require('./files/icon-search.svg'),
  rejectedIcon: require('./files/rejected.svg'),
  inProgressIcon: require('./files/in-progess-icon.svg'),
  okIcon: require('./files/ok-icon.svg'),
  rejectedPendingIcon: require('./files/rejected-pending-icon.svg'),
  draftIconSVG: require('./files/draft-icon.svg'),
  expiredIcon: require('./files/expired.svg'),
  chargeBackRejectedIcon: require('./files/chargeback-rejected.svg'),
  chargeBackAcceptedIcon: require('./files/chargeback-accepted.svg'),
  chargeBackPendingIcon: require('./files/chargeback-pending.svg'),
  refundIcon: require('./files/refund.svg'),
  refundPendingIcon: require('./files/refund-pending.svg'),
  clockIcon: require('./files/clock-icon.svg'),
  shopIcon: require('./files/shop-icon.svg'),
  processIcon: require('./files/process-icon.svg'),
  infoIcon: require('./files/info-icon.svg'),
  mastercardIcon: require('./files/mastercard-icon.svg'),
  amexIcon: require('./files/amex-icon.svg'),
}
