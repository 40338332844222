import React from 'react'
import { ModalProps } from './modal-types'
import ModalContainer from './modal-style'
import { icons } from '../../assets/themes'
import { ModalContext } from '../../context/modal/modal-context'

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const modalContextValue = {
    onClose: props.onClose
  }

  return (
    <ModalContainer className={`${props.className}`}>
      <div className="btn-close" onClick={props.onClose}>
        <img src={icons.btnModalClose} alt="modal close" />
      </div>
      <div className="modal-content">
        <ModalContext.Provider value={modalContextValue}>
          {props.children}
        </ModalContext.Provider>
      </div>
    </ModalContainer>
  )
}

export default Modal
