import React, { useState, useContext } from 'react'
import Modal from '../../modal-container/modal'
import MainButton from '../../main-button/main-button'
import ModalProfileEditNameContainer from './modal-profile-edit-name-style'
import { ModalContext } from '../../../context/modal/modal-context'

interface ProfileEditNameModalType {
  history: any
  changeShowEditNameModal: (show: boolean) => void
}

const useModal = (props: ProfileEditNameModalType) => {
  const [selectedOption, setSelectedOption] = useState('legal-name')

  const changeSelectedOption = (
    option: 'legal-name' | 'name' | 'little-update'
  ) => {
    setSelectedOption(option)
  }

  const isRadioActive = (name: string) => {
    return name === selectedOption
  }

  const submitDecision = () => {
    switch (selectedOption) {
      case 'legal-name':
        props.history.push('/perfil/datos-personales/actualizar-nombre-legal')
        break
      case 'name':
        props.history.push('/perfil/datos-personales/actualizar-nombre')

        break
      case 'little-update':
        props.history.push('/perfil/datos-personales/actualizar-alias')
        break
    }
  }

  return {
    selectedOption,
    submitDecision,
    changeSelectedOption,
    isRadioActive
  }
}

const ModalProfileEditName: React.FC<ProfileEditNameModalType> = (
  props: ProfileEditNameModalType
) => {
  const actions = useModal(props)
  const { onClose } = useContext(ModalContext)

  return (
    <ModalProfileEditNameContainer>
      <p className="edit-name-modal-text">
        Indícanos la razón del cambio de nombre
      </p>

      <ul className="edit-name-modal-list">
        <li className="edit-name-modal-element">
          <div
            className={`radio ${
              actions.selectedOption === 'legal-name' ? 'active' : ''
            }`}
            onClick={() => actions.changeSelectedOption('legal-name')}
          >
            {actions.isRadioActive('legal-name') && <div className="circle" />}
          </div>

          <div className="radio-label">
            <p className="radio-label-title">Cambio de nombre legal</p>
            <p className="radio-label-description">
              He cambiado de nombre legal.
            </p>
          </div>
        </li>

        <li className="edit-name-modal-element">
          <div
            className={`radio  ${
              actions.selectedOption === 'name' ? 'active' : ''
            }`}
            onClick={() => actions.changeSelectedOption('name')}
          >
            {actions.isRadioActive('name') && <div className="circle" />}
          </div>

          <div className="radio-label">
            <p className="radio-label-title">Actualizar nombre</p>
            <p className="radio-label-description">
              Me inscribí con un alias y quiero poner mi nombre.
            </p>
          </div>
        </li>

        <li className="edit-name-modal-element">
          <div
            className={`radio  ${
              actions.selectedOption === 'little-update' ? 'active' : ''
            }`}
            onClick={() => actions.changeSelectedOption('little-update')}
          >
            {actions.isRadioActive('little-update') && (
              <div className="circle" />
            )}
          </div>

          <div className="radio-label">
            <p className="radio-label-title">
              Realizar una pequeña rectificación
            </p>
            <p className="radio-label-description">
              Realizar 1 o 2 cambios ortográficos.
            </p>
          </div>
        </li>
      </ul>

      <div className="edit-name-modal-buttons">
        <div className="btn-cancel">
          <MainButton
            type="secondary"
            text="Cancelar"
            onClick={() => onClose()}
          />
        </div>

        <div className="btn-accept">
          <MainButton
            type="primary"
            text="Aceptar"
            onClick={() => actions.submitDecision()}
          />
        </div>
      </div>
    </ModalProfileEditNameContainer>
  )
}

export default ModalProfileEditName
