import React from 'react';
import { TableAvatarStyle } from './table-avatar-style';

interface ITableAvatarProps {
  img: string;
}

const TableAvatar: React.FC<ITableAvatarProps> = (props: ITableAvatarProps) => {
  const { img } = props;
  return <TableAvatarStyle>{img && <img src={img} alt="" />}</TableAvatarStyle>;
};

export default TableAvatar;
