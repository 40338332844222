import React from "react";
import { ToastProps } from "react-toast-notifications";
import { icons } from "../../assets/themes";
import { MyCustomToastStyle } from "./custom-toast-style";

export const MyCustomToast: React.FC<ToastProps> = prop => {
  return (
    <MyCustomToastStyle className={prop.appearance}>
      <div className="my-custom-toast-icon">
        <img
          src={
            prop.appearance === "error"
              ? icons.errorRed
              : prop.appearance === "success"
              ? icons.tickGreen
              : prop.appearance === "warning"
              ? icons.alertWarning
              : ""
          }
          alt=""
        />
      </div>
      <div className="my-custom-toast-text">
        <p>{prop.children}</p>
      </div>
      <div className="my-custom-toast-close" onClick={() => prop.onDismiss()}>
        <img
          src={
            prop.appearance === "error"
              ? icons.iconCloseError
              : prop.appearance === "success"
              ? icons.iconCloseSuccess
              : prop.appearance === "warning"
              ? icons.iconCloseWarning
              : ""
          }
          alt=""
        />
      </div>
    </MyCustomToastStyle>
  );
};

export default MyCustomToast;
