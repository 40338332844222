import styled from 'styled-components/macro';
import { colors } from "../../../assets/themes";

//Constants
import { images } from '../../../assets/images/images';

const CreditCardContainer = styled.div`
    width: 303px;    
    /* background-image: url(${images.walletTempCreditCard}); */
    background-color: ${colors['PR––001-100']};
    background-repeat: no-repeat;
    background-size: cover; 
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    
    .credit-card-topbar {
        display: flex; 
        align-items: center; 
        justify-content: space-between; 
        margin-bottom: 40px; 

        .alias {
            font-family: "Gilroy";
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: #FFFFFF;
        }

        
    }

    .credit-card-number {
        margin-bottom: 36px; 
        display: flex; 
        align-items: center; 
        justify-content: flex-end;    

            .private-number {
                font-family: "Gilroy";
                font-size: 18px;
                line-height: 24px;
                color: #FFFFFF;
                margin-right: 40px; 
            }   

            .last-digits {
                font-family: "Gilroy";
                font-size: 18px;
                line-height: 24px;
                color: #FFFFFF;
            }      
        }

        .credit-card-bottom {
            display: flex; 
            align-items: center; 

            .valid-date-label {
                margin-right: 10px;
                font-family: "Gilroy";
                font-size: 12px;
                line-height: 8px;
                color: #FFFFFF;
            }

            .valid-date {
                font-family: "Gilroy";
                font-size: 12px;
                line-height: 8px;
                color: #FFFFFF;
            }
        }
        
    
`;

export default CreditCardContainer;