import styled, { css } from 'styled-components/macro';
import { weights } from '../../../assets/themes';

export interface BodyTextProps {
  children: any;
  className?: string;
  color?: string;
  textType?: string;
}

const controlTypographyDesktop = (prop?: string) => {
  let style = '';

  switch (prop) {
    case 'p-21':
      style = `
          font-size: 21px;
          line-height: 28px;
        `;
      break;
    case 'p-18':
      style = `
          font-size: 18px;
          line-height: 26px;
        `;
      break;
    case 'p-16':
      style = `
          font-size: 16px;
          line-height: 24px;
          font-weight: ${weights.regular};
        `;
      break;
    case 'p-14':
      style = `
          font-size: 14px;
          line-height: 22px;
        `;
      break;
    case 'p-12':
      style = `
          font-size: 12px;
          line-height: 18px;
        `;
      break;

    default:
      style = `
        font-size: 14px;
      `;
  }
  return style;
};

export const BodyText = styled.div<BodyTextProps>`
  font-family: 'gilroy';
  ${(props: BodyTextProps) => controlTypographyDesktop(props.textType)};

  ${(props: BodyTextProps) => css`
    color: ${props.color};
  `}
`;

export default BodyText;
