import { IUser } from '@aluxion-lightpay/interfaces'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authSelectors } from '../../../store/auth/selectors'
import { transactionActions } from '../../../store/transaction/actions'
import { transactionSelector } from '../../../store/transaction/selector'
import { ITransactionPayment } from '../../../store/transaction/types'
import HomeContainer from './home-style'
import HomeOptions from './options/home-options'
import HomeResume from './resume/home-resumen'
import { cardActions } from '../../../store/card/actions'
import amplitude from 'amplitude-js'

const Home: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const { items } = useSelector(
    transactionSelector.getCompletedPaymentsData()
  ) as ITransactionPayment
  const me = useSelector(authSelectors.getMe()) as IUser

  const getPurchases = useCallback(async () => {
    await Promise.all([
      dispatch(transactionActions.getHomePaymentsDetails(me.id)),
      dispatch(cardActions.getCardData(me.id)),
    ])
  }, [dispatch, me.id]) // eslint-disable-line

  useEffect(() => {
    getPurchases()
  }, [getPurchases])

  useEffect(() => {
    amplitude.getInstance().setUserId(`${me.id}SLIGHTPAY`);
  }, [])

  return (
    <HomeContainer>
      {items.length !== 0 ? <HomeResume /> : <HomeOptions />}  
    </HomeContainer>
  )
}

export default Home
