import React from 'react';
import Accordion from '../../../../../components/accordion/accordion';
import { IPropsAnswers } from '../../../../../utils/utils';

const RefundQuestions: React.FC<IPropsAnswers> = (props) => {
  const { onClick } = props;
  return (
    <div className="help-content">
      <div className="help-content-return" onClick={ () => onClick() }>
        <div><i className="arrow"></i></div>
        <p>Reembolsos y Devoluciones</p>
      </div>
      <div className="help-section-box">

        <Accordion title="¿Qué sucede si se envía un reembolso a una tarjeta cancelada o cuenta cerrada?" className="help-item">
          <p className="help-text">
            <p>
              Esto puede ser complicado.
            </p>
            <p>
              Cuando procesamos un reembolso a una cuenta que ha sido cerrada o cancelada, los fondos generalmente se mantendrán en una 
              "cuenta no asignada" por el Banco emisor de la tarjeta.
            </p>
            <p>
              Debes comunicarte con el emisor de tu tarjeta lo antes posible. Es posible que usen una terminología diferente para este 
              tipo de cuentas, pero si usas los términos “fondos no asignados”, esto debería ayudarlos a comprender lo que sucedió. 
              Es posible que deba solicitar un equipo de especialistas dentro del banco para ayudate a recuperar este fondo. 
              Normalmente, este es un proceso manual (en lugar de uno automático), por lo que deberás hablar con las personas que a
              dministran estos procesos dentro de cada banco o emisor de tarjetas.
            </p>
            <p>
              Debes asegurarte de tener la fecha, la hora y el monto específicos del reembolso para ayudar a hacer coincidir el dinero con tu cuenta. Si necesitas ayuda para encontrar esta información, ponte en contacto con nosotros aquí y solicita una confirmación de reembolso.
            </p>
          </p>
        </Accordion>
        
        <Accordion title="¿Qué debo hacer si mis productos no han llegado o están dañados?" className="help-item">
          <p className="help-text">
            <p>
              En Slightpay nos encanta ayudarte a planificar, reservar, comprar y pagar los productos y servicios que necesitas, 
              sin perder el control de tu dinero.
            </p>
            <p>
              Pero es importante que sepas que la entrega y la calidad de los productos y servicios que compras es responsabilidad de 
              los comercios en los cuales realizas tus compras. Eso significa que debes ponerte en contacto con el comercio si la entrega 
              demora más de lo esperado o si llegó con algo faltante o dañado. También significa que una vez que se aprueba un pedido, 
              solo el comercio puede cambiar ese pedido.
            </p>
            <p>
              Normalmente puedes encontrar los detalles de contacto de una tienda en su sitio web o en el resumen del pedido que te 
              enviamos cuando realizaste tu compra. Y generalmente debes esperar 48 horas para que la tienda responda. Mientras tanto, 
              nuestro sistema continuará cobrando sus cuotas según nuestro acuerdo. Si tienes alguna pregunta, por favor escríbenos a 
              hello@slightpay.com
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Por qué me siguen cobrando las cuotas si he devuelto los productos?" className="help-item">
          <p className="help-text">
            <p>
              Veamos por partes: una vez que hayas devuelto sus productos, necesitamos que el comercio acepte el reembolso y nos avise. 
              El comercio no realiza el reembolso a menos que estén satisfechos de que los productos cumpla con su política de devoluciones 
              y reembolsos. Por lo tanto, debemos esperar a que esto suceda antes de que podamos liquidar tu cuenta; de lo contrario, 
              Slightpay se quedaría con el costo de cualquier devolución en disputa.
            </p>
            <p>
              Trabajamos con todos nuestros increíbles comercios y proveedores de servicios para tratar de asegurarnos de que este 
              proceso suceda lo más rápido posible, siempre pensando que sea lo mejor para nuestros clientes. Desafortunadamente no 
              tenemos forma de controlar sus tiempos y, por lo tanto, tenemos que esperar a que nuestros comercios pasen por el proceso 
              adecuado.
            </p>
            <p>
              Eso significa que nuestro acuerdo contigo, es decir tu plan de pagos, debe continuar hasta que recibamos la confirmación 
              final de una devolución o el reembolso y nuestros sistemas procesen su reembolso en la cantidad acordada entre usted y 
              el comercio.
            </p>
            <p>
              Si necesitas ayuda para resolver una disputa de devolución con un comercio porque no atendieron correctamente tu caso, 
              escríbenos a soporte@slightpay.com y cuéntanos lo que está sucediendo.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Qué pasa si quiero devolver parte de mi pedido?" className="help-item">
          <p className="help-text">
            <p>
              En Slightpay nos encanta ayudarte a planificar, reservar, comprar y pagar los bienes y servicios que necesitas, 
              sin perder el control de tu dinero.
            </p>
            <p>
              Pero la entrega, la calidad y la devolución de los bienes y servicios que compres es siempre responsabilidad de los 
              minoristas y de los proveedores de servicios que compras. Y la cantidad y el tipo de devolución que puede hacer sobre un 
              producto siempre estará determinada por la política de devoluciones del minorista individual del que compró el artículo. 
              Debería poder conocer sus opciones consultando la política de devolución en su sitio web o contactándolos directamente.
            </p>
            <p>
              Una vez que el minorista haya procesado un reembolso, se enviará automáticamente a nosotros y, una vez que lo procesemos, 
              aparecerá en tu plan de pago.
            </p>
            <p>
              Ahora, aquí es donde se complica un poco, ¡así que presta atención!
            </p>
            <p>
              Nuestro sistema ajustará tus pagos de tu última cuota al revés. Esto significa que tu pago final podría reducirse a cero, 
              pero el resto de tus pagos se mantendrán igual. Nuestros sistemas no pueden distribuir el nuevo precio de compra de manera 
              uniforme en tus cuatro pagos o en los pagos restantes.
            </p>
            <p>
              Ahora, en la aplicación, tu pedido reembolsado aparecerá como "PAGADO". Sabemos que esto puede ser confuso y estamos trabajando
              para solucionarlo ahora mismo. Mientras tanto, puede hacer clic en el botón "PAGAR" para ver que el pago ha sido reembolsado.
            </p>
            <p>
              Los reembolsos financieros pueden demorar hasta 7 días hábiles, dependiendo de su institución financiera. Si tiene alguna 
              pregunta, debe seguir con ellos lo antes posible.
            </p>
            <p>
              Sabemos que mantener su primer par de pagos igual, aunque haya recibido un reembolso parcial, puede que no sea lo que quería. 
              Pero es la forma más fácil de ejecutar un sistema que lo ayuda a planificar, reservar, comprar y pagar sin perder nunca el 
              control de su dinero.
            </p>
            <p>
              Habiendo dicho esto, siempre estamos revisando y tratando de mejorar el Slightpay, así que háganos saber sus opiniones y mantén la conversación con nosotros.
            </p>
          </p>
        </Accordion>
        
        <Accordion title="¿Qué pasa si quiero devolver toda mi compra?" className="help-item">
          <p className="help-text">
            <p>
              En Slightpay nos encanta ayudarte a planificar, reservar, comprar y pagar los productos y servicios que necesitas, 
              sin perder el control de tu dinero.
            </p>
            <p>
              Pero la entrega, la calidad y la devolución de los bienes y servicios que compres es responsabilidad de los comercios y de 
              los proveedores de servicios que adquieres. Por lo cual la cantidad y el tipo de devolución que puedes hacer sobre un producto 
              siempre estará determinada por la política de devoluciones del comercio individual del que compraste el artículo. 
              Puedes conocer sus opciones consultando la política de devolución en su sitio web o contactándolos directamente en la tienda.
            </p>
            <p>
              Una vez que el comercio haya procesado un reembolso, se notifica automáticamente a nosotros y, una vez que lo procesemos, 
              se verá reflejado en tu plan de pagos.
            </p>
            <p>
              Ahora, aquí es donde se pudiera complicar un poco, ¡así que presta atención!
            </p>
            <p>
              Nuestro sistema ajustará tus pagos desde la última cuota hacia la primera. Esto significa que tu pago final podría reducirse 
              a cero, pero el resto de tus pagos se mantendrán igual. Nuestros sistemas no pueden distribuir el nuevo precio de compra de 
              manera uniforme en los cuatro pagos o en los pagos restantes.
            </p>
            <p>
              Ahora, en tu cuenta, tu pedido reembolsado aparecerá como  <span>---------</span>. Sabemos que esto puede ser confuso y estamos trabajando 
              para solucionarlo ahora mismo. 
            </p>
            <p>
              Los reembolsos financieros pueden demorar hasta 7 días hábiles, dependiendo de tu institución financiera. 
              Si tienes alguna pregunta, debes contactar con ellos lo antes posible.
            </p>
            <p>
              Sabemos que mantener tu primer par de pagos igual, aunque hayas recibido un reembolso parcial, no es lo que esperabas. 
              Pero es la forma más fácil de ejecutar un sistema que te ayuda a planificar, reservar, comprar y pagar sin perder nunca 
              el control de tu dinero.
            </p>
            <p>
              Habiendo dicho esto, siempre estamos revisando y tratando de mejorar Slightpay, así que escríbenos para compartir tus opiniones, 
              nos encanta escucharte.
            </p>
          </p>
        </Accordion>
      </div>
    </div>
  );
};

export default RefundQuestions;