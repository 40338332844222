import { store } from '../index';
import { toastActions } from './../store/toasts/actions';

export const handleReasonsError = (data: any) => {
  console.log(data, 'la data')
  if (data.reasons) {
    console.log('en reasons')
    let messagesError = [
      'OpenPay regreso un error',
      'Tiene 4 intentos de OpenPay',
      'user is approved',
      'No puede adelantar cuotas'
    ]
    if (!messagesError.includes(data?.reasons[0]?.validation)) {
      data.reasons.forEach((reason: { message: any; }) => {
        store.dispatch<any>(
          toastActions.error(reason.message || 'Ocurrió un error inesperado. Intente más tarde.'),
        );
      })
    }
  }
  else if (data.message) {
    console.log('en message')

    if (data.message === 'Email not verified')
      store.dispatch<any>(
        toastActions.error('Verifica tu email antes de continuar.'),
      )
    else {
      console.log('en else del email not verified')
      if(data.code !== 'OPENPAY_EXCEPTION') {
        console.log('diferente de OPENPAY_EXCEPTION')
        store.dispatch<any>(toastActions.error(data.message))
      } 
    }
  }
  else {
    console.log('else final')

    store.dispatch<any>(
      toastActions.error('Ocurrió un error inesperado. Intente nuevamente más tarde.'),
    )
  }
}