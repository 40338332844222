import { Dispatch } from 'redux';

import { IToastActions, IToastActionsTypes, IToast } from './types';

const success = (message: string) => async (dispatch: Dispatch<IToastActionsTypes>, getState: any) => {
  try {
    //const currentId = useSelector(toastSelector.countAllToasts());
    const currentId = getState().toasts.toasts.length;
    const newToast: IToast = {
      id: currentId,
      message: message,
      type: 'success',
      autoDismiss: 'short',
      options: {
        appearance: 'success',
        autoDismiss: true,
        onDismiss: () => {
          dispatch({ type: IToastActions.REMOVE_TOAST, id: currentId });
        } /*autoDismissTimeout: DISMMIS_TIMES['none']*/,
      },
    };

    dispatch({
      toast: newToast,
      type: IToastActions.ADD_TOAST,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const info = (message: string) => async (dispatch: Dispatch<IToastActionsTypes>, getState: any) => {
  try {
    //const currentId = useSelector(toastSelector.countAllToasts());
    const currentId = getState().toasts.toasts.length;
    const newToast: IToast = {
      id: currentId,
      message: message,
      type: 'info',
      autoDismiss: 'short',
      options: {
        appearance: 'info',
        autoDismiss: true,
        onDismiss: () => {
          dispatch({ type: IToastActions.REMOVE_TOAST, id: currentId });
        } /*autoDismissTimeout: DISMMIS_TIMES['none']*/,
      },
    };

    dispatch({
      toast: newToast,
      type: IToastActions.ADD_TOAST,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const warning = (message: string) => async (dispatch: Dispatch<IToastActionsTypes>, getState: any) => {
  try {
    //const currentId = useSelector(toastSelector.countAllToasts());
    const currentId = getState().toasts.toasts.length;
    const newToast: IToast = {
      id: currentId,
      message: message,
      type: 'warning',
      autoDismiss: 'long',
      options: {
        appearance: 'warning',
        autoDismiss: true,
        onDismiss: () => {
          dispatch({ type: IToastActions.REMOVE_TOAST, id: currentId });
        } /*autoDismissTimeout: DISMMIS_TIMES['none']*/,
      },
    };

    dispatch({
      toast: newToast,
      type: IToastActions.ADD_TOAST,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const error = (message: string) => async (dispatch: Dispatch<IToastActionsTypes>, getState: any) => {
  try {
    //const currentId = useSelector(toastSelector.countAllToasts());
    const currentId = getState().toasts.toasts.length;
    const newToast: IToast = {
      id: currentId,
      message: message,
      type: 'error',
      autoDismiss: 'long',
      options: {
        appearance: 'error',
        autoDismiss: true,
        onDismiss: () => {
          dispatch({ type: IToastActions.REMOVE_TOAST, id: currentId });
        },
        // autoDismissTimeout: '5000',
      },
    };

    dispatch({
      toast: newToast,
      type: IToastActions.ADD_TOAST,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const removeToast = (id: number) => async (dispatch: Dispatch<IToastActionsTypes>) => {
  dispatch({
    id,
    type: IToastActions.REMOVE_TOAST,
  });
};

const removeAllToast = () => async (dispatch: Dispatch<IToastActionsTypes>) => {
  dispatch({
    type: IToastActions.REMOVE_ALL_TOAST,
  });
};

export const toastActions = {
  success,
  info,
  warning,
  error,
  removeToast,
  removeAllToast,
};
