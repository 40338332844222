import React, { useState } from 'react';
import { LatePaymentsContainer } from './buy-late-payments-style';
import { IBuyLatePaymentsProps, IModalToPayData } from './buy-late-payments-types';
import { ILatePaymentFee } from '@aluxion-lightpay/interfaces';
import moment from 'moment';
import Pagination from '../pagination/pagination';
import { useDispatch } from 'react-redux';
import { transactionActions } from '../../store/transaction/actions';
import MainButton from '../main-button/main-button';
import ModalToPaid from '../modals/modal-to-paid/modal-to-paid';
import useModal from '../../hooks/useModal/use-modal'
import { icons } from '../../assets/icons/icons'
import { formatMoney } from '../../utils/utils';
import useUserCards from '../../hooks/useWallet/useUserCards';
import ModalInfo from '../modals/modal-info/modal-info';
import ModalSuccess from '../modals/modal-success/modal-success';
import { latePaymentsStatus } from '../../constants/constants';
import { ILatePaymentFeeClients } from '../../interfaces/purchaseclients.types'

const BuyLatePayments: React.FC<IBuyLatePaymentsProps> = props => {
  const { latePayments, count, purchaseId, commerceName, handleModalError } = props;
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const { isModal, openModal, closeModal } = useModal();
  const [ data, setData ] = useState<IModalToPayData>();
  const [selectedCardId, setSelectedCard ] = useState<number | null>();
  const { setDefaultCardById } = useUserCards();

  const onPageChanged = async (data: any = 0) => {
    const { currentPage } = data;
    await dispatch(transactionActions.getAllLatePayments(purchaseId, currentPage));
  
    setPage(currentPage);
  };

  const selectedCard = async (currentCardId: number) => {
    setSelectedCard(currentCardId);
  }

  const setDefaultCard = async () => {
    try {
      if (selectedCardId) await setDefaultCardById(selectedCardId, purchaseId);
      openModal('paymentSuccessful')
    }catch (error) {
      throw error
    }
  }

  const payLatePayment = async (selectedCardId: number, paymentIndex: number, paymentPartitionId: number) => {
    try {
      if (selectedCardId){
        await dispatch(transactionActions.payTotalLatePayments(purchaseId, paymentPartitionId, selectedCardId));
        if(paymentIndex !== 4) {
          openModal('setCard');
        }else {
          openModal('paymentSuccessful');
        }
      }
    }catch (error) {
      console.log(error)
      handleModalError(error)
    }
  }

  return (
    <>
    <LatePaymentsContainer>
      <div className="wrapper-items">
        {
          latePayments.length !== 0 &&
          <div className="wrapper-info light">
            <div>ID</div>
            <div>Monto</div>
            <div>Próximo intento de cobro</div>
            <div>Fecha de cobro</div>
            <div>Estado</div>
            <div>Tarjeta</div>
            <div></div>
          </div>
        }
        {latePayments && latePayments.map((latePayment: ILatePaymentFeeClients, index: any) =>{
          return (
            <div className="wrapper-info">
                <div>{latePayment.id} </div>
                <div>{formatMoney(latePayment.amount)}</div>
                <div>
                  {latePayment.frontendDate ? moment(latePayment.frontendDate).format('L') : latePayment.dueDate
                    ? moment(latePayment.dueDate).format('L')
                    : '---'}
                </div>
                <div>
                  {latePayment.paymentDate
                    ? moment(latePayment.paymentDate).format('L')
                    : '---'}
                </div>
                <div className={`status ${latePayment.status === 'SUCCESSFUL' ? 'success' : ''}`}>
                  {latePaymentsStatus[latePayment.status]}
                </div>
                <div className="card">{`${latePayment.card?.brand.toUpperCase()} **** ${
                  latePayment.card?.lastDigits
                }`}</div>
              {latePayment.status !== 'SUCCESSFUL' ?
                <div>
                  <MainButton 
                    type="tertiary" 
                    text="Pagar"  
                    className="button"
                    tertiaryColor='#C93976'
                    onClick={() => {
                      setData({
                        price: formatMoney(latePayment.amount),
                        card: latePayment.card!,
                        date: moment(latePayment.dueDate).format('L'),
                        fee: latePayment.paymentPartition.paymentIndex,
                        feeId: latePayment.paymentPartition.id,
                      });
                      openModal('confirmBuy')
                    }
                    } 
                  />
                </div>
                : <div className="button"></div>
              }
          </div>
          );
        })}
      </div>
      <div className="pagination">
        <Pagination
          totalItems={count}
          pageLimit={5}
          nextPage={1}
          onPageChanged={onPageChanged}
        />
        <div></div>
        <div className="pagination-count">
          <p>{`MOSTRANDO ${latePayments.length < 5 ? count : latePayments.length * page} / ${count}`}</p>
        </div>
      </div>
      {/* MODALS */}
      <ModalToPaid
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'confirmBuy') || false,
          onClose: closeModal,
        }}
        logo={icons.slightpayLogo}
        shopName={commerceName}
        prize={data?.price || ''}
        fee={`${data?.fee}/4`}
        date={data?.date || ''}
        card={data?.card!}
        onClick={(value:any) => payLatePayment(value, data?.fee!, data?.feeId!)}
        purchaseId={purchaseId}
        feeId={data?.feeId}
        setDefaultCard={selectedCard}
        latePayment
      />

      <ModalInfo
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'setCard') || false,
          onClose: closeModal,
        }}
        title={'¿Deseas escoger esta tarjeta para que pague las futuras cuotas de esta compra?'}
        text=""
        buttons={[
          {
            text: 'No',
            type: 'ghost',
            onClick: () => {closeModal(); openModal('paymentSuccessful');},
          },
          {
            text: 'Sí',
            type: 'primary',
            onClick: setDefaultCard,
          },
        ]}
      />

      <ModalSuccess
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'paymentSuccessful') || false,
          onClose: () => {closeModal(); window.location.reload()},
        }}
      >
        Se ha pagado <span>su cuota con éxito.</span>
      </ModalSuccess>

    </LatePaymentsContainer>
    </>
  );
};

export default BuyLatePayments;