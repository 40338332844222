import React from 'react';
import ReactDOM from 'react-dom';
// import './index.scss';
import './assets/sass/index.scss';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './store/reducers';
import { Provider } from 'react-redux';
import App from './App';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import IE from './containers/authorization/IE/IE';

interface IExtendedWindow extends Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
  declare let window: IExtendedWindow;
  
  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const GetIEVersion = () => {
    const sAgent = window.navigator.userAgent;
    const Idx = sAgent.indexOf('MSIE');
  
    // If IE, return version number.
    if (Idx > 0) return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)), 10);
    // If IE 11 then look for Updated user agent string.
    // eslint-disable-next-line no-useless-escape
    else if (!!navigator.userAgent.match(/Trident.*rv\:11\./)) return 11;
    else return 0; //It is not IE
  };

  ReactDOM.render(
    GetIEVersion() ? (
      <IE />
    ) : (
      <Provider store={store}>
        <App />
      </Provider>
    ),
    document.getElementById('root'),
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
