import styled from 'styled-components/macro'

//Components
import BaseLayoutContainer from '../../../../components/layout/basicLayoutContainer'

const HomeResumeContainer = styled<any>(BaseLayoutContainer as any)`
  padding: 50px;
  display: flex;

  /* .test-container {
    position: fixed;
    width: 380px;
    height: 100vh;
    background-color: white;
    top: 0px;
    right: 0px;
    z-index: 100000; 
  } */
  #home-resume-sidebar {
    flex: 4;
    min-width: 320px;

    #sidebar-heroblock {
      position: relative;
      .image-container {
        position: relative;
        width: 100%;
      }
    }

    #home-resume-sidebar-cards {
      margin-top: 20px;

      .card {
        margin-bottom: 20px;
      }
    }
  }
  #home-resume-content {
    flex: 7;
    padding-left: 50px;
    max-width: calc(100% - 330px);

    .title-container {
      margin-top: 20px;
      margin-bottom: 40px;
      line-height: 44px;
      &__main {
        font-weight: normal;
        font-size: 26px;
      }
    }

    #home-payment-agend-table {
      margin-bottom: 100px;
    }

    #home-history-table {
      margin-bottom: 50px;
    }
  }

  .payment-schedule-table-wrapper {
    margin-bottom: 58px;
  }

  .payment-schedule-table {
    margin-bottom: 22px;
  }

  .btn-more-payment-schedule {
    width: 122px;
  }

  @media (max-width: 992px) {
    padding-left: 25px;
    padding-right: 25px;

    #home-resume-sidebar {
      flex: 4;
    }
    #home-resume-content {
      flex: 7;
      padding-left: 20px;
    }
  }
  
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-top: 0px;
    flex-flow: column;
    #home-resume-content {
      max-width: calc(100%);
      padding: 20px;
    }

    #home-resume-sidebar {
      margin-bottom: 40px;

      .image-container {
        max-height: 400px;
        overflow: hidden;

        img {
          top: -150px;
          height: auto;
          width: 100%;
          position: relative;
        }
      }

      #home-resume-sidebar-cards {
        display: none;
      }
    }
  }

  @media (max-width: 576px) {
    #home-resume-sidebar {
      margin-bottom: 30px;

      .image-container {
        img {
          top: -80px;
        }
      }
    }

    #home-resume-content {
      #home-payment-agend-table {
        margin-bottom: 40px;
      }

      #home-history-table {
        margin-bottom: 30px;
      }
    }
  }

  @media (max-width: 480px) {
    flex-flow: column;
    #home-resume-sidebar {
      flex: 1;
      margin-bottom: 30px;

      .image-container {
        img {
          top: -10px;
        }
      }
    }
    #home-resume-content {
      flex: 1;

      .title-container {
        margin-top: 10px;
        margin-bottom: 25px;
      }
    }
  }

  .button {
    margin: 1.563rem 0 1.563rem 0;
    width: 8.75rem;
  }

`

export default HomeResumeContainer
