import React from 'react';
import Accordion from '../../../../../components/accordion/accordion';
import { IPropsAnswers } from '../../../../../utils/utils';

const Management: React.FC<IPropsAnswers> = (props) => {
  const { onClick } = props;
  return (
    <div className="help-content">
      <div className="help-content-return" onClick={ () => onClick() }>
        <div><i className="arrow"></i></div>
        <p>Gestión de Órdenes</p>
      </div>
      <div className="help-section-box">
        <Accordion title="Esperando a que llegue mi pedido" className="help-item">
            <p className="help-text">
              <p>
                Si estás esperando la entrega de tu compra y está demorando más de lo esperado o si recibiste tu pedido con artículos 
                faltantes / dañados, debes ponerte en contacto con la tienda directamente.
              </p>
              <p>
                Slightpay no puede modificar, ajustar o cambiar un pedido una vez que se haya aprobado, esto debe manejarse directamente 
                con la tienda. Puedes dar un vistazo en el sitio web de la tienda para obtener los datos de contacto; de lo contrario,  
                recibirás la información de contacto en tu resumen de pedido de Slightpay.
              </p>
              <p>
                Por favor permite al menos 48 horas para que la tienda responda.
              </p>
            </p>
          </Accordion>

          <Accordion title="¿Por qué aun no he recibido mi compra?" className="help-item">
            <p className="help-text">
              <p>
                No tenemos ninguna influencia sobre la entrega de tu compra ya que este proceso es controlado por los comerciantes. 
                Te recomendamos contactar con la tienda donde compraste tus productos y solicites una fecha de entrega / información de 
                seguimiento.
              </p>
              <p>
                Si no puedes ponerte en contacto con el vendedor, comunícate con nosotros y con mucho gusto intentaremos ayudarte.
              </p>
              <p>
                Nota: los pagos continuarán a pesar de que el pedido no se haya entregado ya que Slightpay no tiene control sobre la 
                entrega de los productos.
              </p>
            </p>
          </Accordion>

          <Accordion title="¿Por qué mi orden ha sido rechazada?" className="help-item">
            <p className="help-text">
              <p>
                Slightpay no aprueba el 100% de las solicitudes. Nuestra filosofía y compromiso es apoyar el gasto responsable.
              </p>
              <p>
                Al determinar qué órdenes se aprueban, consideramos una serie de factores diferentes. Como ejemplo, cuanto más tiempo 
                hayas sido comprador utilizando cuotas a través de Slightpay y cuantas más órdenes hayas pagado con éxito, más probabilidades 
                tendrá de gastar más.
              </p>
              <p>
                Cosas para considerar:
              </p>
              <p>
                <ul>
                  <li>¿Hay fondos suficientes en tu tarjeta, buscamos ver el 25% del valor del pedido?</li>
                  <li>La compra mínima es de 500 pesos.</li>
                  <li>La cantidad de tiempo que ha utilizado Slightpay (el sistema de validación es más estricto en las primeras 6 semanas).</li>
                  <li>Integración a Finerio (buscar como ponerlo razon social o algo evitando mistery shoppers a primera vista).</li>
                  <li>Score crediticio.</li>
                  <li>El valor del pedido que está intentando realizar, puede ayudar a reducir el valor de su carrito de compras 
                    (no es una garantía).</li>
                  <li>Agregar una tarjeta de crédito puede aumentar el monto autorizado (no es una garantía).</li>
                  <li>El número de pedidos que tiene actualmente "abierto" con nosotros (no es una garantía).</li>
                </ul>
              </p>
              <p>
                Si bien sabemos que puede ser frustrante no saber exactamente cuánto puede gastar cada vez o cuántos pedidos activos puede 
                tener, nuestras reglas y el proceso de aprobación ayudan a Slightpay a ofrecer un servicio totalmente comprometido con 
                nuestros clientes para fomentar el gasto y compras responsables. 
              </p>
            </p>
          </Accordion>

          <Accordion title="¿Cuánto puedo gastar usando Slightpay?" className="help-item">
            <p className="help-text">
              <p>
                Al determinar qué órdenes aprobar, nuestro sistema automatizado considera una serie de factores diferentes. 
                Como ejemplo, cuanto más tiempo haya sido comprador utilizando cuotas a través de Slightpay y cuantas más órdenes 
                haya pagado con éxito, más probabilidades tendrá de gastar más. Las restricciones son más estrictas para los compradores 
                "nuevos" (primeras 6 semanas) hasta que te conozcamos.
              </p>
              <p>
                Entre más uses Slightpay, podemos confirmar que cumples con tus planes de pago e irás aumentando la cantidad de compras 
                activas permitidas por nuestro sistema. Si bien sabemos que puede ser frustrante no saber exactamente cuánto puedes gastar 
                cada vez, lo hacemos por que estamos comprometidos con nuestros clientes para fomentar el gasto y compras responsables. 
              </p>
              <p>
                La buena noticia es que cada vez que utilices nuestro servicio para pagar tus compras, tomamos una decisión nueva y, 
                con el tiempo, podrás gastar más siempre y cuando continúes pagando tus pagos a tiempo según tu plan de pagos acordado.
              </p>
              <p>
                El gasto mínimo es de $500 y la compra promedio máxima sería de $5000.
              </p>
            </p>
          </Accordion>

          <Accordion title="Mis artículos estaban 'agotados'" className="help-item">
            <p className="help-text">
              <p>
                De vez en cuando, un comercio puede ponerse en contacto para informarte que algunos o todos los artículos que has 
                pedido no estaban disponibles debido al agotamiento del inventario.
              </p>
              <p>
                En esta situación, se pondrán en contacto contigo directamente para aconsejarte u ofrecerte artículos alternativos, o bien un reembolso. 
              </p>
              <p>
                En el caso de que se otorgue un reembolso, una vez que el comerciante procesa el reembolso, se reflejará en tu cuenta de Slightpay.
              </p>
            </p>
          </Accordion>
      </div>
    </div>
  );
};

export default Management;