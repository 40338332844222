import { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { cardActions } from '../../store/card/actions';
import { transactionActions } from '../../store/transaction/actions';
import { modalActions } from '../../store/modal/actions';

import { authSelectors } from '../../store/auth/selectors';
import { cardSelector } from '../../store/card/selectors';
import { transactionSelector } from '../../store/transaction/selector';
import { modalSelector } from '../../store/modal/selectors';

import { ICard, IUser } from '@aluxion-lightpay/interfaces'
import { ITransactionPurchase, ITransactionPayment } from '../../store/transaction/types';
import { IFilterTypes } from '../../containers/ligthpay-app/home/resume/home-resumen';


const useUserCards = () => {
  const dispatch = useDispatch();
  const { id } = useSelector(authSelectors.getMe()) as IUser;
  const cardData = useSelector(cardSelector.getCardData()) as ICard[];
  const { items: itemsPurchases } = useSelector(transactionSelector.getTransactionDataTable()) as ITransactionPurchase;
  const { items, count } = useSelector(transactionSelector.getSelectedCardPurchases()) as ITransactionPurchase;
  const { items: paymentsItems, count: paymentsCount } = useSelector(transactionSelector.getPaymentsData()) as ITransactionPayment;
  const [filter] = useState<IFilterTypes>({
    status: 'PENDING,PAYMENT_REJECTED',
    fromDate: '',
    toDate: '',
    search: '',
  });
  const isModal = useSelector(modalSelector.isShowing());
  
  
  const getCard = (id: number) => {
    const filterData = cardData.filter((card) => {
      return card.id === id
    })

    return filterData[0]
  }

  const deleteCardById = async (cardId: number) => {
    await dispatch(cardActions.deleteCard(id, cardId));
  }

  const setDefaultCardById = async (cardId:number, purchaseId?: number) => {
    await  dispatch(cardActions.addMainCard(purchaseId ? purchaseId : itemsPurchases[0].id, cardId));
    await dispatch(transactionActions.addTransactionSellsData(id, 1));

  }

  const isCurrentCardDefualt = (cardId:number) => {
    return itemsPurchases[0] ? itemsPurchases[0].payments[3]?.card?.id === cardId : false;
  }

  const getCardsUser = useCallback(async () => {
    await dispatch(cardActions.getCardData(id));
    // await dispatch(transactionActions.addTransactionSellsData(id, 1, {status: "", fromDate: "", toDate: "", search: ""}, 'new'));
  }, [dispatch, id]);

  const getStringCardNumber = (lastDigits: string) => {
    return 'xxxx xxxx xxxx ' + lastDigits
  }

  const payPurchasePartition = async (purchaseId:number, paymentPartitionId: number, cardId: number, feeNumber: number) => {
    await dispatch(transactionActions.payTotalLatePayments(purchaseId, paymentPartitionId, cardId));
    await dispatch(transactionActions.payDue(paymentPartitionId, cardId));
    await dispatch(transactionActions.addPaymentsData(id, '1', filter));
    if(feeNumber !== 4) {
      openModal('setCard');
    }else {
      openModal('paymentSuccessful');
    }
  }


  // Esto es  mejor separarlo (No time right now)
  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };
  
  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };

  useEffect(() => {
    getCardsUser();
  }, [getCardsUser])

  return {
    cardData,
    cardsExits: cardData.length !== 0,
    getCard,
    deleteCardById,
    setDefaultCardById,
    getStringCardNumber,
    openModal,
    closeModal,
    isModal,
    items,
    count,
    paymentsItems,
    paymentsCount,
    id,
    isCurrentCardDefualt,
    payPurchasePartition
  }
}

export default useUserCards