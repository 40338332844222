import styled from 'styled-components/macro';
import { colors } from '../../assets/themes';

const PaymentSidebarContainer = styled.div`
    position: relative;
    top: 0px;
    right: 0px;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
    & {
        -ms-overflow-style: none;
    }
    

    a {
        color: ${colors['PR––001-100']};
        text-decoration: underline;
    }

    #my-activity-navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        padding: 0 20px;
        width: 100%;
        
        .image-container {
            width: 20px;
            position: relative;
            cursor:pointer;
            img {
                position: relative;
                width: 100%;
            }
    
            &.horizontal {
                position: relative;
                height: 20px !important;
                img {
                    position: relative;
                    height: 100% !important;
                }
            }
        }
    }

    #my-activity-header {
        text-align: center;
        margin-top: 20px;

        .image-container {
            width: 100px;
            position: relative;
            margin: 0 auto;
            img {
                position: relative;
                width: 100%;
                margin-bottom: 15px;
            }
        }

        .amount,
        .company-name {   
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 5px;
        }
        
        .amount {
            color: #B3B3B6;
        }
    
        .company-name {
            color: #01030C;
        }

    }

    #my-activity-toolbar {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;
        padding: 0 20px;

        .first-row {
            border-bottom: 1px solid #F2F2F3;
        
        }
        .first-row,
        .second-row {
            &.right-border {
                border-right: 1px solid #F2F2F3;
            }
        }
        


        #card-activity-content {
            p {
                font-size: 14px;
            }
            .image-content {
                margin: 0 auto;
                width: 40px;
                position: relative;

                img {
                    width: 100%;
                    position: relative;
                }
            }
        }

        & > div {
            flex: 2 0 50%;
            padding: 20px 30px;
            display: flex;
            justify-content: center;
        }


    }

    #my-activity-due-payments {
        margin-top: 30px;
        .text-container {
            color: #34353D;
            font-size: 12px;
        }
        .data-container {
            margin-top:20px;
            margin-bottom: 30px;

            .payment-partition-container {
                .extra-info-card-data {
                    display: none;
                }
            }

            .payment-partition-container.show {
                .extra-info-card-data {
                    display: block;
                }
            }
        }

        .card-simple-data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            background-color: #E5E5E5;
            margin-top: 10px;

            img  {
                cursor:pointer;
            }

            .text-container {
                font-size: 14px;
            }

            .amount {
                font-weight: 600;
            }

            .amount.in {
                color: #4FB2C6;
            }

            .amount.out {
                color: #CA3976;
            }

            .flex {
                display: flex;
                align-items: center;

            }
        }
    }

    #my-activity-due-latepayments {
        margin-top: 10px;
        .text-container {
            color: #34353D;
            font-size: 12px;
            span {
                color: #CA3976;
                font-size: 14px;
                margin-left: 5px;
            }
            &__button {
                display: flex;
                justify-content: space-between
            }
        }
        
        .pay-late-payments {
            width: 50px;
            font-size: 12px;
        }

        .data-container {
            margin-top:20px;
            margin-bottom: 30px;

            .payment-partition-container {
                .extra-info-card-data {
                    display: none;
                }
            }

            .payment-partition-container.show {
                .extra-info-card-data {
                    display: block;
                }
            }
        }

        .card-simple-data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            background-color: #E5E5E5;
            margin-top: 10px;

            img  {
                cursor:pointer;
            }

            .text-container {
                font-size: 14px;
            }

            .amount {
                font-weight: 600;
            }

            .amount.in {
                color: #4FB2C6;
            }

            .amount.out {
                color: #CA3976;
            }

            .flex {
                display: flex;
                align-items: center;

            }
        }
    }

    .latepayments-extra {
        margin-top: 20px;
    }
    
    #my-activity-actions {

    }

    #my-activity-due-payments,
    #my-activity-actions {
        width: 100%;
        padding: 0 20px;
    }

    .arrow-to-show {
        transform: rotate(90deg);
    }

    .arrow-to-show.open {
        transform: rotate(0deg);
    }

    .extra-info-card-data {
        padding: 15px;
        background-color: #F2F2F3;

        & > div {
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #E5E5E5;

            p {
                margin-top: 5px;
                opacity: 0.8;
                font-size: 14px;
            }
        }

        & > div:last-child {
            border-bottom: 0px solid transparent;
            padding-bottom: 0px;
        }
    }
    .item-card-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        &__item {
            border-bottom: 1px solid #e6e6e7;
            width: 100%;
            padding-bottom: 18px;
            margin-bottom: 20px;
            &:last-child  {
                margin-bottom: 0;
                border-bottom: 0;
                padding-bottom: 5px;
            }
        }
    }

    .final-text {
        p {
            text-decoration: none;
            color: #1A4CCD;
            font-size: 14px;
            line-height: 17px;
            font-weight:500;
        }
        span {
            text-decoration: none;
            color: #CA3976;
            font-size: 14px;
            line-height: 17px;
            font-weight:500;
            text-decoration: underline;
            cursor: pointer;
        }
    }


    @media (max-width: 768px) {
        #my-activity-toolbar {
   
            div {
                flex: 2 0 33%;
            }
            .four {
                display: none;
            }
        }
    }
    
    .pay-button {
        margin-bottom: 20px;
    }

`;




export const Card = styled.div`
    width: 80%;
  .profile-card-item {
    &__title {
      color: #01030C;
      margin-bottom: 4px;
    }
    &__text {
      color: #808185;
    }

    .span {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  }

`;

export default PaymentSidebarContainer;