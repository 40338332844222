import styled from 'styled-components/macro';

export const NotfoundStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 90px;
  align-content: center;
  .item-left {
    align-self: center;
    justify-self: end;
    width: 300px;
    &__title {
      font-size: 4.5rem;
      color: ${props => props.theme.colors.primary};
      line-height: 87px;
      font-weight: 600;
    }

    &__content {
      p {
        font-size: 1.875rem;
        color: #01030c;
        line-height: 36px;
        font-weight: 600;
        margin-bottom: 32px;
      }
      span {
        font-size: 1rem;
        color: #808185;
        line-height: 24px;
        font-weight: 200;
      }
      margin-bottom: 32px;
    }
  }
  .item-right {
    justify-self: start;
  }
`;
