import React from 'react';

// Styles
import CardStatisticsContainer from './card-statistics-style';

// Types
import { ICardStatisticsProps } from './card-statistics-types';

const CardStatistics: React.FC<ICardStatisticsProps> = (props: ICardStatisticsProps) => {
  const { className, icon, overtitle, title, infoNumber, infoText, lost, danger } = props;

  return (
    <CardStatisticsContainer className={className} lost={lost}>
      <div className="wrapper-overtitle">
        <div className="overtitle">{overtitle}</div>
        {/* <div className="icon">{icon ? icon : <></>}</div> */}
      </div>
      <div className={`title ${danger && 'danger'}`}>{title}</div>
      <div className="info">
        <div className="infoNumber">{infoNumber}</div>
        <div className="infoText">{infoText}</div>
      </div>
    </CardStatisticsContainer>
  );
};

export default CardStatistics;
