import { Dispatch } from 'redux';

import { IModalActions, IModalActionsTypes } from './types';

const show = (id: string | number) => async (dispatch: Dispatch<IModalActionsTypes>) => {
  dispatch({ type: IModalActions.SHOW, payload: id });
};

const hide = () => async (dispatch: Dispatch<IModalActionsTypes>) => {
  dispatch({ type: IModalActions.HIDE });
};

export const modalActions = {
  show,
  hide,
};
