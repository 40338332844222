import styled from 'styled-components';
import { colors } from '../../../assets/themes';


const LoaderMainSidebarContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-animation {
    border: 5px solid;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-color: ${colors['PR––001-100']} #f1f1f1 #f1f1f1;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
 
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

`
export default LoaderMainSidebarContainer;