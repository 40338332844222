import styled from 'styled-components/macro';
import sizes from '../../assets/sizes/sizes';

const MobileHeaderContainer = styled.div`
    font-family: 'gilroy';

    #navbar-mobile-id {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }
    #navbar-desktop-id {
        display: block;
    }

    @media(max-width: 1200px) {
        #navbar-mobile-id.main-aside-open{
            display: block !important;
        }
        #navbar-desktop-id.main-aside-open {
            display: none !important;
        }
    }

    @media(max-width: ${sizes.navbar.screen.sm}px) {
        #navbar-mobile-id {
            display: block !important;
        }
        #navbar-desktop-id {
            display: none !important;
        }
    }

`;

export { MobileHeaderContainer }