import { Dispatch } from 'redux';

import { IPaymentSidebarActions, IPaymentSidebarActionsTypes } from './types';
import { LightpayApi, IFormatError } from '../restclient';
import { IServerTransactionDetailsResponse } from '../transaction/actions';

const openSidebar = () => async (dispatch: Dispatch<IPaymentSidebarActionsTypes>) => {
  dispatch({ type: IPaymentSidebarActions.OPEN_SIDEBAR });
};

const closeSidebar = () => async (dispatch: Dispatch<IPaymentSidebarActionsTypes>) => {
  dispatch({ type: IPaymentSidebarActions.CLOSE_SIDEBAR });
};

const toggleSidebar = () => async (dispatch: Dispatch<IPaymentSidebarActionsTypes>) => {
  dispatch({ type: IPaymentSidebarActions.TOGGLE_SIDEBAR });
};

const getCurrentPurchasedData = (idPurchase: number | string) => async (dispatch: Dispatch<IPaymentSidebarActionsTypes>, getState: any) => {
  try {
    dispatch({ type: IPaymentSidebarActions.LOADING_SIDEBAR });
    const { id } = getState().auth.user;
    const response = await LightpayApi.get<IServerTransactionDetailsResponse>(
      `/users/${id}/purchases/${idPurchase}`.concat(
        `?relations=commerce,commerce.user,commerce.user.image,payments,payments.card,payments.latePaymentFee&sort[]=payments.paymentIndex,payments.latePaymentFee.id`),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    dispatch({ type: IPaymentSidebarActions.SAVED_SIDEBAR_DATA, payload: response.data });
    dispatch({ type: IPaymentSidebarActions.LOADED_SIDEBAR_DATA });
  }catch(error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: IPaymentSidebarActions.LOADED_SIDEBAR_DATA });
    throw formatError;
  }
};

export const paymentSidebarActions = {
  openSidebar,
  closeSidebar,
  toggleSidebar,
  getCurrentPurchasedData
};
