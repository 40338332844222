import React, { useEffect } from 'react'
import './App.scss'

// Constants
// import { domain } from './constants/env'
import { facebookPixelKey, googleAnalyticsKey } from '../src/constants/env';

// Components
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux'
// import ResetStyles from './assets/global-styles/reset'
import { ToastProvider } from 'react-toast-notifications';
import CustomScroll from './assets/global-styles/custom-scroll'
import MyCustomToast from './components/custom-toast/custom-toast';
import Loader from './components/loader/loader'
import LightpayApp from './containers/ligthpay-app/lightpay-app'
import AuthorizationRoutes from './navigation/authorization-routes';
import { authSelectors } from './store/auth/selectors'
import { loadSelector } from './store/loader/selectors'


// Styles
import { ThemeProvider } from 'styled-components';
import Normal from './assets/colors/themes/normal/normal';

// Hooks
import ModalErrorCard from './components/modals/modal-error/modal-error';
import Toaster from './components/Toaster/Toaster';
import { OPENPAY_MERCHANTE_ID, OPENPAY_SANDBOX, PUBLIC_KEY } from './constants/env';
import { authActions } from './store/auth/actions';
import { modalActions } from './store/modal/actions';
import { modalSelector } from './store/modal/selectors';
import { isExpiredJWT } from './utils/utils';
import amplitude from 'amplitude-js'
import SendApiConversion from './constants/apiConversionFb';


const App: React.FC = () => {

  // const { isLogged, validateToken } = useToken();
  const dispatch = useDispatch();
  const token = useSelector(authSelectors.getCurrentJWT());
  const loading = useSelector(loadSelector.isLoading());
  const isModal = useSelector(modalSelector.isShowing());

  (window as any).OpenPay.setSandboxMode(OPENPAY_SANDBOX);
  (window as any).OpenPay.setId(OPENPAY_MERCHANTE_ID);
  (window as any).OpenPay.setApiKey(PUBLIC_KEY);

  useEffect(() => {
    if (!token) return;
    if (isExpiredJWT(token as string)) {
      dispatch(authActions.logout());
    }
  }, [token, dispatch]);

  useEffect(()=> {
    ReactGA.initialize(googleAnalyticsKey);
    ReactGA.pageview('https://clientes.slightpay.com/');
    ReactPixel.init(facebookPixelKey);
    ReactPixel.pageView();
    SendApiConversion('visit clientes',[''],[''])
  },[]);


  useEffect(() => {
    amplitude.getInstance().init("1a071dc4782cad551bb9d7d47cfa6cf5",undefined,{
      includeUtm: true
    });
    amplitude.getInstance().logEvent('visit clientes')
  }, [])

  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={Normal}>
        <ToastProvider components={{ Toast: MyCustomToast }}>
          {/* <ResetStyles /> */}
          <CustomScroll />
          {loading && <Loader />}
          {token ? <LightpayApp /> : <AuthorizationRoutes/>}
          <Toaster />
          {/* <ModalErrorCard 
            modalConfig={{
              onClose: closeModal,
              active: (isModal?.show && isModal?.idModal === 'error') || false,
            }}
          /> */}
        </ToastProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App
