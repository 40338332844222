import React, { createContext, ReactChild, useState } from "react";


export interface PropTypes {
    children: (ReactChild | React.FunctionComponent) | (ReactChild | React.FunctionComponent)[];
    value: MainAsideContextType;
};

export interface MainAsideContextType {
  isMenuOpen: boolean;
  htmlContent: (ReactChild | React.FunctionComponent);
  toggleMenu?: () => void;
  updateHtmlContent?: (htmlData: (ReactChild | React.FunctionComponent)) => void;
}

const initialValue: MainAsideContextType = {htmlContent: <div>TEST</div>, isMenuOpen: false};

export const MainAsideContext = createContext(initialValue);

export const MainAsideProvider = (props:any) => {
  // Initial values are obtained from the props
  const {
    children
  } = props;

  // Hook for menu state
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  }

  // Hook for menu html content
  const [htmlContent, setHtmlContent] = useState<any>();

  const updateHtmlContent = (htmlData: (ReactChild | React.FunctionComponent)) => {
    setHtmlContent(htmlData);
  }


  // Make the context object:
  const mainAsideContext = {
    isMenuOpen,
    toggleMenu,
    updateHtmlContent,
    htmlContent
  };

  return (<MainAsideContext.Provider value={mainAsideContext}>
            {children}
          </MainAsideContext.Provider>);
};

export const MainAsideConsumer = MainAsideContext.Consumer;