import React, { useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { SignupStyle } from './Signup-style';

// routes
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import DobleImageAttach from '../../../../components/form-components/attach/doble-image-attach/doble-image-attach';
import { IDefaultAttachImagesObjType } from '../../../../components/form-components/attach/doble-image-attach/doble-image-attach-types';
import { authActions } from '../../../../store/auth/actions';
import { authSelectors } from '../../../../store/auth/selectors';
import BasicDataRegisterForm from './steps/BasicData-form';
import FinalStepRegisterForm from './steps/FinalStep-form';
import LegalDataRegisterForm from './steps/LegalData-form';
import ConfirmSMS from './steps/ConfirmSMS';
import amplitude from 'amplitude-js'

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isIneModalOpen = useSelector(authSelectors.getIneModalStatus());
  const isNewEmail = useSelector(authSelectors.getErrorEmail());
  const handleIneImagesAdded = (images: IDefaultAttachImagesObjType) => {
    const ine = {
      front: images.ImgIneFront.fakeUrl,
      back: images.ImgIneBack.fakeUrl,
    };
    dispatch(authActions.addIneImages(ine));
  }

  useEffect(() => {
    if (!isNewEmail) history.push('/iniciar-sesion');
    console.log(isNewEmail);
  }, [history, isNewEmail]);

  useEffect(() => {
    ReactGA.pageview('https://clientes.slightpay.com/registro');
  }, [])

  return (
    <>
      <SignupStyle>
        <Route exact path="/registro" render={() => <Redirect to="/registro/informacion-basica" />} />
        <Route exact path="/registro/informacion-basica" component={BasicDataRegisterForm} />
        <Route exact path="/registro/informacion-legal" component={LegalDataRegisterForm} />
        <Route exact path="/registro/confirmacion-sms" component={ConfirmSMS} />
        <Route exact path="/registro/finalizado" component={FinalStepRegisterForm} />
      </SignupStyle>
      {isIneModalOpen && <DobleImageAttach onImagesAdded={handleIneImagesAdded} />}
    </>
  );
};

export default Signup
