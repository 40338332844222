import styled from 'styled-components';

export const AppRoutesContainer = styled.div`
  background: #f2f2f3;
  min-height: 100vh;
  color: ${props => props.theme.colors.onBackground};
  width: 100%;
  padding: 117px 88px 140px 200px;
  transition: 0.3s ease-in-out all;

  &.active {
    padding: 117px 88px 140px 350px;
  }
`;

export const AuthorizationRoutesContainer = styled.div`
  color: ${props => props.theme.colors.onBackground};
`;