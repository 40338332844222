import { IBaseAction } from '../base-action.interface';
import { ICard } from '@aluxion-lightpay/interfaces';

export enum CardActions {
    ADD_CARD_DATA = 'ADD_CARD_DATA',
    ADD_LASTCARD_DATA = 'ADD_LASTCARD_DATA',
    ADD_DEBTS_DATA = 'ADD_DEBTS_DATA',
}

export interface IUserCards {
  items: ICard[];
  count: number;
}

export interface ICardState { 
    readonly cards: IUserCards;
    readonly lastCardAdded: ICard | null;
    readonly hasDebts: boolean | null;
}

export interface ICardReducer {
  (state: ICardState, action: CardActionsTypes): ICardState;
}

export interface ICardAddAction extends IBaseAction {
  type: CardActions.ADD_CARD_DATA;
  payload:IUserCards
}

export interface ILastCardAddAction extends IBaseAction {
  type: CardActions.ADD_LASTCARD_DATA;
  payload: ICard;
}

export interface IHasDebtsAction extends IBaseAction {
  type: CardActions.ADD_DEBTS_DATA;
  payload: boolean | null;
}

export type CardActionsTypes = ICardAddAction | ILastCardAddAction | IHasDebtsAction;