import React, { useEffect } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../../store/auth/actions';
import { useHistory } from 'react-router-dom';
import MainButton from '../../../../../components/main-button/main-button';
import ModalCode from '../../../../../components/modals/modal-code/modal-code';
import { modalSelector } from '../../../../../store/modal/selectors';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

const FinalStepRegisterForm: React.FC = () => {
  const history = useHistory();
  const store = useStore();
  const name = store.getState().auth.user?.firstName;
  const isModal = useSelector(modalSelector.isShowing());

  const dispatch = useDispatch();

  const handleLoggin = () => {
    dispatch(authActions.setJwt(localStorage.getItem('token')));
    history.push('/');
  };

  useEffect(() => {
    ReactGA.event({
      category: 'conversion',
      action: 'Sent message'
    });
    localStorage.removeItem('noReload');
    ReactPixel.trackCustom('Lead', {});
  }, []);

  return (
    <>
      <div className="form-p">
        <p>
          <span className="bold">¡Bienvenido </span> <span>{`${name}!`}</span>
          <br />
          <br />
          Por seguridad, te hemos enviado un email de confirmación para que actives tu cuenta.
        </p>
      </div>
      <MainButton className="form-button" type="primary" text="Acceder" onClick={handleLoggin} />
    </>
  );
};

export default FinalStepRegisterForm;
