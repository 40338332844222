export const images: any = {
  defaultPromotionBigCard: require('./files/default-big-card.jpg'),
  helpBanner: require('./files/help-banner.jpg'),
  homeAviableStoreMobile: require('./files/home-aviable-stores-mobile.png'),
  homeAviableStore: require('./files/home-aviable-stores-desktop.png'),
  homeNewPaymentMobile: require('./files/home-new-payments-mobile.png'),
  homeNewPayment: require('./files/home-new-payments-desktop.png'),
  homeNotiConfigMobile: require('./files/home-noti-config-mobile.png'),
  homeNotiConfig: require('./files/home-noti-config-desktop.png'),
  homeResumenSidebar: require('./files/home-resume-sidebar.png'),
  login1: require('./files/login-pic-1.png'),
  login2: require('./files/login-pic-2.jpg'),
  login3: require('./files/login-pic-3.jpg'),
  login5: require('./files/login-pic-5.png'),
  login6: require('./files/login-pic-6.png'),
  loginResp1: require('./files/login-resp-pic-1.svg'),
  loginResp2: require('./files/login-resp-pic-2.jpg'),
  loginResp3: require('./files/login-resp-pic-3.jpg'),
  myActivityHeroblock: require('./files/my-activity-heroblock.png'),
  myWalletHeroblock: require('./files/my-wallet-heroblock-desktop.png'),
  myWalletHeroblockMobil: require('./files/my-wallet-heroblock-mobile.png'),
  myWalletNoCard: require('./files/my-wallet-no-card.png'),
  myWalletCardAdded: require('./files/my-wallet-card-added.png'),
  myActivityIconTempHeader: require('./files/my-activity-temp-logo.svg'),
  profile: require('./files/profile-pic.jpg'),
  profileDefaultBanner: require('./files/profile-default-banner.svg'),
  register1: require('./files/register-pic-1.png'),
  register2: require('./files/register-pic-2.png'),
  register3: require('./files/register-pic-3.png'),
  register5: require('./files/register-pic-5.png'),
  register6: require('./files/register-pic-6.png'),
  successPage1: require('./files/success-page1.png'),
  verifyRegisterCodeResp: require('./files/verify-register-code-resp.svg'),
  walletTempCreditCard: require('./files/wallet-temp-creditcard.png'),
  defaultProfileImage: require('./files/default-profile.png'),
  imgRun: require('./files/run.png'),
  image404: require('./files/404.png'),
  chrome: require('./files/chrome.png'),
  opera: require('./files/opera.png'),
  safari: require('./files/safari.png'),
  firefox: require('./files/firefox.png'),
  edge: require('./files/edge.png'),
  dog: require('./files/dog.png'),
  info: require('./files/icon-info.svg'),
  alanika: require('./comercios/alanika.jpg'),
  arteCuadrado: require('./comercios/artecuadrado.jpg'),
  baker: require('./comercios/baker.jpg'),
  boh: require('./comercios/boh.jpg'),
  glowy: require('./comercios/glowy.jpg'),
  monasita: require('./comercios/monasita.jpg'),
  nanah: require('./comercios/nanah.jpg'),
  snkrsbros: require('./comercios/snkrsbros.png'),
  terra: require('./comercios/terra.jpg'),
  that: require('./comercios/that.jpg'),
  twoFeet: require('./comercios/twofeet.jpg'),
  millie: require('./comercios/millie.jpg'),
  amorpropio: require('./comercios/amorpropio.png'),
  onParty: require('./comercios/OnParty.png'),
  mintRose: require('./comercios/MintRose.png'),
  pink: require('./comercios/pink.png'),
  rhinostore: require('./comercios/RhinoStore.png'),
}
export const wallet987 = require('./wallets/BancoAzteca-1.png');
export const wallet233 = require('./wallets/superapp.png');
export const wallet268 = require('./wallets/broxel.png');
export const wallet476 = require('./wallets/tomiin.png');
export const wallet399 = require('./wallets/klinc.png');
export const wallet467 = require('./wallets/minu.png');
export const wallet825 = require('./wallets/paynom.png');
export const wallet433 = require('./wallets/coltomoney.png');
export const gifAmex = require('./gifBanks/amex.gif');
export const gifAzteca = require('./gifBanks/bancoazteca.gif');
export const gifCoppel = require('./gifBanks/bancoppel.gif');
export const gifBanorte = require('./gifBanks/banorte.gif');
export const gifBanregio = require('./gifBanks/banregio.gif');
export const gifBbva = require('./gifBanks/bbva.gif');
export const gifCiti = require('./gifBanks/citibanamex.gif');
export const gifHsbc = require('./gifBanks/hsbc.gif');
export const gifInvex = require('./gifBanks/invex.gif');
export const gifLiverpool = require('./gifBanks/liverpool.gif');
export const gifSantander = require('./gifBanks/santander.gif');
export const gifScotiabank = require('./gifBanks/scotiabank.gif');
export const infoBank = require('./files/info2.png');
export const infoBank1 = require('./files/info1.png');
export const escudo = require('./files/escudo.png');
export const eye = require('./files/eye.png');
export const exclamacion = require('./files/exclamacion.png');
export const eyeBlue = require("./files/icon-eye-blue.svg");
export const eyeBlueCrossed = require("./files/icon-eye-blue-crossed.svg");