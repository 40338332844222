import React, { useEffect } from "react";
import ModalContainer from "../modal-container/modal-container";
import { ModalErrorStyle } from "./modal-error-style";

const ModalErrorCustom: React.FC<any> = (props: any) => {
  const messagesTitle = [
    'Te recomendamos comunicarte con tu banco antes de intentar nuevamente.',
    'Por la cantidad de intentos de pago se ha generado un bloqueo temporal en tu cuenta.',
    'No es posible adelantar pagos en tu primer compra'
  ]

  const messagesText = [
    '⚠️ Los intentos repetidos rechazados pueden bloquear tu cuenta. ⚠️',
    '⚠️ Te recomendamos comunicarte con tu banco para autorizar la transacción, después de autorizarla comunícate nuevamente con nosotros. ⚠️'
  ]
  const { modalConfig, version } = props;

  useEffect(() => {
    console.log(version, 'la version del error')
  }, [version])

  return (
    <ModalContainer type="light" {...modalConfig}>
      <ModalErrorStyle>
        <div className="modal-error-title">
          <p>{messagesTitle[version-1]}</p>
        </div>
        <div className="modal-error-text">
          <p>{messagesText[version-1]}</p>
        </div>
      </ModalErrorStyle>
    </ModalContainer>
  );
};
export default ModalErrorCustom;
