import React, { useState, useEffect } from 'react';
import { SVGIcon } from '../../svg-icon/svg-icon';
import { icons } from '../../../assets/themes';
import moment from 'moment';
import { translatePurchasePartitionState } from '../../../utils/utils';
import { Link, useHistory } from 'react-router-dom';
import { IPurchase } from '@aluxion-lightpay/interfaces';
import { IPurchaseClients } from '../../../interfaces/purchaseclients.types'
import MainButton from '../../main-button/main-button';

interface IFirstViewProps {
  closeSidebar: () => void;
  data: IPurchaseClients;
}

const FirstViewSidebar: React.FC<IFirstViewProps> = props => {
  const { closeSidebar, data } = props;
  const history = useHistory();
  const [showExtraInfo, setShowExtraInfo] = useState([false, false, false, false]);
  const [count, setCount] = useState(0);
  const [cardData, setCardData] = useState(0);
  const [paymentsData, setPayments] = useState<any>();

  const showExtraData = (currentIndex: number) => {
    setShowExtraInfo(
      showExtraInfo.map((elem, idx) => currentIndex === idx ? !elem : elem)
    )
  }

  useEffect(() => {
    console.log(data)
    let paymentsFilter = data?.payments?.filter(value => {
      if(!data?.type  && value.paymentIndex !== 0 && value.type==="4 Cuotas") {
        return value
      } else if(data?.type === value.type && value.status !== 'CANCELED') return value 
    }) 
    setPayments(paymentsFilter)
    if (data?.type === "4 Cuotas" || !data?.type) {
      setCount(4);
      //setCardData(3)
    } else if (data?.type === "6 Cuotas") {
      setCount(6);

    } else if (data?.type === "8 Cuotas") {
      setCount(8);
    }
  }, [data])


  return (
    <>
      <div id="my-activity-navbar">
        <div className="image-container horizontal" onClick={closeSidebar}>
          <SVGIcon icon={icons.rejectedIcon} color='#01030C' />
        </div>
      </div>
      <div id="my-activity-header">

        <div className="text-container">
          <p className="company-name">{data?.commerce?.name}</p>
          <p className="amount">$ {data?.type === "OneShot" ? data?.totalOneShot : data?.totalAmount}</p>
        </div>
      </div>
      <div id="my-activity-toolbar">
        <div id="card-activity" className="first-row right-border">
          <div id="card-activity-content">
            <div className="image-content">
              <img src={icons.myActivityShoppingCart} alt="shopping cart" />
            </div>
            <p>REF. {data?.id}</p>
          </div>
        </div>
        <div id="card-activity" className="first-row">
          <div id="card-activity-content">
            <div className="image-content">
              <img src={icons.myActivityEvents} alt="activity events" />
            </div>
            <p>{`${moment(data?.createdAt).format('L')}`}</p>
          </div>
        </div>
        <div id="card-activity" className="second-row right-border">
          <div id="card-activity-content">
            <div className="image-content">
              <img src={icons.myActivityCreditcard} alt="credit card" />
            </div>
            <p>{data?.payments[3]?.card?.brand.toUpperCase() + '***' + data?.payments[3]?.card?.lastDigits}</p>
          </div>
        </div>

        <div id="card-activity" className="second-row">
          <div id="card-activity-content">
            <div className="image-content">
              <img src={icons.myActivityShoppingCart} alt="shopping card" />
            </div>
            <p>{translatePurchasePartitionState(data?.status.toUpperCase())}</p>
          </div>
        </div>
      </div>
      <div id="my-activity-due-payments">
        <div className="text-container">
          <p>PLAN DE CUOTAS</p>
        </div>

        <div className="data-container">
          {data?.type === "OneShot" ?
            <>
              <div
                key={0} 
                className={`payment-partition-container ${showExtraInfo[0] ? 'show' : ''}`} 
              >
                <div className="card-simple-data" key={0} onClick={() => showExtraData(0)}>
                  <div className="text-container">
                    <p>PAGO COMPLETO</p>
                  </div>
                  <div className="flex text-container">
                    <div className={data.payments[0].status === "SUCCESSFUL" ? 'amount in' : 'amount out'}>
                      <p>${data.payments[0].amount}</p>
                    </div>
                    <div className={`image-container arrow-to-show ${showExtraInfo[0] ? '' : 'open'}`}>
                      <img src={icons.myActivityArrowRight} alt="right arrow" />
                    </div>
                  </div>
                </div>

                <div className="extra-info-card-data">
                  <div>
                    <h4>Fecha de cobro</h4>
                    <p>{`${moment(data.payments[0].dueDate).format('L')}`}</p>
                  </div>
                  <div>
                    <h4>Nº de Referencia</h4>
                    <p>{data.payments[0].id}</p>
                  </div>
                  <div>
                    <p>
                      <Link to={`/home/${data?.id}/${data.payments[0].paymentIndex}`}>
                        Ver más
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </>
            :
            paymentsData?.map((value: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={`payment-partition-container ${showExtraInfo[index] ? 'show' : ''}`}
                  >
                    <div className="card-simple-data" key={index} onClick={() => showExtraData(index)}>
                      <div className="text-container">
                        <p>Cuota {value.paymentIndex}/{count}</p>
                      </div>
                      <div className="flex text-container">
                        <div className={value.status === "SUCCESSFUL" ? 'amount in' : 'amount out'}>
                          <p>${value.amount}</p>
                        </div>
                        <div className={`image-container arrow-to-show ${showExtraInfo[index] ? '' : 'open'}`}>
                          <img src={icons.myActivityArrowRight} alt="right arrow" />
                        </div>
                      </div>
                    </div>

                    <div className="extra-info-card-data">
                      <div>
                        <h4>Fecha de cobro</h4>
                        <p>{`${moment(value.dueDate).format('L')}`}</p>
                      </div>
                      <div>
                        <h4>Nº de Referencia</h4>
                        <p>{value.id}</p>
                      </div>
                      <div>
                        <p>
                          <Link to={`/home/${data?.id}/${value.paymentIndex}`}>
                            Ver más
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                )
            })
          }
        </div>
        <MainButton
          type="primary"
          text="Ver compra completa"
          className="pay-button"
          onClick={() => history.push(`/detalle-operacion/${data?.id}`)
          }
        />
      </div>
    </>
  );
};

export default FirstViewSidebar;