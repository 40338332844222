import React from 'react'
import { images } from '../../assets/images/images';
import { GridCommerce, ContainerTitle } from './commerceStyles';
import { dataCommerce } from './../../data/sectionCommerce';
import amplitude from 'amplitude-js'

interface Props {
    
}

const CommerceSection = (props: Props) => {
    console.log("Commerce SectioSSSn");
    return (
        <>
        <ContainerTitle>
        <h2 className="title">Nuestras Tiendas</h2>
        </ContainerTitle>
        <GridCommerce>
           
            {
              dataCommerce && dataCommerce.map(item => (
                    <div className="boxElement">
                        <div className="imgContent">
                            <img className="imgCommerce" src={item.img} alt=""/>
                            <div className="text" onClick={() => { 
                                amplitude.getInstance().logEvent('stores click', { site: item.linkSite });
                             }}>
                                <a href={item.linkSite} target="_blank">
                                   Ir a {item.textImg}
                                </a>
                            </div>
                        </div>
                        
                        <div className="textCommerceContent">
                            <h2 className="textCommerce">{item.textImg}</h2>
                        </div>
                    </div> 
                ))
            }
        </GridCommerce>
        </>
    )
}

export default CommerceSection
