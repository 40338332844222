import styled from "styled-components";
import { icons } from "../../../assets/themes";
import { CheckboxColors } from "../../../assets/colors/checkbox-colors/checkbox-colors";

export const CheckboxContainer = styled.div`
  .checkbox-wrapper {
    display: inline-block;
  }

  .checkbox-container {
    display: flex;
    position: relative;
    cursor: pointer;

    &:hover {
      .checkmark-item {
        border: 1px solid ${CheckboxColors.checkbox.hover};
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:focus ~ .checkmark-container {
        .checkmark-item {
          border: 1px solid ${CheckboxColors.checkbox.focus};
        }
      }

      &:checked ~ .checkmark-container {
        justify-content: flex-end;

        .checkmark-item {
          &:after {
            background: url(${icons.tickBlueIcon}) no-repeat center;
            background-size: contain;
            width: 24px;
            height: 24px;
            content: "";
            position: absolute;
            top: -6px;
            left: -6px;
          }
        }

        img {
          max-width: 14px;
        }
      }
    }
  }

  .checkbox-error {
    color: red;
    display: block;
  }

  .checkmark-container {
    transition: 0.3s all ease-in-out;
    margin-right: 14px;
    margin-top: 3px;

    img {
      max-width: 10px;
    }
  }

  .checkmark-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    border: 1px solid ${CheckboxColors.checkbox.default};
    position: relative;
  }

  .checkbox-label {
    display: inline-block;
    font-family: "gilroy";
    font-size: 14px;
    font-weight: 300; 
    line-height: 1.5em;
    color: ${CheckboxColors.label.default};
  }

  &.disabled {
    cursor: default;
    pointer-events: none;

    .checkmark-item {
      border: 1px solid ${CheckboxColors.checkbox.disabled};
    }

    .checkbox-label {
      color: ${CheckboxColors.label.disabled};
    }

    .checkbox-container {
      input {
        &:checked ~ .checkmark-container {
          .checkmark-item {
            &:after {
              background: url(${icons.tickGreyIcon}) no-repeat center;
              background-size: contain;
              width: 23px;
              height: 23px;
              content: "";
              position: absolute;
            }
          }
        }
      }
    }
  }

  &.error {
    .checkmark-item {
      border: 1px solid ${CheckboxColors.checkbox.error};
    }

    .checkbox-container {
      &:hover {
        .checkmark-item {
          border: 1px solid ${CheckboxColors.checkbox.error};
        }
      }

      input {
        &:focus ~ .checkmark-container {
          .checkmark-item {
            border: 1px solid ${CheckboxColors.checkbox.error};
          }
        }

        &:checked ~ .checkmark-container {
          .checkmark-item {
            &:after {
              background: url(${icons.tickRedIcon}) no-repeat center;
              background-size: contain;
            }
          }
        }
      }
    }
  }
`;
