import React from 'react';
import { CardDetailHeaderInfoStyle } from './card-detail-header-info-style';
import { icons } from '../../../assets/icons/icons';
import { SVGIcon } from '../../svg-icon/svg-icon';

interface ICardDetailHeaderInfo {
  status?: 'in-progess' | 'pending' | 'resolved' | 'lost' | 'partially' | 'total' | 'nothing';
  id?: string;
  date?: string;
  hour?: string;
  className?: string;
}

const CardDetailHeaderInfo: React.FC<ICardDetailHeaderInfo> = (props: ICardDetailHeaderInfo) => {
  const { status, id, date, hour, className } = props;

  const renderStatus = () => {
    switch (status) {
      case 'in-progess':
        return (
          <div className="card-detail-header-info-item blue">
            <div className="card-detail-header-info-item-icon">
              <SVGIcon icon={icons.processIcon} color="#1A4CCD" />
            </div>
            <div className="card-detail-header-info-item-text">
              <p>EN PROCESO</p>
            </div>
          </div>
        );
      case 'pending':
        return (
          <div className="card-detail-header-info-item yellow">
            <div className="card-detail-header-info-item-icon">
              <SVGIcon icon={icons.processIcon} color="#FFBB36" />
            </div>
            <div className="card-detail-header-info-item-text">
              <p>PENDIENTE</p>
            </div>
          </div>
        );
      case 'total':
        return (
          <div className="card-detail-header-info-item blue">
            <div className="card-detail-header-info-item-icon">
              <SVGIcon icon={icons.processIcon} color="#1A4CCD" />
            </div>
            <div className="card-detail-header-info-item-text">
              <p>TOTAL</p>
            </div>
          </div>
        );
      case 'partially':
        return (
          <div className="card-detail-header-info-item light-blue">
            <div className="card-detail-header-info-item-icon">
              <SVGIcon icon={icons.processIcon} color="#4FB2C6" />
            </div>
            <div className="card-detail-header-info-item-text">
              <p>PARCIAl</p>
            </div>
          </div>
        );
      case 'resolved':
        return (
          <div className="card-detail-header-info-item light-blue">
            <div className="card-detail-header-info-item-icon">
              <SVGIcon icon={icons.checkIconSVG} color="#4FB2C6" />
            </div>
            <div className="card-detail-header-info-item-text">
              <p>Resuelto</p>
            </div>
          </div>
        );
      case 'lost':
        return (
          <div className="card-detail-header-info-item red">
            <div className="card-detail-header-info-item-icon">
              <SVGIcon icon={icons.xIconSVG} color="#CA3976" />
            </div>
            <div className="card-detail-header-info-item-text">
              <p>PERDIDO</p>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <CardDetailHeaderInfoStyle className={className}>
      {status && renderStatus()}
      {id && (
        <div className="card-detail-header-info-item">
          <div className="card-detail-header-info-item-icon">
            <SVGIcon icon={icons.shopIcon} color="#CCCDCE" scale='.8'/>
          </div>
          <div className="card-detail-header-info-item-text">
            <p>REF: {id}</p>
          </div>
        </div>
      )}
      {date && (
        <div className="card-detail-header-info-item">
          <div className="card-detail-header-info-item-icon">
            <SVGIcon icon={icons.calendarGrey} color="#CCCDCE" />
          </div>
          <div className="card-detail-header-info-item-text">
            <p>{date}</p>
          </div>
        </div>
      )}
      {hour && (
        <div className="card-detail-header-info-item">
          <div className="card-detail-header-info-item-icon">
            <SVGIcon icon={icons.clockIcon} color="#CCCDCE" />
          </div>
          <div className="card-detail-header-info-item-text">
            <p>{hour}</p>
          </div>
        </div>
      )}
    </CardDetailHeaderInfoStyle>
  );
};

export default CardDetailHeaderInfo;
