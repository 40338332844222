import React, { useEffect } from 'react';
import MainButton from '../../../../../components/main-button/main-button'
import PasswordBox from '../../../../../components/form-components/password-box/password-box';
import { useForm } from 'react-hook-form';
import { getLoginInputMetada, LOGIN_PASSWORD_INPUT_NAME } from '../../Auth-inputs-names';
import { getLoginFormErrors } from '../../Auth-inputs-validations';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../../../../store/auth/actions';
import { authSelectors } from '../../../../../store/auth/selectors';
import { useHistory } from 'react-router-dom';
import ModalErrorCard from '../../../../../components/modals/modal-error/modal-error';
import useModal from '../../../../../hooks/useModal/use-modal';
import amplitude from 'amplitude-js'
import SendApiConversion from '../../../../../constants/apiConversionFb';

const MainLogin: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openModal, isModal, closeModal } = useModal();
  const currentEmail = useSelector(authSelectors.getEmail());

  useEffect(() => {
    if (!currentEmail) history.push('/');
  }, [currentEmail, history]);

  const { register, handleSubmit, errors } = useForm();

  const handleForm = async (data: any) => {
    try {
      await dispatch(authActions.login(currentEmail as string, data[LOGIN_PASSWORD_INPUT_NAME]));
      dispatch(authActions.setJwt(localStorage.getItem('token')));
      history.push('/');
      amplitude.getInstance().logEvent('logged in', {'account_type': 'shopper', 'email': currentEmail, 'location': 'clientes.slightpay'});
      SendApiConversion('Client_Loggin')
    } catch (error) {
      if(error.statusCode === 403) {
        openModal('errorLogin');
      }
      amplitude.getInstance().logEvent('error encountered', {'error_type': 'login', 'error_reason': JSON.stringify(error)});
      console.log(error);
    }
  };

  const handleView = async () => {
    try {
      await dispatch(authActions.recoverPassword(currentEmail || ''));
      history.push('/iniciar-sesion/olvido-de-contraseña');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PasswordBox
        labelText="contraseña"
        name={LOGIN_PASSWORD_INPUT_NAME}
        inputRef={register(getLoginInputMetada(LOGIN_PASSWORD_INPUT_NAME))}
        errorCode={getLoginFormErrors(errors, LOGIN_PASSWORD_INPUT_NAME)}
        onKeyPress={handleSubmit(handleForm)}
      />
      <button className="button-underline button-underline_main" onClick={handleView}>
        He olvidado mi contraseña
      </button>
      <MainButton className="form-button" type="primary" text="Login" onClick={handleSubmit(handleForm)} />
      {/* MODAL ERROR */}
      <ModalErrorCard
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'errorLogin') || false,
        }}
        title="Esta ingresando con una cuenta de comercio, por favor registrate nuevamente como cliente."
      />
    </>
  );
};

export default MainLogin;
