import styled from 'styled-components/macro';
import cardBase from '../cards/card-base-style';

const BuyDetailContainer = styled<any>(cardBase)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  box-shadow: none;
  border-radius: 0px;
  padding: 40px;

  &.isolated {
    border-radius: 3px;
    padding: 29px 26px;
    box-shadow: 0px 4px 4px rgba(1, 3, 12, 0.15);
  }
  .heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;

    .title {
      font-size: 18px;
      line-height: 18px;
      color: #1a1c24;
    }

    .icon {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }

  .wrapper-items {
    width: 100%;
    display: block;
    border-bottom: 1px solid #e6e6e7;
    border-top: 1px solid #e6e6e7;
  }

  .wrapper-table {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;

    .table {
      width: 50%;
      align-self: flex-end;
    }
  }
`;

export default BuyDetailContainer;
