import React from 'react';

//Styles
import {MobileHeaderContainer} from './header-type';

//Components
import MobileHeader from './mobile/mobile';
import DesktopHeader from './desktop/desktop';

interface MainHeaderProps {
    className?: string
}

const MainHeader: React.FC<MainHeaderProps> = (props: MainHeaderProps) => {

    return (
        <MobileHeaderContainer>
            <div id="navbar-mobile-id" className={props.className}>
                <MobileHeader />
            </div>
            <div id="navbar-desktop-id" className={props.className}>
                <DesktopHeader />
            </div>
        </MobileHeaderContainer>
    )
}

export default MainHeader;