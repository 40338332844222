import React, { useEffect } from 'react';
import ModalCode from '../../../../../components/modals/modal-code/modal-code';
import ReactPixel from 'react-facebook-pixel';

const ConfirmSms: React.FC = () => {
  // const isModal = useSelector(modalSelector.isShowing());

  useEffect(() => {
    ReactPixel.trackCustom('Initiate Checkout', {});
  }, []);

  return (
    <ModalCode
      modalConfig={{
        active: true, //(isModal?.show && isModal?.idModal === 'SmsCode') || false,
      }}
    />
  );
};

export default ConfirmSms;
