import React, { useState, useEffect } from 'react';
import { SVGIcon } from '../../svg-icon/svg-icon';
import { icons } from '../../../assets/themes';
import CircleStat from '../../circle-stat/circle-stat';
import { formatMoney } from '../../../utils/utils';
import { Card } from '../payment-sidebar-style';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import MainButton from '../../main-button/main-button';
import { IPaymentPartition, IPurchase } from '@aluxion-lightpay/interfaces';
import { IPurchaseClients, IPaymentPartitionClients } from '../../../interfaces/purchaseclients.types'
import { LabelText } from '../../components-text/label-text/label-text';
import BodyText from '../../components-text/body-text/body-text';
import useModal from '../../../hooks/useModal/use-modal';
import { useSelector, useDispatch } from 'react-redux';
import { transactionSelector } from '../../../store/transaction/selector';
import { transactionActions } from '../../../store/transaction/actions';
import ModalInfo from '../../modals/modal-info/modal-info';
import ModalToPaid from '../../modals/modal-to-paid/modal-to-paid';
import useUserCards from '../../../hooks/useWallet/useUserCards';
import { latePaymentsStatus } from '../../../constants/constants';

interface ISecondViewSideBar {
  feeData: IPaymentPartitionClients[],
  feeNumber: number;
  data: IPurchaseClients;
}

interface IItemProps {
  title: string;
  url?: string;
  text?: string;
  email?: string;
  span?: any;
}

export const Item: React.FC<IItemProps> = (props: IItemProps) => {
  const { title, text, span } = props;

  return (
    <div className="profile-card-item">
      <div className="profile-card-item__title">
        <LabelText>{title}</LabelText>
      </div>
      <div className={`profile-card-item__text ${span && 'span'}`}>
        {text ? (
          <>
            <BodyText textType="p-14">{text}</BodyText>
            {span && span}
          </>

        ) : (
          '<p>--</p>'
        )}
      </div>
    </div>
  );
};


const SecondViewSidebar: React.FC<ISecondViewSideBar> = props => {
  const { feeData, feeNumber, data } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { openModal, isModal, closeModal } = useModal();
  const [showData, setShowedData] = useState<number | null>();
  const latePaymentAmount = useSelector(transactionSelector.getLatePaymentAmount());
  const [selectedCardId, setSelectedCard] = useState<number | null>();
  const { setDefaultCardById, payPurchasePartition } = useUserCards();
  const [latePayment, setLatePayment] = useState<boolean>(true);
  const paymentDate = feeData[0]?.latePaymentFee?.find(fee => fee.status === 'PENDING');
  const [count, setCount] = useState(0);

  const handleMenu = (index: number) => {
    if (index === showData) {
      setShowedData(null);
    } else {
      setShowedData(index)
    }
  }

  const payLatePayment = async (selectedCardId: number) => {
    try {
      if (selectedCardId) {
        await dispatch(transactionActions.payTotalLatePayments(data.id, feeData[0]?.id, selectedCardId));
        if (feeNumber !== 4) {
          openModal('setCard');
        } else {
          openModal('paymentSuccessful');
        }
      }
    } catch (error) {
      throw error
    }
  }

  const selectedCard = async (currentCardId: number) => {
    setSelectedCard(currentCardId);
  }

  const setDefaultCard = async () => {
    try {
      if (selectedCardId) await setDefaultCardById(selectedCardId, data.id);
      openModal('paymentSuccessful')
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    if (feeData[0].type === "4 Cuotas" || !feeData[0].type) {
      setCount(4);
      //setCardData(3)
    } else if (feeData[0].type === "6 Cuotas") {
      setCount(6);

    } else if (feeData[0].type === "8 Cuotas") {
      setCount(8);
    }
  }, [data])


  return (
    <>
      <div id="my-activity-navbar">
        <div className="image-container horizontal" onClick={() => history.push(`/home/${data?.id}`)}>
          <SVGIcon icon={icons.arrowBackLeft} color='#01030C' />
        </div>
      </div>

      <CircleStat
        radius={130}
        stroke={20}
        progress={feeData[0].type === "OneShot" ? 100 : (feeNumber * 100) / 8}
        fill={feeData[0]?.status === 'SUCCESSFUL' ? '#1A4CCD' : '#CA3976'}
        text={feeData[0].type === "OneShot" ? "Pago Completo" : `Cuota ${feeNumber}/${count}`}
        span={formatMoney(feeData[0]?.amount)}
        status={feeData[0]?.status}
      />
      <Card>
        <div className="item-card-info">
          <div className="item-card-info__item">
            <Item title="Nº de referencia" text={`${feeData[0]?.id}` || '---'} />
          </div>

          <div className="item-card-info__item">
            <Item title="Fecha cobro" text={moment(feeData[0]?.dueDate).format('L')} />
          </div>

          <div className="item-card-info__item">
            <Item title="Tarjeta" text={`${feeData[0]?.card?.brand} **** ${feeData[0]?.card?.lastDigits}`} />
          </div>

          <div className="item-card-info__item">
            <Item title="Nombre del comercio" text={`${data?.commerce?.name}`} />
          </div>

          <div className="item-card-info__item">
            <Item title="Precio compra completa" text={feeData[0].type === "OneShot" ? `${formatMoney(data?.totalOneShot)}` : `${formatMoney(data?.totalAmount)}`} />
            <Link to={`/detalle-operacion/${data?.id}`}>
              <div className="final-text">
                <p>
                  Ver compra completa
                </p>
              </div>
            </Link>
          </div>
          <div className="item-card-info__item">
            <Item
              title="Cargos por mora:"
              text={`${formatMoney(latePaymentAmount ? latePaymentAmount : 0)}`}
              span={latePaymentAmount !== 0 &&
                <div className="final-text" onClick={() => { setLatePayment(true); openModal('confirmBuy') }}>
                  <span>
                    Pagar
                  </span>
                </div>
              }
            />
          </div>
        </div>

        {
          feeData[0]?.latePaymentFee?.length !== 0 &&
          <div id="my-activity-due-latepayments">
            <div className="data-container">
              {feeData[0]?.latePaymentFee?.slice(0, 5).map((value, index) => {
                return (
                  <div
                    key={index}
                    className={`payment-partition-container ${showData === index ? 'show' : ''}`}
                  >
                    <div className="card-simple-data" key={index} onClick={() => handleMenu(index)}>
                      <div className="text-container">
                        <p>Mora {`${index + 1}/${feeData[0]?.latePaymentFee.length}`}</p>
                      </div>
                      <div className="flex text-container">
                        <div className={value.status === "SUCCESSFUL" ? 'amount in' : 'amount out'}>
                          <p>${value.amount}</p>
                        </div>
                        <div className={`image-container arrow-to-show ${showData === index ? '' : 'open'}`}>
                          <img src={icons.myActivityArrowRight} alt="right arrow" />
                        </div>
                      </div>
                    </div>

                    <div className="extra-info-card-data">
                      <div>
                        <h4>Fecha de creación</h4>
                        <p>{`${moment(value.createdAt).format('L')}`}</p>
                      </div>
                      <div>
                        <h4>Fecha de cobro</h4>
                        <p>{`${moment(value.dueDate).format('L')}`}</p>
                      </div>
                      <div>
                        <h4>Nº de Referencia</h4>
                        <p>{value.id}</p>
                      </div>
                      <div>
                        <h4>Estado</h4>
                        <p>{latePaymentsStatus[value.status]}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
              {feeData[0]?.latePaymentFee.length > 5 &&
                <div className='latepayments-extra'>
                  <Link to={`/detalle-operacion/${data?.id}`}>
                    <div className="final-text">
                      <p>
                        Ver más
                      </p>
                    </div>
                  </Link>
                </div>

              }
            </div>
          </div>
        }
        {
          feeData[0]?.status === "PAYMENT_REJECTED" &&
          (<MainButton
            type="primary"
            text="Pagar Cuota"
            className="pay-button"
            onClick={() => {
              setLatePayment(false);
              try {
                // payPurchasePartition(feeData[0]?.id)
                openModal('confirmBuy')
              } catch (err) {
                console.error(err)
                openModal('error');
              }
            }
            }
          />)
        }
      </Card>
      <ModalToPaid
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'confirmBuy') || false,
          onClose: closeModal,
        }}
        logo={icons.slightpayLogo}
        shopName={data?.commerce?.name || ''}
        prize={formatMoney(feeData[0]?.amount!) || ''}
        fee={`${feeData[0]?.paymentIndex}/4`}
        date={latePayment ? moment(paymentDate?.dueDate).format('L') : moment(feeData[0]?.dueDate).format('L')}
        card={feeData[0]?.card!}
        onClick={latePayment ? payLatePayment : (value: number) => payPurchasePartition(data.id, feeData[0]?.id, value, feeNumber)}
        purchaseId={data.id}
        feeId={feeData[0]?.id}
        setDefaultCard={selectedCard}
        latePayment={latePayment}
      />

      <ModalInfo
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'setCard') || false,
          onClose: closeModal,
        }}
        title={'¿Deseas escoger esta tarjeta para que pague las futuras cuotas de esta compra?'}
        text=""
        buttons={[
          {
            text: 'No',
            type: 'ghost',
            onClick: () => { closeModal(); openModal('paymentSuccessful'); },
          },
          {
            text: 'Sí',
            type: 'primary',
            onClick: setDefaultCard,
          },
        ]}
      />
    </>
  );
};

export default SecondViewSidebar;