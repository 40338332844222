import React, { useState, useEffect } from 'react'
import MyWalletWithCardContainer from './my-wallet-with-card-style'

//Components
import SelectBox from '../../../../components/form-components/select-box/select-box'
import MainButton from '../../../../components/main-button/main-button'

//Constants
import { icons } from '../../../../assets/icons/icons'

//Test data (While backend is develop)
import WalletAside from '../../../../components/asides/wallet-aside/wallet-aside'
import { Link } from 'react-router-dom'
import PaymentTableWrapper from '../../../../components/tables/payment-table-wrapper/payment-table-wrapper'
import useUserCards from '../../../../hooks/useWallet/useUserCards'
import { ICard } from '@aluxion-lightpay/interfaces'
import { isNumeric } from '../../../../utils/validation'
import ModalSuccess from '../../../../components/modals/modal-success/modal-success'
import ModalInfo from '../../../../components/modals/modal-info/modal-info'
import CircleStat from '../../../../components/circle-stat/circle-stat'
import { formatMoney } from '../../../../utils/utils'
import { IFilterTypes } from '../../home/resume/home-resumen'
import { transactionActions } from '../../../../store/transaction/actions'
import { useDispatch } from 'react-redux'
import { SVGIcon } from '../../../../components/svg-icon/svg-icon'
import MainTableHeader from '../../../../components/main-table/main-table-header/main-table-header'
import MainTable from '../../../../components/main-table/main-table-layout';
import CalendarBox from '../../../../components/calendar-box'
import Pagination from '../../../../components/pagination/pagination'
import moment from 'moment';

// Move to other file
interface IModalsData {
  title: string;
  text: string;
  onClick: () => void;
}

const MyWalletWithCard: React.FC<any> = () => {
  const {
    cardData,
    getCard, 
    deleteCardById, 
    setDefaultCardById, 
    getStringCardNumber, 
    openModal, 
    closeModal, 
    isModal,
    items,
    count,
    paymentsItems,
    paymentsCount,
    isCurrentCardDefualt,
    id
  } = useUserCards();
  const dispatch = useDispatch();
  const [currentCard, setCurrentCard] = useState<ICard>(cardData[0])
  const [warningKeysData, setWarningData] = useState<IModalsData | null>(null);
  const [ tableSelected, setTableSelected ] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<IFilterTypes>({
    status: 'SUCCESSFUL,PENDING,CHARGEBACK_ACCEPTED,REFUNDED_TOTALLY,REFUNDED_PARTIALLY,CANCELED,CHARGEBACK_REJECTED,PAYMENT_REJECTED',
    fromDate: '',
    toDate: '',
    search: '',
  });

  const onPageChanged = async (data: any = 0) => {
    const { currentPage } = data;
    await Promise.all([
      dispatch(transactionActions.purchasesCard(currentCard.id, currentPage, filter)),
      dispatch(transactionActions.paymentsCard(currentCard.id, currentPage, filter)),
    ])
    setPage(currentPage);
  };

  const getSelectCardNumber = (cardData: ICard[]) => {
    let resp: { [key: number]: string } = {};
    cardData.forEach((card) => {
      resp[card.id] = getStringCardNumber(card.lastDigits)
    })

    return resp
  }

  const handleCardChange = (cardId: number) => {
    const card = getCard(cardId)

    setCurrentCard(card);
  }

  const handleModalShow = (modalId: string, modalsOpts: IModalsData) => {
    setWarningData({
      title:modalsOpts.title,
      text:modalsOpts.text,
      onClick:() => modalsOpts.onClick()
    })

    openModal(modalId);
  }


  const alertBeforeDelete = () => {
    handleModalShow(
      'cardChangeWarning',
      {
        title: '¿Estás seguro de eliminar esta tarjeta?',
        text: `Esta operación hará que la tarjeta se elimine de tu cuenta, 
        pero el pago asociado anteriormente a esta tarjeta continuara.`,
        onClick: deleteCard
      }
    )
  }

  const deleteCard = async () => {
    try {
      await deleteCardById(currentCard.id);
      setCurrentCard(cardData[0])
      openModal('deleteSuccessModal');

    }catch(error) {
      throw error

    }
  }

  const alertBeforeSetDefault = () => {
    handleModalShow(
      'cardChangeWarning',
      {
        title: '¿Estás seguro de cambiar esta tarjeta a la predeterminada?',
        text: 'Esta operación hará que los siguientes pagos se realicen con la tarjeta seleccionada.',
        onClick: setDefaultCard
      }
    )
  }

  const setDefaultCard = async () => {
    try {
      await setDefaultCardById(currentCard.id);
      await Promise.all([
        dispatch(transactionActions.purchasesCard(currentCard.id, '1', filter)),
        dispatch(transactionActions.paymentsCard(currentCard.id, '1', filter)),
      ])
      openModal('changeCardSuccess');
      
    }catch(error) {
      throw error

    }
  }

  // TABLE DATA
  // SECOND TAB
  const columnsDues = [
    {
      text: 'Id. Transacción',
      filter: false,
      ascendent: true,
      className: 'table-col-2 start',
    },
    {
      text: 'Nº cuotas',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Fecha',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Nombre del Comercio',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Importe',
      filter: false,
      ascendent: true,
      className: 'table-col-2 end',
    },
    
  ];

  const rowKeyBuys = ['state', 'refencialNumber', 'date', 'commerce', 'totalAmount'];

  const completedPaymentRows = paymentsItems.map((payment, index) => {
    return {
      refencialNumber: {
        text: payment.purchase.id,
        key: 'highlighted',
        className: `table-col-2 start_row ${ payment.status === 'CANCELED' && 'canceled' }`,
      },
      payments: {
        key:'icon',
        text: `${payment.paymentIndex}/4`,
        img: 
        payment.status === 'CANCELED' ? 
          <SVGIcon icon={icons.rejectedIcon} color="#B3B3B6" scale="1.5"/>
        :
          <CircleStat 
            radius={14} 
            stroke={3} 
            progress={(payment.paymentIndex * 100) / 4}
            fill={payment.status === 'SUCCESSFUL' ? '#1A4CCD' :  '#CA3976' }
          />,
        className: `table-col-2 ${ payment.status === 'CANCELED' && 'canceled cross' }`,
      },
      date: {
        text: moment(payment?.dueDate).format('L'),
        key: 'highlighted',
        className: `table-col-2 ${ payment.status === 'CANCELED' && 'canceled' }`,
      },
      commerce: {
        text: payment.purchase.commerce?.name || '',
        className: `table-col-2 ${ payment.status === 'CANCELED' && 'canceled' }`,
      },
      totalAmount: {
        key:'icon-right',
        text: formatMoney(payment?.amount),
        // img:  payment.status === 'SUCCESSFUL' 
        // ? <SVGIcon icon={icons.tickGreen } color="#1A4CCD" scale="1" />
        // :'',
        // span:`${ item.actualPaymentIndex + 1 === index ? 'A pagar ahora' : '' }`,
        className: `table-col-2 end_row ${payment.status === 'SUCCESSFUL' ? 'payment active' : 'payment'} 
        ${ payment.status === 'CANCELED' && 'canceled cross' }`,
      },
    link: `/detalle-operacion/${payment.purchase.id}`,
    }
  });
  const rowKeysDues = ['refencialNumber', 'payments', 'date', 'commerce', 'totalAmount',];

  // FIRST TAB
  const columnsBuys = [
    {
      text: 'Estado',
      filter: false,
      ascendent: true,
      className: 'table-col-3 start', 
    },
    {
      text: 'Id. Transacción',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Fecha',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Nombre del Comercio',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Monto',
      filter: false,
      ascendent: true,
      className: 'table-col-2 end',
    },
  ]

  const rows = items.map(item => {
    let text = '';
    let img: any = '';
    switch (item.status) {
      case 'SUCCESSFUL':
        text = 'completada';
        img = <SVGIcon icon={icons.okIcon} color="#4FB2C6" scale="1" />;
        break;
      case 'CANCELED':
        text = 'cancelado';
        img = <SVGIcon icon={icons.rejectedIcon} color="#CA3976" scale="1" />;
        break;
      case 'EXPIRED':
        text = 'expirada';
        img = <SVGIcon icon={icons.expiredIcon} scale="1" />;
        break;
      case 'PENDING':
        text = 'exitosa';
        img = <SVGIcon icon={icons.inProgressIcon} color="#4FB2C6" scale="1" />;
        break;
      case 'CHARGEBACK_REJECTED':
        text = 'contracargo';
        img = <SVGIcon icon={icons.chargeBackRejectedIcon} scale="1" />;
        break;
      case 'CHARGEBACK_ACCEPTED':
        text = 'contracargo';
        img = <SVGIcon icon={icons.chargeBackAcceptedIcon} scale="1" />;
        break;
      case 'REFUNDED_TOTALLY':
        text = 'devolución';
        img = <SVGIcon icon={icons.refundIcon} color="#8CA5E6" scale="1" />;
        break;
      case 'REFUNDED_PARTIALLY':
        text = 'devolución';
        img = <SVGIcon icon={icons.refundIcon} color="#FFBB36" scale="1" />;
        break;
    }
    return {
      state: {
        key: 'icon',
        img,
        text: text.toUpperCase(),
        className: 'table-col-3 start_row',
      },
      refencialNumber: {
        text: item.id,
        className: 'table-col-2',
      },
      date: {
        key: 'date',
        text: moment(item.createdAt).format('L'),
        span: `${moment(item.createdAt).format('LT')}h`,
        className: 'table-col-2 date',
      },
      commerce: {
        text: item.commerce?.name || '---',
        className: 'table-col-2 date',
      },
      totalAmount: {
        text: formatMoney(item.totalAmount),
        className: 'table-col-2 end_row',
      },
      link: `/detalle-operacion/${item.id}`,
    };
  });
  // END TABLE DATA


  const handleTable = (name:string) => {
    setTableSelected(name);
    setFilter({
      status: 'SUCCESSFUL,PENDING,CHARGEBACK_ACCEPTED,REFUNDED_TOTALLY,REFUNDED_PARTIALLY,CANCELED,CHARGEBACK_REJECTED',
      fromDate: '',
      toDate: '',
      search: '',
    })
  }

  // FILTERS FUNCTION
  const handleChange = (event: any, name: string) => {
    if (name === 'status' && event !== filter.status) {
      let filterStatus = '';
      switch (event) {
        case 'COMPLETADA':
          filterStatus = 'SUCCESSFUL';
          break;
        case 'CANCELADO':
          filterStatus = 'CANCELED';
          break;
        case 'EXITOSA':
          filterStatus = 'PENDING';
          break;
        case 'CONTRACARGO RECHAZADO':
          filterStatus = 'CHARGEBACK_REJECTED';
          break;
        case 'CONTRACARGO ACEPTADO':
          filterStatus = 'CHARGEBACK_ACCEPTED';
          break;
        case 'DEVOLUCIÓN TOTAL':
          filterStatus = 'REFUNDED_TOTALLY';
          break;
        case 'DEVOLUCIÓN PARCIAL':
          filterStatus = 'REFUNDED_PARTIALLY';
          break;
        case 'PAGOS DE CUOTAS RECHAZADAS':
          filterStatus = 'PAYMENT_REJECTED';
          break;
        default:
          filterStatus = 'SUCCESSFUL,PENDING,CHARGEBACK_ACCEPTED,REFUNDED_TOTALLY,REFUNDED_PARTIALLY,CANCELED,CHARGEBACK_REJECTED,PAYMENT_REJECTED';
      }

      if (filterStatus !== filter.status) {
        setFilter({
          ...filter,
          [name]: filterStatus,
        });
      }
    } else if ((name === 'fromDate' && event !== filter.fromDate) || (name === 'toDate' && event !== filter.toDate)) {
      setFilter({
        ...filter,
        [name]: moment(event, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      });
    }
  };
  
  const handleSearch = (e: any) => {
    setFilter({
      ...filter,
      [e.name]: e.value,
    });
  };

  useEffect(() => {
    setFilter({
      status: 'SUCCESSFUL,PENDING,CHARGEBACK_ACCEPTED,REFUNDED_TOTALLY,REFUNDED_PARTIALLY,CANCELED,CHARGEBACK_REJECTED',
      fromDate: '',
      toDate: '',
      search: '',
    });
    const getData = async () => {
      await Promise.all([
        dispatch(transactionActions.addTransactionSellsData(id, '1')),
        dispatch(transactionActions.purchasesCard(currentCard.id, '1', filter)),
        dispatch(transactionActions.paymentsCard(currentCard.id, '1', filter)),
      ])
    }
    getData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentCard])

  return (
    <MyWalletWithCardContainer>
      <div>
        <div className="heroblock">
          <div className="heroblock-select-input">
            <SelectBox
              name="current-card"
              value={getStringCardNumber(currentCard?.lastDigits)}
              options={getSelectCardNumber(cardData)}
              onChange={(id) => {
                if (isNumeric(id)) handleCardChange(Number(id))
              }}
              whiteIcon={true}
            />
          </div>
          <div className="heroblock-nav">
            <div className="nav-opts" />
            {/* <div className="filters-opt">
              <p>FILTRAR</p>
              <img src={icons.helpFilter} alt="card filter button" />
            </div> */}
          </div>
        </div>
      </div>

      <div className="content-table">
        {/* <div className="filters-opt mobile">
          <img src={icons.helpFilter} alt="card filter button" />
          <p>FILTRAR</p>
        </div> */}

        <div id="wallet-sidebar">
          <WalletAside 
            card={currentCard} 
            currentCardStatus={isCurrentCardDefualt(currentCard.id)} 
            setDefaultCard={alertBeforeSetDefault} 
            deleteCard={alertBeforeDelete} 
          />
        </div>

        <div className="wallet-table">
  
          <MainTableHeader
            searchFunction={handleSearch}
            placerholder="Instroduzca un comercio"
            onAnyKeyPress
            // buttons={[
            //   {
            //     text: 'Descargar CSV',
            //     onClick: () => {
            //       '';
            //     },
            //   },
            // ]}
          >
            <div className="main-table-header-filters__row__item">
              <SelectBox
                name="status"
                value="Estado"
                onChange={(e: any) => handleChange(e, 'status')}
                optionsArray={[
                  'TODAS',
                  'COMPLETADA',
                  'CANCELADO',
                  'EXITOSA',
                  'CONTRACARGO RECHAZADO',
                  'CONTRACARGO ACEPTADO',
                  'DEVOLUCIÓN TOTAL',
                  'DEVOLUCIÓN PARCIAL',
                ]}
              />
            </div>
            <div className="main-table-header-filters__row__item fix">
              <CalendarBox onChange={(e: any) => handleChange(e, 'fromDate')} placeholder="Desde" initialValue="" />
            </div>
            <div className="main-table-header-filters__row__item fix">
              <CalendarBox onChange={(e: any) => handleChange(e, 'toDate')} placeholder="Hasta" initialValue="" />
            </div>
          </MainTableHeader>
          <PaymentTableWrapper
            title=""
            tabs={['Mis Compras','Mis Cuotas']}
            tables={[
              <MainTable columns={columnsBuys} rows={rows} keysRow={rowKeyBuys} />,
              <MainTable columns={columnsDues} rows={completedPaymentRows} keysRow={rowKeysDues} />,
            ]}
            onClick={handleTable}
          />

          <div className="pagination">
            <Pagination 
              totalItems={tableSelected === 'Mis Cuotas' ? paymentsCount : count} 
              pageLimit={20} 
              nextPage={1} 
              onPageChanged={onPageChanged}  
              reset={filter}
            />
            <div></div>
            <div className="pagination-count">
              {tableSelected !== 'Mis Cuotas' ?
                <p>{`MOSTRANDO ${items.length < 20 ? count : items.length * page} / ${count}`}</p>
                :
                <p>{`MOSTRANDO ${paymentsItems.length < 20 ? paymentsCount : paymentsItems.length * page} / ${paymentsCount}`}</p>
              }
            </div>
          </div>
        </div>

        {/* <div id="fixed-action">
          <Link to="/mi-cartera/agregar-tarjeta">
            <MainButton type="primary" text="Añadir nueva tarjeta" />
          </Link>
        </div> */}
      </div>

      {/** Modals - this should be move to other file and should be refact */}
      <ModalSuccess
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'deleteSuccessModal') || false,
          onClose: closeModal,
        }}
        text="el próximo pago se hará con su nueva tarjeta."
      >
        <span>Se ha eliminado su tarjeta</span> de manera exitosa.
      </ModalSuccess>

      <ModalSuccess
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'changeCardSuccess') || false,
          onClose: closeModal,
        }}
        text="el próximo pago se hará con su nueva tarjeta."
      >
        Se ha cambiado <span>su tarjeta predeterminada.</span>
      </ModalSuccess>

      <ModalInfo
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'cardChangeWarning') || false,
          onClose: closeModal,
        }}
        title={warningKeysData?.title || ''}
        text={warningKeysData?.text || ''}
        buttons={[
          {
            text: 'Cancelar',
            type: 'ghost',
            onClick: closeModal,
          },
          {
            text: 'Sí',
            type: 'primary',
            onClick: warningKeysData?.onClick,
          },
        ]}
      />
    </MyWalletWithCardContainer>
  )
}

export default MyWalletWithCard
