import styled from 'styled-components';

export const MainTableHeaderStyle = styled.div`
  .main-table-header-top {
    display: flex;
    justify-content: space-between;
  }
  .main-table-header-actions {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
  }

  .main-table-header-actions-search  {
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    cursor: pointer;
    &.active {
      color: black;
    }
    position: relative;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    &:after {
      content: '';
      width: 1px;
      height: 20px;
      background: #cccdce;
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
  .main-table-header-actions-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active {
      color: black;
    }
  }

  .main-table-header-actions-buttons {
    display: flex;
    &__item {
      padding: 12px;
      color: #cccdce;
      position: relative;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      &:after {
        content: '';
        width: 1px;
        height: 20px;
        background: #cccdce;
        position: absolute;
        top: 8px;
        right: 0;
      }
      &:hover {
        color: #01030c;
      }
    }
  }

  .main-table-header-title {
    font-weight: 600;
    display: flex;
    &_button {
      margin-left: 30px;
    }
  }

  .main-table-header-filters {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out all;
    display: none;
    &.active {
      visibility: visible;
      opacity: 1;
      display: flex;
    }
    &__search {
      flex: auto;
    }
    &__row  {
      display: flex;
      width: 100%;
      &__item {
        width: 100%;
        max-width: 300px;
        margin-right: 12px;
        align-content: center;
        &:last-child {
          margin-left: 0px;
        }
        .datepicker-input {
          margin-top: 5.5px;
        }
      }
    }
  }
`;
