import styled from 'styled-components/macro'

const PaymentTableWrapperContainer = styled.div`
  width: 100%;
  overflow: auto;

  .table-topbar {
    width: 100%;
    position: relative;
  }

  .table-title {
    font-family: 'Gilroy';
    font-size: 24px;
    line-height: 29px;
    color: #01030c;
  }

  .tabs-nav {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    top: 12px;
  }

  .tabs-list {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .tab {
    width: 140px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Gilroy';
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #34353d;
    font-weight: 300;
    cursor: pointer;
    background: #e6e6e7;

    &.selected {
      background: #fff;
    }
  }

  .order-container {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    z-index: 3;
  }

  .order-label {
    font-family: 'Gilroy';
    font-size: 14px;
    line-height: 22px;
    color: #b3b3b6;
    font-weight: 300;
    margin-right: 10px;
  }

  .arrows-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .up-arrow {
    cursor: pointer;
  }

  .down-arrow {
    cursor: pointer;
  }

  .payment-table-wrapper-content {
    width: 100%;
  }

  @media (max-width: 768px) {
    .table-title {
      margin-bottom: 24px;
      font-family: 'Gilroy';
      font-size: 20px;
      line-height: 24px;
      color: #01030c;
      font-weight: 600;
    }

    .tabs-nav {
      top: 0;
      width: 100%;
    }

    .tabs-list {
      width: 100%;
    }

    .tab {
      height: 45px;
      width: calc(100% / 2);
    }

    .order-container {
      top: 0;
      bottom: initial;
    }

    .order-label {
      display: none;
    }
  }
`
export default PaymentTableWrapperContainer
