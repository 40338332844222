import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { icons, images } from '../../../../assets/themes'
// import ProfileAddressCard from '../../../../components/cards/profile-address-card/profile-address-card'
// import ProfileAddressCardContainer from '../../../../components/cards/profile-address-card/profile-address-card-style'
import Modal from '../../../../components/modal-container/modal'
import ModalProfileEditName from '../../../../components/modals/modal-profile-edit-name/modal-profile-edit-name'
import ModalUpdateAvatar from '../../../../components/modals/modal-update-avatar/modal-update-avatar'
import ProfilePersonalDataContainer from './profile-personal-data-style'
import { useSelector, useDispatch } from 'react-redux'
import { authSelectors } from '../../../../store/auth/selectors'
import { IUser, ICard } from '@aluxion-lightpay/interfaces'
import { modalSelector } from '../../../../store/modal/selectors'
import { modalActions } from '../../../../store/modal/actions'
import { authActions } from '../../../../store/auth/actions'
import { transformFile } from '../../../../utils/utils'
import { AuthActions } from '../../../../store/auth/types'
import moment from 'moment';
import CardPayMethod from '../../../../components/cards/card-pay-method/card-pay-method'
// import Accordion from '../../../../components/accordion/accordion'
import { cardActions } from '../../../../store/card/actions'
import { cardSelector } from '../../../../store/card/selectors'
import ModalInfo from '../../../../components/modals/modal-info/modal-info'
import ModalSuccess from '../../../../components/modals/modal-success/modal-success'
import { transactionSelector } from '../../../../store/transaction/selector'
import { ITransactionPurchase } from '../../../../store/transaction/types'
import ModalErrorCard from '../../../../components/modals/modal-error/modal-error'
import { transactionActions } from '../../../../store/transaction/actions'
import ModalBankData from '../../../../components/modals/modal-bank-data/modal-bank-data'
import ModalModifyUserData from '../../../../components/modals/modal-modify-user-info/modal-modify-user-info'

// const EXAMPLE_DATA = [
//   {
//     streetName: 'Calle Rafael Calvo 18, Madrid',
//     location: 'Madrid, España'
//   },
//   {
//     streetName: 'Calle Rafael Calvo 18, Madrid',
//     location: 'Madrid, España'
//   },
//   {
//     streetName: 'Calle Rafael Calvo 18, Madrid',
//     location: 'Madrid, España'
//   },
//   {
//     streetName: 'Calle Rafael Calvo 18, Madrid',
//     location: 'Madrid, España'
//   }
// ]

const useEditNameModal = () => {
  const [showEditName, setShowEditName] = useState<boolean>(false)

  const changeShowEditName = (show: boolean) => {
    setShowEditName(show)
  }

  return {
    showEditName,
    changeShowEditName
  }
}

// const useEditAvatarModal = () => {
//   const [showUpdateAvatar, setShowUpdateAvatar] = useState<boolean>(false)

//   const handleChangeShowUpdateAvatar = (show: boolean) => {
//     setShowUpdateAvatar(show)
//   }

//   return {
//     showUpdateAvatar,
//     handleChangeShowUpdateAvatar
//   }
// }

interface IModalsData {
  title: string;
  text: string;
  onClick: () => void;
}

const ProfilePersonalData: React.FC<any> = (props: any) => {
  const { showEditName, changeShowEditName } = useEditNameModal();
  const { id, firstName, lastName, phoneNumber, birthDate, rfc, email, image } = useSelector(authSelectors.getMe()) as IUser;
  const [warningKeysData, setWarningData] = useState<IModalsData | null>(null);
  const isModal = useSelector(modalSelector.isShowing());
  const cardData  = useSelector(cardSelector.getCardData()) as ICard[];
  const { items } = useSelector(transactionSelector.getTransactionDataTable()) as ITransactionPurchase;
  const dispatch = useDispatch();
  const [idCard, setIdCard] = useState<number | null>(null);
  const [times, setTimes] = useState<number>(0);
  const me = useSelector(authSelectors.getMe())
    const scoringStatus = useSelector(authSelectors.getScoringStatus())
    const [approvedUser, setApprovedUser] = useState(true)

  // TODO: make a HOOK for this
  const [imageUrl, setImageUrl] = useState<any>();
  if (image instanceof Blob) {
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(image);
  } else if (image) {
    transformFile(image.url, image.name, image.mimetype).then(data => {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(data);
      dispatch({
        type: AuthActions.SET_PROFILE_IMAGE,
        payload: data,
      });
    });
  }


  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };

  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };

  const changeProfileImage = async (avatar: Blob) => {
    closeModal();
    await dispatch(authActions.changeProfileImage(avatar));
  };

  useEffect(() => {
    if (!scoringStatus) {
      dispatch(authActions.sendBuroDeCredito());
    }else if (scoringStatus.status === 'SUCCESS') {
      setApprovedUser(true)
    }
    else {  
      setApprovedUser(false) 
    }
  }, [scoringStatus, me])

  const handleModal = () =>{
    console.log(scoringStatus, me?.approved, "el scoring")
    if (!me?.approved) {
    if (!scoringStatus) {
      dispatch(authActions.sendBuroDeCredito());
    } else {
      if (scoringStatus.status === 'SUCCESS') {
        closeModal()
      } else if (scoringStatus?.message.includes('Debe proporcionar cierta información para autorizar su préstamo')) {
        if (scoringStatus.message.includes('manual')) {
          dispatch(transactionActions.createUserByFinerio())
        }
        // amplitude.getInstance().logEvent('open bankings scoring starts', purchasesData);
        // dispatch(errorsActions.resetErrors())
        openModal('extraBankData')
      }
      else if (scoringStatus?.message.includes('Debe proporcionar información personal correcta para comprobar su score, por favor revise sus datos')) {
        scoringStatus.message.includes('Times: ') && setTimes(Number(scoringStatus.message.substr(scoringStatus.message.indexOf('Times: ') + 7)))
        openModal('updateUserData')
        // dispatch(errorsActions.resetErrors())
      }
    } }
  console.log(approvedUser,"el usuario")
}
  // const {
  //   showUpdateAvatar,
  //   handleChangeShowUpdateAvatar
  // } = useEditAvatarModal()

  // useEffect(()=>{
  //   const getCardsUser = async () => {
  //     await dispatch(cardActions.getCardData(id));
  //     await dispatch(transactionActions.addTransactionSellsData(id, 1, {status: "", fromDate: "", toDate: "", search: ""}, 'new'));
  //   }
  //   getCardsUser();
  // },[]) //eslint-disable-line


  const addMainCard = async (idCard:number) => {
    try {
      await dispatch(cardActions.addMainCard(items[0].id, idCard));
      await dispatch(transactionActions.addTransactionSellsData(id, 1, {status: "", fromDate: "", toDate: "", search: ""}, 'new'));
      openModal('changeCardSuccess');
    }catch(error) {
      console.log(error)
      openModal('error')
    }
  }

  const deleteCard = async (idCard:number) => {
    try {
      await dispatch(cardActions.deleteCard(id, idCard));
      openModal('changeCardSuccess');
    }catch(error) {
      openModal('error')
    }
  }

  return (
    <ProfilePersonalDataContainer>
      <div className="personal-data-container profile-section">
        <div className="user-banner">
          <img src={images.profileDefaultBanner} alt="default banner" />
        </div>

        <div className="personal-data-info">
          <div className="user-icon">
            {/* <div
              className="edit-user-icon"
              onClick={() => handleChangeShowUpdateAvatar(!showUpdateAvatar)}
            >
              <p className="edit-user-icon-text">Editar</p>
            </div> */}
            <div className="edit-user-icon" onClick={() => openModal('changeImage')}>
              <img src={imageUrl ? imageUrl : images.defaultProfileImage} alt="" />
              <p>editar</p>
            </div>
          </div>

          <div className="user-name-container">
            <h1 className="user-name">{`${firstName} ${lastName}`}</h1>
            <p
              className="edit-user-name-link"
              onClick={() => changeShowEditName(!showEditName)}
            >
              {/* Editar nombre */}
            </p>
          </div>

          <div className="user-important-info">
            <div className="user-important-info-wrapper">
              <div className="user-important-info-element">
                <p className="user-important-info-label">EMAIL</p>
                <p className="user-important-info-data email">{email ? email : '---'}</p>
              </div>
              <div className="user-important-info-element">
                <p className="user-important-info-label">FECHA NACIMIENTO</p>
                <p className="user-important-info-data">{ birthDate ? moment(birthDate).utc().format('L') : '---' }</p>
              </div>
            </div>

            <div className="user-important-info-wrapper">
              <div className="user-important-info-element">
                <p className="user-important-info-label">RFC</p>
                <p className="user-important-info-data">{ rfc ? rfc : '---' }</p>
              </div>
              <div className="user-important-info-element">
                <p className="user-important-info-label">TELÉFONO</p>
                <p className="user-important-info-data">{ phoneNumber ? phoneNumber : '---' }</p>
                <Link className="user-important-info-link" to={'/perfil/datos-personales/actualizar-telefono'}>
                  <div className="edit-phone-icon">
                    <img src={icons.pencilBlue} alt="pencil blue" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
        {!approvedUser &&
            <div className='finerio-btn' onClick={handleModal}>
                <p className="">Ir a Finerio</p>
            </div>
          }
      </div>


      
      {/* MODALS */}

      {showEditName && (
        <Modal onClose={() => changeShowEditName(false)}>
          <ModalProfileEditName
            history={props.history}
            changeShowEditNameModal={changeShowEditName}
          />
        </Modal>
      )}

      <ModalUpdateAvatar
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'changeImage') || false,
        }}
        onClick={changeProfileImage}
      />

      <ModalInfo
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'cardChangeWarning') || false,
          onClose: closeModal,
        }}
        title={warningKeysData?.title || ''}
        text={warningKeysData?.text || ''}
        buttons={[
          {
            text: 'Cancelar',
            type: 'ghost',
            onClick: closeModal,
          },
          {
            text: 'Sí',
            type: 'primary',
            onClick: warningKeysData?.onClick,
          },
        ]}
      />

      <ModalSuccess
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'changeCardSuccess') || false,
          onClose: closeModal,
        }}
        text="el próximo pago se hará con su nueva tarjeta."
      >
        Se ha cambiado <span>su tarjeta predeterminada.</span>
      </ModalSuccess>

      <ModalErrorCard
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'error') || false,
        }}
      />
      <ModalBankData
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'extraBankData') || false,
        }}
        show={isModal?.show && isModal?.idModal === 'extraBankData'}
      />
      <ModalModifyUserData
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'updateUserData') || false,
        }}
        onClick={() => { openModal('extraBankData') }}
        times={times}
        idCard={idCard!}
      />

    </ProfilePersonalDataContainer>
  )
}

export default ProfilePersonalData