import {
  isValidEmail,
  isValidPassword,
  isValidName,
  isRFC,
  isValidNumber,
  isValidUrl,
  isValidSelect,
  isValidPhone,
} from '../../../utils/validation';

// Login
export const LOGIN_EMAIL_INPUT_NAME = 'email';
export const LOGIN_PASSWORD_INPUT_NAME = 'password';
export const LOGIN_PASSWORD_RECOVER_INPUT_NAME = 'recover-password';
export const LOGIN_REPEAT_PASSWORD_INPUT_NAME = 'repeat-pass';

export const getLoginInputMetada = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [LOGIN_EMAIL_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidEmail: (value: any) => isValidEmail(value),
      },
    },
    [LOGIN_PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 8, message: 'too-short-password' },
    },
    [LOGIN_PASSWORD_RECOVER_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidPassword: (value: any) => isValidPassword(value),
      },
    },
    [LOGIN_REPEAT_PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: extraValidation,
    },
  };

  return inputsArray[inputName];
};

// Check
export const CHECK_EMAIL_INPUT_NAME = 'check-email';

export const getCheckEmailInputMetadata = (inputName: string) => {
  const inputsArray: any = {
    [CHECK_EMAIL_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidEmail: (value: any) => isValidEmail(value),
      },
    },
  };
  return inputsArray[inputName];
};

// Register - basic data
export const FIRST_NAME_INPUT_NAME = 'first-name';
export const LAST_NAME_INPUT_NAME = 'last-name';
export const SECOND_LAST_NAME_INPUT_NAME = 'second-last-name';
export const PASSWORD_INPUT_NAME = 'password';
export const REPEAT_PASSWORD_INPUT_NAME = 'repeat-pass';
export const HOW_KNEW_ABOUT_INPUT_NAME = 'how-knew-about-lightpay';
export const LIGHTPAY_TERMS_POLICY_INPUT_NAME = 'lightpay-terms-policy';
export const GENDER_INPUT_NAME = 'gender';

export const getBasicDataInputMetadata = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [FIRST_NAME_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-name' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [LAST_NAME_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-surname' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [SECOND_LAST_NAME_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-surname' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 8, message: 'too-short-password' },
      validate: {
        isValidPassword: (value: any) => isValidPassword(value),
      },
    },
    [REPEAT_PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: extraValidation,
    },
    [HOW_KNEW_ABOUT_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidSelect: (value: any) => isValidSelect(value),
      },
    },
    [LIGHTPAY_TERMS_POLICY_INPUT_NAME]: {
      required: { value: true, message: 'accept-terms' },
    },
  };
  return inputsArray[inputName];
};

// Register - personal data
export const RFC_INPUT_NAME = 'rfc';
export const INE_INPUT_NAME = 'ine';
export const CP_INPUT_NAME = 'cp';
export const STATE_INPUT_NAME = 'state';
export const CITY_INPUT_NAME = 'municipality';
export const BIRTHDATE_INPUT_NAME = 'birthdate';
export const COLONY_INPUT_NAME = 'colony';
export const STREET_INPUT_NAME = 'street';
export const PHONE_NUMBER_INPUT_NAME = 'phoneNumber';


export const getPersonalDataInputMetadata = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [PHONE_NUMBER_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidPhone: (value: any) => isValidPhone(value),
      },
    },
    [RFC_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isRFC: (value: any) => isRFC(value),
      },
    },
    [INE_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
      validate: extraValidation,
    },
    [CP_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidNumber: (value: any) => isValidNumber(value),
      },
    },
    [STATE_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidSelect: (value: any) => isValidSelect(value),
      },
    },
    [CITY_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidSelect: (value: any) => isValidSelect(value),
      },
    },
    [BIRTHDATE_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      // validate: {
      //   isValidDate: (value: any) => isValidDate(value),
      // },
    },
    [COLONY_INPUT_NAME]: {
      required: { value: true, message: 'required' },
    },
    [STREET_INPUT_NAME]: {
      required: { value: true, message: 'required' },
    },
  };
  return inputsArray[inputName];
};

// Register - extra data
export const OWNS_TDC_INPUT_NAME = 'ownsTdc';
export const TDC_INPUT_NAME = 'tdc';
export const MORTGAGE_LOAN_INPUT_NAME = 'mortgageLoan';
export const AUTO_LOAN_INPUT_NAME = 'autoLoan';

export const getExtraDataInputMetadata = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [OWNS_TDC_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
    },
    [TDC_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-tdc' },
      maxLength: { value: 4, message: 'too-short-tdc' },
      validate: {
        isValidNumber: (value: any) => isValidNumber(value),
      },
    },
    [MORTGAGE_LOAN_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
    },
    [AUTO_LOAN_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
    },
  };
  return inputsArray[inputName];
};

// Register - comercial data
export const COMPANY_NAME_INPUT_NAME = 'company-name';
export const COMPANY_WEBSITE_INPUT_NAME = 'company-website';

export const getComercialDataInputMetadata = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [COMPANY_NAME_INPUT_NAME]: {
      required: { value: true, message: 'required' },
    },
    [COMPANY_WEBSITE_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidUrl: (value: any) => isValidUrl(value),
      },
    },
  };
  return inputsArray[inputName];
};

// Register - verify phone code
export const CODE_NUMBER_INPUT_NAME = 'codeNumber'
export const CONFIRM_PASSWORD_INPUT_NAME = 'confirmPassowrd';

export const getCodeNumberInputMetadata = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [CODE_NUMBER_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 6, message: 'too-short-code' },
      maxLength: { value: 6, message: 'too-short-code' },
    },
    [CONFIRM_PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: extraValidation,
    },
  };
  return inputsArray[inputName];
};

export const getRegisterInputMetadata = (inputName: string, extraValidation: any = {}) => {
  const inputsArray: any = {
    [PHONE_NUMBER_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidPhone: (value: any) => isValidPhone(value),
      },
    },
    [CODE_NUMBER_INPUT_NAME]: {
      required: { value: true, message: 'required' },
    },
    [CONFIRM_PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: extraValidation,
    },
    [FIRST_NAME_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-name' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [LAST_NAME_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-surname' },
      validate: {
        isValidName: (value: any) => isValidName(value),
      },
    },
    [PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 8, message: 'too-short-password' },
      validate: {
        isValidPassword: (value: any) => isValidPassword(value),
      },
    },
    [REPEAT_PASSWORD_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: extraValidation,
    },
    [LIGHTPAY_TERMS_POLICY_INPUT_NAME]: {
      required: { value: true, message: 'accept-terms' },
    },
    [RFC_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isRFC: (value: any) => isRFC(value),
        // existRFC: async (value:any) => {
        //   store.dispatch<any>(
        //     loaderActions.loading(),
        //   )
        //   const res =  await LightpayApi.post<IServerResponseCheckEmail>('/auth/check-rfc', {
        //      rfc: value,
        //    });
        //    store.dispatch<any>(
        //     loaderActions.loaded(),
        //   )
        //    if(res.data.exists){
        //      store.dispatch<any>(
        //        toastActions.error( authActions.handleCustomErrors('REGISTER' , 'RFC_EXIST')),
        //      )
        //      return false;
        //    }
        // },
      },
    },
    [INE_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
      validate: extraValidation,
    },
    [CP_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidNumber: (value: any) => isValidNumber(value),
      },
    },
    [STATE_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidSelect: (value: any) => isValidSelect(value),
      },
    },
    [CITY_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      validate: {
        isValidSelect: (value: any) => isValidSelect(value),
      },
    },
    [BIRTHDATE_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      // validate: {
      //   isValidDate: (value: any) => isValidDate(value),
      // },
    },
    [COLONY_INPUT_NAME]: {
      required: { value: true, message: 'required' },
    },
    [STREET_INPUT_NAME]: {
      required: { value: true, message: 'required' },
    },
    [OWNS_TDC_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
    },
    [TDC_INPUT_NAME]: {
      required: { value: true, message: 'required' },
      minLength: { value: 4, message: 'too-short-tdc' },
      maxLength: { value: 4, message: 'too-short-tdc' },
      validate: {
        isValidNumber: (value: any) => isValidNumber(value),
      },
    },
    [MORTGAGE_LOAN_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
    },
    [AUTO_LOAN_INPUT_NAME]: {
      // required: { value: true, message: 'required' },
    },
  };
  return inputsArray[inputName];
};

  // EXTRA BANK DATA

  export const BANK_INPUT_NAME = 'bankName';
  export const BANK_USER_INPUT = 'username';
  export const BANK_PASSOWRD_INPUT = 'passoword';
  export const BANK_EMAIL_INPUT = 'email';
  export const BANK_TOKEN_INPUT = 'token';
  
  export const getBankInputMetadata = (inputName: string, extraValidation: any = {}) => {
    const inputsArray: any = {
      [BANK_INPUT_NAME]: {
        required: { value: true, message: 'required' },
        validate: {
          isValidName: (value: any) => isValidName(value),
        },
      },
  
      [BANK_USER_INPUT]: {
        required: { value: true, message: 'required' },
        minLength: { value: 3, message: 'too-short-name' },
      },
  
      [BANK_PASSOWRD_INPUT]: {
        required: { value: true, message: 'required' },
        minLength: { value: 3, message: 'too-short-password-bank' },
      },
  
      [BANK_TOKEN_INPUT]: {
        required: { value: true, message: 'required' },
      },
  
      [BANK_EMAIL_INPUT]: {
        required: { value: true, message: 'required' },
        validate: {
          isValidEmail: (value: any) => isValidEmail(value),
        },
      },
    }
  
  
    return inputsArray[inputName];
  };