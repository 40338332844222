import styled from 'styled-components';

export const MyCustomToastStyle = styled.div`
  border-radius: 3px;
  padding: 14px 18px 22px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 375px;
  margin-bottom: 24px;
  z-index:999999;

  .my-custom-toast-icon {
    margin-right: 12px;
    width: 34px;
    height: 34px;
  }
  .my-custom-toast-close {
    cursor: pointer;
  }

  .my-custom-toast-text {
    font-family: Gilroy;
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    flex: auto;
  }

  &.success {
    background: #edf7f9;
    color: #72C1D1;
  }

  &.warning {
    color: #FFC85E;
    background: #FFF8EB;
  }

  &.error {
    background: #FAEBF1;
    color: #E49CBB;
  }

`;
