import { IUser, ICommerceProposal, IFile } from '@aluxion-lightpay/interfaces';
import { IBaseAction } from '../base-action.interface';
import { IFormatError } from '../restclient';

// Register Form Interfaces
export interface IRegisterFormBasicData {
  name: string;
  surname: string;
  secondSurname: string;
  password: string;
  repeatPassword: string;
  termsAndCondition: boolean;
  gender?: string;
}

export interface IRegisterFormIneFormat {
  front?: File;
  back?: File;
}

export interface IRegisterFormLegalData {
  rfc: string;
  ine?: IRegisterFormIneFormat;
  cp: string;
  colony: string;
  municipality: string;
  street: string;
  state: string;
  birthDate: string;
  phoneNumber: string;
}

export interface IRegisterFormExtraData {
  haveTdc: boolean;
  tdc?: string;
  mortgageLoan: boolean;
  autoLoan: boolean;
}

export interface IRegisterFormComercialData {
  companyName: string;
  companyUrl: string;
}

export interface IRegisterFormIneModal {
  isOpen: boolean;
}

export interface IRegisterForm {
  email: string | null;
  basicData: IRegisterFormBasicData | null;
  legalData: Partial<IRegisterFormLegalData> | null;
  // extraData: IRegisterFormExtraData | null;
  comercialData: IRegisterFormComercialData | null;
  ineModal: Partial<IRegisterFormIneModal> | null;
}

export interface IRegisterVerifyEmail {
  token: string | null;
}
// End Register Form Interfaces

export enum AuthActions {
  REGISTER_FORM_INE_MODAL_OPEN = 'REGISTER_FORM_INE_MODAL_OPEN',
  REGISTER_FORM_INE_MODAL_CLOSE = 'REGISTER_FORM_INE_MODAL_CLOSE',
  REGISTER_FORM_INE_MODAL_DATA_ADDED = 'REGISTER_FORM_INE_MODAL_DATA_ADDED',
  REGISTER_FORM_BASIC_DATA_ADDED = 'REGISTER_FORM_BASIC_DATA_ADDED',
  REGISTER_FORM_LEGAL_DATA_ADDED = 'REGISTER_FORM_LEGAL_DATA_ADDED',
  REGISTER_FORM_EXTRA_DATA_ADDED = 'REGISTER_FORM_EXTRA_DATA_ADDED',
  REGISTER_FORM_COMERCIAL_DATA_ADDED = 'REGISTER_FORM_COMERCIAL_DATA_ADDED',
  REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAIL = 'REGISTER_USER_FAIL',
  EMAIL_CHECKED_SUCCESS = 'EMAIL_CHECKED_SUCCESS',
  EMAIL_CHECKED_FAIL = 'EMAIL_CHECKED_FAIL',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE',
  LOGIN_FAIL = 'LOGIN_FAIL',
  SET_JWT_TOKEN = 'SET_JWT_TOKEN',
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  RECOVER_PASSWORD_FAIL = 'RECOVER_PASSWORD_FAIL',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL',
  UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER',
  LOGOUT = 'LOGOUT',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  SET_SCORING_RESULT = 'SET_SCORING_RESULT'
}

export interface IAuthState {
  readonly loggingError?: IFormatError | null;
  readonly registerError?: IFormatError | null;
  readonly checkEmailError?: IFormatError | null;
  readonly user: Partial<IUser> | null;
  readonly token: string | null;
  readonly registerForm: Partial<IRegisterForm> | null;
  readonly recoverPasswordError: IFormatError | null;
  readonly resetPasswordError: IFormatError | null;
  readonly userProductionFormData: ICommerceProposal | null;
  readonly scoringResult?: any;
}

export interface IAuthReducer {
  (state: IAuthState, action: AuthActionsTypes): IAuthState;
}

export interface IRegisterFormIneModalOpen extends IBaseAction {
  type: AuthActions.REGISTER_FORM_INE_MODAL_OPEN;
}

export interface IRegisterFormIneModalClose extends IBaseAction {
  type: AuthActions.REGISTER_FORM_INE_MODAL_CLOSE;
  message?: string;
  status?: string;
}

export interface IRegisterFormIneModalDataAdded extends IBaseAction {
  type: AuthActions.REGISTER_FORM_INE_MODAL_DATA_ADDED;
  payload: IRegisterFormIneFormat;
}

export interface IRegisterFormBasicDataAdded extends IBaseAction {
  type: AuthActions.REGISTER_FORM_BASIC_DATA_ADDED;
  payload: IRegisterFormBasicData;
}

export interface IRegisterFormLegalDataAdded extends IBaseAction {
  type: AuthActions.REGISTER_FORM_LEGAL_DATA_ADDED;
  payload: IRegisterFormLegalData;
}

export interface IRegisterFormExtraDataAdded extends IBaseAction {
  type: AuthActions.REGISTER_FORM_EXTRA_DATA_ADDED;
  payload: IRegisterFormExtraData;
}

export interface IRegisterFormComercialDataAdded extends IBaseAction {
  type: AuthActions.REGISTER_FORM_COMERCIAL_DATA_ADDED;
  payload: IRegisterFormComercialData;
}

export interface IRegisterSuccessAction extends IBaseAction {
  type: AuthActions.REGISTER_USER_SUCCESS;
  payload: IUser;
}

export interface IRegisterFailureAction extends IBaseAction {
  type: AuthActions.REGISTER_USER_FAIL;
  error: IFormatError;
}

export interface IEmailCheckedSuccessAction extends IBaseAction {
  type: AuthActions.EMAIL_CHECKED_SUCCESS;
  payload: string;
}

export interface IEmailCheckedFailureAction extends IBaseAction {
  type: AuthActions.EMAIL_CHECKED_FAIL;
  error: IFormatError;
  payload: string;
}

export interface ILoginSuccessAction extends IBaseAction {
  type: AuthActions.LOGIN_SUCCESS;
  payload: IUser;
}

export interface ILoginSetAvatar extends IBaseAction {
  type: AuthActions.SET_PROFILE_IMAGE;
  payload: File | IFile;
}

export interface ILoginFailureAction extends IBaseAction {
  type: AuthActions.LOGIN_FAIL;
  error: IFormatError;
}

export interface ISetJWT extends IBaseAction {
  type: AuthActions.SET_JWT_TOKEN;
  payload: string;
}

export interface IRecoverPasswordAction extends IBaseAction {
  type: AuthActions.RECOVER_PASSWORD;
}

export interface IRecoverPasswordFailAction extends IBaseAction {
  type: AuthActions.RECOVER_PASSWORD_FAIL;
  error: IFormatError;
}

export interface IResetPasswordAction extends IBaseAction {
  type: AuthActions.RESET_PASSWORD;
}

export interface IResetPasswordFailAction extends IBaseAction {
  type: AuthActions.RESET_PASSWORD_FAIL;
  error: IFormatError;
}

export interface IUpdatePhoneNumber extends IBaseAction {
  type: AuthActions.UPDATE_PHONE_NUMBER;
  payload: string;
}

export interface ILogoutAction extends IBaseAction {
  type: AuthActions.LOGOUT;
}

export interface IVerifyEmailAction extends IBaseAction {
  type: AuthActions.VERIFY_EMAIL;
}

export interface IScoringResultAction extends IBaseAction {
  type: AuthActions.SET_SCORING_RESULT;
  payload: any;
}

export type AuthActionsTypes =
  | IRegisterFormIneModalOpen
  | IRegisterFormIneModalClose
  | IRegisterFormIneModalDataAdded
  | IRegisterFormBasicDataAdded
  | IRegisterFormLegalDataAdded
  | IRegisterFormExtraDataAdded
  | IRegisterFormComercialDataAdded
  | IRegisterSuccessAction
  | IRegisterFailureAction
  | IEmailCheckedSuccessAction
  | IEmailCheckedFailureAction
  | ILoginSuccessAction
  | ILoginSetAvatar
  | ILoginFailureAction
  | ISetJWT
  | IRecoverPasswordAction
  | IRecoverPasswordFailAction
  | IResetPasswordAction
  | IResetPasswordFailAction
  | IUpdatePhoneNumber
  | ILogoutAction
  | IScoringResultAction
  | IVerifyEmailAction;
