import styled from 'styled-components';

export const CardDetailHeaderInfoStyle = styled.div`
  display: flex;
  border-radius: 3px;
  box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
  padding: 30px 0;
  background: #ffffff;
  .card-detail-header-info-item {
    flex: auto;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #f2f2f3;
    padding-bottom: 10px;
    color: #1a1c24;
    &:last-child {
      border-right: 0px solid #f2f2f3;
    }

    &.blue {
      .card-detail-header-info-item-text {
        color: #1a4ccd;
        font-weight: 600;
      }
    }
    &.yellow {
      .card-detail-header-info-item-text {
        color: #ffbb36;
        font-weight: 600;
      }
    }
    &.light-blue {
      .card-detail-header-info-item-text {
        color: #4fb2c6;
        font-weight: 600;
      }
    }
    &.red {
      .card-detail-header-info-item-text {
        color: #ca3976;
        font-weight: 600;
      }
    }
  }

  .card-detail-header-info-item-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
  }
  .card-detail-header-info-item-text {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    .card-detail-header-info-item {
      width: 50%;
    }
  } 
`;
