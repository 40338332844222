import {useState} from 'react';

//Types
import { UseMobileHeaderType } from './use-mobile-header-types';

const useMobileHeader: UseMobileHeaderType = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const logout = () => {
        console.log("to implement");
    }

    return {
        isMenuOpen,
        toggleMenu,
        logout
    }
}

export { useMobileHeader };