import { IBaseAction } from '../base-action.interface';
import { IPurchase, IPaymentPartition } from '@aluxion-lightpay/interfaces';
import { IPurchaseClients, IPaymentPartitionClients } from '../../interfaces/purchaseclients.types'

export enum IPaymentSidebarActions {
  OPEN_SIDEBAR='OPEN_SIDEBAR',
  CLOSE_SIDEBAR='CLOSE_SIDEBAR',
  TOGGLE_SIDEBAR='TOGGLE_SIDEBAR',
  LOADING_SIDEBAR='LOADING_SIDEBAR',
  LOADED_SIDEBAR_DATA='LOADED_SIDEBAR_DATA',
  SAVED_SIDEBAR_DATA='SAVED_SIDEBAR_DATA',
  GET_SIDEBAR='GET_SIDEBAR',
}

export interface IPaymentSidebarState {
  readonly isOpen: boolean;
  readonly loading: boolean;
  readonly selectedPurchased: IPurchaseClients | null;
  readonly selectedPaymentPartition: Partial<IPaymentPartitionClients>;
}

export interface IPaymentSidebarReducer {
  (state: IPaymentSidebarState, action: IPaymentSidebarActionsTypes): IPaymentSidebarState;
}

export interface IOpenSidebar extends IBaseAction {
  type: IPaymentSidebarActions.OPEN_SIDEBAR;
}

export interface ICloseSidebar extends IBaseAction {
  type: IPaymentSidebarActions.CLOSE_SIDEBAR;
}

export interface IToggleSidebar extends IBaseAction {
  type: IPaymentSidebarActions.TOGGLE_SIDEBAR;
}

export interface ILoadingSidebar extends IBaseAction {
  type: IPaymentSidebarActions.LOADING_SIDEBAR;
}

export interface ILoadedSidebar extends IBaseAction {
  type: IPaymentSidebarActions.LOADED_SIDEBAR_DATA;
}

export interface ISavedSidebarData extends IBaseAction {
  type: IPaymentSidebarActions.SAVED_SIDEBAR_DATA;
  payload: IPurchaseClients | null;
}



export type IPaymentSidebarActionsTypes = IOpenSidebar |
                                    ICloseSidebar | 
                                    IToggleSidebar |
                                    ILoadingSidebar |
                                    ISavedSidebarData |
                                    ILoadedSidebar;
