import { IPaymentPartition } from '@aluxion-lightpay/interfaces';
import { IPaymentPartitionClients } from '../../interfaces/purchaseclients.types'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import useUserCards from '../../hooks/useWallet/useUserCards';
import ModalErrorCard from '../modals/modal-error/modal-error';
import ModalSuccess from '../modals/modal-success/modal-success';
import PaymentSidebarContainer from './payment-sidebar-style';
import { IPaymentSidebarProps } from './payment-sidebar-type';
import FirstViewSidebar from './views/payment-sidebar-first-view';
import SecondViewSidebar from './views/payment-sidebar-second-view';


const PaymentSidebar: React.FC<IPaymentSidebarProps> = (props: IPaymentSidebarProps) => {
  const { id, className, data, closeSidebar } = props;
  const [ feeData, setFeeData ] = useState<IPaymentPartitionClients[] | null>();
  const { isModal, closeModal } = useUserCards();
  const { feeId } = useParams();

  
  useEffect(()=> {
    if(!data) {
      closeSidebar();
    }
    if(feeId){
      const newData = data?.payments.filter( payment =>  payment.type === data?.type);
      setFeeData(newData);
    }
    console.log('feeid',feeId)
  },[closeSidebar, data, feeId])
  
  return (
    <PaymentSidebarContainer id={id} className={className}>
      {
        feeId && feeData && feeData?.length > 0 ? 
          <SecondViewSidebar 
              feeData={feeData}
              feeNumber={parseInt(feeId, 10)}
              data={data}
          />
        :
          <FirstViewSidebar
            closeSidebar={closeSidebar}
            data={data}
          />
      }
     {/* MODALS */}
     
      <ModalSuccess
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'paymentSuccessful') || false,
          onClose: () => {closeModal(); window.location.reload()},
        }}
      >
        Se ha pagado <span>su cuota con éxito.</span>
      </ModalSuccess>

      <ModalErrorCard
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'error') || false,
        }}
      />


    </PaymentSidebarContainer >
  )
}

export default PaymentSidebar;