import React from 'react'
import MyWalletContainer from './my-wallet-style'

//Components
import MyWalletWithoutCard from './wallet-without-card/my-wallet-without-card'
import MyWalletWithCard from './wallet-with-card/my-wallet-with-card'
import useUserCards from '../../../hooks/useWallet/useUserCards'


const MyWallet: React.FC = () => {
  const { cardsExits } = useUserCards()
  
  return (
    <MyWalletContainer>
      {cardsExits ? <MyWalletWithCard /> : <MyWalletWithoutCard />}
    </MyWalletContainer>
  )
}

export default MyWallet
