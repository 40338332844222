import { Dispatch } from 'redux';

import {
  AuthActions,
  AuthActionsTypes,
  // IRegisterFormIneFormat,
  IRegisterForm,
  IRegisterFormBasicData,
  IRegisterFormComercialData,
  IRegisterFormExtraData,
  IRegisterFormIneFormat,
  IRegisterFormLegalData,
} from './types';

import { ICommerceProposal, IFile, IUser } from '@aluxion-lightpay/interfaces';
import moment from 'moment';
import { store } from '../..';
import { toBase64, transformFile } from '../../utils/utils';
import { CardActions, CardActionsTypes } from '../card/types';
import { ApiErrorCodes } from '../http-errors';
import { ILoadingActions, ILoadingActionsTypes } from '../loader/types';
import { CURRENT_PLATFORM, IFormatError, LightpayApi } from '../restclient';
import { toastActions } from '../toasts/actions';
import { TrasactionActionsTypes } from '../transaction/types';
import amplitude from 'amplitude-js'

interface IServerResponseRegister {
  data: {
    user: IUser;
    token: string;
    proposal: ICommerceProposal;
  };
}

interface IServerResponseCheckEmail {
  data: {
    exists: boolean;
    statusCode: number;
  };
}

interface IServerResponseVerifyEmail {
  data: {
    sent: boolean;
  };
}

interface IServerResponseUser {
  data: IUser;
}

interface IServerResponsePhone {
  data: {
    verified: boolean;
  }
}

const instanceOfA = (object: any): object is IFile => {
  return true;
};

const handleCustomErrors = (action: 'LOGIN' | 'FORGOT' | 'CHECK' | 'REGISTER', code?: string): string => {
  let message = '';
  if (code) {
    const messageByCode: string = ApiErrorCodes[action][code] || 'error';
    message = messageByCode;
  }
  return message;
};

const addIneImages = (ineImage: IRegisterFormIneFormat) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  try {
    dispatch({ type: AuthActions.REGISTER_FORM_INE_MODAL_DATA_ADDED, payload: ineImage });
    dispatch({ type: AuthActions.REGISTER_FORM_INE_MODAL_CLOSE });

    if (ineImage.front instanceof Blob && ineImage.back instanceof Blob) {
      const [frontFile, backFile] = await Promise.all([toBase64(ineImage.front), toBase64(ineImage.back)]);

      if (localStorage.getItem('localImages')) {
        let lsImages = JSON.parse(localStorage.getItem('localImages') as string);
        lsImages = {
          ...lsImages,
          ine: {
            front: frontFile,
            back: backFile,
          },
        };
        localStorage.setItem('localImages', JSON.stringify(lsImages));
      } else {
        const lsImages = {
          ine: {
            front: frontFile,
            back: backFile,
          },
        };
        localStorage.setItem('localImages', JSON.stringify(lsImages));
      }
    }

  } catch (error) {
    console.log('Error al agregar images del INE', error);
    dispatch({ type: AuthActions.REGISTER_FORM_INE_MODAL_CLOSE });
  }
};

const addRegisterFormBasicData = (basicData: IRegisterFormBasicData) => async (
  dispatch: Dispatch<AuthActionsTypes>,
) => {
  try {
    dispatch({ type: AuthActions.REGISTER_FORM_BASIC_DATA_ADDED, payload: basicData });
  } catch (error) {
    console.log('Error al agregar la informción de Basic Form', error);
  }
};

const checkNinja = async (rfc: string) => {
  let responseScoringNinja: any = await LightpayApi.post(`/scoring/check-rfc`, { rfc }, { headers: { 'Content-Type': 'application/json' } });
  console.log(responseScoringNinja, responseScoringNinja.data.aceptado, 'ninja')
  if (!responseScoringNinja.data.data.aceptado) {
    amplitude.getInstance().logEvent('error encountered', { 'error_type': 'create account', 'error_reason': 'El rfc no es valido ante el SAT' });
    throw new Error('El RFC no es válido, por favor verifiquelo y vuelva a intentar.')
  }
  amplitude.getInstance().logEvent('RFC verified');
}

const addRegisterFormLegalData = (legalData: IRegisterFormLegalData) => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>, getState: any,
) => {
  try {
    const registerForm = getState().auth.registerForm;
    dispatch({ type: ILoadingActions.LOADING });
    legalData.ine = registerForm.legalData ? registerForm.legalData.ine : null;
    dispatch({ type: AuthActions.REGISTER_USER_FAIL, error: { message: '' } });
    const mergedLegalData = legalData;
    /* const validRFC: any = await LightpayApi.post(
      '/auth/check-rfc',
      {
        rfc: legalData.rfc,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: '',
        },
      },
    );
    if (validRFC) {
      if (validRFC.data.exists) {
        throw new Error('El RFC ingresado ya esta registrado.');
      }
    }
    await checkNinja(legalData.rfc) */
    dispatch({ type: AuthActions.REGISTER_FORM_LEGAL_DATA_ADDED, payload: mergedLegalData });
    dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: AuthActions.REGISTER_USER_FAIL, error: formatError });
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const addRegisterFormExtraData = (extraData: IRegisterFormExtraData) => async (
  dispatch: Dispatch<AuthActionsTypes>,
) => {
  try {
    dispatch({ type: AuthActions.REGISTER_FORM_EXTRA_DATA_ADDED, payload: extraData });
  } catch (error) {
    console.log('Error al agregar la informción de Legal Form', error);
  }
};

const addRegisterFormComercialData = (comercialData: IRegisterFormComercialData) => async (
  dispatch: Dispatch<AuthActionsTypes>,
) => {
  try {
    dispatch({ type: AuthActions.REGISTER_FORM_COMERCIAL_DATA_ADDED, payload: comercialData });
  } catch (error) {
    console.log('Error al agregar la informción de Legal Form', error);
  }
};

const checkScorce = async (email: string) => {
  let responseScoringScorce: any = await LightpayApi.post(`/scoring/check-email`, { email }, { headers: { 'Content-Type': 'application/json' } });
  console.log(responseScoringScorce, responseScoringScorce.data.data.aceptado, 'scorce')
  if (!responseScoringScorce.data.data.aceptado) throw new Error('EMAIL_NO_VALID')
}

const registerUser = () => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>, getState: any) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    const registerForm = getState().auth.registerForm as IRegisterForm;
    if (
      !registerForm.legalData ||
      !registerForm.basicData ||
      // !registerForm.extraData ||
      !registerForm.email
    ) {
      throw new Error('Falta información en el formulario');
    }
    const data: any = {
      municipality: registerForm.legalData.municipality,
      zipCode: registerForm.legalData.cp,
      state: registerForm.legalData.state,
      firstName: registerForm.basicData.name,
      lastName: registerForm.basicData.surname,
      secondLastName: registerForm.basicData.secondSurname,
      password: registerForm.basicData.password,
      birthDate: new Date(
        moment(registerForm.legalData.birthDate, 'DD/MM/YYYY').utc().format('') || '08/09/1993',
      ).toISOString(),
      rfc: registerForm.legalData.rfc,
      email: registerForm.email,
      ineFront: registerForm.legalData.ine?.front,
      ineBack: registerForm.legalData.ine?.back,
      ownsAutoLoan: false,
      ownsMortgageLoan: false,
      ownsCreditCard: false,
      // tdc: registerForm.extraData.tdc,
      suburb: registerForm.legalData.colony,
      street: registerForm.legalData.street,
      phoneNumber: registerForm.legalData.phoneNumber?.replace(/\s/g, ''),
      gender: registerForm.basicData.gender
    };

    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    console.log('Antes de registrar -> ', data);
    console.log('Antes de formData -> ', formData.getAll);

    const response: IServerResponseRegister = await LightpayApi.post<IServerResponseRegister>(
      '/auth/payer-register',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: '',
        },
      },
    );

    amplitude.getInstance().setUserId(`${response.data.user.id}SLIGHTPAY`);

    localStorage.setItem('token', response.data.token);
    console.log('REGISTRO:  ', response.data);
    dispatch({ type: AuthActions.REGISTER_USER_SUCCESS, payload: response.data.user });
    dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: AuthActions.REGISTER_USER_FAIL, error: formatError });
    dispatch({ type: ILoadingActions.LOADED });

    throw formatError;
  }
};

const checkEmail = (email: string) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });

    const response = await LightpayApi.post<IServerResponseCheckEmail>('/auth/check-email', {
      email,
    });
    if (response.data.exists) await dispatch({ type: AuthActions.EMAIL_CHECKED_SUCCESS, payload: email });
    else {
      await checkScorce(email)
      throw new Error('email-not-found');
    }


    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error, error.message === 'EMAIL_NO_VALID' ? 'REGISTER' : undefined);
    formatError.message = handleCustomErrors('LOGIN', formatError.code) || formatError.message;
    await dispatch({
      type: AuthActions.EMAIL_CHECKED_FAIL,
      error: formatError,
      payload: email,
    });
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const login = (user: string, pass: string) => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes | TrasactionActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });

    const response = await LightpayApi.post<IServerResponseRegister>('/auth/login', {
      email: user,
      password: pass,
    });
    if (!response.data.user.rfc) {
      throw new Error('Registro con cuenta de ecommerce');
    }

    let { image } = response.data.user;
    if (image && instanceOfA(image)) {
      image = await transformFile(image.url, image.name, image.mimetype);
      await dispatch({
        type: AuthActions.SET_PROFILE_IMAGE,
        payload: image,
      });
    }

    amplitude.getInstance().setUserId(`${response.data.user.id}SLIGHTPAY`);

    LightpayApi.setJWT(response.data.token);
    localStorage.setItem('token', response.data.token);
    // await dispatch({ type: AuthActions.SET_JWT_TOKEN, payload: response.data.token });
    await dispatch({
      type: AuthActions.LOGIN_SUCCESS,
      payload: response.data.user,
    });

    if (image && image instanceof Blob) {
      const profileImage = await toBase64(image);

      if (localStorage.getItem('localImages')) {
        let lsImages = JSON.parse(localStorage.getItem('localImages') as string);
        lsImages = {
          ...lsImages,
          userImage: {
            profileImage,
          },
        };
        localStorage.setItem('localImages', JSON.stringify(lsImages));
      } else {
        const lsImages = {
          userImage: {
            profileImage,
          },
        };
        localStorage.setItem('localImages', JSON.stringify(lsImages));
      }
      await dispatch({
        type: AuthActions.SET_PROFILE_IMAGE,
        payload: image,
      });
    }

    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    formatError.message = handleCustomErrors('LOGIN', formatError.code) || formatError.message;
    await dispatch({
      type: AuthActions.LOGIN_FAIL,
      error: formatError,
    });
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const changeProfileImage = (profileImage: Blob) => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>,
  getState: any,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const { id } = getState().auth.user;
    profileImage = await transformFile(window.URL.createObjectURL(profileImage), 'jpg', profileImage.type);
    const data: any = {
      id,
      profileImage,
    };
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }
    const response = await LightpayApi.post<IServerResponseUser>(`/users/${id}/profile-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    });
    let { image } = response.data;
    if (image && instanceOfA(image)) {
      image = await transformFile(image.url, image.name, image.mimetype);
      if (image instanceof Blob) {
        const profileImage = await toBase64(image);

        if (localStorage.getItem('localImages')) {
          let lsImages = JSON.parse(localStorage.getItem('localImages') as string);
          lsImages = {
            ...lsImages,
            userImage: {
              profileImage,
            },
          };
          localStorage.setItem('localImages', JSON.stringify(lsImages));
        } else {
          const lsImages = {
            userImage: {
              profileImage,
            },
          };
          localStorage.setItem('localImages', JSON.stringify(lsImages));
        }
      }
      await dispatch({
        type: AuthActions.SET_PROFILE_IMAGE,
        payload: image,
      });
    }
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    await dispatch({ type: ILoadingActions.LOADED });
    console.log(error);
  }
};

const verifyEmail = (jwt: any) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.post('/auth/verify-email', '', {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `bearer ${jwt}`,
      },
    });
    await dispatch({ type: AuthActions.VERIFY_EMAIL });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const requestVerifyEmail = () => async (dispatch: Dispatch<ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.post<IServerResponseVerifyEmail>('/auth/verify-email/request', {
      site: CURRENT_PLATFORM,
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const setJwt = (jwt?: string | null) => async (dispatch: Dispatch<AuthActionsTypes>) => {
  try {
    // This sould be refacroted
    if (jwt) await dispatch({ type: AuthActions.SET_JWT_TOKEN, payload: jwt });
  } catch (error) {
    console.log(error);
  }
};

const jwtLogin = (jwt: string) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    LightpayApi.setJWT(jwt);
    const { data } = await LightpayApi.get('/auth/validate');
    await dispatch({
      type: AuthActions.LOGIN_SUCCESS,
      payload: data.user,
    });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({
      type: AuthActions.LOGIN_FAIL,
      error: formatError,
    });
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const recoverPassword = (email: string) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await dispatch({ type: AuthActions.RECOVER_PASSWORD });
    const response = await LightpayApi.post('/auth/reset-password/request', {
      email, site: CURRENT_PLATFORM,
    });
    await dispatch({ type: ILoadingActions.LOADED });
    return response;
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    formatError.message = handleCustomErrors('FORGOT', formatError.code) || formatError.message;
    await dispatch({ type: ILoadingActions.LOADED });
    await dispatch({
      type: AuthActions.RECOVER_PASSWORD_FAIL,
      error: formatError,
    });
    throw formatError;
  }
};

// tslint:disable-next-line: max-line-length
const resetPassword = (password: string, token: string | undefined) => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await dispatch({ type: AuthActions.RESET_PASSWORD });
    const response = await LightpayApi.post(
      '/auth/reset-password',
      {
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      },
    );
    await dispatch({ type: ILoadingActions.LOADED });
    return response;
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    await dispatch({
      type: AuthActions.RECOVER_PASSWORD_FAIL,
      error: formatError,
    });
    throw formatError;
  }
};

const checkPhone = (phoneNumberVerificationCode: string) => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    const response = await LightpayApi.post<IServerResponsePhone>('/auth/verify-phone-number',
      {
        phoneNumberVerificationCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      });
    if (!response.data.verified) {
      store.dispatch<any>(
        toastActions.error('El código no es valido'),
      );
      throw new Error('El código no es valido');
    }
    dispatch({ type: ILoadingActions.LOADED });

  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);

    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }

};

const getCodePhone = () => async (
  dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.post('/auth/request-verification-code',
      {
        method: 'sms',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      });
    dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const updatePhoneNumber = (phoneNumber: string, id: number) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.patch(`/users/${id}/update-phone-number`,
      {
        phoneNumber: phoneNumber.replace(/\s/g, ''),
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      });
    dispatch({ type: AuthActions.UPDATE_PHONE_NUMBER, payload: phoneNumber });
    dispatch({ type: ILoadingActions.LOADED });

  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const logout = () => async (dispatch: Dispatch<AuthActionsTypes | CardActionsTypes>) => {
  await dispatch({ type: AuthActions.LOGOUT });
  await dispatch({ type: CardActions.ADD_CARD_DATA, payload: { items: [], count: 0 } });
  localStorage.removeItem('token');
  localStorage.removeItem('ProductionFormState');
  localStorage.removeItem('TransactionState');
  localStorage.removeItem('localImages');
  window.location.href = '/'
};

const registerEmail = (email: string) => async (dispatch: Dispatch<AuthActionsTypes>) => {

  await LightpayApi.post(`/auth/retargeting`, { email, origin: 'clientes.slightpay' },
    { headers: { 'Content-Type': 'application/json' } });
};

const updateRetargeting = (email?: string | null | undefined, nombre?: string, apellido?: string, segundoApellido?: string) =>
  async (dispatch: Dispatch<AuthActionsTypes>) => {

    await LightpayApi.put(`/auth/retargeting`, {
      email,
      name: nombre,
      surname: apellido,
      secondSurname: segundoApellido
    },
      { headers: { 'Content-Type': 'application/json' } });
  };

const getDataPostalCode = async (cp: string) => {
  console.log(cp, 'getDataPostalCode')
  try {
    let result: any = await LightpayApi.post(`/scoring/postal-code`, { cp },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        }
      });
  
      console.log(result.data, 'actions')
  
      return result.data
  } catch(e) {
    console.log(e)
  }
};

const sendBuroDeCredito = (purchase?: any) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  try {
    // dispatch({ type: ILoadingActions.LOADING });
    console.log('token...', localStorage.getItem('token'))
    let result: any = await LightpayApi.post(`/scoring/buro-credito`,
      purchase,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        }
      }
    )

    console.log(result, 'el resultado de scoring')
    dispatch({ type: AuthActions.SET_SCORING_RESULT, payload: result.data });

    // dispatch({ type: ILoadingActions.LOADED });

    return result
  } catch (error) {
    console.log("Send Error", error)
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    // dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}
const updateUser = (registerFormData: any) => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>, getState: any) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    let data: any = {
      firstName: registerFormData.name,
      lastName: registerFormData.surname,
      secondLastName: registerFormData.secondSurname,
      municipality: registerFormData.municipality,
      state: registerFormData.state,
      street: registerFormData.street,
      suburb: registerFormData.colony,
      zipCode: registerFormData.cp,
      rfc: registerFormData.rfc,
      birthDate: `${registerFormData.birthDate.split('/').reverse()[0]}/${registerFormData.birthDate.split('/').reverse()[1]}/${registerFormData.birthDate.split('/').reverse()[2]}`
    };

    const response: any = await LightpayApi.put(
      '/auth/payer-update',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    console.log('response update user', response)
    await dispatch({
      type: AuthActions.LOGIN_SUCCESS,
      payload: { ...response.data.userUpdate, address: { ...response.data.addressUpdate[0] } },
    });
    dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    console.log(error)
    const formatError: IFormatError = LightpayApi.errorHandler(error, 'REGISTER');
    // dispatch({ type: AuthActions.REGISTER_USER_FAIL, error: formatError });
    dispatch({ type: ILoadingActions.LOADED });

    throw formatError;
  }
};

export const authActions = {
  addIneImages,
  addRegisterFormBasicData,
  addRegisterFormLegalData,
  addRegisterFormExtraData,
  addRegisterFormComercialData,
  registerUser,
  checkEmail,
  login,
  changeProfileImage,
  setJwt,
  jwtLogin,
  recoverPassword,
  resetPassword,
  verifyEmail,
  requestVerifyEmail,
  handleCustomErrors,
  checkPhone,
  getCodePhone,
  updatePhoneNumber,
  logout,
  registerEmail,
  updateRetargeting,
  getDataPostalCode,
  sendBuroDeCredito,
  updateUser
};
