import React from 'react';
import ProfileContainer from './profile-style';
import ProfileAside from '../../../components/asides/profile-aside/profile-aside';
import ProfileAsideResp from '../../../components/asides/profile-aside-resp/profile-aside-resp';


const Profile: React.FC<any> = (props: any) => {
    return(
        <ProfileContainer>
            <div className="user-banner-resp">
                <p className="user-banner-resp-text">Mi perfil</p>
            </div>

            <div className="profile-aside-container">
                <ProfileAside/>
            </div>

            <div className="profile-aside-resp-container">
                <ProfileAsideResp/>
            </div>

            <div className="profile-content">
                {props.children}
            </div>
        </ProfileContainer>
    )
}   

export default Profile; 