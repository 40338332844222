import styled from 'styled-components/macro';

const MainSubTitle = styled.h1`
    font-size: 36px;
    font-weight: 600;
    color: black;

    &.highlight {
        color: #1A4CCD;
    }

    @media (max-width: 576px) {
        font-size: 28px;
        font-weight: 500;
    } 

    @media (max-width: 480px) {
        font-size: 24px;
    }
`;

export default MainSubTitle;