import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../../../store/auth/actions';
import { useHistory, useParams } from 'react-router-dom';
import MainButton from '../../../../../components/main-button/main-button';

// TODO: MOVE THIS
const ConfirmEmailStyle = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f2f2f3;
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .form-button {
    width: 13.25rem;
    margin: 10px auto;
  }
  .form-p {
    text-align: left;
    font-weight: 300;
    max-width: 440px;
    padding: 0px 30px;
    span {
      color: #1a4ccd;
      font-weight: 500;
      button {
        font-size: 1rem;
        font-weight: 500;
        &:after {
          content: '';
          position: absolute;
          width: 7rem;
          height: 1px;
          background: #1a4ccd;
          bottom: -2px;
          left: 6%;
        }
      }
    }
    .bold {
      color: #01030c;
      font-weight: 500;
    }
  }
`;

const ConfirmEmail: React.FC = () => {
  const history = useHistory();
  const { token } = useParams();

  const dispatch = useDispatch();
  const handleForm = async () => {
    try {
      await dispatch(authActions.verifyEmail(token));
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <ConfirmEmailStyle>
      <div className="form-p">
        <p>
          <span className="bold">¡Bienvenido!</span>
          <br />
          <br />
          Por seguridad y para tener confirmada tu cuenta, solo tienes que hacer click al boton de abajo!
        </p>
      </div>
      <MainButton className="form-button" type="primary" text="Verificar" onClick={handleForm} />
    </ConfirmEmailStyle>
  );
};

export default ConfirmEmail;
