import React, { useEffect, useState } from 'react'
import HomeResumeContainer from './home-resume-style'

//Components
import MainButton from '../../../../components/main-button/main-button'
import MainSubTitle from '../../../../components/text-title/sub-title'
import MainTableHeader from '../../../../components/main-table/main-table-header/main-table-header'
import MainTable from '../../../../components/main-table/main-table-layout';

//Constant
import { icons } from './../../../../assets/icons/icons'

//Images
import { images } from '../../../../assets/images/images'

import CardBasicAside from '../../../../components/cards/card-basic-aside/card-basic-aside'
import PaymentTableWrapper from '../../../../components/tables/payment-table-wrapper/payment-table-wrapper'

import { useDispatch, useSelector } from 'react-redux'
import { authSelectors } from '../../../../store/auth/selectors'
import { IUser } from '@aluxion-lightpay/interfaces'
import { transactionSelector } from '../../../../store/transaction/selector'
import { ITransactionPurchase, ITransactionPayment } from '../../../../store/transaction/types'
import { transactionActions } from '../../../../store/transaction/actions'
import { SVGIcon } from '../../../../components/svg-icon/svg-icon'
import CircleStat from '../../../../components/circle-stat/circle-stat'
import { formatMoney } from '../../../../utils/utils'
import { Link, useParams, useHistory } from 'react-router-dom'
import moment from 'moment';
import MainInfoSidebar from '../../../../components/main-info-sidebar/main-info-sidebar'
import useMainInfoSidebar from '../../../../hooks/useMainInfoSidebar/useMainInfoSidebar'

export interface IFilterTypes {
  status: string;
  fromDate: string;
  toDate: string;
  search: string;
}

const HomeResume: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, firstName } = useSelector(authSelectors.getMe()) as IUser;
  const { items } = useSelector(transactionSelector.getTransactionDataTable()) as ITransactionPurchase;
  const { items: paymentsItems } = useSelector(transactionSelector.getPaymentsData()) as ITransactionPayment;
  const { items: compledPaymentsItems } = useSelector(transactionSelector.getCompletedPaymentsData()) as ITransactionPayment;
  const { paymentId } = useParams();

  const { openMainInfoSidebar, isMainInfoSidebarOpen, closeMainInfoSidebar } = useMainInfoSidebar()

  useEffect(() => {
    const getData = async () => {
      await dispatch(transactionActions.getHomePaymentsDetails(id))
    }
    getData();
  }, [dispatch, id]);

  useEffect(() => {
    console.log(items)
  }, []);

  useEffect(() => {
    if (paymentId) {
      openMainInfoSidebar(paymentId);
    } else {
      closeMainInfoSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId])

  const columnsBuys = [
    {
      text: 'Estado',
      filter: false,
      ascendent: true,
      className: 'table-col-3 start',
    },
    {
      text: 'Tipo',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Id. Transacción',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Fecha',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Nombre del Comercio',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Monto',
      filter: false,
      ascendent: true,
      className: 'table-col-2 end',
    },
  ]

  // FIRTS TABLE DATA
  const paymentRows = paymentsItems.map((payment, index) => {
    return {
      refencialNumber: {
        text: payment.purchase.id,
        key: 'highlighted',
        className: `table-col-2 start_row ${payment.status === 'CANCELED' && 'canceled'}`,
      },
      payments: {
        key: 'icon',
        text: `${payment.paymentIndex}/${payment.type ? payment?.type?.substr(0, 1) : 4}`,
        img:
          payment.status === 'CANCELED' ?
            <SVGIcon icon={icons.rejectedIcon} color="#B3B3B6" scale="1.5" />
            :
            <CircleStat
              radius={14}
              stroke={3}
              progress={(payment.paymentIndex * 100) / Number(payment.type ? payment?.type?.substr(0, 1) : 4)}
              fill={payment.status === 'SUCCESSFUL' ? '#1A4CCD' : '#CA3976'}
            />,
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled cross'}`,
      },
      date: {
        text: moment(payment?.dueDate).format('L'),
        key: 'highlighted',
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled'}`
      },
      commerce: {
        text: payment.purchase.commerce.name,
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled'}`
      },
      totalAmount: {
        key: 'icon-right',
        text: formatMoney(payment?.amount),
        // img:  payment.status === 'SUCCESSFUL' 
        // ? <SVGIcon icon={icons.tickGreen } color="#1A4CCD" scale="1" />
        // :'',
        // span:`${ item.actualPaymentIndex + 1 === index ? 'A pagar ahora' : '' }`,
        className: `table-col-2 end_row ${payment.status === 'SUCCESSFUL' ? 'payment active' : 'payment'} 
        ${payment.status === 'CANCELED' && 'canceled cross'}`,
      },
      link: `/detalle-operacion/${payment.purchase.id}`,
    }
  });



  // ENDS FIRST TABLE DATA


  // SECOND TABLE DATA

  const rowKeyBuys = ['state', 'type', 'refencialNumber', 'date', 'commerce', 'totalAmount'];

  const completedPaymentRows = compledPaymentsItems.map((payment, index) => {
    return {
      refencialNumber: {
        text: payment.purchase.id,
        key: 'highlighted',
        className: `table-col-2 start_row ${payment.status === 'CANCELED' && 'canceled'}`,
      },
      payments: {
        key: 'icon',
        text: payment.paymentIndex === 0 ? 'Una sola exhibición' : payment.type ? `${payment.paymentIndex}/${payment?.type?.substr(0, 1)}` : `${payment.paymentIndex}/4`,
        img:
          payment.status === 'CANCELED' ?
            <SVGIcon icon={icons.rejectedIcon} color="#B3B3B6" scale="1.5" />
            :
            <CircleStat
              radius={14}
              stroke={3}
              progress={(payment.paymentIndex * 100) / Number(payment.type ? payment?.type?.substr(0, 1) : 4)}
              fill={payment.status === 'SUCCESSFUL' ? '#1A4CCD' : '#CA3976'}
            />,
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled cross'}`,
      },
      date: {
        text: moment(payment?.dueDate).format('L'),
        key: 'highlighted',
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled'}`,
      },
      commerce: {
        text: payment.purchase.commerce.name,
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled'}`,
      },
      totalAmount: {
        key: 'icon-right',
        text: formatMoney(payment?.amount),
        // img:  payment.status === 'SUCCESSFUL' 
        // ? <SVGIcon icon={icons.tickGreen } color="#1A4CCD" scale="1" />
        // :'',
        // span:`${ item.actualPaymentIndex + 1 === index ? 'A pagar ahora' : '' }`,
        className: `table-col-2 end_row ${payment.status === 'SUCCESSFUL' ? 'payment active' : 'payment'} 
        ${payment.status === 'CANCELED' && 'canceled cross'}`,
      },
      link: `/detalle-operacion/${payment.purchase.id}`,
    }
  });

  const rows = items.map(item => {
    let text = '';
    let img: any = '';
    let tipo: string = '';
    let amount: number
    if (item.type === "OneShot") {
      tipo = 'OneShot'
      amount = item.totalOneShot;
    } else {
      tipo = "BNPL"
      amount = item.totalAmount
    }
    switch (item.status) {
      case 'SUCCESSFUL':
        text = 'completada';
        img = <SVGIcon icon={icons.okIcon} color="#4FB2C6" scale="1" />;
        break;
      case 'CANCELED':
        text = 'cancelado';
        img = <SVGIcon icon={icons.rejectedIcon} color="#CA3976" scale="1" />;
        break;
      case 'CANCELED_PENDING':
        text = 'cancelado';
        img = <SVGIcon icon={icons.rejectedPendingIcon} scale="1" />;
        break;
      case 'DRAFT':
        text = 'preparando';
        img = <SVGIcon icon={icons.draftIconSVG} color="#999A9E" scale="1" />;
        break;
      case 'EXPIRED':
        text = 'expirada';
        img = <SVGIcon icon={icons.expiredIcon} scale="1" />;
        break;
      case 'PENDING':
        text = 'exitosa';
        img = <SVGIcon icon={icons.inProgressIcon} color="#4FB2C6" scale="1" />;
        break;
      case 'CHARGEBACK_REJECTED':
        text = 'contracargo';
        img = <SVGIcon icon={icons.chargeBackRejectedIcon} scale="1" />;
        break;
      case 'CHARGEBACK_ACCEPTED':
        text = 'contracargo';
        img = <SVGIcon icon={icons.chargeBackAcceptedIcon} scale="1" />;
        break;
      case 'CHARGEBACK_PENDING':
        text = 'contracargo';
        img = <SVGIcon icon={icons.chargeBackPendingIcon} scale="1" />;
        break;
      case 'REFUNDED_TOTALLY':
        text = 'devolución';
        img = <SVGIcon icon={icons.refundIcon} color="#8CA5E6" scale="1" />;
        break;
      case 'REFUNDED_PENDING':
        text = 'devolución';
        img = <SVGIcon icon={icons.refundPendingIcon} scale="1" />;
        break;
      case 'REFUNDED_PARTIALLY':
        text = 'devolución';
        img = <SVGIcon icon={icons.refundIcon} color="#FFBB36" scale="1" />;
        break;
      case 'INDEBTED':
        text = 'incobrable';
        img = <SVGIcon icon={icons.refundIcon} color="#FFBB36" scale="1" />;
        break;
      case 'PREPENDING':
        text = 'En validacion';
        img = <SVGIcon icon={icons.clockIcon} color="#11c6ffb8" scale="1" />;
        break;
    }
    return {
      state: {
        key: 'icon',
        img,
        text: text.toUpperCase(),
        className: 'table-col-3 start_row',
      },
      type: {
        text: tipo,
        className: 'table-col-2',
      },
      refencialNumber: {
        text: item.id,
        className: 'table-col-2',
      },
      date: {
        key: 'date',
        text: moment(item.createdAt).format('L'),
        span: `${moment(item.createdAt).format('LT')}h`,
        className: 'table-col-2 date',
      },
      commerce: {
        text: item.commerce.name,
        className: 'table-col-2 date',
      },
      totalAmount: {
        text: formatMoney(amount),
        className: 'table-col-2 end_row',
      },
      onClick: () => { history.push(`/home/${item.id}`); openMainInfoSidebar(item.id) }
    };
  });
  const columnsDues = [
    {
      text: 'Id. Transacción',
      filter: false,
      ascendent: true,
      className: 'table-col-2 start',
    },
    {
      text: 'Nº cuotas',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Fecha',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Nombre del Comercio',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Importe',
      filter: false,
      ascendent: true,
      className: 'table-col-2 end',
    },

  ];
  const rowKeysDues = ['refencialNumber', 'payments', 'date', 'commerce', 'totalAmount',];

  // END SECOND TABLE DATA



  return (
    <HomeResumeContainer id="home-resume">

      {isMainInfoSidebarOpen() ?
        (<MainInfoSidebar id='resume-info-sidebar'></MainInfoSidebar>)
        :
        <></>
      }


      <div id="home-resume-sidebar">
        <div className="sidebar-heroblock">
          <div className="image-container">
            <img src={images.homeResumenSidebar} alt="resume sidebar" />
          </div>
        </div>
        <div id="home-resume-sidebar-cards">
          <CardBasicAside
            icon={icons.homeCreditcard}
            title={'Vincular una tarjeta'}
            description={
              'Añade una tarjeta y comienza a disfrutar de las ventajas de Slightpay.'
            }
            url="/perfil/datos-personales/añadir-direccion"
          />

          {/* <CardBasicAside
            icon={icons.homeClickcard}
            title={'Comenzar a comprar'}
            description={
              'Añade una tarjeta y comienza a disfrutar de las ventajas de Lightpay.'
            }
          /> */}
        </div>
      </div>
      <div id="home-resume-content">
        <div className="title-container">
          <h1 className="title-container__main">Hola,</h1>
          <MainSubTitle className="highlight">{firstName}</MainSubTitle>
        </div>

        {
          paymentRows.length !== 0 ?
            <article>
              <MainTableHeader
                title={'Agenda de pagos'}
                placerholder="Instroduzca un comercio"
                onAnyKeyPress
                noFilters
              >
              </MainTableHeader>
              <MainTable columns={columnsDues} rows={paymentRows} keysRow={rowKeysDues} />
              <Link to="/mi-actividad/agenda-de-pagos">
                <MainButton className="button" type="primary" text="Ver más" />
              </Link>

            </article>
            :
            <></>
        }

        {
          completedPaymentRows.length !== 0 ?
            <article>
              <MainTableHeader
                // onClick={generateOperation}
                // mainButton
                placerholder="Introduzca un comercio"
                title="Historial"
                onAnyKeyPress
                noFilters
              // buttons={[
              //   {
              //     text: 'Descargar CSV',
              //     onClick: () => {
              //       '';
              //     },
              //   },
              // ]}
              >
              </MainTableHeader>
              <PaymentTableWrapper
                title=""
                tabs={['Mis Compras', 'Mis Cuotas']}
                tables={[
                  <MainTable columns={columnsBuys} rows={rows} keysRow={rowKeyBuys} />,
                  <MainTable columns={columnsDues} rows={completedPaymentRows} keysRow={rowKeysDues} />,
                ]}
              />
              <Link to="/mi-actividad/historial-de-pagos">
                <MainButton className="button" type="primary" text="Ver más" />
              </Link>
            </article>
            :
            <></>
        }



      </div>
    </HomeResumeContainer>
  )
}

export default HomeResume
