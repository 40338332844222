import styled from 'styled-components/macro';

export const IEStyle = styled.div`
  width: 100vw;
  height: 100vh;
  text-align: center;
  vertical-align: middle;
  display:table-cell;
  margin: 0 auto;

  p {
    margin-top: 30px;
    font-size: 40px;
  }
  .browser-container {
    margin-top: 40px;
    display:inline-flex;
    .browser-item {
      margin-right: 30px;
    }
  }
`;
