import styled from 'styled-components';

export const ModalSuccessStyle = styled.div`
  .modal-info-title {
    max-width: 394px;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #01030c;
    font-weight: 600;
    margin-bottom: 24px;

    span {
      color: #1a4ccd;
    }
  }
  .modal-info-text {
    max-width: 394px;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #01030c;
    text-align: center;
    text-align: center;
    font-weight: 300;
  }
  .modal-info-image {
    margin-bottom: 24px;
  }
`;
