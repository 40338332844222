import styled from 'styled-components/macro';

export const GridMasonryStyle = styled.div`
  width: auto;
  min-width: 0;
  position: fixed;
  top: 0;
  left: 0%;
  width: 50%;
  .masonry {
    display: grid;
    padding: 1.125rem;
    grid-template-columns: repeat(5, 1fr);
    height: calc(100vh);
    width: 100%;
    grid-template-rows: repeat(8, 1fr);
    grid-gap: 20px;
    grid-template-areas:
      'd0 d0 d0 d1 d1 d1'
      'd0 d0 d0 d1 d1 d1'
      'd0 d0 d0 d5 d5 d5'
      'd2 d2 d2 d5 d5 d5'
      'd2 d2 d2 d5 d5 d5'
      'd2 d2 d2 d5 d5 d5'
      'd3 d3 d4 d4 d4 d4'
      'd3 d3 d4 d4 d4 d4'
      'd3 d3 d4 d4 d4 d4'
      'd3 d3 d4 d4 d4 d4';
  }

  .item {
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      display: block;
      height: 100%;
      width: auto;
      min-width: 100%;
      object-fit: cover;
    }
  }

  .d0 {
    grid-area: d0;
  }

  .d1 {
    grid-area: d1;
  }

  .d2 {
    grid-area: d2;
  }

  .d3 {
    grid-area: d3;
  }

  .d4 {
    grid-area: d4;
  }

  .blackSquare {
    grid-area: d5;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    p {
      margin-left: 2.375rem;
      font-size: 2.25rem;
      font-weight: 500;
      span {
        font-weight: 300;
        color: #b3b3b6;
        text-transform: uppercase;
        font-size: 1rem;
      }
      @media screen and (max-width: 1145px) {
        font-size: 1.5rem;
        span {
          font-size: 0.8rem;
        }
      }
    }
  }


  @media (max-width: 768px){
    display: none;
  }
`;
