import styled from 'styled-components/macro';

import { images } from '../../../assets/images/images';

const WalletAsideContainer = styled.aside`
    width: 100%;
    max-width: 360px;
    padding-right: 60px;
    position: relative; 
    top: -120px;

    @media (max-width: 992px) {
        max-width: initial !important;
        padding-right: initial !important;

        .wallet-aside-credit-card {
            display: flex;
            justify-content: center;
        }

        .wallet-aside-credit-card-info {
            display: flex;
            justify-content: center;            
        }
    }

    .wallet-aside-credit-card {
        width: 100%; 
        margin-bottom:28px;
    }

    .wallet-aside-credit-card-info {
        width: 100%; 
        margin-bottom:28px;
    }
`;

export default WalletAsideContainer;