import styled from 'styled-components/macro';

const ModalUpdateAvatarContainer = styled.div`
  .modal-update-avatar-title {
    font-family: 'Gilroy';
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #01030c;
    margin-bottom: 26px;
  }

  .avatar-editor-container {
    width: 100%;
    height: 212px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e6e6e7;
    margin-bottom: 14px;
    cursor: pointer;

    .no-image-container {
      border-radius: 100%;
      border: 1px dashed #b3b3b6;
      width: 128px;
      height: 128px;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        display: none;
      }
    }

    .no-image-icon {
      width: 50px;
      height: 50px;
    }
  }

  .modal-update-avatar-text {
    font-family: 'Gilroy';
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #01030c;
    margin-bottom: 32px;
  }

  .modal-update-avatar-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-cancel {
      width: calc((100% - 20px) / 2);
      margin-right: 20px;
    }

    .btn-accept {
      width: calc((100% - 20px) / 2);
    }
  }
`;

export default ModalUpdateAvatarContainer;
