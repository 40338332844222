import styled from 'styled-components';
import { IMainInfoSidebarProps } from './main-info-sidebar-type';

const MainInfoSidebarContainer = styled.div<IMainInfoSidebarProps>`
  position: fixed;
  top: 0px;
  right: 0px;
  width: 380px;
  height: 100vh;
  z-index: 5000;
  background-color: white;

  .veil {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;

  }

`

export const WrapperContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
   filter: contrast(4px);
  backdrop-filter: contrast(.4);
`;

export default MainInfoSidebarContainer;