import { IBaseAction } from '../base-action.interface';
import { Options } from 'react-toast-notifications';

export type TOAST_TYPES = 'success' | 'info' | 'warning' | 'error';
export type DISMISS_TYPES = 'none' | 'short' | 'normal' | 'long';
export const DISMMIS_TIMES = {
  none: 0,
  short: 4,
  normal: 7,
  long: 15,
};

export interface IToast {
  id: number;
  type: TOAST_TYPES;
  message: string;
  options: Options;
  autoDismiss?: DISMISS_TYPES;
}

export enum IToastActions {
  ADD_TOAST = 'ADD_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',
  REMOVE_ALL_TOAST = 'REMOVE_ALL_TOAST',
}

export interface IToastState {
  readonly toasts: IToast[];
  readonly lastToast: IToast | null;
}

export interface IToastReducer {
  (state: IToastState, action: IToastActionsTypes): IToastState;
}

export interface IAddToastAction extends IBaseAction {
  type: IToastActions.ADD_TOAST;
  toast: IToast;
  //autoDismiss: DISMISS_TYPES;
}

export interface IRemoveToastAction extends IBaseAction {
  type: IToastActions.REMOVE_TOAST;
  id: number;
}

export interface IRemoveAllToastAction extends IBaseAction {
  type: IToastActions.REMOVE_ALL_TOAST;
}

export type IToastActionsTypes = IAddToastAction | IRemoveToastAction | IRemoveAllToastAction;
