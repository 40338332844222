import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selectors";
import { IUser } from "@aluxion-lightpay/interfaces";
import { transactionSelector } from "../../store/transaction/selector";
import { ITransactionPayment, ITransactionPurchase } from "../../store/transaction/types";

const useUserActivies = () => {
  const { id } = useSelector(authSelectors.getMe()) as IUser;
  const { items, count } = useSelector(transactionSelector.getTransactionDataTable()) as ITransactionPurchase;
  const { items: paymentsItems, count: paymentsCount } = useSelector(transactionSelector.getPaymentsData()) as ITransactionPayment;
  const { items: completedPaymentsItems, count: completedPaymentsCount} = useSelector(transactionSelector.getCompletedPaymentsData()) as ITransactionPayment;

  return {
    items,
    count,
    paymentsItems,
    paymentsCount,
    completedPaymentsItems,
    completedPaymentsCount,
    id,
  }
}

export default useUserActivies