import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { DesktopHeaderContainer } from './desktop-styles'

import { icons } from '../../../assets/icons/icons'
import { images } from '../../../assets/images/images'
import { SVGIcon } from '../../svg-icon/svg-icon'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../../store/auth/actions'
import { authSelectors } from '../../../store/auth/selectors'
import { IUser } from '@aluxion-lightpay/interfaces'
import { transformFile } from '../../../utils/utils'
import { AuthActions } from '../../../store/auth/types'
import ModalInfo from '../../modals/modal-info/modal-info'
import { modalSelector } from '../../../store/modal/selectors'
import { modalActions } from '../../../store/modal/actions'
import amplitude from 'amplitude-js'
import SendApiConversion from '../../../constants/apiConversionFb'


const DesktopHeader: React.FC = () => {
  const dispatch = useDispatch();
  const isModal = useSelector(modalSelector.isShowing());
  const { image } = useSelector(authSelectors.getMe()) as IUser;
  const me = useSelector(authSelectors.getMe())
  const scoringStatus = useSelector(authSelectors.getScoringStatus())
  const [approvedUser, setApprovedUser] = useState(true)
  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };

const closeModal = async () => {
    await dispatch(modalActions.hide());
};

  // TODO: CREATE A HOOK FOR THIS
  const [imageUrl, setImageUrl] = useState<any>();
  if (image instanceof Blob) {
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(image);
  } else if (image) {
    transformFile(image.url, image.name, image.mimetype).then(data => {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(data);
      dispatch({
        type: AuthActions.SET_PROFILE_IMAGE,
        payload: data,
      });
    });
  }

  useEffect(() => {
    console.log(scoringStatus, 'scoring status desktop')

    //console.log(scoringStatus, me?.approved, "el scoring")
    if (!scoringStatus) {
      dispatch(authActions.sendBuroDeCredito());
    }else if (scoringStatus.status === 'SUCCESS') {
      setApprovedUser(true)
    }
    else {  
      setApprovedUser(false) 
    }
  }, [scoringStatus, me])

  const handleLogout = () => {
    dispatch(authActions.logout());
    amplitude.getInstance().logEvent('logged out');
    SendApiConversion('Client_Loggout')
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
    closeModal();
  }

  return (
    <DesktopHeaderContainer>
      <div id="nav-logo">
        <div className="img-container">
          <Link to="/">
            {/* <img src={icons.logoNavbarDesktop} alt="Lightpay Logo" /> */}
            <SVGIcon icon={icons.slightpayLogo} scale='1.2' color='black'/>
          </Link>
        </div>
      </div>

      <div id="nav-actions">
        <div className="nav-opts">
          {/**  TODO: hay que mover las opciones 
                        dentrod e cada componente a un json */}

          <NavLink exact to="/" activeClassName="active">
            Resumen
          </NavLink>
          <NavLink to="/mi-actividad/agenda-de-pagos" activeClassName="active" onClick={() => { amplitude.getInstance().logEvent('get activity'); }}>
            Mi actividad
          </NavLink>
          <NavLink to="/mi-cartera" activeClassName="active" onClick={() => { amplitude.getInstance().logEvent('get wallet'); }}>
            Mi cartera
          </NavLink>
          <a href="https://slightpay.com/donde-comprar" rel="noopener noreferrer" target="_blank" onClick={() => { amplitude.getInstance().logEvent('get stores'); }}>
            Dónde Comprar
          </a>
          {/* <NavLink to="/mi-cartera" activeClassName="active">
            Mi cartera
          </NavLink>
          {/* <NavLink to="/mi-actividad/agenda-de-pagos" activeClassName="active">
            Mi actividad
          </NavLink>
          <NavLink to="/promociones" activeClassName="active">
            Promociones
          </NavLink> */}
          <div className="nav-opts__item" onClick={()=>openModal('logoutWarning')}>
            <img src={icons.power} alt="cerrar sesión" />
          </div>
        </div>

        <div id="nav-toolbar">
          {/* <div className="img-container">
            <img
              src={icons.navbarNotiPending}
              alt="Notificaciones del Navbar"
            />
          </div> */}

          <Link to="/perfil/datos-personales">
            <div id="img-profile">
              <img src={imageUrl ? imageUrl : images.defaultProfileImage} alt="profile"/>
              {!approvedUser ? 
              <div className="invalid-finerio"></div>:
              <div className="approved-finerio"></div>}
            </div>
          </Link>
        </div>
      </div>

      <ModalInfo
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'logoutWarning') || false,
          onClose: closeModal,
        }}
        title={'¿Estás seguro de cerrar sesión?'}
        text=""
        buttons={[
          {
            text: 'Cancelar',
            type: 'ghost',
            onClick: closeModal,
          },
          {
            text: 'Sí',
            type: 'primary',
            onClick: handleLogout,
          },
        ]}
      />
    </DesktopHeaderContainer>
  )
}

export default DesktopHeader
