import React from 'react';
import ModalContainer, { IModalContainerProps } from '../modal-container/modal-container';
import CardImageShadow from '../../cards/card-image-shadow/card-image-shadow';
import { images } from '../../../assets/images/images';
import { ModalSuccessStyle } from './modal-success-style';

interface IModalSuccess {
  children: any;
  text?: string;
  modalConfig: IModalContainerProps;
  image?: string;
}

const ModalSuccess: React.FC<IModalSuccess> = ({ text, modalConfig, children, image }) => {
  return (
    <ModalContainer {...modalConfig}>
      <ModalSuccessStyle>
        <div className="modal-info-title">{children}</div>
        <div className="modal-info-text">
          <p>{text}</p>
        </div>
        <div className="modal-info-image">
          <CardImageShadow image={image ? image : images.imgRun} />
        </div>
      </ModalSuccessStyle>
    </ModalContainer>
  );
};

export default ModalSuccess;
