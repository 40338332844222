import React from 'react';

// Styles
import TableSimpleContainer from './table-simple-style';

// Types
import { ITableSimpleProps } from './table-simple-types';

const TableSimple: React.FC<ITableSimpleProps> = (props: ITableSimpleProps) => {
  const { className, header, data } = props;

  return (
    <TableSimpleContainer className={className}>
      <table>
        <thead>
          <tr>
            {header
              ? header.map(function(element: any, index: any) {
                  return <th key={index}>{element}</th>;
                })
              : null}
          </tr>
        </thead>

        <tbody>
          {data.map(function(element: any, index: any) {
            return (
              <tr key={'table-index-' + index}>
                {element.map(function(_element: any, _index: any) {
                  return <td key={'table-index-td-' + _index}>{data[index][_index]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableSimpleContainer>
  );
};

export default TableSimple;
