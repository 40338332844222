import React from 'react';

// Styles
import MainButtonContainer from './main-button-style';

// Types
import { IMainButtonProps } from './main-button-types';

const MainButton: React.FC<IMainButtonProps> = (props: IMainButtonProps) => {
  const { text, type, icon, iconImg, onClick, className, disabled, tertiaryColor } = props;

  const handleClick = () => {
    if (disabled) return;
    /* eslint-disable */
    onClick ? onClick() : null;
  };

  return (
    <MainButtonContainer
      className={`${className} ${type} ${disabled ? 'disabled' : ''}`}
      onClick={() => (onClick ? handleClick() : '')}
      tertiaryColor={tertiaryColor}
    >
      {icon && (
        <div className="main-button-icon">
          <img src={iconImg ? iconImg : ''} alt="main button icon" />
        </div>
      )}

      <p className="main-button-text">{text}</p>
    </MainButtonContainer>
  );
};

export default MainButton;
