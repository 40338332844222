import React from 'react';
import Accordion from '../../../../../components/accordion/accordion';
import { IPropsAnswers } from '../../../../../utils/utils';

const Commerces: React.FC<IPropsAnswers> = (props) => {
  const { onClick } = props;
  return (
    <div className="help-content">
      <div className="help-content-return" onClick={ () => onClick() }>
        <div><i className="arrow"></i></div>
        <p>Comercios</p>
      </div>
      <div className="help-section-box">
        <Accordion title="Tengo una tienda en línea en Slightpay, ¿cómo puedo ponerme en contacto con ustedes?" className="help-item">
          <p className="help-text">
            <p>
              Puedes enviarnos un correo a hello@slightpay.com y cuéntanos qué dudas tienes. 
            </p>
          </p>
        </Accordion>
        
        <Accordion title="Soy comerciante, ¿puedo llamarte en busca de ayuda?" className="help-item">
          <p className="help-text">
            <p>
              Escríbenos a soporte@slightpay.com y un representante se pondrá en contacto inmediatamente contigo.
            </p>
          </p>
        </Accordion>

        <Accordion title="Tengo una tienda en línea afiliada a Slightpay, ¿cuándo me pagan?" className="help-item">
          <p className="help-text">
            <p>
              Los pagos de liquidación se activan desde el momento en que Slightpay aprueba el pedido y se procesan de acuerdo con lo 
              siguiente:
            </p>

            <p>
              Los pagos de la liquidación se realizarán en función del tiempo de procesamiento de la liquidación acordado (generalmente 
              dentro de los 3 días hábiles).
            </p>
            
            <p>
              Se envía un correo electrónico a la dirección de la cuenta registrada en el comercio (si está configurada) que indica el monto 
              total del pago de la liquidación a pagar. Los correos electrónicos no se envían si no hay cantidades debidas al comerciante.
              El desembolso de la liquidación a los Comercios se realiza mediante un SPEI a cuentas bancarias en México únicamente. El 
              comercio debe tener en cuenta los tiempos de procesamiento de SPEI para su compensación. Las comisiones se calculan y se 
              deducen de la cantidad que se pagará al Comercio en el momento de la liquidación. 
            </p>
          </p>
        </Accordion>
        
        <Accordion title="Tengo una tienda en línea afiliada a Slightpay, ¿cómo puedo procesar un reembolso?" className="help-item">
          <p className="help-text">
            <p>
              Slightpay dirigirá todas las consultas de los compradores, con respecto a la devolución de productos, al Comercio Afiliado. 
              Por motivos de privacidad, Slightpay no puede emitir un reembolso a un comprador en nombre de un Comerciante.
            </p>
            
            <p>
              Slightpay solo procesará los reembolsos que se generen a través de una solicitud de reembolso a través del Portal del Comercio.
            </p>

            <p>
              Slightpay determinará cómo aplicar el reembolso al pedido del comprador y ajustará el calendario de pagos del comprador según 
              corresponda para reflejar el reembolso emitido.
            </p>

            <p>
              Slightpay procesará los montos a la tarjeta del comprador en los casos en que el comprador ya haya efectuado pagos a Slightpay.
            </p>

            <p>
              Se enviará un correo electrónico de Slightpay al usuario para confirmar que el reembolso se ha procesado y comunicará los 
              cambios al calendario de pago.
            </p>

            <p>
              Cualquier reembolso se contabilizará en la próxima fecha de liquidación. Los montos de los reembolsos se identificarán 
              claramente como tales en la información detallada de la liquidación.
            </p>

            <p>
              Las comisiones del comercio de Slightpay no son reembolsables en el caso de un reembolso.
            </p>

            <p>
              Una vez que se haya emitido un reembolso, Slightpay no podrá revertir ni cambiar esta acción.
            </p>

            <p>
              NUNCA debe REEMBOLSAR EFECTIVO a un comprador en relación con los montos pagados por los compradores a Slightpay.
            </p>

            <p>
              <span>
                Reembolsos parciales
              </span>
            </p>

            <p>
              Cualquier reembolso que no sea el importe total del pedido se considera un reembolso parcial.
            </p>
            <p>
              Slightpay actualizará automáticamente el calendario de pagos del comprador al reducir las cuotas o al procesar 
              un reembolso a la tarjeta del comprador cuando sea necesario. El reembolso se aplica primero a la cuarta cuota, 
              luego a la tercera cuota y así sucesivamente.
            </p>
            <p>
              Ejemplo: Si el valor del pedido es de $1000 y el comprador ha pagado una cuota de $250, en caso de un reembolso 
              parcial ($600 para este ejemplo), Slightpay cancelará la cuarta y tercera cuota del comprador de $250 y reducirá la 
              segunda cuota a $150.
            </p>
            <p>
              <span>
                Reembolsos completos
              </span>
            </p>
            <p>
              Cualquier reembolso que sea del importe total del pedido se considerará como un reembolso completo.
            </p>
            <p>
              Slightpay cancelará automáticamente el calendario de pago del comprador para que el comprador ya no tenga que pagar el pedido. 
              Si el comprador ha realizado algún pago, Slightpay procesará un reembolso a su tarjeta.
            </p>
            <p>
              Por ejemplo: si el valor del pedido es de $ 1000 y el comprador ha pagado 2 cuotas de $250, en caso de un reembolso completo, 
              Slightpay cancelaría la tercera y la cuarta entrega y procesaría el reembolso de $500 (es decir, cuotas de $250 X 2) para 
              devolver a la tarjeta del comprador.
            </p>
            <p>
              <span>
                Emisión de reembolsos
              </span>
            </p>
            <p>
              Para emitir un reembolso a través del Portal de Comercios:
            </p>
            <p>
              <ul>
                <li>
                  Ingresa tu Portal de Comercios
                </li>
                <li>
                  Haz clic en el pedido al que desea aplicar un reembolso
                </li>
                <li>
                  Ver detalles de la orden y presionar el botón de DEVOLUCIÓN
                </li>
                <li>
                  Ingresa la cantidad de reembolso
                </li>
                <li>
                  Presiona el botón ACEPTAR
                </li>
              </ul>
            </p>
            <p>
              El reembolso será procesado por Slightpay a la orden del comprador.
            </p>
            <p>
              El reembolso se contabilizará en Liquidación en la próxima fecha de liquidación.
            </p>
            <p>
              Una vez que se haya emitido un reembolso, Slightpay no podrá revertir ni cambiar esta acción.
            </p>
            <p>
              Nota: Estarás sujeto a los términos de su acuerdo con SlightPay en cuanto a cómo maneja los cambios, devoluciones y reembolsos.
            </p>
          </p>
        </Accordion>
        
        <Accordion title="Tengo una tienda afiliada a Slightpay, ¿cómo modifico un pedido existente?" className="help-item">
          <p className="help-text">
            <p>
              Una vez que Slightpay haya aprobado un pedido, los detalles del pedido y el valor del pedido no se pueden cambiar. 
              Usted, el comercio, podrá emitir reembolsos totales o parciales si los artículos no están en stock o no se pueden 
              cumplir por cualquier motivo. Slightpay no admite la adición de artículos a un pedido. El comprador deberá realizar 
              un pedido nuevo e independiente para los nuevos artículos o cancelar el pedido anterior y hacer un nuevo pedido para 
              todos los artículos si desea usar el método de pago en cuotas de Slightpay para pagar los nuevos artículos en el 
              mismo calendario de pagos.
            </p>
          </p>
        </Accordion>
        
        <Accordion title="Tengo una tienda afiliada a Slightpay, ¿cómo cancelo un pedido existente?" className="help-item">
          <p className="help-text">
            <p>
              El proceso de cancelación es muy fácil.
            </p>
            <p>
              <ul>
                <li>
                  Inicia sesión en tu Panel de Comercio; necesitará credenciales de inicio de sesión, que generalmente se configuran en el momento de la creación de la cuenta.
                </li>
                <li>
                  Localiza el pedido que desea modificar utilizando el número de pedido (se aceptan devoluciones totales o parciales)
                </li>
                <li>
                  Introduce el importe del reembolso
                </li>
                <li>
                  ¡Listo! Nosotros nos encargamos del resto, el dinero se devolverá de inmediato a la tarjeta utilizada y le enviamos un 
                  correo electrónico al Comprador para informarle del status de la cancelación
                </li>
              </ul>
            </p>
          </p>
        </Accordion>

        <Accordion title="Tengo una tienda en línea ¿cómo configuro Slightpay en mi tienda?" className="help-item">
          <p className="help-text">
            <p>
              Cuéntanos más sobre tu negocio en México enviando un correo a hello@slightpay.com.
            </p>
            <p>
              <ul>
                <li>
                  Volumen de ventas en línea por año:
                </li>
                <li>
                  Valor medio del pedido:
                </li>
                <li>
                  URL de la tienda para México:
                </li>
                <li>
                  # de seguidores de Instagram:
                </li>
              </ul>
            </p>
            <p>
              Puedes iniciar el proceso de ceración de tu cuenta de comercio aquí: http://comercios.slightpay.com/
            </p>
          </p>
        </Accordion>
        
        <Accordion 
          title="Tengo una tienda en línea afiliada con Slightpay ¿cómo puedo acceder al directorio de la tienda de Slightpay" 
          className="help-item"
        >
          <p className="help-text">
            <p>
              Para incluir tu tienda en nuestro directorio de tiendas, envía un correo electrónico directamente a nuestro equipo de 
              marketing: hello@slightpay.com
            </p>
          </p>
        </Accordion>

        <Accordion title="Tengo una tienda en línea ¿por qué una orden puede fallar?" className="help-item">
          <p className="help-text">
            <p>
            Las razones más comunes por las cuales un pedido no ha sido aprobado por Slightpay se deben a lo siguiente:
            </p>
            <p>
              <ul>
                <li>
                  El cliente no tiene fondos suficientes en su tarjeta para efectuar el pago del primer 25% de la compra. 
                </li>
                <li>
                  El comprador no ingresa los datos de su tarjeta correctamente.
                </li>
                <li>
                  En el momento de la compra, realizamos un análisis de capacidad de pago de nuestros clientes, si no proporcionó la información completa.
                </li>
                <li>
                  Si nuestro algoritmo de riesgo considera que es un cliente que no asumirá el pago del 75% restante de la compra. 
                </li>
              </ul>
            </p>
          </p>
        </Accordion>
      </div>
    </div>
  );
};

export default Commerces;