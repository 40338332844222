import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  getBasicDataInputMetadata,
  FIRST_NAME_INPUT_NAME,
  LAST_NAME_INPUT_NAME,
  SECOND_LAST_NAME_INPUT_NAME,
  PASSWORD_INPUT_NAME,
  REPEAT_PASSWORD_INPUT_NAME,
  LIGHTPAY_TERMS_POLICY_INPUT_NAME,
  GENDER_INPUT_NAME
} from '../../Auth-inputs-names';
import { getBasicDataFormErrors } from '../../Auth-inputs-validations';
import { IRegisterFormBasicData } from '../../../../../store/auth/types';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../../store/auth/actions';
import { useHistory } from 'react-router-dom';
import { authSelectors } from '../../../../../store/auth/selectors';
import InputBox from '../../../../../components/form-components/input-box/input-box';
import PasswordBox from '../../../../../components/form-components/password-box/password-box';
import Checkbox from '../../../../../components/form-components/checkbox/checkbox';
import MainButton from '../../../../../components/main-button/main-button';
import ReactPixel from 'react-facebook-pixel';
import amplitude from 'amplitude-js'
import SelectBox from '../../../../../components/form-components/select-box/select-box';
import SendApiConversion from '../../../../../constants/apiConversionFb';

const BasicDataRegisterForm: React.FC = () => {
  const history = useHistory();
  const basicData = useSelector(authSelectors.getRegisterFormBasicData());
  const currentEmail = useSelector(authSelectors.getEmail());

  // This should be refactored
  const getFormDefaultValues = () => {
    if (!basicData) {
      if (!currentEmail) history.push('/');
      return {};
    }
    const formData = {
      [FIRST_NAME_INPUT_NAME]: basicData.name,
      [LAST_NAME_INPUT_NAME]: basicData.surname,
      [SECOND_LAST_NAME_INPUT_NAME]: basicData.secondSurname,
      [PASSWORD_INPUT_NAME]: basicData.password,
      [REPEAT_PASSWORD_INPUT_NAME]: basicData.repeatPassword,
      [LIGHTPAY_TERMS_POLICY_INPUT_NAME]: basicData.termsAndCondition,
    };
    return formData;
  };
  // End refactoring

  const { register, handleSubmit, errors, watch, control } = useForm({
    defaultValues: getFormDefaultValues(),
  });
  const dispatch = useDispatch();

  const passwordInput = watch('password');

  const handleForm = async (data: any) => {
    // DEFINE SCHEMA TO FORMS
    const basiData: IRegisterFormBasicData = {
      name: data[FIRST_NAME_INPUT_NAME],
      surname: data[LAST_NAME_INPUT_NAME],
      secondSurname: data[SECOND_LAST_NAME_INPUT_NAME],
      password: data[PASSWORD_INPUT_NAME],
      repeatPassword: data[REPEAT_PASSWORD_INPUT_NAME],
      termsAndCondition: data[LIGHTPAY_TERMS_POLICY_INPUT_NAME],
      gender: data[GENDER_INPUT_NAME]
    };

    try {
      await dispatch(authActions.addRegisterFormBasicData(basiData));
      const name = basiData.name;
      const surname = basiData.surname;
      const secondSurname = basiData.secondSurname;
      await dispatch(
        authActions
          .updateRetargeting(currentEmail, name, surname, secondSurname)
      );
      amplitude.getInstance().logEvent('create account started', {
        name: basiData.name,
        surname: basiData.surname,
        secondSurname: basiData.secondSurname,
        termsAndCondition: basiData.termsAndCondition,
      });
      SendApiConversion('create account started')
      history.push('/registro/informacion-legal');
    } catch (error) {
      amplitude.getInstance().logEvent('error encountered', { 'error_type': 'register basic information', 'error_reason': JSON.stringify(error) });
      console.log(error);
    }
  };

  const validatePassword = (value: string) => {
    return value === passwordInput;
  };

  useEffect(() => {
    if (!localStorage.getItem('noReload')) {
      ReactPixel.trackCustom('Purchase', {});
    }
  }, []);

  return (
    <>
      <div className="form-p">
        <p>
          Introduce los datos que se muestran <span>a continuación</span>
        </p>
      </div>
      <div className="row">
        <InputBox
          className="col-sm-6 form-input"
          labelText="nombre"
          name={FIRST_NAME_INPUT_NAME}
          inputRef={register(getBasicDataInputMetadata(FIRST_NAME_INPUT_NAME))}
          errorCode={getBasicDataFormErrors(errors, FIRST_NAME_INPUT_NAME)}
        />
        <InputBox
          className="col-sm-6 form-input"
          labelText="apellido paterno"
          name={LAST_NAME_INPUT_NAME}
          inputRef={register(getBasicDataInputMetadata(LAST_NAME_INPUT_NAME))}
          errorCode={getBasicDataFormErrors(errors, LAST_NAME_INPUT_NAME)}
        />
        <InputBox
          //secondSurname
          className="col-sm-6 form-input"
          labelText="apellido materno"
          name={SECOND_LAST_NAME_INPUT_NAME}
          inputRef={register(getBasicDataInputMetadata(SECOND_LAST_NAME_INPUT_NAME))}
          errorCode={getBasicDataFormErrors(errors, SECOND_LAST_NAME_INPUT_NAME)}
        />
        <Controller
          as={
            <SelectBox
              className="col-sm-6 form-input"
              name={GENDER_INPUT_NAME}
              optionsArray={['Masculino', 'Femenino']}
              labelText="Género"
            />
          }
          name={GENDER_INPUT_NAME}
          control={control}
        />
        <PasswordBox
          className="col-sm-6 form-input"
          labelText="contraseña"
          name={PASSWORD_INPUT_NAME}
          inputRef={register(getBasicDataInputMetadata(PASSWORD_INPUT_NAME))}
          errorCode={getBasicDataFormErrors(errors, PASSWORD_INPUT_NAME)}
        />
        <PasswordBox
          className="col-sm-6 form-input"
          labelText="repetir contraseña"
          name={REPEAT_PASSWORD_INPUT_NAME}
          inputRef={register(
            getBasicDataInputMetadata(REPEAT_PASSWORD_INPUT_NAME, {
              passwordMissmatch: validatePassword,
            }),
          )}
          errorCode={getBasicDataFormErrors(errors, REPEAT_PASSWORD_INPUT_NAME)}
        />
      </div>
      <div className="row">
        <Checkbox
          inputRef={register(
            getBasicDataInputMetadata(LIGHTPAY_TERMS_POLICY_INPUT_NAME),
          )}
          className="col-sm-12 form-input"
          name={LIGHTPAY_TERMS_POLICY_INPUT_NAME}
          errorCode={getBasicDataFormErrors(
            errors,
            LIGHTPAY_TERMS_POLICY_INPUT_NAME,
          )}
          labelTextOptions={[
            {
              text: 'He leído y acepto las condiciones de uso',
              url: 'https://clientes.slightpay.com/#/terminos-y-condiciones',
            },
            {
              text: 'y la política de privacidad.',
              url: 'https://clientes.slightpay.com/#/politica-de-privacidad',
            },
          ]}
        />
      </div>
      <MainButton
        className="form-button"
        type="primary"
        text="Siguiente"
        onClick={handleSubmit(handleForm)}
      />
    </>
  );
};

export default BasicDataRegisterForm;
