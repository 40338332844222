import React, { useEffect, useRef, useState } from 'react';
import { AccordionStyle } from './accordion-style';
import HeadingText from '../components-text/heading-text/heading-text';

interface IAccordionProps {
  children?: any;
  title: string;
  className?: string;
}

const Accordion: React.FC<IAccordionProps> = (props: IAccordionProps) => {
  const accordionContent: any = useRef(null);
  const accordionDrop: any = useRef(null);
  const [active, setActive] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { children, title, className } = props;

  useEffect(() => {
    if (accordionContent.current && accordionContent.current.offsetHeight && accordionDrop.current) {
      if (active) {
        accordionDrop.current.style.maxHeight = `${accordionContent.current.offsetHeight + 16}px`;
        setTimeout(() => {
          if (accordionContent.current) {
            accordionContent.current.style.opacity = '1';
          }
        }, 300);
      } else {
        setTimeout(() => {
          if (accordionDrop.current) {
            accordionDrop.current.style.maxHeight = '0px';
          }
        }, 300);
        accordionContent.current.style.opacity = '0';
      }
    }
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 300);
  }, [active]);

  useEffect(() => {
    document.addEventListener('resize', () => {
      accordionDrop.current.style.maxHeight = `${accordionContent.current.offsetHeight + 16}px`;
    });
  }, []);

  return (
    <AccordionStyle className={`${className ? className : ''} ${active ? 'active' : ''}`}>
      <div className={`accordion-head ${disabled ? 'disabled' : ''}`} onClick={() => setActive(!active)}>
        <div className="accordion-head__title">
          <HeadingText textType={'h5'}>
            {title}
          </HeadingText>
        </div>
        <div className="accordion-head-icon">
          <div className="accordion-head-icon__top" />
          <div className="accordion-head-icon__bottom" />
        </div>
      </div>
      <div className="accordion-drop" ref={accordionDrop}>
        <div className="accordion-drop-content" ref={accordionContent}>
          {children}
        </div>
        {/* {children} */}
      </div>
    </AccordionStyle>
  );
};

export default Accordion;
