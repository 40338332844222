export const colors : any = {
  'NT—001-000': '#fff',
  'PR––001-30': '#BAC9F0',
  'PR––001-10': '#E8EDFA',
  'PR––001-50': '#8CA5E6',
  'PR––001-100': '#1A4CCD',
  'PR––001-5': '#F4F6FC',
  'SC–003-100': '#0A1971',
  'SC–003-80': '#3B478D',
  'SC–003-50': '#848CB8',
  'SC–003-30': '#B6BAD4',
  'SC–003-10': '#E7E8F1',
  'SC–002-80': '#39406A',
  'SC–002-50': '#8387A2',
  'SC–002-30': '#B5B7C7',
  'SC–002-10': '#E6E7EC',
  'SC–002-100': '#071045',
  'SC–001-80': '#34353D',
  'SC–001-90': '#1A1C24',
  'SC–001-50': '#808185',
  'SC–001-40': '#999A9E',
  'SC–001-70': '#4D4F55',
  'SC–001-60': '#67686D',
  'SC–001-30': '#B3B3B6',
  'SC–001-20': '#CCCDCE',
  'SC–001-10': '#E6E6E7',
  'SC–001-5': '#F2F2F3',
  'SC––004-50': '#E49CBB',
  'AL–001-10': '#FFF8EB',
  'ER–001-10': '#FAEBF1',
  'SC–001-100': '#01030C',
  'ER–001-80': '#D56191',
  'ER–001-50': '#E49CBB',
  'ER–001-30': '#EFC4D6',
  'ER–001-100': '#CA3976',
  'AL–001-80': '#FFC85E',
  'AL–001-50': '#FFDD9B',
  'AL–001-30': '#FFEBC3',
  'AL–001-100': '#FFBB36',
  'SU–001-80': '#72C1D1',
  'SU–001-50': '#A7D8E2',
  'SU–001-30': '#CAE8EE',
  'SU–001-10': '#EDF7F9',
  'SU–001-100': '#4FB2C6',
  'FL-001-Default': '#FFFFFF',
  'FL-001-Disabled': '#F7F7F7',
  'NT–001-100': '#01030C',
  'NT–002-100': '#FFFFFF',
  'TX–001-100': '#01030C',
  'TX–001-80': '#34353D',
  'TX–001-50': '#808185',
  'TX–001-30': '#B3B3B6',
  'TX–001-15': '#D9D9DB',
  'SC–005-80': '#72C1D1',
  'SC–005-50': '#A7D8E2',
  'SC–005-30': '#CAE8EE',
  'SC–005-10': '#EDF7F9',
  'SC–005-100': '#4FB2C6',
  'SC–004-80': '#D56191',
  'SC–004-50': '#E49CBB',
  'SC–004-30': '#EFC4D6',
  'SC–004-10': '#FAEBF1',
  'SC–004-100': '#CA3976',
  'HV––Text': '#112F7C',
  'HV—TextLink': '#B23369',
  'HV—Field': '#EAEAEA',
  'HV—Error': '#B83737',
  'HV—Alert': '#F0AF31',
  'HV—Success': '#46B45E',
};
