import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { STATES } from '@aluxion-lightpay/interfaces';
import { selectCity } from '../../../../../utils/selectCity';
import { authActions } from '../../../../../store/auth/actions';
import {
  BIRTHDATE_INPUT_NAME,
  STATE_INPUT_NAME,
  CITY_INPUT_NAME,
  CP_INPUT_NAME,
  STREET_INPUT_NAME,
  RFC_INPUT_NAME,
  INE_INPUT_NAME,
  COLONY_INPUT_NAME,
  getPersonalDataInputMetadata,
  PHONE_NUMBER_INPUT_NAME,
} from '../../Auth-inputs-names';
import { getLegalDataFormErrors } from '../../Auth-inputs-validations';
import { IRegisterFormLegalData } from '../../../../../store/auth/types';
import { authSelectors } from '../../../../../store/auth/selectors';
import { isValidImage, isValidSize } from '../../../../../utils/validation';
import CalendarBox from '../../../../../components/calendar-box';
import { IDefaultAttachImagesObjType } from '../../../../../components/form-components/attach/doble-image-attach/doble-image-attach-types';
import InputBox from '../../../../../components/form-components/input-box/input-box';
import Attach from '../../../../../components/form-components/attach/attach';
import SelectBox from '../../../../../components/form-components/select-box/select-box';
import MainButton from '../../../../../components/main-button/main-button';
import CustomAlert from '../../../../../components/custom-alerts/alert';
import moment from 'moment';
import ModalInfo from '../../../../../components/modals/modal-info/modal-info';
import { modalSelector } from '../../../../../store/modal/selectors';
import { modalActions } from '../../../../../store/modal/actions';
import ReactPixel from 'react-facebook-pixel';
import amplitude from 'amplitude-js'
import SendApiConversion from '../../../../../constants/apiConversionFb';

const LegalDataRegisterForm: React.FC = () => {
  const history = useHistory();
  const legalData = useSelector(authSelectors.getRegisterFormLegalData());
  const basicData = useSelector(authSelectors.getRegisterFormBasicData());
  const formDataError = useSelector(authSelectors.getRegisterError());
  const isModal = useSelector(modalSelector.isShowing());
  const [disabled, setDisabled] = useState(true)
  const [bdate, setBirthDate] = useState("");
  let [hasErrorRFC, setHasErrorRFC] = useState<string>('');
  let [colonies, setColonies] = useState<any>([]);
  let maxlen: number = 30;

  // This should be refactored
  const store = useStore();
  const getFormDefaultValues = () => {
    if (!legalData) {
      if (!basicData) history.push('/registro/informacion-basica');
      return {};
    }
    const formData = {
      [RFC_INPUT_NAME]: legalData.rfc,
      [CP_INPUT_NAME]: legalData.cp,
      [COLONY_INPUT_NAME]: legalData.colony,
      [CITY_INPUT_NAME]: legalData.municipality,
      [STREET_INPUT_NAME]: legalData.street,
      [STATE_INPUT_NAME]: legalData.state,
      [BIRTHDATE_INPUT_NAME]: legalData.birthDate,
      [PHONE_NUMBER_INPUT_NAME]: legalData.phoneNumber,
    };
    return formData;
  };

  const { register, handleSubmit, errors, watch, control, setValue } = useForm({ defaultValues: getFormDefaultValues() });
  const dispatch = useDispatch();
  const name = store.getState().auth.registerForm?.basicData?.name;
  const passwordInput = useSelector(authSelectors.getPassword());

  const cpInput = watch(CP_INPUT_NAME) as string;

  let callBackCP = useCallback(async (cp = '0') => {
    if (cp.length === 5) {
      let result = await authActions.getDataPostalCode(cp);

      let arrayColony: any = []
      result.map((colony: any) => {
        arrayColony.push(colony?.NomAsentamiento);
      });

      console.log(arrayColony, 'enviado a setColonies')
      setColonies(arrayColony)

      setValue(STATE_INPUT_NAME, result[0]?.CatMunicipio?.CatEstado?.NomEstado);
      setValue(CITY_INPUT_NAME, result[0]?.CatMunicipio?.NomMunicipio);
      if (arrayColony.length === 0) {
        console.log('si es 0')
        setDisabled(false);
        // setValue(STATE_INPUT_NAME, result[0]?.CatMunicipio?.CatEstado?.NomEstado);
        // setValue(CITY_INPUT_NAME, result[0]?.CatMunicipio?.NomMunicipio);
      } else {
        setDisabled(true);
        setValue(STATE_INPUT_NAME, result[0]?.CatMunicipio?.CatEstado?.NomEstado);
        setValue(CITY_INPUT_NAME, result[0]?.CatMunicipio?.NomMunicipio);
      }
    }
  }, []);

  //Verificar el RFC con el dia de nacimiento
  const RFCInput = watch(RFC_INPUT_NAME) as string;

  const BDRFC = useCallback ((rfc : string = '0') =>{
    if (rfc.length === 13) {
      //RFCInput.valueOf();
      let dateRfc = rfc.substr(4, 6)
      let year = Number(dateRfc.substr(0, 2)) <= 99 && Number(dateRfc.substr(0, 2)) >= 21 ? String(1900 + Number(dateRfc.substr(0, 2))) : String(2000 + Number(dateRfc.substr(0, 2)))
      let month = dateRfc.substr(2, 2)
      let day = dateRfc.substr(4, 2)
      let bd =`${day}/${month}/${year}`;
      console.log(bd);
      setBirthDate(bd);
    } else if(rfc.length === 12) {
      let dateRfc = rfc.substr(3, 6)
      let year = Number(dateRfc.substr(0, 2)) <= 99 && Number(dateRfc.substr(0, 2)) >= 21 ? String(1900 + Number(dateRfc.substr(0, 2))) : String(2000 + Number(dateRfc.substr(0, 2)))
      let month = dateRfc.substr(2, 2)
      let day = dateRfc.substr(4, 2)
      let bd =`${day}/${month}/${year}`;
      console.log(bd);
      setBirthDate(bd);
    }
  },[]);

  useEffect(() => {
    callBackCP(cpInput)
  }, [cpInput]);

  useEffect(()=> {
    BDRFC(RFCInput);
    console.log("renderizado");
  },[RFCInput]);

  const cityInput = watch(STATE_INPUT_NAME) as any; // Refact with better typing
  const city = selectCity(cityInput);



  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };

  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };

  const handleForm = async (data: any) => {
    // DEFINE SCHEMA TO FORMS
    console.log(data);
    const legalData: IRegisterFormLegalData = {
      rfc: data[RFC_INPUT_NAME],
      cp: data[CP_INPUT_NAME],
      colony: data[COLONY_INPUT_NAME],
      municipality: data[CITY_INPUT_NAME],
      street: data[STREET_INPUT_NAME],
      state: data[STATE_INPUT_NAME],
      birthDate: bdate,
      phoneNumber: data[PHONE_NUMBER_INPUT_NAME].replace(/\s/g, ''),
    };
    console.log("Legal data", legalData);

    try {
      // DEFINE SCHEMA TO FORMS
      console.log(data);
      const legalData: IRegisterFormLegalData = {
        rfc: data[RFC_INPUT_NAME],
        cp: data[CP_INPUT_NAME],
        colony: data[COLONY_INPUT_NAME],
        municipality: data[CITY_INPUT_NAME],
        street: data[STREET_INPUT_NAME],
        state: data[STATE_INPUT_NAME],
        birthDate: bdate,
        phoneNumber: data[PHONE_NUMBER_INPUT_NAME].replace(/\s/g, ''),
      };
      amplitude.getInstance().logEvent('account profile submitted');

      setHasErrorRFC('')

      try {
        await dispatch(authActions.addRegisterFormLegalData(legalData));
        amplitude.getInstance().logEvent('account profile validated', { 'type': 'frontend validation' });
        openModal('authorization');
      } catch (error) {
        if (error.message !== 'El RFC no es válido, por favor verifiquelo y vuelva a intentar.') amplitude.getInstance().logEvent('error encountered', { 'error_type': 'create account', 'error_reason': JSON.stringify(error) });
        console.log(error);
      }
    } catch (error) {
      setHasErrorRFC('invalid-date-rfc')
      amplitude.getInstance().logEvent('error encountered', { 'error_type': 'create account', 'error_reason': 'La fecha de nacimiento no coincide con el RFC' });
    }
  };

  const handleAuthorization = async (data: any) => {
    try {
      await dispatch(authActions.registerUser());
      await dispatch(authActions.sendBuroDeCredito());
      amplitude.getInstance().logEvent('credit score consent');
      amplitude.getInstance().logEvent('account created', { 'account_type': 'shopper', 'location': 'clientes.slightpay' });
      SendApiConversion('Client_Lead')
      // openModal('SmsCode');
      history.push('/registro/confirmacion-sms');
    } catch (error) {
      amplitude.getInstance().logEvent('error encountered', { 'error_type': 'create account', 'error_reason': JSON.stringify(error) });
      openModal('error');
    }
  }

  const validateIneIfUploaded = () => {
    const currentIneStatus = store.getState().auth.registerForm?.legalData?.ine;
    if (!currentIneStatus) return false;
    if (!currentIneStatus.front || !currentIneStatus.back) return false;
    if (!isValidImage(currentIneStatus.front)) return false;
    if (!isValidImage(currentIneStatus.back)) return false;
    if (!isValidSize(currentIneStatus.front)) return false;
    if (!isValidSize(currentIneStatus.back)) return false;

    return true;
  };

  // Encapsular junto con la del signup
  const handleIneAdded = (img: any) => {
    const images: IDefaultAttachImagesObjType = img[0];
    const ine = {
      front: images.ImgIneFront.fakeUrl,
      back: images.ImgIneBack.fakeUrl,
    };

    dispatch(authActions.addIneImages(ine));
  };
  // Fin del encapsular

  useEffect(() => {
    localStorage.setItem('noReload', 'true');
    ReactPixel.trackCustom('Add Payment Info', {});
  }, []);

  return (
    <>
      <div className="form-scroll">
        {formDataError?.message && <CustomAlert text={formDataError.message || ''} showIcon type="error" />}
        <div className="form-p">
          <p>{`Bienvenido ${name}, rellena los siguientes datos para completar tu perfil`}.</p>
        </div>
        <div className="row">
          <InputBox
            className="col-sm-12 form-input"
            labelText="rfc"
            name={RFC_INPUT_NAME}
            inputRef={register(getPersonalDataInputMetadata(RFC_INPUT_NAME))}
            errorCode={getLegalDataFormErrors(errors, RFC_INPUT_NAME) || hasErrorRFC}
            rightLabel={{
              onClick: () => {
                window.open('https://www.mi-rfc.com.mx/consulta-rfc-homoclave', '_blank')?.focus()
              },
              text: 'Consultar mi RFC'
            }}
          />

          {/* <Controller
            as={
              <Attach
                className="col-sm-12 form-input"
                labelText="ine"
                name={INE_INPUT_NAME + 'inner'}
                // initialValue={form.ineFront.value || form.ineBack.value}
                errorCode={getLegalDataFormErrors(errors, INE_INPUT_NAME)}
              />
            }
            onChange={(e: any) => handleIneAdded(e)}
            name={INE_INPUT_NAME}
            control={control}
            rules={getPersonalDataInputMetadata(INE_INPUT_NAME, {
              ineNotUploaded: validateIneIfUploaded,
            })}
          /> */}

          <InputBox
            className="col-sm-12 form-input"
            labelText="calle y número"
            name={STREET_INPUT_NAME}
            inputRef={register(getPersonalDataInputMetadata(STREET_INPUT_NAME))}
            errorCode={getLegalDataFormErrors(errors, STREET_INPUT_NAME)}
            maxLength={maxlen}
          />
          <Controller
            as={
              <InputBox
                className="col-sm-12 form-input"
                labelText="Nº de Télefono"
                initialValue="+52"
                name={PHONE_NUMBER_INPUT_NAME}
                errorCode={getLegalDataFormErrors(errors, PHONE_NUMBER_INPUT_NAME)}
                cleaveFormat={{
                  phone: true,
                  prefix: '+',
                  phoneRegionCode: 'MX',
                }}
                placeholder="+52"
              />
            }
            name={PHONE_NUMBER_INPUT_NAME}
            control={control}
            rules={getPersonalDataInputMetadata(PHONE_NUMBER_INPUT_NAME)}
          />
        </div>
        <div className="row">
          <InputBox
            className="col-sm-6 form-input"
            labelText="Código Postal"
            name={CP_INPUT_NAME}
            inputRef={register(getPersonalDataInputMetadata(CP_INPUT_NAME))}
            errorCode={getLegalDataFormErrors(errors, CP_INPUT_NAME)}
          />
          {disabled ?
            <Controller
              as={
                <SelectBox
                  className="col-sm-6 form-input"
                  name={COLONY_INPUT_NAME + 'inner'}
                  optionsArray={colonies}
                  labelText="Colonia"
                  disabled={!cpInput}
                />
              }
              name={COLONY_INPUT_NAME}
              control={control}
            // rules={getPersonalDataInputMetadata(COLONY_INPUT_NAME)}
            /> :
            <InputBox
              className="col-sm-6 form-input"
              labelText="Colonia"
              name={COLONY_INPUT_NAME}
              inputRef={register(getPersonalDataInputMetadata(COLONY_INPUT_NAME))}
              errorCode={getLegalDataFormErrors(errors, COLONY_INPUT_NAME)}
            />}
          {/* COLONIAS */}
          {/* <InputBox
            className="col-sm-6 form-input"
            labelText="colonia"
            name={COLONY_INPUT_NAME}
            inputRef={register(getPersonalDataInputMetadata(COLONY_INPUT_NAME))}
            errorCode={getLegalDataFormErrors(errors, COLONY_INPUT_NAME)}
            disabled={!cpInput}
          /> */}
          {/* <Controller
            as={
              <SelectBox
                className="col-sm-6 form-input"
                name={COLONY_INPUT_NAME + 'inner'}
                optionsArray={colonies}
                labelText="colonia"
                disabled={!cpInput}
              />
            }
            name={COLONY_INPUT_NAME}
            control={control}
          // rules={getPersonalDataInputMetadata(COLONY_INPUT_NAME)}
          /> */}

          {
            disabled ?
              <InputBox
                className="col-sm-6 form-input"
                name={STATE_INPUT_NAME}
                labelText="estado"
                inputRef={register(getPersonalDataInputMetadata(STATE_INPUT_NAME))}
                errorCode={getLegalDataFormErrors(errors, STATE_INPUT_NAME)}
                disabled
              />
              :
              <Controller
                as={
                  <SelectBox
                    className="col-sm-6 form-input"
                    name={STATE_INPUT_NAME + 'inner'}
                    optionsArray={STATES}
                    labelText="estado"
                  />
                }
                name={STATE_INPUT_NAME}
                control={control}
              />
          }

          {
            disabled ?
              <InputBox
                className="col-sm-6 form-input"
                name={CITY_INPUT_NAME}
                labelText={'Municipio'}
                inputRef={register(getPersonalDataInputMetadata(CITY_INPUT_NAME))}
                errorCode={getLegalDataFormErrors(errors, CITY_INPUT_NAME)}
                disabled
              />
              :
              <Controller
                as={
                  <SelectBox
                    className="col-sm-6 form-input"
                    name={CITY_INPUT_NAME + 'inner'}
                    optionsArray={city && city.sort((a: string, b: string) => a.localeCompare(b))}
                    labelText={cityInput === 'Ciudad de México' ? 'DELEGACIÓN / MUNICIPIO' : 'Municipio'}
                    errorCode={getLegalDataFormErrors(errors, CITY_INPUT_NAME)}
                  />
                }
                name={CITY_INPUT_NAME}
                control={control}
                rules={getPersonalDataInputMetadata(CITY_INPUT_NAME)}
              />
          }
        </div>
        {/* <div className="row">
          <Controller
            as={
              <CalendarBox
                onChange={value => {
                  console.log(value);
                }}
                className="col-sm-12 form-input"
                initialValue={''}
                labelText="Fecha de nacimiento"
                placeholder="día/mes/año"
                errorCode={getLegalDataFormErrors(errors, BIRTHDATE_INPUT_NAME)}
                minAge={18}
                maxAge={83}
              />
            }
            onChange={data => {
              return data[0];
            }}
            name={BIRTHDATE_INPUT_NAME}
            control={control}
            rules={getPersonalDataInputMetadata(BIRTHDATE_INPUT_NAME)}
          />
        </div> */}
        <MainButton className="form-button" type="primary" text="Siguiente" onClick={handleSubmit(handleForm)} />
      </div>
      <ModalInfo
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'authorization') || false,
        }}
        password={passwordInput}
        title={'Antes de formalizar tu registro...'}
        text="Por este conducto autorizo expresamente a YUBO SAPI DE CV, para que
                por conducto de sus funcionarios facultados lleve a cabo Investigaciones, sobre mi
                comportamiento Crediticio en las Sociedades de Información Crediticia que estime conveniente."

        text2="Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará,
                del use que YUBO SAPI DE CV hará de tal información y de que ésta podrá realizar
                consultas periódicas de mi historial crediticio, consintiendo que esta autorización se encuentre
                vigente por un período de 3 años contados a partir de la fecha de su expedición y en todo caso durante 
                el tiempo que mantegamos relación jurídica."

        onClick={handleSubmit(handleAuthorization)}
      />
    </>
  )
};

export default LegalDataRegisterForm;
