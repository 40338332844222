import styled from 'styled-components/macro';

export const SignupStyle = styled.div`
  .form-button {
    width: 13.25rem;
    margin: 0 auto;
  }

  .form-scroll {
    /* overflow-y: scroll;
    text-align: left;
    height: calc(100vh - 500px); */
    /* Hide scrollbar for Chrome, Safari and Opera  */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE and Edge  */
    & {
      -ms-overflow-style: none;
    }
  }

  .form-ul {
    margin-top: 1.875rem;
    li {
      position: relative;
      display: flex;
      justify-content: space-between;
      p {
        font-weight: 500;
      }
      &:before {
        position: absolute;
        content: '';
        border-radius: 100%;
        width: 6px;
        height: 6px;
        left: -12px;
        top: 5px;
        background-color: #1a4ccd;
      }
    }
  }
`;
