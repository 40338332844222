import styled from 'styled-components/macro';

const CardBasicAsideContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(1, 3, 12, 0.05);
    border-radius: 3px;
    display: flex; 
    align-items: center;  
    padding:60px 33px; 
    cursor:pointer;

    .card-basic-aside-icon {
        background: #1A4CCD;
        border-radius: 100%; 
        min-width: 70px;
        width: 70px; 
        height: 70px; 
        margin-right: 20px; 
        display: flex; 
        align-items: center; 
        justify-content: center; 

        img {
            width: 60%; 
            height: auto; 
        }
    }

    .card-basic-aside-title {
        font-family: "Gilroy";
        font-size: 20px;
        line-height: 26px;
        color: #000000;
    }

    .card-basic-aside-description {
        font-family: "Gilroy";
        font-size: 14px;
        line-height: 22px;
        color: #B3B3B6;
    }

    


`;

export default CardBasicAsideContainer;