import React from 'react';

// Styles
import CardUserContainer from './card-user-style';

// Types
import { ICardUserProps } from './card-user-types';

const CardUser: React.FC<ICardUserProps> = (props: ICardUserProps) => {
  const { className, img, title, info, infoTelephone, id } = props;

  return (
    <CardUserContainer className={className}>
      { img && 
        <div className="img">
          <img src={img} alt="Card user art" />
        </div>
      }
      <div className="title">{title}</div>
      {info && info.map((info, index) => {
        return <div key={index} className="info_email">{info}</div>
      })}
      <div className="info_telephone">{infoTelephone}</div>
      {id &&
        <div className="button">ID. <span className="title">{` ${id}`}</span></div>
      }
    </CardUserContainer>
  );
};

export default CardUser;
