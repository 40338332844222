import produce from 'immer';

import { AuthActions, IAuthState, IAuthReducer } from './types';
import { urlToFile } from '../../utils/utils';

// Move to other file
const checkForDataOnLocalstorage = (blockName: string): IAuthState => {
  let tempState: IAuthState;
  // Think a more abstract solution
  const defaultState: IAuthState = {
    loggingError: null,
    registerError: null,
    checkEmailError: null,
    user: null,
    token: null,
    registerForm: { ineModal: { isOpen: false } },
    recoverPasswordError: null,
    resetPasswordError: null,
    userProductionFormData: null,
  };
  try {
    if (localStorage.getItem(blockName)) tempState = JSON.parse(localStorage.getItem(blockName) as string);
    else tempState = defaultState;
  } catch (error) {
    tempState = defaultState;
  }

  const initialState: IAuthState = {
    ...tempState,
    token: localStorage.getItem('token'),
  }; // Refact this and use a redux action
  return initialState;
};

// End move to other file

// FOR IMAGES:
const checkForImages = (initialState: IAuthState) => {
  const localImages = JSON.parse(localStorage.getItem('localImages') as string);
  let { registerForm, user } = initialState;
  if (localImages) {
    if (localImages.ine) {
      registerForm = {
        ...registerForm,
        legalData: {
          ...registerForm?.legalData,
          ine: {
            front: urlToFile(localImages.ine.front, 'ine-front.png'),
            back: urlToFile(localImages.ine.back, 'ine-back.png'),
          }
        },
      };
    }

    if (localImages.userImage) {
      user = {
        ...user,
        image: urlToFile(localImages.userImage.profileImage, 'accountImage'),
      };
    }
  }

  return [registerForm, user];
};


export const initialState: IAuthState = checkForDataOnLocalstorage('AuthState');
const [ registerForm, user ] = checkForImages(initialState) as any;
const initialState2: IAuthState = { ...initialState, registerForm, user };


export const saveStoreInLocalstorage = (blockName: string, data: IAuthState) => {
  localStorage.setItem(blockName, JSON.stringify(data));
};

const authReducer: IAuthReducer = (state = initialState2, action) =>
  produce(state, draft => {
    switch (action.type) {
      case AuthActions.REGISTER_FORM_INE_MODAL_OPEN:
        if (!draft.registerForm) draft.registerForm = {};
        if (!draft.registerForm.ineModal) draft.registerForm.ineModal = {};
        draft.registerForm.ineModal.isOpen = true;
        break;

      case AuthActions.REGISTER_FORM_INE_MODAL_CLOSE:
        if (!draft.registerForm) draft.registerForm = {};
        if (!draft.registerForm.ineModal) draft.registerForm.ineModal = {};
        draft.registerForm.ineModal.isOpen = false;
        break;

      case AuthActions.REGISTER_FORM_INE_MODAL_DATA_ADDED:
        if (!draft.registerForm) draft.registerForm = {};
        if (!draft.registerForm.legalData) draft.registerForm.legalData = {};
        draft.registerForm.legalData.ine = action.payload;
        break;

      case AuthActions.REGISTER_FORM_BASIC_DATA_ADDED:
        if (!draft.registerForm) draft.registerForm = {};
        draft.registerForm.basicData = action.payload;
        break;

      case AuthActions.REGISTER_FORM_LEGAL_DATA_ADDED:
        if (!draft.registerForm) draft.registerForm = {};
        draft.registerForm.legalData = action.payload;
        break;

      // case AuthActions.REGISTER_FORM_EXTRA_DATA_ADDED:
      //   if (!draft.registerForm) draft.registerForm = {};
      //   draft.registerForm.extraData = action.payload;
      //   break;

      case AuthActions.REGISTER_FORM_COMERCIAL_DATA_ADDED:
        if (!draft.registerForm) draft.registerForm = {};
        draft.registerForm.comercialData = action.payload;
        break;

      case AuthActions.REGISTER_USER_SUCCESS:
        if (!draft.registerForm) draft.registerForm = {};
        draft.user = action.payload;

        draft.registerError = null;
        draft.registerForm = null;
        break;

      case AuthActions.REGISTER_USER_FAIL:
        if (!draft.registerForm) draft.registerForm = {};
        draft.registerError = action.error;
        break;

      case AuthActions.EMAIL_CHECKED_SUCCESS:
        if (!draft.registerForm) draft.registerForm = {};
        draft.registerForm.email = action.payload;
        draft.checkEmailError = null;
        break;

      case AuthActions.EMAIL_CHECKED_FAIL:
        draft.checkEmailError = action.error;
        if (!draft.registerForm) draft.registerForm = {};
        draft.registerForm.email = action.payload;
        break;
        
      case AuthActions.VERIFY_EMAIL:
        if (draft.user) draft.user.emailVerified = true;
        break;

      case AuthActions.LOGIN_SUCCESS:
        draft.user = action.payload;
        draft.registerError = null;
        draft.registerForm = null;
        draft.loggingError = null;
        break;

      case AuthActions.LOGIN_FAIL:
        draft.loggingError = action.error;
        break;

      case AuthActions.SET_PROFILE_IMAGE:
        if (!draft.user) draft.user = {};
        draft.user.image = action.payload;
        break;

      case AuthActions.SET_JWT_TOKEN:
        draft.token = action.payload;
        break;

      case AuthActions.RECOVER_PASSWORD:
        break;

      case AuthActions.RECOVER_PASSWORD_FAIL:
        draft.recoverPasswordError = action.error;
        break;

      case AuthActions.RESET_PASSWORD:
        break;

      case AuthActions.RESET_PASSWORD_FAIL:
        draft.recoverPasswordError = action.error;
        break;

      case AuthActions.UPDATE_PHONE_NUMBER:
        if (!draft.user) draft.user = {};
        draft.user.phoneNumber = action.payload;
        break;

      case AuthActions.LOGOUT:
        draft.user = null;
        draft.token = null;
        break;

      case AuthActions.SET_SCORING_RESULT:
        draft.scoringResult = action.payload;
        break;  

      default:
    }
    saveStoreInLocalstorage('AuthState', draft);
  });

export default authReducer;
