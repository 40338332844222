import styled from "styled-components";
import { InputColors } from "../../../assets/colors/input-colors/input-colors";

export const ModalBankDataStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out all;
  background: white;
  z-index: 1000;
  scrollbar-width: none;
  background: rgba(242, 242, 243, 0.98);
  background-color: #0000008a;
  visibility: visible;
  opacity: 1;
  overflow-y: scroll;
  align-items: flex-start;
  .content{
    margin: auto;
    background-color: ${props=> props.color};
    width: 40%;
    height: 80%;
    min-height: 620px;
  }
  @media (max-width: 1216px) {
    .content{
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    .content{
      width: 320px;
    }
  }
`;

export const Step4Styles = styled.div`
  display: inline-grid;
  width: 100%;
  height: 100%;
  .grid-container {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    grid-template-rows: 0.4fr 0.25fr 1.65fr 0.25fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "back . close"
      ". oops ."
      ". img-error ."
      ". text ."
      ". btn .";
  }
  .back { margin: auto; grid-area: back; }
  .close { margin: auto; grid-area: close; }
  .oops { margin: auto; grid-area: oops; }
  .img-error { margin: auto; grid-area: img-error; }
  .text { margin: auto; grid-area: text; }
  .btn { grid-area: btn; padding-top: 20px; padding-top: 20px; margin-left: 20%; margin-right: 20%; }
`;

export const Step2Styles = styled.div`
  display: inline-grid;
  width: 100%;
  height: 100%;
  .grid-container {
    padding-left: 50px;
    padding-right: 50px;
    display: grid;
    grid-template-columns: 0.5fr 3fr 0.5fr;
    grid-template-rows: 0.5fr 1.5fr 0.2fr 0.2fr auto;
    gap: 0px 0px;
    grid-template-areas:
      "back . close"
      ". forms ."
      ". keys ."
      ". main-btn ."
      ". imageInfo .";
  }
  .back { margin: auto; grid-area: back; z-index:1}
  .close { margin: auto; grid-area: close; z-index:1}
  .closeBank{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 4rem;
  }
  .imgCloseBank{
    cursor: pointer;
    width: 15px;
    height: 15px;
    padding-top: 0px;
    margin-top: 5rem;
  }
  .logo { margin: auto; grid-area: logo; }
  .imgInfo{
    margin: auto; 
    grid-area: imageInfo; 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:1rem;
  }
  .imgInfoBank{
    width: 200px;
    height: 65px;
  }
  .keys{
    grid-area: keys;
    font-weight: 300;
    color: #1a4ccd;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  .forms {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: .3fr 1.5fr;
    gap: 0px 0px;
    grid-template-areas:
      "bank-logo"
      "contentform";
    grid-area: forms;
    margin-top:-2rem;
    @media (max-width: 956px){
      margin-top: -4rem;
    }
  }
  
  .contentBg{
    display: grid;
    grid-template-columns: .1fr 1fr .1fr;
    grid-template-rows: 1.3fr 1.2fr 1.2fr;
    grid-template-areas:
      "title title title"
      ". input-1 ."
      ". input-2 .";
    grid-area:contentform;
    background-color: #F7F7F7;
    border-radius:7px;
  }
  .descriptionModalBank{
    @media (max-width: 768px) {
      font-size: 15px;
    }
  }
  .whereKeys{
    height: 55%;
    width: 390px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin-top: -8rem;
    @media (min-width: 1500px){
      height: 53%;
      width: 550px;
    }
  }

  .contentGif{
    display: none;
  }
  .keys:active, .keys:hover {
    background-color: #F7F7F7;
    .contentGif{
      display: grid;
      grid-template-columns: .1fr 1fr .1fr;
      grid-template-areas: '. gifImage .';
      grid-area:contentform;
      background-color: #F7F7F7;
      border-radius:7px;
      align-items: center;
      justify-items: center;
    }
  }
  .bank-logo { grid-area: bank-logo; margin: auto; }
  .title { grid-area: title;margin-top:1rem; padding: 0 25px;}
  .label-1 { grid-area: label-1; }
  .input-1 { grid-area: input-1; }
  .label-2 { grid-area: label-2; }
  .input-2 { grid-area: input-2; }
  .btnConectar{
    font-weight: 400;
    border-radius: 15px;
  }
  .infoOne{
    width: 280px;
    @media (max-width: 768px){
      width: 215px;
    }
  }
  .infoTwo{
    width: 210px;
    @media (max-width: 768px){
      width: 150px;
    }
  }
  .main-btn { 
    grid-area: main-btn;
    padding-left: 10%;
    padding-right: 10%;
  }
  @media (max-width: 768px) {
    .grid-container {
      padding-left: 0px;
      padding-right: 0px;
    }
    .main-btn { 
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

export const Step5Styles = styled.div`
  display: inline-grid;
  width: 100%;
  height: 100%;
  .grid-container {
    display: grid;
    grid-template-columns: 0.5fr 3fr 0.5fr;
    grid-template-rows: 0.3fr 0.25fr 1.75fr 0.5fr;
    gap: 0px 0px;
    grid-template-areas:
      "back . close"
      ". logo ."
      ". texts ."
      ". main-btn .";
  }
  .back { margin: auto; grid-area: back; }
  .close { margin: auto; grid-area: close; }
  .logo { margin: auto; grid-area: logo; }
  .texts { grid-area: texts; }
  .main-btn { grid-area: main-btn; }
  .largeText{
    @media (min-width: 1300px){
      font-size:18px;
    }
    @media (min-width: 1500px){
      font-size:20px;
    }
  }
`;
export const Step1Styles = styled.div`
  display: inline-grid;
  width: 100%;
  height: 100%;
  .grid-container {
    display: grid;
    grid-template-columns: 0.5fr 3fr 0.5fr;
    grid-template-rows: 0.5fr 0.15fr 0.15fr 3fr 0.25fr 0.25fr;
    gap: 0px 0px;
    grid-template-areas:
      "back . close"
      ". bankCredential ."
      ". title ."
      ". banks-list ."
      ". question ."
      ". click-here .";
  }
  .back { margin: auto; grid-area: back;  }
  .close { margin: auto; grid-area: close; }
  .credentials{grid-area: bankCredential; text-align: center; font-weight: 300; margin-top: -2rem;}
  .title {  
    grid-area: title; 
    text-align: center; 
    font-weight: bold; 
    @media (max-width: 768px) {
      margin-top: .5rem;
    }
  }
  .banks-list { 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-area:banks-list;
    grid-gap: 8px;
    margin-top: 8px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      grid-gap: .2rem;
      margin-top: .5rem;
    }
  }
  .question { grid-area: question; margin-top:1rem;}

  .click-here { grid-area: click-here;}
  
  .bank-item{
    cursor: pointer;
  }
  .contentImg{
    width: 125px;
    height: 75px;
    @media (max-width: 768px) {
    width: 80px;
    height: 50px;
    }
  }
  .defaultBank{
    width:120px;
    height:68px;
    background-color: #1A4CCD;
    border-radius: 20px;
    @media (max-width: 768px) {
      border-radius: 10px;
      width: 80px;
      height: 50px;
      font-size: 10px;
    }
  }
  .heybanco{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .imgBank:hover{
    border-radius: 20px;
    @media (max-width: 768px) {
      border-radius: 10px;
    }
  }

  .americanexpress:hover{
    box-shadow: rgba(47,156,255,0.45) -4px 8px 4px;
  }
  .banorte:hover{
    box-shadow: rgba(226, 10, 41,0.45) -4px 8px 4px;
  }
  .santander:hover{
    box-shadow: rgba(226, 10, 41,0.45) -4px 8px 4px;
  }
  .citibanamex:hover{
    box-shadow: rgba(0,92,176,0.45) -4px 8px 4px;
  }
  .bancoazteca:hover{
    box-shadow: rgba(23, 102, 64,0.45) -4px 8px 4px;
  }
  .banregio:hover{
    box-shadow: rgba(255, 103, 27,0.45) -4px 8px 4px;
  }
  .invex:hover{
    box-shadow: rgba(164, 1, 51,0.45) -4px 8px 4px;
  }
  .scotiabank:hover{
    box-shadow: rgba(236,17,26,0.45) -4px 8px 4px;
  }
  .bancoppel:hover,.defaultBank:hover{
    box-shadow: rgba(47,156,255,0.45) -4px 8px 4px;
  }
  .bbvabancomer:hover{
    box-shadow: rgba(0,92,176,0.45) -4px 8px 4px;
  }
  .liverpool:hover{
    box-shadow: rgba(225, 18, 152,0.45) -4px 8px 4px;
  }
  .heybanco:hover{
    box-shadow: rgba(13, 13, 13, 0.45) -4px 8px 4px;
  }


  @media (max-width: 834px) {
    .bank-item{
      margin: 2px;
    }
  }
`;

export const Step0Styles = styled.div`
  display: inline-grid;
  width: 100%;
  height: 100%;
  .grid-container {
    display: grid;
    grid-template-columns: 0.5fr 3fr 0.5fr;
    grid-template-rows: 0.4fr 0.25fr 1.4fr 0.5fr 0.4fr 2fr;
    gap: 0px 0px;
    grid-template-areas:
      "back . close"
      ". logo ."
      ". text ."
      ". save ."
      ". private ."
      ". main-btn .";
  }

  .back { margin: auto; grid-area: back; }
  .close { margin: auto; grid-area: close; }
  .logo { margin: auto; grid-area: logo; }
  .text { margin: auto; grid-area: text; 
    @media (max-width: 540px) {
      font-size: 14px;
    } }
  .save { 
    display: grid;
    grid-template-columns: 0.2fr 1.25fr 1.25fr;
    grid-template-rows: 0.5fr 1.5fr;
    gap: 0px 0px;
    grid-template-areas:
      "eyes subtitle-1 subtitle-1"
      "eyes description-1 description-1";
    grid-area: save;
  }
  .eyes { 
    margin-right: 10px;
    margin-left: auto;
    margin-bottom: auto; 
    grid-area: eyes; 
  }
  .subtitle-1 {  grid-area: subtitle-1; }
  .description-1 { grid-area: description-1; }
  .private { 
    display: grid;
    grid-template-columns: 0.2fr 1.25fr 1.25fr;
    grid-template-rows: 0.25fr 1.75fr;
    gap: 0px 0px;
    grid-template-areas:
      "shild subtitle-2 subtitle-2"
      "shild description-2 description-2";
    grid-area: private;
  }
  .shild { 
    margin-right: 10px;
    margin-left: auto;
    margin-bottom: auto; 
    grid-area: shild; 
  }
  .subtitle-2 { grid-area: subtitle-2; }
  .description-2 { grid-area: description-2; }
  .main-btn { 
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.25fr 0.25fr 1.5fr;
    gap: 0px 0px;
    grid-template-areas:
      "btn"
      "label"
      "finerio-icon";
    grid-area: main-btn;
  }
  .btn {  grid-area: btn; padding-top: 20px; margin-left: 20%; margin-right: 20%; }
  .label { margin: auto; grid-area: label; }
  .finerio-icon { margin: auto; grid-area: finerio-icon; }
`;