import React from 'react';
import Accordion from '../../../../../components/accordion/accordion';
import { IPropsAnswers } from '../../../../../utils/utils';

const Account: React.FC<IPropsAnswers> = (props) => {
  const { onClick } = props;
  return (
    <div className="help-content">
      <div className="help-content-return" onClick={ () => onClick() }>
        <div><i className="arrow"></i></div>
        <p>Manejo de Cuenta</p>
      </div>
      <div className="help-section-box">
        <Accordion title="¿Puedo tomar un descanso de Slightpay?" className="help-item">
          <p className="help-text">
            <p>
              Si en algún momento deseas dejar de usar Slightpay, solo debes comunicarte con nosotros e informarnos. 
              Podemos congelar tu cuenta temporalmente y evitar que puedas seguir comprando. En cualquier momento puedes 
              descongelarla nuevamente. Ésta es sólo otra forma en que te queremos ayudar a mantener el control del uso responsable de gastos.
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Cómo cambio mi número de teléfono móvil?" className="help-item">
          <p className="help-text">
            <p>
                ¡Fácil! Ingresa a tu cuenta y en <span>mi perfil</span> ingresa tu nuevo número de celular. 
                Recibirás un código a tu nuevo número. 
                Ingresa este código en el espacio para verificar ¡Todo listo! 
            </p>
          </p>
        </Accordion>

        <Accordion title="¿Cómo puedo agregar o eliminar una tarjeta en mi cuenta?" className="help-item">
          <p className="help-text">
            <p>
              Puedes agregar una nueva tarjeta a tu cuenta siguiendo las indicaciones en la sección "Mi Cartera" en tu cuenta Slightpay.
            </p>
            <p>
              Una de tus tarjetas siempre aparecerá como "Método de pago preferido". Esta es la tarjeta de la que se ejecutarán los pagos 
              automáticos de las cuotas, a menos que selecciones PAGAR Cuota y decidas realizar un pago único con una tarjeta diferente. 
              Por lo tanto, si agregas una nueva tarjeta y quieres que éste sea tu método de pago preferido, tendrás que seleccionar esa 
              opción.
            </p>
            <p>
              Las cuotas vencidas se intentarán cobrar en tu método de pago preferido. Si esto falla, entonces intentaremos realizar el 
              cobro en cualquier otra tarjeta que hayas dado de alta en tu cuenta.
            </p>
            <p>
              Solo puedes eliminar una tarjeta de tu cuenta una vez que se hayan pagado todas las cuotas de los pedidos realizados con esa 
              tarjeta. Una vez hecho esto, puedes eliminar una tarjeta siguiendo las indicaciones en la sección "Mi Cartera".
            </p>
            <p>
              Al agregar una nueva tarjeta, asegúrate de que se haya activado con tu banco emisor, también es importante asegurarte de que 
              tenga saldo o crédito disponibles para ejecutar los pagos y cobros. De lo contrario, nuestro sistema se confundirá y rechazará 
              la tarjeta.
            </p>
            <p>
              Si tienes alguna pregunta, por favor contáctanos.
            </p>
          </p>
        </Accordion>
        
        {/* TODO: */}
        <Accordion title="¿Cómo cierro mi cuenta?" className="help-item">
          <p className="help-text">
            <p>
              De acuerdo. Esto para nosotros es un tema súper importante.
            </p>
            <p>
              Por supuesto que puedes cerrar tu cuenta y realmente nos duele verte partir. Para poder cerrar una cuenta, 
              necesitamos asegurarnos de que se hayan pagado todas tus cuotas pendientes primero. Luego, puedes enviarnos un mensaje rápido 
              e informarnos que deseas cerrar tu cuenta.
            </p>
            <p>
              Respetamos totalmente tu decisión y es por eso que realmente valoramos tus comentarios, para que podamos seguir aprendiendo y 
              mejorando nuestro servicio para todos los Slightpayers. Si quieres hacerlo, comunícate con nosotros al correo 
              hello@slightpay.com y cuéntanos qué fue lo bueno y lo malo para poder seguir mejorando.
            </p>
            <p>
            ¡Buena suerte!
            </p>
          </p>
        </Accordion>
      </div>
    </div>
  );
};

export default Account;