import React, { useState } from 'react';
import { ICheckboxProps, IUseCheckboxType } from './checkbox-types';
import { ERROR_MESSAGES } from '../../../constants/errorMessages';
import { CheckboxContainer } from './checkbox-style';
import ErrorText from '../../components-text/error-text/error-text';

const useCheckbox: IUseCheckboxType = (props: ICheckboxProps) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (isChecked: boolean) => {
    setChecked(isChecked);

    if (props.onCheck) {
      props.onCheck(isChecked, props.name);
    }
  };

  return {
    checked,
    handleChange,
  };
};

const Checkbox: React.FC<ICheckboxProps> = (props: ICheckboxProps) => {
  const { name, disabled, errorCode, labelText, className, inputRef, children, link, labelTextOptions } = props;
  const actions = useCheckbox(props);

  const errorText = errorCode ? ERROR_MESSAGES[errorCode] : '';

  return (
    <CheckboxContainer
      className={`${className} ${disabled ? 'disabled' : ''} ${errorCode ? 'error' : ''} ${
        actions.checked ? 'active' : ''
        }`}
    >
      <div className="checkbox-wrapper">
        <label className="checkbox-container">
          <input
            ref={inputRef}
            name={name}
            disabled={disabled}
            checked={actions.checked}
            type="checkbox"
            onChange={() => actions.handleChange(!actions.checked)}
          />
          <div className="checkmark-container">
            <div className="checkmark-item" />
          </div>
          <div className="checkbox-label">
            {labelTextOptions ? (
              <div>
                {labelTextOptions.map((item, index) => {
                  return (
                    <a href={item.url} key={index} target="_blank" rel="noopener noreferrer">
                      {item.text}
                      &nbsp;
                    </a>
                  );
                })}
              </div>
            ) : link ? (
              <>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <p>{labelText}</p>
                </a>
              </>
            ) : (
              <p>{labelText}</p>
            )}
            <span>{children}</span>
          </div>
        </label>
      </div>

      <div className="checkbox-error">
        <ErrorText> {errorText} </ErrorText>
      </div>
    </CheckboxContainer>
  );
};

export default Checkbox;
