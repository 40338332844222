import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../../store/auth/actions';
import { authSelectors } from '../../../../../store/auth/selectors';

const RecoverPassword: React.FC = () => {
  const currentEmail = useSelector(authSelectors.getEmail());
  const [sent, setSend] = useState(false);
  const dispatch = useDispatch();

  const handleView = async () => {
    try {
      await dispatch(authActions.recoverPassword(currentEmail || ''));
      setSend(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="form-p">
        {!sent ? (
          <>
            <p>
              Te hemos enviado un correo con un link de recuperación de contraseña al email introducido anteriormente.
            </p>
            <p>
              No lo he recibido,
              <span>
                <button className="button-underline" onClick={handleView}>
                  volver a enviar.
                </button>
              </span>
            </p>
          </>
        ) : (
          <p>Te hemos enviado un correo con un link de recuperación de contraseña.</p>
        )}
      </div>
    </>
  );
};

export default RecoverPassword;
