import React from 'react';
import MainAsideContainer from './main-aside-style';
import { MainAsideProps } from './main-aside-types';

const MainAside: React.FC<any> = (props: MainAsideProps) => {
    const {content} = props.children;
    
    return (
        <MainAsideContainer id={props.id} className={props.className}>
            {
                content ? 
                    content
                :
                    null
            }
        </MainAsideContainer>
    )
}

export default MainAside;