import React, { useState } from 'react';

// Themes
import { icons } from '../../../assets/themes'

// Constants
import { ERROR_MESSAGES } from '../../../constants/errorMessages';

// Components
import { LabelText } from '../../components-text/label-text/label-text';
import { NoteText } from '../../components-text/note-text/note-text';

// Styles
import { PasswordBoxContainer } from './password-box-style';

// Types
import { IPasswordBoxProps, IUseInputType, IUsePasswordType } from './password-box-types';

const validMinLength = (value: string): boolean => value.length > 7;

const validPuntuationSing = (value: string): boolean => /[.,\/#!$%\^&\*;:{}=\-_`~()”“"…]/.test(value);

const validUpercase = (value: string): boolean => !/^(?!^.*[A-Z].*$)/.test(value);

const useInput: IUseInputType = (initialValue: string, props: IPasswordBoxProps) => {
  const [value, setValue] = useState(initialValue);
  const [name, setName] = useState(props.name);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    setName(e.target.name);
    if (props.onChange) {
      props.onChange(e, props.name);
    }
  };

  const handleKeyPressed = (e: any) => {
    if (e.key === 'Enter') {
      /* eslint-disable */
      props.onKeyPress ? props.onKeyPress() : null;
    }
  };

  return {
    handleChange,
    handleKeyPressed,
    value,
    name,
  };
};

const usePassword: IUsePasswordType = (initialValue: boolean) => {
  const [showPassword, setShowPassword] = useState(initialValue);

  const handleOnClick = (value: boolean) => {
    setShowPassword(value);
  };

  return {
    handleOnClick,
    showPassword,
  };
};

const PasswordBox: React.FC<IPasswordBoxProps> = (props: IPasswordBoxProps) => {
  const {
    name,
    inputRef,
    disabled,
    className,
    required,
    labelText,
    value,
    errorCode,
    formatRules,
    formatRulesCompleted,
    placeholder,
  } = props;

  const inputActions = useInput(value || '', props);
  const errorText = errorCode ? ERROR_MESSAGES[errorCode] : '';
  const passwordActions = usePassword(false);

  return (
    <PasswordBoxContainer
      className={`${errorCode ? 'error' : ''} ${className} ${disabled ? 'disabled' : ''} ${errorCode ? 'error' : ''}`}
    >
      {labelText && (
        <div className="password-box-topbar">
          <div className="password-box-topbar-label">
            <LabelText>
              <p>{labelText}</p>
            </LabelText>
          </div>
        </div>
      )}
      <div className="password-box-main">
        <div className={`password-box-main-field ${required ? 'required' : ''}`}>
          <input
            type={passwordActions.showPassword ? 'text' : 'password'}
            // value={inputActions.value}
            // name={inputActions.name}
            name={name}
            ref={inputRef}
            onChange={e => inputActions.handleChange(e)}
            onKeyPress={(e: any) => inputActions.handleKeyPressed(e)}
            placeholder={placeholder}
          />
        </div>
        <div
          className={`password-box-icon ${passwordActions.showPassword ? 'active' : ''}`}
          onClick={() => passwordActions.handleOnClick(!passwordActions.showPassword)}
        >
          <img src={` ${passwordActions.showPassword ? icons.eyeBlue : icons.eyeBlueCrossed}`} />
        </div>
      </div>
      {formatRules && (
        <div className="password-box-format-rules">
          <div className="password-box-format-rules-text">
            {formatRulesCompleted ? (
              <p>Nivel de seguridad alto</p>
            ) : (
              <NoteText>
                <p>Tu contraseña debe incluir:</p>
                <ul>
                  <li className={validMinLength(inputActions.value) ? 'ok' : ''}>- Mínimo 8 caracteres</li>
                  <li className={validPuntuationSing(inputActions.value) ? 'ok' : ''}>
                    - Al menos un signo de puntuación
                  </li>
                  <li className={validUpercase(inputActions.value) ? 'ok' : ''}>- Al menos una mayúscula</li>
                </ul>
              </NoteText>
            )}
          </div>
          <div className="password-box-format-rules-level-container">
            {/* TODO */}
            {/* <div className={`password-box-format-rules-level-item bar-1 ok`} />
            <div className={`password-box-format-rules-level-item bar-2 ${validMinLength(inputActions.value) ? 'ok' : ''}`} />
            <div className={`password-box-format-rules-level-item bar-3 ${validPuntuationSing(inputActions.value) ? 'ok' : ''}`} />
            <div className={`password-box-format-rules-level-item bar-4 ${validUpercase(inputActions.value) ? 'ok' : ''}`} /> */}
          </div>
        </div>
      )}
      {errorCode && (
        <div className="password-box-error">
          <NoteText>
            <p>{`${errorText}`}</p>
          </NoteText>
        </div>
      )}
    </PasswordBoxContainer>
  );
};

export default PasswordBox;
