import styled from 'styled-components/macro'

const ModalSelectPaymentStyle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  // max-width: 430px;
  width: 100%;

  .payment-method-item {
    flex: 1 1 45%;
    margin: 5px;
    background: #1A4CCD;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    cursor: pointer;
  }

  .wallets-items {
    flex: 1 1 30px;
    flex-direction: column;
    margin: 15px;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    cursor: pointer;
    width: 50%;
  }
  .wallets-items_text {
    text-align: center; 
    color: gray;
    font-size: 10px;
    line-height: 10px;
    margin-top: 10px;
  }

  .wallet-img {
    width: 80px;
    height: 80px;
  }

  .payment-method-item:hover {
    background: #306aff;
  }

  .modal-to-paid-description {
    font-family: 'Gilroy';
    font-size: 16px;
    line-height: 24px;
    color: #01030c;
    margin-bottom: 36px;
  }

  .modal-to-paid-content {
    width: 100%;
    padding: 32px 38px;
    background: #ffffff;
    margin-bottom: 43px;

    @media (max-width: 500px) {
      height: 500px !important;
    }
  }

  .modal-to-paid-topbar {
    width: 100%;
    border-bottom: 1px solid #f2f2f3;
    padding-bottom: 16px;
  }

  .modal-to-paid-logo {
    margin-bottom: 18px;
    height: 35px;
    width: 77px;
  }

  .modal-to-paid-topbar-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Gilroy';
    font-size: 18px;
    line-height: 22px;
    color: #1a4ccd;
    font-weight: 600;
  }

  .modal-to-paid-row {
    width: 100%;
    border-bottom: 1px solid #f2f2f3;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 1px solid transparent;
    }
  }

  .modal-to-paid-column {
    width: 100%;
    border-bottom: 1px solid #f2f2f3;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 1px solid transparent;
    }
  }

  .modal-to-paid-label {
    font-family: 'Gilroy';
    font-size: 14px;
    line-height: 22px;
    color: #01030c;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .modal-to-paid-text {
    font-family: 'Gilroy';
    font-size: 14px;
    line-height: 22px;
    color: #b3b3b6;
    // width: 350px;
    // max-width: 350px;
    font-weight: 300;
    span {
      color: #CA3976;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .modal-to-paid-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .btn-cancel {
      width: calc((100% - 20px) / 2);
      margin-right: 20px;
    }

    .btn-yes {
      width: calc((100% - 20px) / 2);
    }
  }

  .modal-to-paid-fee-number {
    width: 50%;
    border-right: 1px solid #f2f2f3;
  }

  .modal-to-paid-paid-date {
    padding-left: 28px;
    width: 50%;
  }

  .note {
    margin-top: 20px;
    line-height: 22px;
    max-width: 100%;
    word-break: inherit;
    font-size: 13px;
    font-weight: 300;
  }

`

export default ModalSelectPaymentStyle
