import { combineReducers } from 'redux';

import loaderReducer from './loader/reducer';
import authReducer from './auth/reducer';
import toastsReducer from './toasts/reducer';
import modalReducer from './modal/reducer';
import transactionReducer from './transaction/reducer';
import cardsReducer from './card/reducer';
import paymentSidebarReducer from './paymentSidebar/reducer';

export default combineReducers({
  auth: authReducer,
  load: loaderReducer,
  paymentSidebar: paymentSidebarReducer,
  toasts: toastsReducer,
  modal: modalReducer,
  transactions: transactionReducer,
  card: cardsReducer,
});