import React from 'react';

import BuyDetailContainer from './buy-detail-style';
import { IBuyDetailProps } from './buy-detail-types';

import InventoryItem from './inventory-item/inventory-item';
import TableSimple from '../tables/table-simple/table-simple';
import { SVGIcon } from '../svg-icon/svg-icon';
import { icons } from '../../assets/themes';
import { formatMoney } from '../../utils/utils';

const BuyDetail: React.FC<IBuyDetailProps> = (props: IBuyDetailProps) => {
  const { className, title, inventory__data, table__data, isolated } = props;

  return (
    <BuyDetailContainer className={`${className} ${isolated ? 'isolated' : ''}`}>
      {isolated && (
        <div className="heading">
          <div className="title">{title}</div>
          <div className="icon">
            {/* <InfoIcon fill="blue" scale="1.5"></InfoIcon> */}
            <SVGIcon color="blue" scale="1.5" icon={icons.infoIcon}/>
          </div>
        </div>
      )}

      <div className="wrapper-items">
        {inventory__data.map(function(element: any, index: any) {
          return (
            <InventoryItem
              key={'inventory-item' + index}
              // image={element.image}
              title={element.name}
              infoQuantity={element.quantity}
              infoPrice={formatMoney(element.amount * element.quantity)}
            />
          );
        })}
      </div>

      <div className="wrapper-table">
        <div className="table">
          <TableSimple className="simple" data={table__data} />
        </div>
      </div>
    </BuyDetailContainer>
  );
};

export default BuyDetail;
