import React, { useState, useEffect } from 'react'

//Style
import MyActivityPaymentScheduleContainer from './payment-schedule-style'


import useUserActivies from '../../../../hooks/useActivity/useUserActivities'
import MainTableHeader from '../../../../components/main-table/main-table-header/main-table-header'
import MainTable from '../../../../components/main-table/main-table-layout';

import CalendarBox from '../../../../components/calendar-box'
import moment from 'moment';
import { IFilterTypes } from '../../home/resume/home-resumen'
import CircleStat from '../../../../components/circle-stat/circle-stat'
import { formatMoney } from '../../../../utils/utils'
import { transactionActions } from '../../../../store/transaction/actions'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '../../../../components/pagination/pagination'
import ModalToPaid from '../../../../components/modals/modal-to-paid/modal-to-paid'
import ModalToPaidManual from '../../../../components/modals/modal-to-paid/modal-to-paid-manual'
import ModalToSelectPaymentMethod from '../../../../components/modals/modal-to-paid/modal-select-payment'
import { icons } from '../../../../assets/themes'
import useModal from '../../../../hooks/useModal/use-modal'
import ModalErrorCard from '../../../../components/modals/modal-error/modal-error'
import ModalSuccess from '../../../../components/modals/modal-success/modal-success'
import { ICard } from '@aluxion-lightpay/interfaces'
import { cardActions } from '../../../../store/card/actions';
import useUserCards from '../../../../hooks/useWallet/useUserCards';
import ModalInfo from '../../../../components/modals/modal-info/modal-info';
import { SVGIcon } from '../../../../components/svg-icon/svg-icon';
import { authSelectors } from '../../../../store/auth/selectors'
import ModalErrorCustom from '../../../../components/modals/modal-error/modal-error-custom';
import ModalBankData from '../../../../components/modals/modal-bank-data/modal-bank-data';
import { TransactionActions } from '../../../../store/transaction/types';

interface IModalsData {
  shopName: string;
  prize: string;
  fee: string | number;
  date: string;
  card: ICard;
  onClick: (value: any) => void;
  purchaseId: number;
  feeId: number;
}

const MyActivityPaymentSchedule: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const [paymentState, setPaymentState] = useState<any>({})
  const [warningKeysData, setWarningData] = useState<IModalsData | null>(null);
  const { isModal, openModal, closeModal } = useModal();
  const { paymentsItems, id, paymentsCount } = useUserActivies();
  const [page, setPage] = useState<number>(1);
  const [version, setVersion] = useState<number>(0)
  const [title, setTitle] = useState<string>('')
  const [walletScreen, setWalletScreen] = useState<boolean>(false)
  const [filter, setFilter] = useState<IFilterTypes>({
    status: 'PENDING,PAYMENT_REJECTED',
    fromDate: '',
    toDate: '',
    search: '',
  });
  const [selectedCardId, setSelectedCard] = useState<number | null>();
  const { setDefaultCardById } = useUserCards();
  const { id: userId, firstName } = useSelector(authSelectors.getMe()) as any;
  const onPageChanged = async (data: any = 0) => {
    const { currentPage } = data;
    await Promise.all([
      dispatch(cardActions.getCardData(id)),
      dispatch(transactionActions.addPaymentsData(id, currentPage, filter)),
    ])
    setPage(currentPage);
  };

  const columnsDues = [
    {
      text: 'Id. Transacción',
      filter: false,
      ascendent: true,
      className: 'table-col-2 start',
    },
    {
      text: 'Nº cuotas',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Fecha',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Nombre del Comercio',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Importe',
      filter: false,
      ascendent: true,
      className: 'table-col-2 end',
    },

  ];

  const rowKeysDues = ['refencialNumber', 'payments', 'date', 'commerce', 'totalAmount',];

  const paymentRows = paymentsItems.map((payment, index) => {
    return {
      refencialNumber: {
        text: payment.purchase.id,
        key: 'highlighted',
        className: `table-col-2 start_row ${payment.status === 'CANCELED' && 'canceled'}`,
      },
      payments: {
        key: 'icon',
        text: `${payment.paymentIndex}/${payment?.type ? payment?.type?.substr(0, 1) : 4}`,
        img:
          payment.status === 'CANCELED' ?
            <SVGIcon icon={icons.rejectedIcon} color="#B3B3B6" scale="1.5" />
            :
            <CircleStat
              radius={14}
              stroke={3}
              progress={(payment.paymentIndex * 100) / Number(payment.type ? payment?.type?.substr(0, 1) : 4)}
              fill={payment.status === 'SUCCESSFUL' ? '#1A4CCD' : '#CA3976'}
            />,
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled cross'}`,
      },
      date: {
        text: moment(payment?.dueDate).format('L'),
        key: 'highlighted',
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled'}`,
      },
      commerce: {
        text: payment.purchase.commerce.name,
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled'}`,
      },
      totalAmount: {
        key: 'action',
        text: formatMoney(payment?.amount),
        span: payment.status === 'PAYMENT_REJECTED' ? 'PAGAR AHORA' : (payment.status === 'PENDING' && payment.purchase.actualPaymentIndex + 2 === payment.paymentIndex) && 'ADELANTAR PAGO',
        click: async () => {
          setPaymentState(payment)
          openModal('selectPaymentMethod')
          // payment.status === 'PAYMENT_REJECTED' ? openModal('confirmBuy') : (payment.status === 'PENDING' && payment.purchase.actualPaymentIndex + 2 === payment.paymentIndex) && openModal('confirmPaymentPartition');
          setWarningData({
            shopName: payment.purchase.commerce.name,
            fee: payment.paymentIndex,
            prize: formatMoney(payment?.amount),
            date: moment(payment?.dueDate).format('L'),
            card: payment?.card!,
            onClick: (cardId: number) => {
              payment.status === 'PAYMENT_REJECTED' ? payDue(payment.id, cardId, payment.purchase.id, payment.paymentIndex) : payment.status === 'PENDING' && payAdvancement(payment.id, cardId)
            },
            purchaseId: payment.purchase.id,
            feeId: payment.id,
          })
        },
        className: `table-col-2 end_row ${payment.status === 'SUCCESSFUL' ? 'payment active' : 'payment'} 
        ${payment.status === 'CANCELED' && 'canceled cross'}`,
      },
      link: `/detalle-operacion/${payment.purchase.id}`,
    }
  });



  // FILTERS FUNCTIONS
  const handleSearch = (e: any) => {
    setFilter({
      ...filter,
      [e.name]: e.value,
    });
  };

  const handleChange = (event: any, name: string) => {
    if (name === 'status' && event !== filter.status) {
      let filterStatus = '';
      switch (event) {
        case 'PREPARANDO':
          filterStatus = 'DRAFT';
          break;
        case 'COMPLETADA':
          filterStatus = 'SUCCESSFUL';
          break;
        case 'CANCELADO':
          filterStatus = 'CANCELED';
          break;
        case 'CANCELADO PENDIENTE':
          filterStatus = 'CANCELED_PENDING';
          break;
        case 'EXPIRADA':
          filterStatus = 'EXPIRED';
          break;
        case 'EN PROCESO':
          filterStatus = 'PENDING';
          break;
        case 'CONTRACARGO RECHAZADO':
          filterStatus = 'CHARGEBACK_REJECTED';
          break;
        case 'CONTRACARGO ACEPTADO':
          filterStatus = 'CHARGEBACK_ACCEPTED';
          break;
        case 'DEVOLUCIÓN TOTAL':
          filterStatus = 'REFUNDED_TOTALLY';
          break;
        case 'DEVOLUCIÓN PARCIAL':
          filterStatus = 'REFUNDED_PARTIALLY';
          break;
        default:
          break;
      }
      if (filterStatus !== filter.status) {
        setFilter({
          ...filter,
          [name]: filterStatus,
        });
      }
    } else if ((name === 'fromDate' && event !== filter.fromDate) || (name === 'toDate' && event !== filter.toDate)) {
      setFilter({
        ...filter,
        [name]: moment(event, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      });
    }
  };
  // END FILTERS FUNCTIONS

  const payDue = async (paymentId: number, cardId: number, purchaseId: number, paymentIndex: number) => {
    try {
      await dispatch(transactionActions.payTotalLatePayments(purchaseId, paymentId, cardId));
      await dispatch(transactionActions.payDue(paymentId, cardId));
      await dispatch(transactionActions.addPaymentsData(id, '1', filter));
      if (paymentIndex !== 4) {
        openModal('setCard');
      } else {
        openModal('paymentSuccessful');
      }
    } catch (error) {
      console.log(error)
      if (error?.code === 'OPENPAY_EXCEPTION') {
        setTitle(error?.message)
        openModal('error');
      } else if (error?.reasons[0]?.validation === 'OpenPay regreso un error') {
        setVersion(1)
        openModal('error-custom');
      } else if (error?.reasons[0]?.validation === 'Tiene 4 intentos de OpenPay') {
        setVersion(2)
        openModal('error-custom');
      } else if (error?.reasons[0]?.validation === 'No puede adelantar cuotas') {
        setVersion(3)
        openModal('error-custom');
      } else {
        openModal('error');
      }
    }
  }

  const payAdvancement = async (partitionId: number, cardId: number) => {
    try {
      await dispatch(transactionActions.payAdvancement(partitionId, cardId));
      await dispatch(transactionActions.getHomePaymentsDetails(userId))
      openModal('paymentSuccessful');
    } catch (error) {
      console.log(error)
      if (error?.code === 'OPENPAY_EXCEPTION') {
        setTitle(error?.message)
        openModal('error');
      } else if (error?.reasons[0]?.validation === 'OpenPay regreso un error') {
        setVersion(1)
        openModal('error-custom');
      } else if (error?.reasons[0]?.validation === 'Tiene 4 intentos de OpenPay') {
        setVersion(2)
        openModal('error-custom');
      } else if (error?.reasons[0]?.validation === 'No puede adelantar cuotas') {
        setVersion(3)
        openModal('error-custom');
      } else {
        openModal('error');
      }
    }
  }

  const selectedCard = async (currentCardId: number) => {
    setSelectedCard(currentCardId);
  }

  const setDefaultCard = async () => {
    try {
      if (selectedCardId) await setDefaultCardById(selectedCardId, warningKeysData?.purchaseId);
      openModal('paymentSuccessful')
    } catch (error) {
      throw error
    }
  }



  return (
    <MyActivityPaymentScheduleContainer>
      <div className="payments-schedule-filters-container">
        <form />
      </div>
      <div className="payments-schedule-content">

        {
          (paymentRows.length !== 0) ?
            (
              <>
                <MainTableHeader
                  searchFunction={handleSearch}
                  placerholder="Instroduzca un comercio"
                  onAnyKeyPress
                >
                  <div className="main-table-header-filters__row__item fix">
                    <CalendarBox onChange={(e: any) => handleChange(e, 'fromDate')} placeholder="Desde" initialValue="" />
                  </div>
                  <div className="main-table-header-filters__row__item fix">
                    <CalendarBox onChange={(e: any) => handleChange(e, 'toDate')} placeholder="Hasta" initialValue="" />
                  </div>
                </MainTableHeader>
                <MainTable columns={columnsDues} rows={paymentRows} keysRow={rowKeysDues} />
                <div className="pagination">
                  <Pagination
                    totalItems={paymentsCount}
                    pageLimit={20}
                    nextPage={1}
                    onPageChanged={onPageChanged}
                    reset={filter}
                  />
                  <div></div>
                  <div className="pagination-count">
                    <p>{`MOSTRANDO ${paymentsItems.length < 20 ? paymentsCount : paymentsItems.length * page} / ${paymentsCount}`}</p>
                  </div>
                </div>
              </>
            )
            :
            (

              <>
                <MainTableHeader
                  searchFunction={handleSearch}
                  placerholder="Instroduzca un comercio"
                  onAnyKeyPress
                >
                  <div className="main-table-header-filters__row__item fix">
                    <CalendarBox onChange={(e: any) => handleChange(e, 'fromDate')} placeholder="Desde" initialValue="" />
                  </div>
                  <div className="main-table-header-filters__row__item fix">
                    <CalendarBox onChange={(e: any) => handleChange(e, 'toDate')} placeholder="Hasta" initialValue="" />
                  </div>
                </MainTableHeader>
                <div className="zero-content-title">
                  No se encontraron resultados para<span> agenda de pago</span>
                </div>
                <div className="pagination" style={{ visibility: "hidden" }}>
                  <Pagination
                    totalItems={paymentsCount}
                    pageLimit={20}
                    nextPage={1}
                    onPageChanged={onPageChanged}
                    reset={filter}
                  />
                  <div></div>
                  <div className="pagination-count">
                    <p>{`MOSTRANDO ${paymentsItems.length < 20 ? paymentsCount : paymentsItems.length * page} / ${paymentsCount}`}</p>
                  </div>
                </div>
              </>
            )
        }




      </div>
      {/* MODALS */}
      <ModalToPaid
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'confirmBuy') || false,
          onClose: closeModal,
        }}
        logo={icons.slightpayLogo}
        shopName={warningKeysData?.shopName || ''}
        prize={warningKeysData?.prize || ''}
        fee={`${warningKeysData?.fee}/4`}
        date={warningKeysData?.date || ''}
        card={warningKeysData?.card!}
        onClick={warningKeysData?.onClick}
        purchaseId={warningKeysData?.purchaseId}
        feeId={warningKeysData?.feeId}
        setDefaultCard={selectedCard}
      />

      <ModalToPaidManual
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'confirmPaymentPartition') || false,
          onClose: closeModal,
        }}
        logo={icons.slightpayLogo}
        shopName={warningKeysData?.shopName || ''}
        prize={warningKeysData?.prize || ''}
        fee={`${warningKeysData?.fee}/4`}
        date={warningKeysData?.date || ''}
        card={warningKeysData?.card!}
        onClick={warningKeysData?.onClick}
        purchaseId={warningKeysData?.purchaseId}
        feeId={warningKeysData?.feeId}
        setDefaultCard={selectedCard}
      />

      <ModalToSelectPaymentMethod
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'selectPaymentMethod') || false,
          onClose: () => {
            closeModal()
            setWalletScreen(false)
          }
        }}
        setWalletScreen={setWalletScreen}
        walletScreen={walletScreen}
        onClick={() => {
          paymentState?.status === 'PAYMENT_REJECTED' ? openModal('confirmBuy') : (paymentState?.status === 'PENDING' && paymentState?.purchase.actualPaymentIndex + 2 === paymentState?.paymentIndex) && openModal('confirmPaymentPartition');
        }}
        payment={paymentState}
      />

      <ModalInfo
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'setCard') || false,
          onClose: closeModal,
        }}
        title={'¿Deseas escoger esta tarjeta para que pague las futuras cuotas de esta compra?'}
        text=""
        buttons={[
          {
            text: 'No',
            type: 'ghost',
            onClick: () => { closeModal(); openModal('paymentSuccessful'); },
          },
          {
            text: 'Sí',
            type: 'primary',
            onClick: setDefaultCard,
          },
        ]}
      />
      <ModalSuccess
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'paymentSuccessful') || false,
          onClose: closeModal,
        }}
      >
        Se ha pagado <span>su cuota con éxito.</span>
      </ModalSuccess>

      <ModalErrorCard
        title={title}
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'error') || false,
        }}
      />

      <ModalErrorCustom
        version={version}
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'error-custom') || false,
        }}
      />
    </MyActivityPaymentScheduleContainer>
  )
}

export default MyActivityPaymentSchedule
