import React from 'react';
import { NotfoundStyle } from './Not-found-style';
import { images } from '../../../assets/images/images';
import { Link } from 'react-router-dom';
import MainButton from '../../../components/main-button/main-button';
import ZeroContent from '../../../components/zero-content/zero-content';

const Notfound = () => {
  return (
    <NotfoundStyle>
      <div className="item-left">
        <div className="item-left__title">
          <p>404</p>
        </div>
        <div className="item-left__content">
          <p>¡Ups! No sabemos cómo has llegado hasta aquí</p>
          <span>
            La página que buscas no existe o no está disponible. Utiliza el menú para volver a la tierra y seguir
            disfrutando de Slightpay.
          </span>
        </div>
        <Link to="/">
          <MainButton text="Volver al inicio" type="primary" />
        </Link>
      </div>
      <div className="item-right">
        <ZeroContent shadowColor="#1A4CCD" image={images.image404} />
      </div>
    </NotfoundStyle>
  );
};

export default Notfound;
