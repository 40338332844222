/** THIS SHOULD BE REFACTORED IN SEVERAL FILES  */
import React from 'react'
import { IMainInfoSidebarProps } from './main-info-sidebar-type'
import MainInfoSidebarContainer, { WrapperContainer } from './main-info-sidebar-style';
import PaymentSidebar from '../payment-sidebar/payment-sidebar';
import LoaderInfoSidebar from './loader/loader';
import useMainInfoSidebar from '../../hooks/useMainInfoSidebar/useMainInfoSidebar';
// import { IPurchase } from '@aluxion-lightpay/interfaces';
import { IPurchaseClients } from '../../interfaces/purchaseclients.types'
import { Route } from 'react-router-dom';



const MainInfoSidebar: React.FC<IMainInfoSidebarProps> = (props: IMainInfoSidebarProps) => {
  const {id} = props;
  const {
    isMainInfoSidebarLoading, 
    closeMainInfoSidebar, 
    selectedPurchase
  } = useMainInfoSidebar();
  

  return (
    <WrapperContainer >
      <MainInfoSidebarContainer id={id}>
        <div className="veil" onClick={() => closeMainInfoSidebar()}>
        </div>
        { 
          isMainInfoSidebarLoading() ? 
          (
            <LoaderInfoSidebar />
            ) :
            (
              <>

                <Route path="/home/:paymentId/:feeId?" render={() => 
                  <PaymentSidebar 
                  id="home-resumen-sidebar" 
                  className="home-resume"
                  data={selectedPurchase as IPurchaseClients}
                  closeSidebar={closeMainInfoSidebar}
                  />
                } 
                />

                {/* <Route path="/home/:paymentId/:feeId" render={() => 
                  <PaymentSidebar 
                  id="home-resumen-sidebar" 
                  className="home-resume"
                  data={selectedPurchase as IPurchaseClients}
                  closeSidebar={closeMainInfoSidebar}
                  />
                } 
              /> */}
              </>
            )
          }
        

      </MainInfoSidebarContainer>
    </WrapperContainer>
  )
}

export default MainInfoSidebar;