import styled from 'styled-components/macro';

const CreditCardInfoContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
    border-radius: 3px;
    width: 100%;
    padding: 44px 31px;

    .credit-card-info-topbar {
        border-bottom: 1px solid #E6E6E7;
        margin-bottom: 26px; 
        padding-bottom: 20px; 

        .title {
            font-family: "Gilroy";
            font-size: 24px;
            line-height: 29px;
            display: flex;
            align-items: center;
            color: #01030C;
            margin-bottom: 9px; 
        }

        .valid-date {
            font-family: "Gilroy";
            font-size: 16px;
            line-height: 24px;
            color: #01030C;
            margin-bottom: 20px; 
        }

        .actions-icons-container {
            left: -5px;
            position: relative;
            width: 100%; 
            display: flex; 
            align-items: center; 

            .fav-icon {
                cursor: pointer; 
                width: 28px;
                height: 28px;
                

                img {
                    width: 100%; 
                    height: auto; 
                }
            }

            .trash-icon {
                width: 28px;
                height: 28px;
                cursor: pointer; 

                img {
                    width: 100%; 
                    height: auto; 
                }
            }
        }
    }

    .credit-card-info-address-container {
        .address-label {
            font-family: "Gilroy";
            font-size: 14px;
            line-height: 22px;
            color: #B3B3B6;
        }

        .address {
            font-family: "Gilroy";
            font-size: 14px;
            line-height: 22px;
            color: #B3B3B6;
        }
    }
`

export default CreditCardInfoContainer;
