export const weights : any = {
  ultraLight: 100,
  light: 200,
  regular: 300,
  medium: 400,
  semibold: 500,
  bold: 600,
  extraBold: 700,
  black: 800,
  heavy: 900,
};
