import styled from 'styled-components/macro';

const CustomAlertContainer = styled.div`
  position: relative;
  display: flex;
  padding: 20px 25px;
  width: auto;
  display: flex;
  align-items: center;
  background-color: gainsboro;
  border-radius: 3px;
  font-size: 14px;
  /* margin-bottom: 30px; */
  z-index:2;

  #custom-alert-icon-box {
    margin-right: 18px;
  }

  a {
    color: #1a4ccd;
  }

  &.info {
    background-color: #f4f6fc;
  }

  &.error {
    background-color: #faebf1;
  }

  &.warning {
    background-color: #fff8eb;
  }

  &.success {
    background-color: #edf7f9;
  }

  .hidden {
    display: none;
  }

  &.profile {
    @media (max-width: 768px){
      margin-top: 110px;
    }
  }
`;

export { CustomAlertContainer };
