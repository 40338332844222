import React, { useState } from 'react'
import ProfileAsideRespContainer from './profile-aside-resp-style'
import { icons } from '../../../assets/themes'
import { Link } from 'react-router-dom'

const OPTIONS: any = {
  personalData: 'Datos personales',
  // accountConfig: 'Configuración de la cuenta',
  help: 'Ayuda'
}

const isActive = (page: string) => {
  if (window.location.href.indexOf('/datos-personales') !== -1) {
    return OPTIONS.personalData
  }

  if (window.location.href.indexOf('/configuracion') !== -1) {
    return OPTIONS.accountConfig
  }

  if (window.location.href.indexOf('/ayuda') !== -1) {
    return OPTIONS.help
  }
}

const getURL = (key: string) => {
  switch (key) {
    case 'personalData':
      return '/perfil/datos-personales'
      break
    case 'accountConfig':
      return '/perfil/configuracion'
      break
    case 'help':
      return '/perfil/ayuda'
      break
    default:
      return ''
  }
}

const useMenu = () => {
  const [showMenu, setShowMenu] = useState(false)

  const handleClickMenu = (show: boolean) => {
    setShowMenu(show)
  }

  return {
    showMenu,
    handleClickMenu
  }
}

const useProfileAsideResp = () => {
  const [activeLabel, setActiveLabel] = useState('personalData')

  const handleClickElement = (elementName: string) => {
    setActiveLabel(elementName)
  }

  return {
    activeLabel,
    handleClickElement
  }
}

const ProfileAsideResp: React.FC = () => {
  const menuActions = useMenu()
  const actions = useProfileAsideResp()

  return (
    <ProfileAsideRespContainer>
      <ul
        className={`profile-aside-resp-list ${
          menuActions.showMenu ? 'active' : ''
        }`}
      >
        <li className="profile-aside-resp-element active-element" onClick={() => menuActions.handleClickMenu(!menuActions.showMenu)}>
          <p className="element-text">{OPTIONS[actions.activeLabel]}</p>

          <div
            className="element-icon"
            onClick={() => menuActions.handleClickMenu(!menuActions.showMenu)}
          >
            {menuActions.showMenu ? (
              <div className="less-element" />
            ) : (
              <img src={icons.plusWhite} alt="white plus" />
            )}
          </div>
        </li>

        {Object.keys(OPTIONS).map((key: string) => {
          if (key !== actions.activeLabel) {
            return (
              <Link to={getURL(key)}>
                <li
                  className="profile-aside-resp-element"
                  onClick={() => actions.handleClickElement(key)}
                >
                  <p className="element-text">{OPTIONS[key]}</p>
                </li>
              </Link>
            )
          }
        })}
      </ul>
    </ProfileAsideRespContainer>
  )
}

export default ProfileAsideResp
