import styled from 'styled-components/macro';

const HelpSectionBoxContainer = styled.div`
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    padding-left: 27px; 
    padding-right: 27px;
    background: #fff; 
    height: 60px; 
    border-radius: 3px;

    .help-section-box-title {
        font-family: "Gilroy";
        font-size: 18px;
        line-height: 22px;
        color: #01030C;
    }

    .help-section-box-icon {
        cursor: pointer; 
    }
`;

export default HelpSectionBoxContainer; 