import React from 'react';
import { LegalStyle } from './legal-styles';

const Privacy = () => {
  return (
    <LegalStyle>
      <div className="legal-title">
        <p>Aviso</p>
        <p>de Privacidad</p>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>1. Privacidad</p>
        </div>
        <div className="legal-text">
          <p>
            Nuestras políticas de confidencialidad y privacidad de protección de datos personales y el presente aviso de
            privacidad (en adelante, el “Aviso”) se encuentran en estricto cumplimiento de la Ley Federal de Protección
            de Datos Personales en Posesión de los Particulares, su Reglamento y los Lineamientos del Aviso de
            Privacidad con el objeto de que usted pueda conocer nuestro compromiso y responsabilidad de proteger los
            datos que nos proporcione.
          </p>
          <p>
            Usted tiene a su disposición, en todo momento, este Aviso en nuestra página de internet{' '}
            <a href="https://www.slightpay.com" target="_blank" rel="noopener noreferrer">
              https://www.slightpay.com
            </a>{' '}
            y puede acceder al contenido de la regulación de protección de datos a través del portal delInstituto
            Federal de Acceso a la Información y Protección de Datos{' '}
            <a href="http://www.ifai.gob.mx" target="_blank" rel="noopener noreferrer">
              http://www.ifai.gob.mx
            </a>
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>2. Identidad y domicilio del responsable</p>
        </div>
        <div className="legal-text">
          <p>
            YUBO, S.A.P.I. DE C.V. (en adelante, “Slightpay”), con domicilio en Sierra Leona 705, Residencial San
            Agustín 1 Sector, San Pedro Garza García, NL, MX, 66260 y correo electrónico hello@slightpay.com es el
            responsable de recabar sus datos personales, el uso que se le dé a los mismos y de su protección.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>3. Finalidades del tratamiento</p>
        </div>
        <div className="legal-text">
          <p>
            Los datos personales recabados podrán ser utilizados con la finalidad de cumplir con los derechos y
            obligaciones de la relación jurídico-comercial que deriven del servicio que ha solicitado a Slightpay
            incluyendo lo siguiente: (i) identificación del solicitante; (ii) análisis crediticio; (iii) generación de
            documentos de los servicios; (iv) Proporcionar los servicios expresamente contenidos en los términos y
            condiciones; (v) informarle periódicamente el estatus del servicio contratado; (vi) enviar notificaciones
            relacionadas con el servicio contratado; (vii) atención de consultas, dudas, aclaraciones o reclamaciones; y
            (viii) dar cumplimiento a disposiciones legales. Adicionalmente, con finalidades secundarias los datos
            personales podrán ser utilizados con fines mercadotécnicos, publicitarios y ofrecimiento de promociones de
            productos y servicios de Slightpay.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>4. Datos personales tratados</p>
        </div>
        <div className="legal-text">
          <p>
            Para llevar la adecuada realización de las finalidades descritas en el presente Aviso podremos recabar,
            poseer y utilizar sus datos personales de identificación, contacto, laborales y financieros los cuales le
            podrán ser recabados personalmente, directamente o indirectamente mediante cualesquiera de los siguientes
            medios: 1) Formatos de solicitud del servicio que desea contratar; 2) Entrevista personal; 3) Medios
            electrónicos; 4) Llamada(s) telefónica(s) de nuestros ejecutivos; 5) Sociedades de Información Crediticia.
          </p>
          <p>
            Slightpay podrá realizar las investigaciones y acciones que considere necesarias a efecto de comprobar a
            través de cualquier tercero, dependencia u autoridad, la veracidad de los datos que le fueron
            proporcionados.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>5. Transferencias de datos</p>
        </div>
        <div className="legal-text">
          <p>
            Slightpay podrá transferir sus datos personales a terceros mexicanos o extranjeros cuando la transferencia
            (i) esté prevista en una ley o tratado de los que los Estados Unidos Mexicanos sea parte; (ii) sea efectuada
            a sociedades controladoras, subsidiarias o afiliadas bajo el control común de Slightpay o a una sociedad
            matriz o a cualquier sociedad del mismo grupo que opere bajo los mismos procesos y políticas internas; (iii)
            sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular, por Slightpay y un
            tercero; (iv) sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la
            procuración o administración de justicia; o (v) sea precisa para el mantenimiento o cumplimiento de una
            relación jurídica entre Slightpay y el titular.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>6. Derechos ARCO</p>
        </div>
        <div className="legal-text">
          <p>
            Si desea dejar de recibir información con fines mercadotécnicos, publicidad y promociones o pretende ejercer
            los derechos de acceso, rectificación, cancelación u oposición de sus datos personales podrá solicitarlo en
            cualquier momento mediante el envío de un correo electrónico a{' '}
            <a href="mailto:hello@slightpay.com" target="_blank" rel="noopener noreferrer">
              hello@slightpay.com
            </a>
          </p>
          <p>
            La solicitud deberá contener y acompañar lo siguiente: (i) El nombre del titular y domicilio u otro medio
            para comunicarle la respuesta a su solicitud; (ii) Los documentos que acrediten la identidad o, en su caso,
            la representación legal del titular; (iii) La descripción clara y precisa de los datos personales respecto
            de los que se busca ejercer alguno de los derechos antes mencionados; y (iv) Cualquier otro elemento o
            documento que facilite la localización de los datos personales.
          </p>
          <p>
            Una vez recibida la solicitud, Slightpay le comunicará en un plazo máximo de 20 (veinte) días, contados
            desde la fecha en que se recibió la solicitud, la determinación adoptada, a efecto de que, si resulta
            procedente, se haga efectiva dentro de los 15 (quince) días siguientes a la fecha en que se comunica la
            respuesta.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>7. Modificaciones al Aviso</p>
        </div>
        <div className="legal-text">
          <p>
            Slightpay se reserva el derecho de realizar cualquier modificación al presente Aviso. Estas modificaciones
            se darán a conocer a través de cualquiera de los siguientes medios: (i) un mensaje al correo electrónico
            proporcionado; o (ii) publicadas en nuestro sitio web{' '}
            <a href="http://www.slightpay.com" target="_blank" rel="noopener noreferrer">
              http://www.slightpay.com
            </a>
            .
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-subtitle">
          <p>8. Uso de cookies</p>
        </div>
        <div className="legal-text">
          <p>
            Slightpay utiliza cookies y otras tecnologías de rastreo (en conjunto, “Cookies”) para reconocerlo como
            Usuario y para personalizar su experiencia en línea, los servicios de Slightpay que utiliza y otro contenido
            en línea y publicidad; también se utilizan Cookies para medir la eficacia de las promociones y realizar
            análisis de dicha información, para mitigar riesgos, evitar los posibles fraudes y promover la confianza y
            seguridad en el sitio web y los servicios de Slightpay. Ciertos aspectos y funcionalidades de los servicios
            solo están disponibles a través del uso de Cookies, por lo que si decide desactivar o rechazar Cookies, el
            uso del sitio web y los servicios podría estar limitado o no ser posible.
          </p>
        </div>
      </div>
      <div className="legal-section">
        <div className="legal-last-update">
          <p>Fecha de última actualización: 18 de marzo de 2020.</p>
        </div>
      </div>
    </LegalStyle>
  );
};

export default Privacy;
