import React, { useState, useEffect } from 'react'
import ModalSelectPaymentStyle from './modal-select-payment-style'
import ModalContainer from '../modal-container/modal-container'
import { transactionActions } from '../../../store/transaction/actions'
import { useSelector, useDispatch } from 'react-redux'
import { wallet987, wallet233, wallet268, wallet399, wallet433, wallet476, wallet467, wallet825 } from "../../../assets/images/images";


const ModalSelectPaymentMethod: React.FC<any> = (props) => {
  const { modalConfig, onClick, payment, purchase, setWalletScreen, walletScreen } = props
  const dispatch = useDispatch();
  const [wallets, setWallets] = useState<any>([])

  useEffect(() => {
    dispatch(transactionActions.getDappWallets(setWallets))
  }, [])

  useEffect(() => {
    console.log(wallets)
  }, [wallets])

  const generateSPEITransaction = () => {
    if (payment) {
      if (payment.status === 'PAYMENT_REJECTED') {
        dispatch(transactionActions.createSpeiOrPaynetTransaction(payment.purchase.id, 'SPEI', 1, payment.id))
      } else if ((payment.status === 'PENDING' && payment.purchase.actualPaymentIndex + 2 === payment.paymentIndex)) {
        dispatch(transactionActions.createSpeiOrPaynetTransaction(payment.purchase.id, 'SPEI', 0, payment.id))
      }
    } else if (purchase) {
      dispatch(transactionActions.createSpeiOrPaynetTransaction(purchase.id, 'SPEI', 1))
    }
    modalConfig.onClose()
  }

  const generatePaynetTransaction = () => {
    if (payment) {
      if (payment.status === 'PAYMENT_REJECTED') {
        dispatch(transactionActions.createSpeiOrPaynetTransaction(payment.purchase.id, 'PayNet', 1, payment.id))
      } else if ((payment.status === 'PENDING' && payment.purchase.actualPaymentIndex + 2 === payment.paymentIndex)) {
        dispatch(transactionActions.createSpeiOrPaynetTransaction(payment.purchase.id, 'PayNet', 0, payment.id))
      }
    } else if (purchase) {
      dispatch(transactionActions.createSpeiOrPaynetTransaction(purchase.id, 'PayNet', 1))
    }
    modalConfig.onClose()
  }

  const generateCodiTransaction = (qrType: number) => {
    if (payment) {
      if (payment.status === 'PAYMENT_REJECTED') {
        dispatch(transactionActions.createCodiTransaction(payment.purchase.id, 1, qrType, payment.id))
      } else if ((payment.status === 'PENDING' && payment.purchase.actualPaymentIndex + 2 === payment.paymentIndex)) {
        dispatch(transactionActions.createCodiTransaction(payment.purchase.id, 0, qrType, payment.id))
      }
    } else if (purchase) {
      dispatch(transactionActions.createCodiTransaction(purchase.id, 1, qrType))
    }
    modalConfig.onClose()
  }

  return (
    <ModalContainer {...modalConfig} type="light">
      <ModalSelectPaymentStyle>
        {
          !walletScreen ?
            <>
              <div className="modal-to-paid-description">
                <p>Elige un método de pago</p>
              </div>
              <div className="modal-to-paid-content">
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '320px', justifyContent: 'center', alignItems: 'center ' }}>
                  <div className='payment-method-item' onClick={onClick}>
                    <p style={{ margin: '25px 50px', textAlign: 'center' }}>Pago con Tarjeta</p>
                  </div>

                  <div className='payment-method-item' onClick={generatePaynetTransaction}>
                    <p style={{ margin: '25px 50px', textAlign: 'center' }}>Efectivo</p>
                  </div>

                  <div className='payment-method-item' onClick={generateSPEITransaction}>
                    <p style={{ margin: '25px 50px', textAlign: 'center' }}>Transferencia (SPEI)</p>
                  </div>

                  <div className='payment-method-item' onClick={() => { setWalletScreen(true) }}>
                    <p style={{ margin: '25px 50px', textAlign: 'center' }}>Wallet</p>
                  </div>
                </div>
              </div>
            </> :
            <>
              <div className="modal-to-paid-description">
                <p>Elige una wallet</p>
              </div>
              <div className="modal-to-paid-content">
                <p style={{ textAlign: 'center' }}>Al seleccionar una opción se mostrará un código QR, el cual deberás escanear con la app de la wallet.</p>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '320px', justifyContent: 'center', alignItems: 'center'}}>
                  {
                    wallets.map((wallet: any, key: number) => {
                      return (
                        <div key={key} className='wallets-items' onClick={() => {
                          //setWalletQR(wallet.qr) 
                          generateCodiTransaction(wallet.qr)
                        }}>
                          {wallet.id == 476 ?
                            <img src={wallet476} className="wallet-img" />
                            : wallet.id == 233 ?
                              <img src={wallet233} className="wallet-img" />
                              : wallet.id == 268 ?
                                <img src={wallet268} className="wallet-img" />
                                : wallet.id == 399 ?
                                  <img src={wallet399} className="wallet-img" />
                                  : wallet.id == 433 ?
                                    <img src={wallet433} className="wallet-img" />
                                    : wallet.id == 987 ?
                                      <img src={wallet987} className="wallet-img" />
                                      : wallet.id == 467 ?
                                      <img src={wallet467} className="wallet-img" />
                                      : wallet.id == 825 &&
                                      <img src={wallet825} className="wallet-img" />}

                          <p className="wallets-items_text">{wallet.name}</p>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </>
        }
      </ModalSelectPaymentStyle>

    </ModalContainer >
  )
}

export default ModalSelectPaymentMethod
