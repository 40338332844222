import produce from 'immer';

import { IModalActions, IModalState, IModalReducer } from './types';

export const initialState: IModalState = {
  options: null,
};

const loaderReducer: IModalReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case IModalActions.SHOW:
        if (!draft.options) draft.options = {};
        draft.options.show = true;
        draft.options.idModal = action.payload;
        break;

      case IModalActions.HIDE:
        if (!draft.options) draft.options = {};
        draft.options.show = false;
        break;

      default:
    }
  });

export default loaderReducer;
