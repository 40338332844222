import styled from 'styled-components'

export const GridCommerce = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: auto;
    grid-gap: 1.4rem;
    margin:2rem 4rem;

  
    /* Tamano Grande */
    @media (max-width: 992px) { 
        grid-template-columns: repeat(3,1fr);
    }
      /* Tamaño mediano */
      @media (max-width: 768px) { 
        grid-template-columns: repeat(2,1fr);
    }

    /* Tamaño pequeno */
    @media (max-width: 576px) { 
        display: flex;
        flex-direction: column;
    }

    .boxElement{
        background-color: #ebedee;
        display: flex;
        flex-direction: column;
        -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        -moz-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 4px;
        margin-top: 2rem;

        &:hover{
            box-shadow: rgba(12,164,255,0.16) 0px 8px 6px,rgba(9,104,125,0.23) 0px 6px 6px;
            transform: scale(1.05);
            transition: .3s ease;
        }
    }
    .imgContent{
        display:flex;
        position: relative;
        &:hover {
            opacity: .87;
            .text {
                display: block;
                font-size: 27px;
                opacity: .8;
                background-color: #4FB2C6D6;
                border-radius: 8px;
                color: white;
                padding: 15px 32px;
                text-align: center;
                text-decoration: none
            }
        }
        @media(max-width: 576px){
            .text{
                font-size: 17px;
                opacity: 0.9;
                display: block;
                background-color: #4FB2C6D6;
                border-radius: 8px;
                color: white;
                padding: 15px 32px;
                text-align: center;
                text-decoration: none
            }
        }
           
    }
    .imgCommerce{
        height: 300px;
        object-fit: cover;
        border-radius: 4px;
                 /* Tamaño pequeno */
        @media (max-width: 576px) { 
            height: 180px;
        }
    }
    .textCommerceContent{
        display: flex;
        height: 100px;
        font-size: 22px;
        color: rgb(41, 41, 41);
        text-shadow: rgb(139, 144, 167) 2px 2px 2px;
        align-items: center;
        justify-content: center;
        /* Tamaño pequeno */
        @media (max-width: 576px) { 
            height: 80px;
        }
    }
    .textCommerce{
        font-weight:500;
        /* Tamaño pequeno */
        @media (max-width: 576px) { 
            font-size: 18px;
        }
    }

    .text {
        position: absolute;
        top: 45%;
        left: 0%;
        right: 0%;
        text-align: center;  
        cursor: pointer;
        display: none;
    }

`;

export const ContainerTitle = styled.div`
    display: flex;
    margin:2rem 4rem;
    justify-content: center;

    .title{
        font-size:2rem;
        letter-spacing: 2px;
        @media (max-width: 992px) { 
            margin-top:2rem;
        }
    }

`