import React from 'react';
import { useForm } from 'react-hook-form';
import {
  getLoginInputMetada,
  LOGIN_EMAIL_INPUT_NAME,
  LOGIN_REPEAT_PASSWORD_INPUT_NAME,
  LOGIN_PASSWORD_RECOVER_INPUT_NAME,
} from '../../Auth-inputs-names';
import { getLoginFormErrors, getBasicDataFormErrors } from '../../Auth-inputs-validations';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../../../../store/auth/actions';
import { authSelectors } from '../../../../../store/auth/selectors';
import { useHistory, useParams } from 'react-router-dom';
import PasswordBox from '../../../../../components/form-components/password-box/password-box';
import MainButton from '../../../../../components/main-button/main-button';
import styled from 'styled-components';
import { SVGIcon } from '../../../../../components/svg-icon/svg-icon';
import { icons } from '../../../../../assets/themes';

// TODO: MOVE THIS
const ResetPassStyle = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f2f2f3;
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .form-button {
    width: 13.25rem;
    margin: 20px auto;
  }
  .main-title {
    margin-bottom: 60px;
  }
  .form-p {
    text-align: left;
    font-weight: 300;
    width: 440px;
    span {
      color: #1a4ccd;
      font-weight: 500;
      button {
        font-size: 1rem;
        font-weight: 500;
        &:after {
          content: '';
          position: absolute;
          width: 7rem;
          height: 1px;
          background: #1a4ccd;
          bottom: -2px;
          left: 6%;
        }
      }
    }
    .bold {
      color: #01030c;
      font-weight: 500;
    }
  }
`;

const ResetPassword: React.FC = () => {
  const currentEmail = useSelector(authSelectors.getEmail());
  // This should be refactored
  const getFormDefaultValues = () => {
    if (!currentEmail) {
      return {};
    }
    const formData = {
      [LOGIN_EMAIL_INPUT_NAME]: currentEmail,
    };
    return formData;
  };
  // End refactoring

  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();
  const { register, handleSubmit, errors, watch } = useForm({ defaultValues: getFormDefaultValues() });

  const passwordInput = watch(LOGIN_PASSWORD_RECOVER_INPUT_NAME);

  const handleForm = async (data: any) => {
    try {
      await dispatch(authActions.resetPassword(data[LOGIN_PASSWORD_RECOVER_INPUT_NAME], token));
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  const validatePassword = (value: string) => {
    return value === passwordInput;
  };

  return (
    <ResetPassStyle>
      <div className="main-title">
       <SVGIcon icon={icons.slightpayLogo} scale='2.5' color='black'/>
      </div>
      <div className="form-p">
        <PasswordBox
          className="col-sm-12 form-input"
          labelText="nueva contraseña"
          name={LOGIN_PASSWORD_RECOVER_INPUT_NAME}
          inputRef={register(getLoginInputMetada(LOGIN_PASSWORD_RECOVER_INPUT_NAME))}
          errorCode={getBasicDataFormErrors(errors, LOGIN_PASSWORD_RECOVER_INPUT_NAME)}
          onKeyPress={handleSubmit(handleForm)}
        />
        <PasswordBox
          className="col-sm-12 form-input"
          labelText="repetir contraseña"
          name={LOGIN_REPEAT_PASSWORD_INPUT_NAME}
          inputRef={register(
            getLoginInputMetada(LOGIN_REPEAT_PASSWORD_INPUT_NAME, { passwordMissmatch: validatePassword }),
          )}
          errorCode={getLoginFormErrors(errors, LOGIN_REPEAT_PASSWORD_INPUT_NAME)}
          onKeyPress={handleSubmit(handleForm)}
        />
        <MainButton className="form-button" type="primary" text="Confirmar" onClick={handleSubmit(handleForm)} />
      </div>

    </ResetPassStyle>
  );
};

export default ResetPassword;
