import styled from 'styled-components/macro'

// TODO: very important to refact calc(100% - 380px);  for a constant
const LightpayAppContainer = styled.div`
  /* width: 100vw;
  height: 100vh; */
  /* overflow: auto; */
  background-color: #f2f2f3;
  display: flex;

  #main-content-app {
    /* overflow: auto; */
    width: 100%;

    &.main-aside-open {
      width: calc(100% - 380px);
    }
  }

  #main-sidebar-app {
    width: 0px !important;
    display: none;
    &.main-aside-open {
      display: block;
      width: 380px !important;
    }
  }

  #lightpay-app-main {
    /* height: calc(100vh - 70px); */
    /* overflow: auto; */

    margin: 0 auto;
    /* overflow: auto; */

    & > div.main-aside-open {
      min-width: 700px;
    }
  }

  @media (max-width: 992px ) {
    #lightpay-app-main {
      margin: 60px auto;
    }
  }
  @media (max-width: 576px ) {
    #lightpay-app-main {
      margin: 60px auto;
    }
  }
`

export default LightpayAppContainer
