import produce from 'immer';

import { IPaymentSidebarActions, IPaymentSidebarState, IPaymentSidebarReducer } from './types';

export const initialState: IPaymentSidebarState = {
  isOpen: false,
  loading: false,
  selectedPurchased: null,
  selectedPaymentPartition: {}
};

const paymentSidebarReducer: IPaymentSidebarReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case IPaymentSidebarActions.OPEN_SIDEBAR:
        draft.isOpen = true;
        break;
      case IPaymentSidebarActions.CLOSE_SIDEBAR:
        draft.isOpen = false;
        break;    
      case IPaymentSidebarActions.TOGGLE_SIDEBAR:
        draft.isOpen = !draft.isOpen;
        break;    
      case IPaymentSidebarActions.LOADING_SIDEBAR:
        draft.loading = true;
        break;
      case IPaymentSidebarActions.LOADED_SIDEBAR_DATA:
        draft.loading = false;
        break;      
      case IPaymentSidebarActions.SAVED_SIDEBAR_DATA:
        draft.selectedPurchased = action.payload
        break;         

    }
  });

export default paymentSidebarReducer;
