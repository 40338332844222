import { createGlobalStyle } from 'styled-components';

const CustomScroll = createGlobalStyle`
  .track-horizontal {
    background: #ff00ff77 !important;
    width: 100%;
    bottom: 0;
  }

  .thumb-horizontal {
    background: #ff00ff !important;
  }

  .input-box-main-field-select .track-vertical {
    background: #e8edfa !important;
    min-width: 10px;
    padding: 2px;
    height: 100%;
    right: 0;
  }

  .input-box-main-field-select .thumb-vertical {
    background: #1a4ccd !important;
    border-radius: 3px;
  }

  textarea::-webkit-scrollbar {
    background: transparent !important;
    width: 3px;
    padding: 2px;
    height: 100%;
    right: 0;
  }

  textarea::-webkit-scrollbar-thumb {
    cursor: pointer; 
    background: #D9D9DB !important;
    margin-left: 3px !important; 
    height: 53px;
    border-radius: 3px;
  }
`;

export default CustomScroll;
