import { Validator } from 'class-validator';
import PasswordValidator from 'password-validator';
import { differenceInYears } from 'date-fns';
import parse from 'date-fns/parse';

// EMPTY
export const isEmpty = (value: any) => value === null || value === undefined || value === '';

// EMAIL
export const isValidEmail = (mail: string) => {
  const validator = new Validator();
  return validator.isEmail(mail);
  // /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail)
};

// COLLEGE NUMBER
export const isValidCollegeNumber = (collegeNumber: string) => /^\d{3,10}$/.test(collegeNumber);

// DATE
export const isValidDate = (date: string) => /^([0-2][0-9]|(3)[0-1])-(((0)[0-9])|((1)[0-2]))-\d{4}$/.test(date);

export const isValidNumber = (number: string) => /^\d+(\.\d+)?$/.test(number);

// Documento de Identidad (DNI/NIF, NIE, Passport)
export const isValidDocumentID = (text: string, elemID: string) => {
  if (['NIF', 'NIE', 'DNI'].includes(elemID)) {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const nieRexp = /^[XYZ012][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const str = text.toString().toUpperCase();

    if (elemID === 'NIF' && !nifRexp.test(str)) return false;
    if (elemID === 'NIE' && !nieRexp.test(str)) return false;

    const nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (validChars.charAt(charIndex) === letter) return true;

    return false;
  }
  if (elemID === 'PASP') {
    const pass_esp = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
    const pass_world = /^[A-Za-z0-9]{9}$/i;
    return pass_esp.test(text) || pass_world.test(text);
  }  if (elemID === 'NIFP') {
    return true;
  } else {
    return false;
  }
};

// PHONE (ES)
export const isValidPhone_ESP = (phone: string) => (phone ? /^\d{9}$/.test(phone) : true);

// PHONE INTERNATIONAL
export const isValidPhone = (number: string) => {
  const validator = new Validator();
  return number ? validator.isPhoneNumber(number, 'ZZ') : true;
  // /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail)
};

// name and last name
export const isValidName = (text: string) => {
  // eslint-disable-next-line no-useless-escape
  if (/(?=.*[!¡@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\d])/.test(text)) {
    return false;
  } else return true;
};

// URL
export const isValidUrl = (url: string) => {
  const validator = new Validator();

  if (
    !validator.isURL(url, {
      protocols: ['https'],
      require_protocol: true,
      require_valid_protocol: true,
    })
  ) {
    return false;
  }

  if (url.indexOf('www.') !== -1) {
    const dividedUrl = url.split('//');
    const newUrl = dividedUrl[1].split('.');
    if (newUrl[1].length >= 2) {
      return true;
    } else return false;
  } else {
    const dividedUrl = url.split('//');
    const newUrl = dividedUrl[1].split('.');
    if (newUrl[0].length >= 2) {
      return true;
    } else return false;
  }
};

// VALID PDF
export const isValidPdf = (file: File) => {
  if (!file.type) return false;
  const type = file.type.split('/');
  if (type[1] !== 'pdf') {
    return false;
  } else return true;
};

// VALID XML
export const isValidXML = (file: File) => {
  if (!file.type) return false;
  const type = file.type.split('/');
  if (type[1] !== 'xml') {
    return false;
  } else return true;
};

// VALID PNG/JPG/JPEG
export const isValidImage = (file: File) => {
  if (!file.type) return false;
  const arrayFiles: string[] = ['png', 'jpeg'];
  const type = file.type.split('/');
  if (!arrayFiles.includes(type[1])) {
    return false;
  } else return true;
};

// VALID FILE SIZE
export const isValidSize = (file: File) => {
  if (!file.type) return false;
  if (file.size > 6000000) {
    return false;
  } else return true;
};

// FOR SELECTS
export const isValidSelect = (text: string) => {
  if (text === 'Seleccionar') {
    return false;
  } else return true;
};

// CARD
export const isValidCard = (text: string) => {

  const number = text.split(' ').join('');

  if (number.length < 15 || number.length > 16) return false;

  if (number.length === 15 && number.substr(0, 2) !== '34' && number.substr(0, 2) !== '37') return false;

  if (number.length === 16) {
    const number = text.split(' ').join('');
    console.log((window as any).OpenPay.card.validateCardNumber(number), number)
    return (window as any).OpenPay.card.validateCardNumber(number)
    // const firstDigits = parseInt(number.substr(0, 2), 10);
    // if (number.substr(0, 1) !== '4' && (firstDigits < 51 || firstDigits > 55)) return false;
  }

  return number.match(/[0-9]/);
};

// CIF
export const isValidCif = (text: string) => {
  if (!text || text.length !== 9) return false;

  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'N', 'P', 'Q', 'R', 'S', 'U', 'V', 'W'];
  const letter = text.substr(0, 1);
  const province = text.substr(1, 2);
  const control = text.substr(text.length - 1);

  if (letters.indexOf(letter) === -1) return false;

  if ((['P', 'Q', 'R', 'S', 'W'].indexOf(letter) !== -1 || province === '00') && !control.match(/[A-Z]/)) return false;

  if (['A', 'B', 'E', 'H'].indexOf(letter) !== -1 && !control.match(/[0-9]/)) return false;

  const central = text.substr(1, 7);

  if (Number.isNaN(parseInt(central, 10))) return false;

  let sum = 0;

  central.split('').forEach((value, index) => {
    if ((index + 1) % 2 === 0) {
      sum += parseInt(value, 10);
    } else {
      let doubleValue = parseInt(value, 10) * 2;
      if (doubleValue > 9) doubleValue -= 9;
      sum += doubleValue;
    }
  });

  const units = sum % 10;
  const digit = units === 0 ? 0 : 10 - units;
  const relation = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];

  if (control !== digit.toString() && control !== relation[digit]) return false;

  return true;
};

// password
export const isValidPassword = (password: string) => {
  const validator = new PasswordValidator();
  validator
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols();

  return validator.validate(password) as boolean;
};
export const hasNumber = (text: string) => {
  return /\d/.test(text);
};

export const isNumeric = (text: string) => {
  return /^\d+$/.test(text);
};

// IS UNDER-AGE
export const isUnderAge = (maxAge: number, birthdate: string) => {
  const birthDate = parse(birthdate);
  const age = differenceInYears(new Date(), birthDate);

  return age <= maxAge;
};

// rfc mexico
export const isRFC = (rfc: string) => /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/.test(rfc);
