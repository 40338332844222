import styled from "styled-components";

export const LegalStyle = styled.div`
  max-width: 800px;
  margin: 180px auto;
  .legal-title {
    font-size: 48px;
    line-height: 50px;
    color: #01030c;
    margin-bottom: 100px;
    font-weight: 600;
  }
  .legal-section {
    margin-bottom: 72px;
  }
  .legal-subtitle {
    font-weight: 600;
    margin-bottom: 28px;
    font-size: 24px;
    line-height: 30px;
  }
  .legal-last-update {
    font-weight: bold;
  }

  .legal-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }

    a {
      color: #1a4ccd;
      text-decoration: underline;
    }

    ul  {
      padding-left: 80px;
      margin: 40px 0;
      li {
        margin-bottom: 8px;
        position: relative;
        &::before {
          content: "";
          width: 4px;
          height: 4px;
          position: absolute;
          left: -12px;
          top: 8px;
          background: #01030c;
          border-radius: 100%;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
     padding: 0 40px;
  }
  @media (max-width: 768px) {
    margin: 180px auto;
    width: 100%;
    .legal-text {
        ul  {
        padding-left: 40px;
      }
    }

    .legal-title {
      font-size: 30px;
      line-height: 36px;
    }
    .legal-subtitle {
      font-size: 18px;
      line-height: 24px;
    }
    .legal-text {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;