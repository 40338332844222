import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { MobileHeaderContainer } from './mobile-styles'

import { icons } from '../../../assets/icons/icons'

//Hooks
import { useMobileHeader } from '../../../hooks/useMobileHeader/use-mobile-header'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../../store/auth/actions'
import { SVGIcon } from '../../svg-icon/svg-icon'
import { images } from '../../../assets/themes'
import { authSelectors } from '../../../store/auth/selectors'
import { IUser } from '@aluxion-lightpay/interfaces'
import { transformFile } from '../../../utils/utils'
import { AuthActions } from '../../../store/auth/types'
import ModalInfo from '../../modals/modal-info/modal-info'
import { modalSelector } from '../../../store/modal/selectors'
import { modalActions } from '../../../store/modal/actions'
import amplitude from 'amplitude-js'


const LOGOUT_TEXT = 'Cerrar Sesión';

const MobileHeader = () => {
  const scoringStatus = useSelector(authSelectors.getScoringStatus())
  const mobileHeaderActions = useMobileHeader();
  const dispatch = useDispatch();
  const { image } = useSelector(authSelectors.getMe()) as IUser;
  const isModal = useSelector(modalSelector.isShowing());
  const [approvedUser, setApprovedUser] = useState(true)

  const openModal = async (id: number | string) => {
    await dispatch(modalActions.show(id));
  };

  const closeModal = async () => {
    await dispatch(modalActions.hide());
  };

  const handleLogout = () => {
    dispatch(authActions.logout());
    amplitude.getInstance().logEvent('logged out');
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
    closeModal();
  }

  // TODO: CREATE A HOOK FOR THIS
  const [imageUrl, setImageUrl] = useState<any>();
  if (image instanceof Blob) {
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(image);
  } else if (image) {
    transformFile(image.url, image.name, image.mimetype).then(data => {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(data);
      dispatch({
        type: AuthActions.SET_PROFILE_IMAGE,
        payload: data,
      });
    });
  }
  return (
    <MobileHeaderContainer>
      <div id="nav-mobile">
        <div id="nav-menu">
          <div className="img-container" onClick={mobileHeaderActions.toggleMenu}>
            {/* <img
              src={icons.navbarHamburgerIcon}
              alt="Lightpay menu icon"
              onClick={mobileHeaderActions.toggleMenu}
            /> */}
            <SVGIcon icon={icons.navbarHamburgerIcon} scale='1' color='black' />
          </div>
        </div>
        <div id="nav-logo">
          <div className="img-container">
            <SVGIcon icon={icons.slightpayLogo} scale='1' color='black' />
          </div>
        </div>
        <div id="nav-toolbar">
          {/* <div className="img-container">
            <img
              src={icons.navbarNotiPending}
              alt="Notificaciones del Navbar"
            />
          </div> */}
          <Link to="/perfil/datos-personales">
            <div id="img-profile">
              <img src={imageUrl ? imageUrl : images.defaultProfileImage} alt="user-profile" />
              {!approvedUser ?
                <div className="invalid-finerio"></div> :
                <div className="approved-finerio"></div>}
            </div>
          </Link>
        </div>
      </div>

      {mobileHeaderActions.isMenuOpen ? (
        <div id="nav-full-menu">
          <div id="nav-close">
            <div className="img-container" onClick={mobileHeaderActions.toggleMenu}>
              {/* <img
                src={icons.navbarCloseIcon}
                alt="Lightpay close icon"
                onClick={mobileHeaderActions.toggleMenu}
              /> */}
              <SVGIcon icon={icons.navbarCloseIcon} scale='1' color='black' />
            </div>
          </div>
          <div id="nav-actions" onClick={mobileHeaderActions.toggleMenu}>
            {/**  TODO: hay que mover las opciones 
                        dentrod e cada componente a un json */}

            <NavLink exact to="/" activeClassName="active">
              Resumen
            </NavLink>
            <NavLink to="/mi-cartera" activeClassName="active" onClick={() => { amplitude.getInstance().logEvent('get wallet'); }}>
              Mi cartera
            </NavLink>
            <NavLink
              to="/mi-actividad/agenda-de-pagos"
              activeClassName="active"
              onClick={() => { amplitude.getInstance().logEvent('get activity'); }}
            >
              Mi actividad
            </NavLink>
            <a href="https://slightpay.com/donde-comprar" rel="noopener noreferrer" target="_blank" onClick={() => { amplitude.getInstance().logEvent('get stores'); }}>
              Dónde Comprar
            </a>
            {/* <NavLink to="/promociones" activeClassName="active">
              Promociones
            </NavLink> */}
          </div>
          <div id="nav-logout" onClick={() => openModal('logoutWarning')}>
            <div className="img-container">
              <img
                src={icons.navbarLogoutIcon}
                alt="Lightpay close icon"
                onClick={mobileHeaderActions.logout}
              />
            </div>
            <div>
              <p>{LOGOUT_TEXT}</p>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <ModalInfo
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'logoutWarning') || false,
          onClose: closeModal,
        }}
        title={'¿Estás seguro de cerrar sesión?'}
        text=""
        buttons={[
          {
            text: 'Cancelar',
            type: 'ghost',
            onClick: closeModal,
          },
          {
            text: 'Sí',
            type: 'primary',
            onClick: handleLogout,
          },
        ]}
      />

    </MobileHeaderContainer>
  )
}

export default MobileHeader
