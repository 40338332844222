interface ITextError {
    [key: string]: string;
  }
  
  export const ApiErrorCodes: { [key: string]: ITextError } = {
    LOGIN: {
      RESOURCE_NOT_FOUND: 'Usuario o contraseña incorrectos.',
      PASSWORD_MISMATCH: 'Usuario o contraseña incorrectos.',
      INTERNAL_SERVER_ERROR: 'Error interno. Contactar con servicios centrales.',
      ENTITY_NOT_FOUND: 'Usuario o contraseña incorrectos.',
    },
    FORGOT: {
      RESOURCE_NOT_FOUND: 'Usuario no encontrado en el sistema.',
    },
    CHECK: {
      ENTITY_NOT_FOUND: 'El correo ingresado no pertenece a ningún usuario.',
    },
    REGISTER: {
      EMAIL_EXIST: 'El correo ya esta registrado.',
      RFC_EXIST: 'El RFC ya esta registrado.',
      EMAIL_NO_VALID: 'No es posible registrarse con este email, verifica la direccion de correo',
      RFC_NO_VALID: 'El RFC no es válido, por favor verifiquelo y vuelva a intentar.'
    },
    PROPOSAL: {
      ERROR: 'Error',
    },
};