import { IBaseAction } from '../base-action.interface';

export enum ILoadingActions {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export interface ILoadingState {
  readonly loading: boolean;
}

export interface ILoadingReducer {
  (state: ILoadingState, action: ILoadingActionsTypes): ILoadingState;
}

export interface ILoadingAction extends IBaseAction {
  type: ILoadingActions.LOADING;
}

export interface ILoadedAction extends IBaseAction {
  type: ILoadingActions.LOADED;
}

export type ILoadingActionsTypes = ILoadingAction | ILoadedAction;
