import React, { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/es.js';

const bizSdk = require('facebook-nodejs-business-sdk');
const Content = bizSdk.Content;
const CustomData = bizSdk.CustomData;
const DeliveryCategory = bizSdk.DeliveryCategory;
const EventRequest = bizSdk.EventRequest;
const UserData = bizSdk.UserData;
const ServerEvent = bizSdk.ServerEvent;

const pixel_id = '340936073703925';
const access_token = 'EAAS5HZCl5pc8BAEOdKhRRiG5oxWGvtEZAnIkakyLTFkxZCScaIDT8j15t8dibxQoZBz8e63PTmbc5ZCmKZCpNZAi9RO7cFgxZCuyl4OLDx7ZBlZBKjAlWnvpjqtRCoWEKZA1q1Q2CqTHygO7Sv27n366t6DZBvnXJxctqR4BCvUEE1zltLoOwEHuMv1u6ZAgywYH98pIZD';
const api = bizSdk.FacebookAdsApi.init(access_token);

const SendApiConversion = (name: any, email:any =[''], phone: any=[''], product='',custom:any ='') => {
  console.log(name,email,phone,product)
  const userData = (new UserData())
  .setEmails(email)
  .setPhones(phone)

  const customData = (new CustomData())
    .setCustomProperties(custom);

const serverEvent = (new ServerEvent())
            .setEventName(name)
            .setEventTime(new Date())
            .setUserData(userData)
            .setCustomData(customData)
            .setEventSourceUrl('clients.slightpay.com')
            .setActionSource('website');

  const eventsData = [serverEvent];

  const eventRequest = (new EventRequest(access_token, pixel_id))
    .setTestEventCode("TEST92680")
    .setEvents(eventsData);


  //console.log("Hola")  
  eventRequest.execute().then(
  (response: any) => {
  console.log('Response: ', response);
  },
  (err: any) => {
  console.error('Error: ', err);
  }
  );
}

export default SendApiConversion;
