import React from 'react';
import { NoContentTableStyle } from './no-content-table-style';

interface INoContentTable {
  text: string;
}

const NoContentTable: React.FC<INoContentTable> = ({ text }) => {
  return (
    <NoContentTableStyle>
      <p>{text}</p>
    </NoContentTableStyle>
  );
};

export default NoContentTable;
