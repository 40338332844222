import React from 'react';
import CreditCardInfoContainer from './credit-card-info-style';
import { icons } from '../../../assets/themes';
import { CreditCardInfoProps } from './credit-card-type';

const CreditCardInfo: React.FC<CreditCardInfoProps> = (props: CreditCardInfoProps) => {
    const  {
        lastDigits, 
        validDate, 
        street, 
        location,
        type,
        deleteCard,
        // setDefaultCard,
        // currentCardStatus
    } = props;
    // const { items } = useSelector(transactionSelector.getTransactionDataTable()) as ITransactionPurchase;
    return (
        <CreditCardInfoContainer>
            <div className="credit-card-info-topbar">
                <div className="title">
                    <p>
                        {type === 'visa' ? 'Visa' : 'Mastercard'} **** { lastDigits }
                    </p>
                </div>

                <div className="valid-date">
                    <p>
                        { validDate }
                    </p>
                </div>

                <div className="actions-icons-container">
                    {/** This two div should be buttons, not div. Accesibility problems */}
                    {/** Right now there is not time for see this */}
                    {/** currentCardStatus === true means is the default one */}
                    {/* {
                        items.length !== 0 &&
                        (currentCardStatus ? (
                            <div className="fav-icon">
                                <img src={icons.addFavoriteSelected} alt='botón de favorito activo'/>
                            </div>
                        ) : (
                            <div className="fav-icon" onClick={setDefaultCard}>
                                <img src={icons.addFavorite} alt='botón de favorito'/>
                            </div>
                        ))
                    
                        
                    } */}
                   

                    <div className="trash-icon" onClick={deleteCard}>
                        <img src={icons.addTrash} alt='botón de borrar'/>
                    </div>
                </div>
            </div>

            <div className="credit-card-info-address-container">
                <div className="address-label">
                    <b><p>Dirección asociada</p></b>
                </div>

                <div className="address">

                    <p>{ street }</p>

                    <p>{ location }</p>

                </div>
            </div>
        </CreditCardInfoContainer>
    )
}

export default CreditCardInfo; 