import styled from 'styled-components/macro';

//Components
import BaseLayoutContainer from '../../../components/layout/basicLayoutContainer';

//Images
import {images} from '../../../assets/images/images';

const MyActivityContainer = styled<any>(BaseLayoutContainer)`
    padding-left: 0px !important;
    padding-right: 0px !important;
    overflow: hidden;

    #heroblock {
        position: relative;
        width: 100%;
        height: 350px;
        background-image: url(${images.myActivityHeroblock});
        background-size: cover;
        background-position: 15% 0px;
        background-repeat: no-repeat;
        display: flex;
        align-items: flex-end;

        #heroblock-nav {
            display: flex;
            width: 100%;
            justify-content: space-between;
            color: white;
            margin-bottom: 30px;
            
            #nav-opts {
                display: flex;
                font-size: 30px;

                a {
                    opacity: 0.4;
                    padding: 0 20px;
                    border-left: 1px solid gray;
                }

                a.first {
                    padding: 0px 0px;
                    padding-right: 20px;
                    border-left: 0px solid gray;
                }

                a.active {
                    opacity: 1;
                    font-size: 30px;
                }
            }

            #filters-opt {
                display: flex;
                align-items: center;
                cursor: pointer;
                p {
                    font-size: 14px;
                    margin-right: 10px;
                }
            }
        }
    }

    #heroblock-nav,
    .content-table {
        padding: 0 80px;
    }

    .content-table {
        margin-top: 30px;
        margin-bottom: 40px;
    }

    @media (max-width: 992px) {
        #heroblock-nav,
        .content-table {
            padding: 0 45px;
        }
    }

    @media (max-width: 768px) {
        #heroblock-nav,
        .content-table {
            padding: 0 30px;
        }
        #heroblock {
               
            #heroblock-nav {
                               
                #nav-opts {
    
                    a {

                    }
                }
    
                #filters-opt {
                    display: none;
                }
            }
        }

    }

    @media (max-width: 576px) {
        #heroblock {
            height: 256px;
            background-position: center;
        }
        #heroblock-nav,
        .content-table {
            padding: 0 15px;
        }
    }    
`;

export default MyActivityContainer;