import React, { useState } from 'react';
import { icons } from '../../../../assets/themes'
import { ERROR_MESSAGES } from '../../../../constants/errorMessages';
import ErrorText from '../../../components-text/error-text/error-text';

// Styles
import AttachInputContainer from './attach-input-style';

// Types
import { IAttachInputFileProps, IUseAttachInputFileType } from './attach-input-types';

const useFileInput: IUseAttachInputFileType = (props: IAttachInputFileProps) => {
  const [isFocus, setIsFocus] = useState(false); // Maybe pass a initState ?
  const [added, setAdded] = useState(false);
  const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  const imgInputRef: React.RefObject<HTMLImageElement> = React.createRef<HTMLImageElement>();

  /* Open File dialog
   *  Simulate click in hidden file input to open filesystem dialog
   */
  const handleOpenFileDialog = (): void => {
    if (props.disabled) return;

    fileInputRef.current && fileInputRef.current.click();
  };

  // this should be move out or this,
  // Mery i do this later i promise, now we are out of time
  const filesToArray = (list: FileList, multi: boolean | undefined): Array<File | null> => {
    const array = [];

    const limit = multi ? list.length : 1; // return all files, o just the first added

    for (let i = 0; i < limit; i++) {
      array.push(list.item(i));
    }

    return array;
  };

  /* On filed Added hanlder
   * Get files added, transform the in an array a then trigger onFilesAdded fn
   */
  const handleOnFilesAdded = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.disabled) return;
    // console.log(evt.target.files[0]); // THIS WAS COMMENTED TO APPLY CUSTOM BEHEAVOR ON LIGHTPAY
    /*if (evt.currentTarget.files) {
      const files = filesToArray(evt.currentTarget.files, props.multi);

      // Workaround for typescript error (https://bit.ly/2lHr9fI)
      // Move to separate fn
      const notNullFiles: Array<File> = [];
      files.forEach(elem => {
        // We remove the posible null files in files array
        if (elem) notNullFiles.push(elem);
      });

      props.onFilesAdded && props.onFilesAdded(notNullFiles);
    }*/ if (
      evt.target.files
    ) {
      const notNullFiles: Array<any> = [];
      notNullFiles.push(evt.target.files[0]);
      props.onFilesAdded && props.onFilesAdded(notNullFiles);
      setAdded(true);
    }
  };

  /*  On drag over canvas handler
   *
   */
  const handleOnDragOver = (evt: React.DragEvent<HTMLDivElement>): void => {
    evt.preventDefault();
    if (props.disabled) return;

    setIsFocus(true);
  };

  /* On drag leave canvas hanlder
   *
   */
  const handleOnDragLeave = (evt: React.DragEvent<HTMLDivElement>): void => {
    evt.preventDefault();
    if (props.disabled) return;

    setIsFocus(false);
  };

  /* On drag leave canvas hanlder
   *  Detect and add files to array
   */
  const handleOnDrop = (evt: React.DragEvent<HTMLDivElement>): void => {
    evt.preventDefault();
    if (props.disabled) return;

    if (props.onFilesAdded) {
      const files = filesToArray(evt.dataTransfer.files, props.multi);

      // Workaround for typescript error (https://bit.ly/2lHr9fI)
      // Move to separate fn
      const notNullFiles: Array<File> = [];
      files.forEach(elem => {
        // We remove the posible null files in files array
        if (elem) notNullFiles.push(elem);
      });

      props.onFilesAdded(notNullFiles);
    }

    setIsFocus(false);
  };

  return {
    isFocus,
    added,
    fileInputRef,
    imgInputRef,
    handleOpenFileDialog,
    handleOnFilesAdded,
    handleOnDragOver,
    handleOnDragLeave,
    handleOnDrop,
  };
};

const AttachFileInput: React.FC<IAttachInputFileProps> = (props: IAttachInputFileProps) => {
  const { textHelp, initialValue } = props;
  const [url, setUrl] = useState<any>('');
  const action = useFileInput(props);
  const errorText = textHelp ? ERROR_MESSAGES[textHelp] : '';
  const attachInputClasses = 'attach-input-file ' + (textHelp && 'error ') + (props.disabled ? 'disabled ' : '');
  if (initialValue instanceof Blob) {
    const reader = new FileReader();
    reader.onload = () => {
      setUrl(reader.result);
    };
    reader.readAsDataURL(initialValue);
  }

  const downloadFile = () => {
    const downloableUrl = window.URL.createObjectURL(initialValue);
    const link: HTMLAnchorElement | string = document.createElement('a');
    link.href = downloableUrl;
    link.setAttribute('download', `${initialValue.name}`);
    link.click();
  };
  return (
    <AttachInputContainer className={` ${props.className} ${attachInputClasses} `}>
      <label htmlFor={props.name}>{props.textTitle}</label>
      <div
        id="canvas"
        onDragOver={action.handleOnDragOver}
        onDragLeave={action.handleOnDragLeave}
        onDrop={action.handleOnDrop}
        onClick={action.handleOpenFileDialog}
        tabIndex={0}
        style={{
          cursor: props.disabled ? 'default' : 'pointer',
        }}
      >
        {initialValue && (
          <div className="thumnail">
            {initialValue.type === 'image/jpeg' || initialValue.type === 'image/png' ? (
              <img src={url} alt="preview..." />
            ) : (
              ''
            )}
          </div>
        )}
        <input
          name={props.name}
          ref={action.fileInputRef}
          className="hidden-file-ipnut"
          type="file"
          multiple={props.multi ? props.multi : false}
          onChange={action.handleOnFilesAdded}
        />
        {/* <img src={} alt=""/> */}
        <div className="icon-img-container">
          {/*TODO: Look a better way to do this. now there's no time **/}
          <img src={!action.added ? icons.cameraGrey : icons.check} alt="icon-camara" />
          <span className="icon-text">{props.textIcon}</span>
        </div>
      </div>
      {initialValue instanceof Blob && (
        <button className="download_button" onClick={downloadFile}>
          Descargar {initialValue.type}
        </button>
      )}
      {/* <small>{props.textHelp}</small> */}
      <ErrorText>{errorText}</ErrorText>
    </AttachInputContainer>
  );
};

export default AttachFileInput;
