import styled from 'styled-components/macro';
import sizes from '../../../assets/sizes/sizes';

const MobileHeaderContainer = styled.div`
    max-width: 100%;
    height: ${sizes.navbar.mobile.height}px;
    position: relative;
    display: flex;
    box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
    background-color: white;
    padding: 15px 20px;
    z-index: 99999;

    .invalid-finerio{
        background: red;
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 500;
        color: white;
        border-radius: 9999px;
        font-size: 12px;
        text-align: center;
        align-items: center;
        bottom: 5px;
    }

    .approved-finerio{
        background: green;
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 500;
        color: white;
        border-radius: 9999px;
        font-size: 12px;
        text-align: center;
        align-items: center;
        bottom: 5px;
    }

    #nav-mobile {
        min-width: 100%;
        display: flex; 
        justify-content: space-between;
        align-items: center;
        z-index: 10;
    }

    #img-profile  {
        border-radius: 100%;
        width: 45px;
        height: 45px;
        background: gray;
        overflow: hidden;
        margin-left: 20px;
        cursor: pointer;

        img {
          background-size: cover;
          background-position: 100%;
        }
      }

    #nav-full-menu {
        display: flex; 
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 20;
        width: 100vw;
        height: 100vh;
        background-color: white;
        top: 0px;
        left: 0px;

        #nav-close {
            position: absolute;
            top: 20px;
            left: 20px
        }

        #nav-actions {
            display: flex;
            flex-flow: column;
            text-align: center;
            a {
                font-size: 24px;
                color: #B3B3B6;
                margin-bottom: 20px;
                font-weight: 500;
            }
    
            a.active {
                color: #1A4CCD;
            }
        }

        #nav-logout {
            position: absolute;
            bottom: 30px;
            cursor: pointer;

            display: flex;
            align-items: center;

            color: #1A4CCD; 
        }

    }
`;

export { MobileHeaderContainer }