import React from 'react'
import LoaderMainSidebarContainer from './loader-style'

const LoaderInfoSidebar: React.FC = () => {

  
  return (
    <LoaderMainSidebarContainer>
      <div className="loader-animation" />
    </LoaderMainSidebarContainer>

  )
}

export default LoaderInfoSidebar;