import React from 'react';

// Styles
import CardStateContainer from './card-state-style';

// Types
import { ICardStateProps } from './card-state-types';

const CardState: React.FC<ICardStateProps> = (props: ICardStateProps) => {
  const { state, text, subText, icon } = props;
  console.log(state, 'el status')

  return (
    <CardStateContainer>
      <div className={`card-state-img ${state}`}>
        {/* xIconSVG
            returnIcon
            okIcon
         */}
        <div className={`card-state-icon ${state}`}>{icon}</div>
      </div>
      <div className="card-state-info">
        <div className="card-state-title">
          <p>{subText}</p>
        </div>
        <div className={'card-state-text ' + state}>
          <p>{text}</p>
        </div>
      </div>
    </CardStateContainer>
  );
};

export default CardState;
