import styled from 'styled-components/macro';

const DobleImageAttachContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 80px 30px;
  justify-content: center;
  background: #fefefe;
  border: 1px solid #f3f3f3;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .attach-container {
    width: 50%;
    margin-bottom: 50px;
  }

  h1 {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .attach-h1 {
    font-weight: 300;
    font-size: 16px;
  }

  .add-files {
    width: 50%;
  }

  @media (max-width: 768px) {
    .attach-container {
      width: 90%;
      margin-bottom: 50px;
    }

    h1 {
      width: 90%;
      font-size: 14px;
      margin-bottom: 40px;
    }

    .add-files {
      width: 90%;
    }
  }
  .attach-button {
    width: 13.25rem;
    margin: 0 auto;
  }
`;

export { DobleImageAttachContainer };
