import React, { useEffect } from "react";
import ModalContainer from "../modal-container/modal-container";
import { ModalErrorStyle } from "./modal-error-style";
import { IModalErrorProps } from './modal-error-types';
import { useSelector } from "react-redux";
import { transactionSelector } from "../../../store/transaction/selector";
import { ITransactionPurchase } from "../../../store/transaction/types";

const ModalErrorCard: React.FC<IModalErrorProps> = (props: IModalErrorProps) => {
  const { modalConfig, title } = props;
  const { items } = useSelector(transactionSelector.getTransactionDataTable()) as ITransactionPurchase;

  useEffect(() => {
    console.log(title, 'the title')
  }, [title])

  return (
    <ModalContainer type="light" {...modalConfig}>
      <ModalErrorStyle>
        {title ?
          <div className="modal-error-title">
            <p>{title}</p>
          </div>
          :
          <>
            <div className="modal-error-title">
              <p>¡Lo sentimos! ha ocurrido un error</p>
            </div>
            <div className="modal-error-text">
              {title ?
                <p>{title}</p>
                :
                <p>{!items ? 'Antes de cambiar su tarjeta predeterminada, por favor confirme alguna compra' :
                  'Por favor, intente de nuevo mas tarde'}
                </p>
              }
            </div>
          </>
        }
      </ModalErrorStyle>
    </ModalContainer>
  );
};
export default ModalErrorCard;
