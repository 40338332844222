import { IBaseAction } from '../base-action.interface';

export enum IModalActions {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

export interface IModalOptions {
  show: boolean;
  idModal: string | number;
}

export interface IModalState {
  readonly options: Partial<IModalOptions> | null;
}

export interface IModalReducer {
  (state: IModalState, action: IModalActionsTypes): IModalState;
}

export interface IShowingAction extends IBaseAction {
  type: IModalActions.SHOW;
  payload: string | number;
}

export interface IHideAction extends IBaseAction {
  type: IModalActions.HIDE;
}

export type IModalActionsTypes = IShowingAction | IHideAction;
