import styled from 'styled-components/macro';

export interface ICardBase {
  base?: string;
}

const CardBase = styled.div<ICardBase>`
  /*________________________________________________________________________*/
  /* This is temporal, until we use a component with a GRID or FLEXBOX property,
  for structuring the card-items*/
  /*
  width: 300px;
  min-height: 140px
  display: inline-block;
  */
  /*________________________________________________________________________*/

  padding: 29px 26px 21px;
  box-shadow: 0px 4px 8px rgba(10, 25, 113, 0.03);
  border-radius: 3px;
  background: ${props => props.theme.colors.surface};
  /* border: 1px solid ${props => props.theme.colors.primaryVariant}; */
`;

export default CardBase;
