import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import MainAside from '../../components/asides/main-aside/main-aside';
import MainHeader from '../../components/header/header';
import LightpayAppRoutes from '../../navigation/lightpay-app-routes';
import LightpayAppContainer from './lightpay-app-style';

// Hooks
import { MainAsideConsumer, MainAsideProvider} from '../../context/mainAside/main-aside-context';

const LightpayApp: React.FC<any> = (props: any) => {


    return(
        <MainAsideProvider>
            <MainAsideConsumer>
                {
                    ({isMenuOpen, htmlContent}) => {
                        return (
                            
                            <LightpayAppContainer>
                                <div id="main-content-app" className={isMenuOpen? 'main-aside-open' : ''}>
                                    <Router>
                                        <MainHeader className={isMenuOpen? 'main-aside-open' : ''}/>

                                        <main 
                                        id="lightpay-app-main" >
                                            <div className={isMenuOpen? 'main-aside-open' : ''}>
                                                <LightpayAppRoutes/>
                                            </div>
                                        </main>            
                                    </Router>
                                </div>

                            
                                <MainAside id='main-sidebar-app' className={isMenuOpen? 'main-aside-open' : ''}>
                                    {
                                        {
                                            content: htmlContent
                                        }
                                    }
                                </MainAside>
                            </LightpayAppContainer>

                        )
                    }
                }

            </MainAsideConsumer>
        </MainAsideProvider>
    )
}

export default LightpayApp;