import React from 'react';
import { ModalInfoStyle } from './modal-info-style';
import ModalContainer from '../modal-container/modal-container';
import MainButton from '../../main-button/main-button';
import { IModalInfo } from './modal-info-types';
import PasswordBox from '../../form-components/password-box/password-box';
import { useForm } from 'react-hook-form';
import { CONFIRM_PASSWORD_INPUT_NAME, getCodeNumberInputMetadata } from '../../../containers/authorization/auth/Auth-inputs-names';
import { getCodeNumberDataFormErrors } from '../../../containers/authorization/auth/Auth-inputs-validations';



const ModalInfo: React.FC<IModalInfo> = ({ title, text, text2, onClick, modalConfig, password, buttons }) => {
  const { register, errors, handleSubmit } = useForm();
  
  const validatePassword = (value: string) => {
    return value === password;
  };

  const handleForm = async (data:any) => {
    onClick &&
    onClick();
  }

  return (
    <ModalContainer {...modalConfig}>
      <ModalInfoStyle>
      <div className="modal-info-title">
          <p>{title}</p>
        </div>
        <div className="modal-info-text">
          <p>{text}</p>
        </div>
        {text2 && (
          <div className="modal-info-text">
            <p>{text2}</p>
          </div>
        )}
        {password && (
          <div className="confirm-input">
            <PasswordBox
              labelText="repetir contraseña"
              name={CONFIRM_PASSWORD_INPUT_NAME}
              inputRef={register(
                getCodeNumberInputMetadata(CONFIRM_PASSWORD_INPUT_NAME, {
                  passwordMissmatch: validatePassword,
                }),
              )}
              errorCode={getCodeNumberDataFormErrors(errors, CONFIRM_PASSWORD_INPUT_NAME)}
              onKeyPress={handleSubmit(handleForm)}
            />
          </div>
        )}
        {password ? (
          <div className="modal-info-buttons">
            <div className="modal-info-buttons__item" key={`modal-info-button-1`}>
              <MainButton text="Rechazar" type="ghost" onClick={modalConfig.onClose} />
            </div>

            <div className="modal-info-buttons__item" key={`modal-info-button-2`}>
              <MainButton text="Aceptar" type="primary" onClick={handleSubmit(handleForm)} />
            </div>
          </div>
        ) : (
          <div className="modal-info-buttons">
            {buttons?.map((item, index) => (
              <div className="modal-info-buttons__item" key={`modal-info-button-${index}`}>
                <MainButton {...item} />
              </div>
            ))}
          </div>
        )}
      </ModalInfoStyle>
    </ModalContainer>
  );
};

export default ModalInfo;
