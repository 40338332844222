import React, { useState } from 'react'

//Components
import MyActivityPaymentRecordContainer from './payment-record-style'

//Constants
import { icons } from './../../../../assets/icons/icons'

import PaymentTableWrapper from '../../../../components/tables/payment-table-wrapper/payment-table-wrapper'
import useUserActivies from '../../../../hooks/useActivity/useUserActivities'
import { SVGIcon } from '../../../../components/svg-icon/svg-icon'
import { formatMoney } from '../../../../utils/utils'
import CircleStat from '../../../../components/circle-stat/circle-stat'
import MainTableHeader from '../../../../components/main-table/main-table-header/main-table-header'
import MainTable from '../../../../components/main-table/main-table-layout';
import SelectBox from '../../../../components/form-components/select-box/select-box'
import CalendarBox from '../../../../components/calendar-box'
import { IFilterTypes } from '../../home/resume/home-resumen'
import Pagination from '../../../../components/pagination/pagination'
import { transactionActions } from '../../../../store/transaction/actions'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import useModal from '../../../../hooks/useModal/use-modal'
import ModalToPaidManual from '../../../../components/modals/modal-to-paid/modal-to-paid-manual'
import { ICard } from '@aluxion-lightpay/interfaces'
import ModalSuccess from '../../../../components/modals/modal-success/modal-success'
import ModalToSelectPaymentMethod from '../../../../components/modals/modal-to-paid/modal-select-payment'
import ModalErrorCard from '../../../../components/modals/modal-error/modal-error'
import ModalErrorCustom from '../../../../components/modals/modal-error/modal-error-custom'

interface IModalsData {
  shopName: string;
  prize: string;
  fee: string | number;
  date: string;
  card?: ICard;
  onClick: (value: any) => void;
  purchaseId: number;
  feeId: number;
  products: any;
}

const MyActivityPaymentRecord: React.FC<any> = (props: any) => {
  // const toPaidModal = useToPaidModal();
  const dispatch = useDispatch();
  const { id, completedPaymentsItems, completedPaymentsCount, items, count } = useUserActivies();
  const [tableSelected, setTableSelected] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<IFilterTypes>({
    status: 'SUCCESSFUL,PENDING,CHARGEBACK_ACCEPTED,REFUNDED_TOTALLY,REFUNDED_PARTIALLY,CHARGEBACK_REJECTED,PREPENDING',
    fromDate: '',
    toDate: '',
    search: '',
  });
  const { isModal, openModal, closeModal } = useModal();
  const [warningKeysData, setWarningData] = useState<IModalsData | null>(null);
  const [selectedCardId, setSelectedCard] = useState<number | null>();
  const [paymentState, setPaymentState] = useState<any>({})
  const [version, setVersion] = useState<number>(0)
  const [title, setTitle] = useState<string>('')
  const [walletScreen, setWalletScreen] = useState<boolean>(false)

  console.log(completedPaymentsItems);

  const payAdvancementPurchase = async (purchaseId: number, cardId: number) => {
    try {
      await dispatch(transactionActions.payPurchasePendingTotal(purchaseId, cardId));
      await dispatch(transactionActions.getHomePaymentsDetails(id))
      openModal('paymentSuccessful');
    } catch (error) {
      console.log(error)
      if (error?.code === 'OPENPAY_EXCEPTION') {
        setTitle(error?.message)
        openModal('error');
      } else if (error?.reasons[0]?.validation === 'OpenPay regreso un error') {
        setVersion(1)
        openModal('error-custom');
      } else if (error?.reasons[0]?.validation === 'Tiene 4 intentos de OpenPay') {
        setVersion(2)
        openModal('error-custom');
      } else if (error?.reasons[0]?.validation === 'No puede adelantar cuotas') {
        setVersion(3)
        openModal('error-custom');
      } else {
        openModal('error');
      }
    }
  }

  const selectedCard = async (currentCardId: number) => {
    setSelectedCard(currentCardId);
  }

  const getPendingPaymentPartitions = (partitions: any) => {
    let totalAmount = 0

    partitions.map((partition: any) => {
      if (partition.status === 'PENDING' || partition.status === 'PAYMENT_REJECTED') totalAmount = totalAmount + partition.amount
    })

    return formatMoney(totalAmount)
  }

  const onPageChanged = async (data: any = 0) => {
    const { currentPage } = data;
    await Promise.all([
      await dispatch(transactionActions.addTransactionSellsData(id, currentPage, filter)),
      await dispatch(transactionActions.addPaymentsData(id, currentPage, filter, 'completed')),
    ])

    setPage(currentPage);
  };

  // FIRST TABLE
  const columnsBuys = [
    {
      text: 'Estado',
      filter: false,
      ascendent: true,
      className: 'table-col-3 start',
    },
    {
      text: 'Tipo',
      filter: false,
      ascendent: true,
      className: 'table-col-1',
    },
    {
      text: 'Id. Transacción',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Fecha',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Nombre del Comercio',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Monto',
      filter: false,
      ascendent: true,
      className: 'table-col-2 end',
    },
  ]

  const rowKeyBuys = ['state', 'type', 'refencialNumber', 'date', 'commerce', 'totalAmount'];
  const rows = items.map(item => {
    let text = '';
    let img: any = '';
    let tipo: string = '';
    let amount: number
    if (item.type === "OneShot") {
      tipo = 'OneShot'
      amount = item.totalOneShot;
    } else {
      tipo = "BNPL"
      amount = item.totalAmount
    }
    switch (item.status) {
      case 'SUCCESSFUL':
        text = 'completada';
        img = <SVGIcon icon={icons.okIcon} color="#4FB2C6" scale="1" />;
        break;
      case 'CANCELED':
        text = 'cancelado';
        img = <SVGIcon icon={icons.rejectedIcon} color="#CA3976" scale="1" />;
        break;
      case 'CANCELED_PENDING':
        text = 'cancelado';
        img = <SVGIcon icon={icons.rejectedPendingIcon} scale="1" />;
        break;
      case 'DRAFT':
        text = 'preparando';
        img = <SVGIcon icon={icons.draftIconSVG} color="#999A9E" scale="1" />;
        break;
      case 'EXPIRED':
        text = 'expirada';
        img = <SVGIcon icon={icons.expiredIcon} scale="1" />;
        break;
      case 'PENDING':
        text = 'exitosa';
        img = <SVGIcon icon={icons.inProgressIcon} color="#4FB2C6" scale="1" />;
        break;
      case 'CHARGEBACK_REJECTED':
        text = 'contracargo';
        img = <SVGIcon icon={icons.chargeBackRejectedIcon} scale="1" />;
        break;
      case 'CHARGEBACK_ACCEPTED':
        text = 'contracargo';
        img = <SVGIcon icon={icons.chargeBackAcceptedIcon} scale="1" />;
        break;
      case 'CHARGEBACK_PENDING':
        text = 'contracargo';
        img = <SVGIcon icon={icons.chargeBackPendingIcon} scale="1" />;
        break;
      case 'REFUNDED_TOTALLY':
        text = 'devolución';
        img = <SVGIcon icon={icons.refundIcon} color="#8CA5E6" scale="1" />;
        break;
      case 'REFUNDED_PENDING':
        text = 'devolución';
        img = <SVGIcon icon={icons.refundPendingIcon} scale="1" />;
        break;
      case 'REFUNDED_PARTIALLY':
        text = 'devolución';
        img = <SVGIcon icon={icons.refundIcon} color="#FFBB36" scale="1" />;
        break;
      case 'INDEBTED':
        text = 'incobrable';
        img = <SVGIcon icon={icons.refundIcon} color="#FFBB36" scale="1" />;
        break;
      case 'PREPENDING':
        text = 'En validacion';
        img = <SVGIcon icon={icons.clockIcon} color="#11c6ffb8" scale="1" />;
        break;
    }
    return {
      state: {
        key: 'icon',
        img,
        text: text.toUpperCase(),
        className: 'table-col-3 start_row',
      },
      type: {
        text: tipo,
        className: 'table-col-1 ',
      },
      refencialNumber: {
        text: item.id,
        className: 'table-col-2',
      },
      date: {
        key: 'date',
        text: moment(item.createdAt).format('L'),
        span: `${moment(item.createdAt).format('LT')}h`,
        className: 'table-col-2 date',
      },
      commerce: {
        text: item.commerce.name,
        className: 'table-col-2 date',
      },
      totalAmount: {
        key: 'action',
        text: formatMoney(amount),
        span: item.status === 'PENDING' && 'PAGAR TODAS LAS CUOTAS',
        click: () => {
          openModal('selectPaymentMethod')
          setPaymentState(item)
          setWarningData({
            shopName: item.commerce.name,
            fee: item.actualPaymentIndex + 1,
            prize: getPendingPaymentPartitions(item.payments),
            date: moment(item?.createdAt).format('L'),
            card: item.payments[0].card,
            onClick: (cardId: number) => {
              payAdvancementPurchase(item.id, cardId)
            },
            purchaseId: item.id,
            feeId: item.payments[item.actualPaymentIndex].id,
            products: item.products
          })
        },
        className: 'table-col-2 end_row',
      },
      link: `/detalle-operacion/${item.id}`,
    };
  });
  // END FIRST TABLE

  // SECOND TABLE
  const columnsDues = [
    {
      text: 'Id. Transacción',
      filter: false,
      ascendent: true,
      className: 'table-col-2 start',
    },
    {
      text: 'Nº cuotas',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Fecha',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Nombre del Comercio',
      filter: false,
      ascendent: true,
      className: 'table-col-2',
    },
    {
      text: 'Importe',
      filter: false,
      ascendent: true,
      className: 'table-col-2 end',
    },

  ];

  const rowKeysDues = ['refencialNumber', 'payments', 'date', 'commerce', 'totalAmount',];

  let completedPaymentsRows = completedPaymentsItems.filter(value => value.paymentIndex != 0)
  const completedPaymentRows = completedPaymentsRows.map((payment, index) => {
    return {
      refencialNumber: {
        text: payment.purchase.id,
        key: 'highlighted',
        className: `table-col-2 start_row ${payment.status === 'CANCELED' && 'canceled'}`,
      },
      payments: {
        key: 'icon',
        text: `${payment?.paymentIndex}/${payment.type ? payment?.type?.substr(0, 1) : 4}`,
        img:
          payment.status === 'CANCELED' ?
            <SVGIcon icon={icons.rejectedIcon} color="#B3B3B6" scale="1.5" />
            :
            <CircleStat
              radius={14}
              stroke={3}
              progress={(payment?.paymentIndex * 100) / Number(payment.type ? payment?.type?.substr(0, 1) : 4)}
              fill={payment.status === 'SUCCESSFUL' ? '#1A4CCD' : '#CA3976'}
            />,
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled cross'}`,
      },
      date: {
        text: moment(payment?.dueDate).format('L'),
        key: 'highlighted',
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled'}`,
      },
      commerce: {
        text: payment.purchase.commerce.name,
        className: `table-col-2 ${payment.status === 'CANCELED' && 'canceled'}`,
      },
      totalAmount: {
        key: 'icon-right',
        text: formatMoney(payment?.amount),
        // img:  payment.status === 'SUCCESSFUL' 
        // ? <SVGIcon icon={icons.tickGreen } color="#1A4CCD" scale="1" />
        // :'',
        // span:`${ item.actualPaymentIndex + 1 === index ? 'A pagar ahora' : '' }`,
        className: `table-col-2 end_row ${payment.status === 'SUCCESSFUL' ? 'payment active' : 'payment'} 
        ${payment.status === 'CANCELED' && 'canceled cross'}`,
      },
      link: `/detalle-operacion/${payment.purchase.id}`,
    }
  });
  // END SECOND TABLE

  const handleTable = (name: string) => {
    setTableSelected(name);
    setFilter({
      status: 'SUCCESSFUL,PENDING,CHARGEBACK_ACCEPTED,REFUNDED_TOTALLY,REFUNDED_PARTIALLY,CHARGEBACK_REJECTED',
      fromDate: '',
      toDate: '',
      search: '',
    });
  }

  // FILTER FUNCTIONS
  const handleChange = (event: any, name: string) => {
    if (name === 'status' && event !== filter.status) {
      let filterStatus = '';
      switch (event) {
        case 'COMPLETADA':
          filterStatus = 'SUCCESSFUL';
          break;
        case 'CANCELADO':
          filterStatus = 'CANCELED';
          break;
        case 'EXITOSA':
          filterStatus = 'PENDING';
          break;
        case 'EN PROCESO':
          filterStatus = 'PENDING';
          break;
        case 'CONTRACARGO RECHAZADO':
          filterStatus = 'CHARGEBACK_REJECTED';
          break;
        case 'CONTRACARGO ACEPTADO':
          filterStatus = 'CHARGEBACK_ACCEPTED';
          break;
        case 'DEVOLUCIÓN TOTAL':
          filterStatus = 'REFUNDED_TOTALLY';
          break;
        case 'DEVOLUCIÓN PARCIAL':
          filterStatus = 'REFUNDED_PARTIALLY';
          break;
        default:
          filterStatus = 'SUCCESSFUL,PENDING,CHARGEBACK_ACCEPTED,REFUNDED_TOTALLY,REFUNDED_PARTIALLY,CHARGEBACK_REJECTED';
      }

      if (filterStatus !== filter.status) {
        setFilter({
          ...filter,
          [name]: filterStatus,
        });
      }
    } else if ((name === 'fromDate' && event !== filter.fromDate) || (name === 'toDate' && event !== filter.toDate)) {
      setFilter({
        ...filter,
        [name]: moment(event, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      });
    }
  };

  const handleSearch = (e: any) => {
    setFilter({
      ...filter,
      [e.name]: e.value,
    });
  };

  return (
    <MyActivityPaymentRecordContainer>
      <div className="payment-record-filter-container">
        <form />
      </div>
      <div className="payment-record-content">
        {(rows.length !== 0 || completedPaymentRows.length !== 0) ?
          (
            <>
              <MainTableHeader
                // onClick={generateOperation}
                // mainButton
                searchFunction={handleSearch}
                placerholder="Introduzca un comercio"
                onAnyKeyPress
              // buttons={[
              //   {
              //     text: 'Descargar CSV',
              //     onClick: () => {
              //       '';
              //     },
              //   },
              // ]}
              >
                <div className="main-table-header-filters__row__item">
                  <SelectBox
                    name="status"
                    value='Estado'
                    onChange={(e: any) => handleChange(e, 'status')}
                    optionsArray={[
                      'TODAS',
                      'COMPLETADA',
                      'CANCELADO',
                      tableSelected === 'Mis Cuotas' ? 'EN PROCESO' : 'EXITOSA',
                      'CONTRACARGO RECHAZADO',
                      'CONTRACARGO ACEPTADO',
                      'DEVOLUCIÓN TOTAL',
                      'DEVOLUCIÓN PARCIAL',
                    ]}
                  />
                </div>
                <div className="main-table-header-filters__row__item fix">
                  <CalendarBox onChange={(e: any) => handleChange(e, 'fromDate')} placeholder="Desde" initialValue="" />
                </div>
                <div className="main-table-header-filters__row__item fix">
                  <CalendarBox onChange={(e: any) => handleChange(e, 'toDate')} placeholder="Hasta" initialValue="" />
                </div>
              </MainTableHeader>
              <PaymentTableWrapper
                title=""
                tabs={['Mis Compras', 'Mis Cuotas']}
                tables={[
                  <MainTable columns={columnsBuys} rows={rows} keysRow={rowKeyBuys} />,
                  <MainTable columns={columnsDues} rows={completedPaymentRows} keysRow={rowKeysDues} />,
                ]}
                onClick={handleTable}
              />

              <div className="pagination">
                <Pagination
                  totalItems={tableSelected === 'Mis Cuotas' ? completedPaymentsCount : count}
                  pageLimit={20}
                  nextPage={1}
                  onPageChanged={onPageChanged}
                  reset={filter}
                />
                <div></div>
                <div className="pagination-count">
                  {tableSelected !== 'Mis Cuotas' ?
                    <p>{`MOSTRANDO ${items.length < 20 ? count : items.length * page} / ${count}`}</p>
                    :
                    <p>{`MOSTRANDO ${completedPaymentsItems.length < 20 ? completedPaymentsCount : completedPaymentsItems.length * page} / ${completedPaymentsCount}`}</p>
                  }
                </div>
              </div>
            </>
          )
          :
          (
            <>
              <MainTableHeader
                searchFunction={handleSearch}
                placerholder="Instroduzca un comercio"
                onAnyKeyPress
              >
                <div className="main-table-header-filters__row__item">
                  <SelectBox
                    name="status"
                    value="Estado"
                    onChange={(e: any) => handleChange(e, 'status')}
                    optionsArray={[
                      'TODAS',
                      'COMPLETADA',
                      'CANCELADO',
                      tableSelected === 'Mis Cuotas' ? 'EN PROCESO' : 'EXITOSA',
                      'CONTRACARGO RECHAZADO',
                      'CONTRACARGO ACEPTADO',
                      'DEVOLUCIÓN TOTAL',
                      'DEVOLUCIÓN PARCIAL',
                    ]}
                  />
                </div>
                <div className="main-table-header-filters__row__item fix">
                  <CalendarBox onChange={(e: any) => handleChange(e, 'fromDate')} placeholder="Desde" initialValue="" />
                </div>
                <div className="main-table-header-filters__row__item fix">
                  <CalendarBox onChange={(e: any) => handleChange(e, 'toDate')} placeholder="Hasta" initialValue="" />
                </div>
              </MainTableHeader>

              <div className="zero-content-title">
                No se encontraron resultados del<span> historial</span>
              </div>

              <div className="pagination" style={{ visibility: "hidden" }}>
                <Pagination
                  totalItems={tableSelected === 'Mis Cuotas' ? completedPaymentsCount : count}
                  pageLimit={20}
                  nextPage={1}
                  onPageChanged={onPageChanged}
                  reset={filter}
                />
                <div></div>
                <div className="pagination-count">
                  {tableSelected !== 'Mis Cuotas' ?
                    <p>{`MOSTRANDO ${items.length < 20 ? count : items.length * page} / ${count}`}</p>
                    :
                    <p>{`MOSTRANDO ${completedPaymentsItems.length < 20 ? completedPaymentsCount : completedPaymentsItems.length * page} / ${completedPaymentsCount}`}</p>
                  }
                </div>
              </div>
            </>
          )}
      </div>
      <ModalToPaidManual
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'confirmPaymentPurchase') || false,
          onClose: closeModal,
        }}
        logo={icons.slightpayLogo}
        shopName={warningKeysData?.shopName || ''}
        prize={warningKeysData?.prize || ''}
        fee={`${warningKeysData?.fee}/4`}
        date={warningKeysData?.date || ''}
        card={warningKeysData?.card!}
        onClick={warningKeysData?.onClick}
        purchaseId={warningKeysData?.purchaseId}
        feeId={warningKeysData?.feeId}
        setDefaultCard={selectedCard}
        payPurchase
        products={warningKeysData?.products}
      />

      <ModalToSelectPaymentMethod
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'selectPaymentMethod') || false,
          onClose: () => {
            closeModal()
            setWalletScreen(false)
          }
        }}
        setWalletScreen={setWalletScreen}
        walletScreen={walletScreen}
        onClick={() => {
          openModal('confirmPaymentPurchase')
        }}
        purchase={paymentState}
      />


      <ModalSuccess
        modalConfig={{
          active: (isModal?.show && isModal?.idModal === 'paymentSuccessful') || false,
          onClose: closeModal,
        }}
      >
        Se ha liquidado <span>su compra con éxito.</span>
      </ModalSuccess>

      <ModalErrorCard
        title={title}
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'error') || false,
        }}
      />

      <ModalErrorCustom
        version={version}
        modalConfig={{
          onClose: closeModal,
          active: (isModal?.show && isModal?.idModal === 'error-custom') || false,
        }}
      />
    </MyActivityPaymentRecordContainer>
  )
}

export default MyActivityPaymentRecord
