import styled from 'styled-components/macro';
import { images } from '../../../assets/themes';

const ProfileContainer = styled.section`
    margin-top: 45px;

    .user-banner-resp {
        position: relative; 
        top: 60px; 
        width: 100%; 
        height: 115px; 
        background-image: url(${images.profileDefaultBanner});
        background-size: cover;   
        display: none; 
        align-items: center; 
        justify-content: center;

        .user-banner-resp-text {
            font-family: "Gilroy";
            font-size: 20px;
            line-height: 24px;
            color: #FFFFFF;
            text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        }      
    }

    .profile-aside-container {
        display: block; 
    }

    .profile-aside-resp-container {
        display: none; 
    }

    .profile-content {        
        padding-left: 385px; 
    }

    @media(max-width: 768px) {
        margin-top: 0px;

        .profile-content {
            padding-left: 0; 
            display: flex;
            align-items: center; 
            justify-content: center; 
        }

        .user-banner-resp {
            display: flex; 
            
        }

        .profile-aside-container {
            display: none; 
        }

        .profile-aside-resp-container {
            display: block; 
        }
        
    }

`

export default ProfileContainer;