import React, { useState } from 'react'
import ProfileHelpContainer from './profile-help-style'
import HelpSectionBox from '../../../../components/help-section-box/help-section-box'
import Pagos from './answers/pagos';
import HowToUse from './answers/how-to-use'
import Commerces from './answers/commerces'
import Management from './answers/management'
import Account from './answers/account'
import Verification from './answers/verification'
import LoginQuestions from './answers/login-questions'
import Contact from './answers/contact'
import RefundQuestions from './answers/refunds-questions'

const ProfileHelp: React.FC<any> = (props: any) => {
  const [section, setSection] = useState<string>('');

  const tabNavigation = () => {
    switch (section) {
      case 'pagos':
        return <Pagos onClick={()=> setSection('')} />
      case 'como usar':
        return <HowToUse onClick={()=> setSection('')} />
      case 'comercios':
        return <Commerces onClick={()=> setSection('')} />
      case 'management':
        return <Management onClick={()=> setSection('')} />
      case 'manejo de cuenta':
        return <Account onClick={()=> setSection('')} />
      case 'verificacion': 
        return <Verification onClick={()=> setSection('')} />
      case 'login':
        return <LoginQuestions onClick={()=> setSection('')} />
      case 'contacto':
        return <Contact onClick={()=> setSection('')} />
      case 'reembolso':
        return <RefundQuestions onClick={()=> setSection('')} />
      
      default:
        break;
    }
  };

  return (
    <ProfileHelpContainer>
      <div className="help-banner">
        <h1 className="help-title">FAQS</h1>

        {/* <div className="help-searcher-container">
          <div className="category-select">
            <SelectBox
              value="Selecciona una categoría..."
              name="category"
              optionsArray={['Opción 1', 'Opción 2']}
            />
          </div>

          <div className="search-category-input">
            <InputBox
              name="searcherCategory"
              placeholder="Buscar por palabras clave"
            />
          </div>
        </div> */}
      </div>

      {/* <div className="help-filter">
        <div className="help-filter-topbar">
          <div
            className="help-filter-icon"
            onClick={() => handleChangeActiveFilters(!showFilters)}
          >
            <img
              src={showFilters ? icons.closeHelpFilters : icons.helpFilter}
              alt="help filter"
            />
          </div>

          <p className="help-filter-title">FILTRAR</p>
        </div>

        {showFilters && (
          <form className="help-filter-form">
            <div className="category-filter">
              <SelectBox
                value="Selecciona una categoría..."
                name="category"
                optionsArray={['Opción 1', 'Opción 2']}
              />
            </div>

            <div className="search-category-filter">
              <InputBox
                name="searcherCategory"
                placeholder="Buscar por palabras clave"
              />
            </div>
          </form>
        )}
      </div> */}

      {/* <div className="selected-title-container">
        <div className="back-icon" />
        <h2 className="selected-help-title" />
      </div> */}
      { section !== '' ?  tabNavigation() :
      
      <div className="help-content">
        <div className="help-section-box">
          <HelpSectionBox labelText="Pagos" onClick={()=> setSection('pagos') } />
        </div>

        <div className="help-section-box">
          <HelpSectionBox labelText="Cómo usar Slightpay" onClick={()=> setSection('como usar')} />
        </div>

        <div className="help-section-box">
          <HelpSectionBox labelText="Comercios" onClick={()=> setSection('comercios')} />
        </div>

        <div className="help-section-box">
          <HelpSectionBox labelText="Gestión de Órdenes" onClick={()=> setSection('management')} />
        </div>

        <div className="help-section-box">
          <HelpSectionBox labelText="Manejo de cuenta" onClick={()=> setSection('manejo de cuenta')} />
        </div>

        <div className="help-section-box">
          <HelpSectionBox labelText="Verificación de Identidad" onClick={()=> setSection('verificacion')} />
        </div>

        <div className="help-section-box">
          <HelpSectionBox labelText="Login" onClick={()=> setSection('login')} />
        </div>

        <div className="help-section-box">
          <HelpSectionBox labelText="Contáctanos" onClick={()=> setSection('contacto')} />
        </div>

        <div className="help-section-box">
          <HelpSectionBox labelText="Reembolsos y Devoluciones" onClick={()=> setSection('reembolso')} />
        </div>
      </div>
    }
    </ProfileHelpContainer>
  )
}

export default ProfileHelp
