import React from 'react';
import Accordion from '../../../../../components/accordion/accordion';
import { IPropsAnswers } from '../../../../../utils/utils';

const Contact: React.FC<IPropsAnswers> = (props) => {
  const { onClick } = props;
  return (
    <div className="help-content">
      <div className="help-content-return" onClick={ () => onClick() }>
        <div><i className="arrow"></i></div>
        <p>Contáctanos</p>
      </div>
      <div className="help-section-box">
        <Accordion title="¿Les puedo enviar un mensaje?" className="help-item">
          <p className="help-text">
            <p>
              Claro! Nos encantaría que lo hicieras.
            </p>
            <p>
              Simplemente envíanos un correo a hello@slightpay.com y cuéntanos que es lo que está pasando. Estamos disponibles en 
              línea los 7 días de la semana, así que nos pondremos en contacto contigo lo antes posible y así tu puedes seguir con tu día.
            </p>
          </p>
        </Accordion>
      </div>
    </div>
  );
};

export default Contact;