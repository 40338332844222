import styled from 'styled-components/macro';
import { colors } from '../../../assets/themes';

const ErrorText = styled.p`
    min-height: 17px; 
    font-family: "Gilroy";
    font-size: 12px;
    line-height: 17px;
    color: ${colors['SC––004-50']};
`; 

export default ErrorText;