// THis FILE SHOULD BE REFACTROED AFTED DECIDED IF USE REACT HOOK FORM OFFICALY
import { NestDataObject } from 'react-hook-form';

// FOR LOGIN
export const getLoginFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  if (inputErrors[inputName]?.type === 'isValidEmail') return 'invalid-email';
  if (inputErrors[inputName]?.type === 'isValidPassword') return 'invalid-password';
  if (inputErrors[inputName]?.type === 'passwordMissmatch') return 'no-match';

  return inputErrors[inputName]?.message;
};

// FOR CHECK EMAIL
export const getCheckEmailFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  if (inputErrors[inputName]?.type === 'isValidEmail') return 'invalid-email';

  return inputErrors[inputName]?.message;
};

// FOR REGISTER  - BASIC DATA
export const getBasicDataFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  if (inputErrors[inputName]?.type === 'passwordMissmatch') return 'no-match';
  if (inputErrors[inputName]?.type === 'isValidName') return 'invalid-name';
  if (inputErrors[inputName]?.type === 'isValidPassword') return 'no-strong';
  if (inputErrors[inputName]?.type === 'isValidSelect') return 'required';

  return inputErrors[inputName]?.message;
};

// FOR REGISTER  - LEGAL DATA
export const getLegalDataFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  // if (inputErrors[inputName]?.type === 'ineNotUploaded') return 'both-ine-images-needed';
  if (inputErrors[inputName]?.type === 'isRFC') return 'invalid-rfc';
  if (inputErrors[inputName]?.type === 'isValidNumber') return 'invalid-format';
  if (inputErrors[inputName]?.type === 'isValidName') return 'invalid-format';
  if (inputErrors[inputName]?.type === 'isValidDate') return 'invalid-format';
  if (inputErrors[inputName]?.type === 'isValidPhone') return 'invalid-phone';
  if (inputErrors[inputName]?.type === 'isValidSelect') return 'required';
  if (inputErrors[inputName]?.type === 'isValidImage') return 'invalid-image';
  if (inputErrors[inputName]?.type === 'isValidSize') return 'too-heavy-file';
  if (inputErrors[inputName]?.type === 'ineNotUploaded') return 'error-ine';

  return inputErrors[inputName]?.message;
};

// FOR REGISTER  - EXTRA DATA
export const getExtraDataFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  if (inputErrors[inputName]?.type === 'isValidNumber') return 'invalid-format';

  return inputErrors[inputName]?.message;
};

// FOR REGISTER  - COMERCIAL DATA
export const getComercialDataFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;
  if (inputErrors[inputName]?.type === 'isValidUrl') return 'invalid-url';


  return inputErrors[inputName]?.message;
};

// FOR REGISTER - VERIFY CODE PHONE
export const getCodeNumberDataFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;
  if (inputErrors[inputName]?.type === 'passwordMissmatch') return 'no-match';

  return inputErrors[inputName]?.message;
};

export const getRegisterFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  if (inputErrors[inputName]?.type === 'passwordMissmatch') return 'no-match';
  if (inputErrors[inputName]?.type === 'isValidName') return 'invalid-name';
  if (inputErrors[inputName]?.type === 'isValidPassword') return 'no-strong';
  if (inputErrors[inputName]?.type === 'isValidSelect') return 'required';
  if (inputErrors[inputName]?.type === 'isValidPhone') return 'invalid-phone';

  if (inputErrors[inputName]?.type === 'isValidEmail') return 'invalid-email';
  if (inputErrors[inputName]?.type === 'existEmail') return 'invalid-email';

  if (inputErrors[inputName]?.type === 'ineNotUploaded') return 'both-ine-images-needed';
  if (inputErrors[inputName]?.type === 'isRFC') return 'invalid-rfc';
  if (inputErrors[inputName]?.type === 'existRFC') return 'invalid-rfc';
  if (inputErrors[inputName]?.type === 'isValidNumber') return 'invalid-format';
  if (inputErrors[inputName]?.type === 'isValidDate') return 'invalid-format';
  if (inputErrors[inputName]?.type === 'isValidPhoto') return 'invalid-image';
  if (inputErrors[inputName]?.type === 'isValidSize') return 'too-heavy-file';

  return inputErrors[inputName]?.message;
};
// FOR EXTRA BANK DATA

export const getExtraBankDataFormErrors = (inputErrors: NestDataObject<Record<any, string>>, inputName: string) => {
  if (!inputErrors[inputName]) return;

  if (inputErrors[inputName]?.type === 'isValidName') return 'invalid-name';
  if (inputErrors[inputName]?.type === 'isValidEmail') return 'invalid-email';

  return inputErrors[inputName]?.message;
};
