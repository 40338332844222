import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthorizationRoutesContainer } from '../containers/containers-style';

// components
import Auth from '../containers/authorization/auth/Auth';
import ConfirmEmail from '../containers/authorization/auth/signup/steps/ConfirmEmail';
import ResetPass from '../containers/authorization/auth/login/steps/Reset-password';
import TermsAndConditions from '../containers/legal/terms';
import Privacy from '../containers/legal/privacy';

const AuthorizationRoutes: React.FC = () => {
  return (
    <AuthorizationRoutesContainer>
      <Router>
        <Switch>
          {/* Aquí irán las rutas de login, register y todas las rutas para autorizar al user */}
          <Route path="/registro/verificar-email/:token" component={ConfirmEmail} />
          <Route path="/iniciar-sesion/cambio-de-contraseña/:token" component={ResetPass} />
          <Route path="/terminos-y-condiciones" component={TermsAndConditions} />
          <Route path="/politica-de-privacidad" component={Privacy} />
          <Route path="/" component={Auth} />
          <Route path="/not-found" component={() => <h1>NOT FOUND AUTH</h1>} />
        </Switch>
      </Router>
    </AuthorizationRoutesContainer>
  );
};  

export default AuthorizationRoutes;
