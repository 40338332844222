import { Dispatch } from 'react';
import { ILoadingActionsTypes, ILoadingActions } from '../loader/types';
import { IFormatError, LightpayApi } from '../restclient';
import { TrasactionActionsTypes, ITransactionPurchase, TransactionActions, ITransactionPayment, IAllLatePayments } from './types';
import moment from 'moment';
import { IPurchase } from '@aluxion-lightpay/interfaces';
import { IPurchaseClients } from '../../interfaces/purchaseclients.types'
import { AuthActions, AuthActionsTypes } from '../auth/types';

export interface IServerTransactionPurchasesResponse {
  data: ITransactionPurchase;
}

export interface IServerTransactionPaymentsResponse {
  data: ITransactionPayment;
}

export interface IServerTransactionDetailsResponse {
  data: IPurchaseClients;
}

export interface IServerLatePaymentsResponse {
  data: {
    totalAmount: number;
  };
}

interface IServerAllLatePaymentsResponse {
  data: IAllLatePayments
}

interface IBankForm {
  bankName: string;
  username: string;
  password: string;
}

const addTransactionSellsData = (id: number, currentPage: any, filter?: any, type?: string) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    dispatch({ type: ILoadingActions.LOADING });
    const response = await LightpayApi.get<IServerTransactionPurchasesResponse>(
      `/users/${id}/purchases`.concat(
        `?relations=commerce,commerce.user,commerce.user.image,payments,payments.card&take=20&skip=${currentPage === '1' ? 0 : 20 * (currentPage - 1)
          }&sort[]=${type === 'new' ? 'payments.paymentIndex' : '-createdAt'}`
          .concat(`${filter?.status ? `&${type === 'Mis Cuotas' ? 'payments.' : ''}status[]=${filter.status}` : ''}`)
          .concat(`${filter && filter?.search ? `&commerce.name~=${filter.search}%25` : ''}`)
          .concat(
            `${filter?.fromDate && filter?.toDate
              ? `&${type !== 'Mis Compras' ? 'payments.dueDate' : 'createdAt'}>=${filter.fromDate}&${type !== 'Mis Compras'
                ? 'payments.dueDate' : 'createdAt'}<=${moment(filter.toDate, 'YYYY-MM-DD')
                  .add(1, 'days')
                  .format('YYYY-MM-DD')}`
              : ''
            }`,
          ),
      ),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    dispatch({ type: TransactionActions.PURCHADES_ADD_DATA, payload: response.data });
    dispatch({ type: ILoadingActions.LOADED });
    return response
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const addPaymentsData = (id: number, currentPage: any, filter?: any, type?: string) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const response = await LightpayApi.get<IServerTransactionPaymentsResponse>(
      `/users/${id}/payments`.concat(
        `?take=20&skip=${currentPage === '1' ? 0 : 20 * (currentPage - 1)
          }&sort=${type === 'completed' ? '-dueDate' : 'dueDate'}&relations=card`
          .concat(`${filter?.status ? `&purchase.status[]=PENDING&status[]=${filter.status}` : ''}`)
          .concat(`${filter && filter?.search ? `&purchase.commerce.name~=${filter.search}%25` : ''}`)
          .concat(
            `${filter?.fromDate && filter?.toDate
              ? `&${type !== 'Mis Compras' ? 'dueDate' : 'createdAt'}>=${filter.fromDate}&${type !== 'Mis Compras'
                ? 'dueDate' : 'createdAt'}<=${moment(filter.toDate, 'YYYY-MM-DD')
                  .add(1, 'days')
                  .format('YYYY-MM-DD')}`
              : ''
            }`,
          ),
      ),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    if (type === 'completed') {
      dispatch({ type: TransactionActions.ADD_COMPLETED_PAYMENTS_DATA, payload: response.data });
    } else dispatch({ type: TransactionActions.PAYMENTS_ADD_DATA, payload: response.data });

    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}


const getPaymentDetails = (idPurchase: number | string, type?: string) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>, getState: any,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const { id } = getState().auth.user;
    const response = await LightpayApi.get<IServerTransactionDetailsResponse>(
      `/users/${id}/purchases/${idPurchase}`.concat(
        `?relations=commerce,commerce.user,commerce.user.image,payments,payments.card,paymentDiscountCode&sort=payments.paymentIndex`),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    console.log(response.data)
    dispatch({ type: TransactionActions.DETAILS_PURCHASES_ADD_DATA, payload: response.data });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const getHomePaymentsDetails = (id: number) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const responsePurchase = await LightpayApi.get<IServerTransactionPurchasesResponse>(
      `/users/${id}/purchases`.concat(
        `?relations=commerce,commerce.user,payments,payments.card,payments.latePaymentFee&take=10&skip=0`
      )
        .concat(`&sort[]=payments.paymentIndex,-createdAt,payments.latePaymentFee.createdAt&status[]=SUCCESSFUL,PENDING,CHARGEBACK_ACCEPTED,REFUNDED_TOTALLY,REFUNDED_PARTIALLY,CANCELED,CHARGEBACK_REJECTED,PREPENDING`),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    dispatch({ type: TransactionActions.PURCHADES_ADD_DATA, payload: responsePurchase.data });
    const response = await LightpayApi.get<IServerTransactionPaymentsResponse>(
      `/users/${id}/payments`.concat(
        `?take=10&skip=0&relations=latePaymentFee&sort=dueDate&status=SUCCESSFUL`
      ),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    dispatch({ type: TransactionActions.ADD_COMPLETED_PAYMENTS_DATA, payload: response.data });

    const responsePendingPayments = await LightpayApi.get<IServerTransactionPaymentsResponse>(
      `/users/${id}/payments`.concat(
        `?take=10&skip=0&sort=dueDate&status[]=PENDING,PAYMENT_REJECTED&purchase.status[]=PENDING`
      ),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    dispatch({ type: TransactionActions.PAYMENTS_ADD_DATA, payload: responsePendingPayments.data });
    await dispatch({ type: ILoadingActions.LOADED });

  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const payDue = (id: number, cardId: number) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.post(`/purchases/charge/${id}?cardId=${cardId}`, '',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const purchasesCard = (cardId: number, currentPage?: any, filter?: any, type?: string) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>, getState: any
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const { id } = getState().auth.user;
    const response = await LightpayApi.get<IServerTransactionPurchasesResponse>(
      `/users/${id}/cards/${cardId}/purchases`.concat(
        `?relations=commerce&take=20&skip=${currentPage === '1' ? 0 : 20 * (currentPage - 1)
          }&sort=${type === 'new' ? 'payments.paymentIndex' : '-createdAt'}`
          .concat(`${filter?.status ? `&status[]=${filter.status}` : ''}`)
          .concat(`${filter && filter?.search ? `&commerce.name~=${filter.search}%25` : ''}`)
          .concat(
            `${filter?.fromDate && filter?.toDate
              ? `&${type !== 'Mis Compras' ? 'payments.dueDate' : 'createdAt'}>=${filter.fromDate}&${type !== 'Mis Compras'
                ? 'payments.dueDate' : 'createdAt'}<=${moment(filter.toDate, 'YYYY-MM-DD')
                  .add(1, 'days')
                  .format('YYYY-MM-DD')}`
              : ''
            }`,
          ),
      ),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    dispatch({ type: TransactionActions.ADD_SELECTED_CARD_PURCHASES, payload: response.data });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const paymentsCard = (cardId: number, currentPage?: any, filter?: any, type?: string) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>, getState: any
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const { id } = getState().auth.user;
    const response = await LightpayApi.get<IServerTransactionPaymentsResponse>(
      `/users/${id}/cards/${cardId}/payments`.concat(
        `?relations=purchase.commerce&take=20&skip=${currentPage === '1' ? 0 : 20 * (currentPage - 1)
          }&sort=${type === 'new' ? 'payments.paymentIndex' : '-createdAt'}`
          .concat(`${filter?.status ? `&status[]=${filter.status}` : ''}`)
          .concat(`${filter && filter?.search ? `&purchase.commerce.name~=${filter.search}%25` : ''}`)
          .concat(
            `${filter?.fromDate && filter?.toDate
              ? `&${type !== 'Mis Compras' ? 'payments.dueDate' : 'createdAt'}>=${filter.fromDate}&${type !== 'Mis Compras'
                ? 'payments.dueDate' : 'createdAt'}<=${moment(filter.toDate, 'YYYY-MM-DD')
                  .add(1, 'days')
                  .format('YYYY-MM-DD')}`
              : ''
            }`,
          ),
      ),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    dispatch({ type: TransactionActions.PAYMENTS_ADD_DATA, payload: response.data });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}


const getTotalLateFeeAmount = (purchaseId: number, paymentPartitionId: number) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>, getState: any) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const { id } = getState().auth.user;

    const response = await LightpayApi.get<IServerLatePaymentsResponse>(
      `/users/${id}/purchases/${purchaseId}/payment-partition/${paymentPartitionId}/late-payment-fee/total-amount`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    dispatch({ type: TransactionActions.ADD_LATEPAYMENT_DATA, payload: response.data.totalAmount });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }

}

const payTotalLatePayments = (purchaseId: number, paymentPartitionId: number, cardId: number) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>, getState: any) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const { id } = getState().auth.user;
    await LightpayApi.post(
      `/users/${id}/purchases/${purchaseId}/payment-partition/${paymentPartitionId}/late-payment-fee/charge?cardId=${cardId}`, '',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const getAllLatePayments = (purchaseId: number, currentPage: number) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>, getState: any) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const { id } = getState().auth.user;
    const res = await LightpayApi.get<IServerAllLatePaymentsResponse>(
      `/users/${id}/purchases/${purchaseId}/late-payment-fee?take=5&skip=${currentPage === 1 ? 0 : 5 * (currentPage - 1)
      }&relations=card,paymentPartition&sort=-createdAt&status[]=SUCCESSFUL,PENDING,PAYMENT_REJECTED`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    dispatch({ type: TransactionActions.ADD_LATEPAYMENTS_OBJECT_DATA, payload: res.data });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const payAllDebts = (cardId: number, purchaseId?: number) => async (
  dispatch: Dispatch<ILoadingActionsTypes>) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.post(`/purchases/charge-debts${purchaseId ? `?purchaseId=${purchaseId}` : ''}`, {
      cardId,
    },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const getPurchaseLatePaymentsAmount = (purchaseId: number | string) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>, getState: any
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    const { id } = getState().auth.user;
    const response = await LightpayApi.get<IServerLatePaymentsResponse>(
      `/users/${id}/purchases/${purchaseId}/late-payment-fee/total-amount`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    dispatch({ type: TransactionActions.ADD_PURCHASE_LATEPAYMENTS_AMOUNT_DATA, payload: response.data.totalAmount });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
};

const payAdvancement = (paymentPartitionId: number, cardId: number) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.post(`/purchases/manual-payment-partition`, {
      paymentPartitionId,
      cardId
    },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const payPurchasePendingTotal = (purchaseId: number, cardId: number) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    await LightpayApi.post(`/purchases/manual-payment-purchase`, {
      purchaseId,
      cardId
    },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      });
    await dispatch({ type: ILoadingActions.LOADED });
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const createSpeiOrPaynetTransaction = (purchaseId: number, type: string, latepf: number, paymentPartitionId?: number) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    let resultUrl: any = await LightpayApi.post(`/purchases/charge-openpay-manual`, {
      purchaseId, type, paymentPartitionId, latepf
    },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      });

    console.log(resultUrl)
    window.open(resultUrl.data, '_blank')
    await dispatch({ type: ILoadingActions.LOADED });

    return resultUrl
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const getDappWallets = (setWallets: any) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>,
) => {
  let resultUrl: any = await LightpayApi.get(`/purchases/dapp-wallets`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    });

  setWallets(resultUrl.data.data)
}

const createCodiTransaction = (purchaseId: number, latepf: number, qrType: number, paymentPartitionId?: number) => async (
  dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>,
) => {
  try {
    await dispatch({ type: ILoadingActions.LOADING });
    let resultUrl: any = await LightpayApi.post(`/purchases/charge-codi-dapp-clients`, {
      purchaseId, paymentPartitionId, latepf, qrType
    },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      });

    window.open(resultUrl.data.data.qr_image, '_blank')
    await dispatch({ type: ILoadingActions.LOADED });

    return resultUrl
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    await dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const sendFinerioToken = (token: any, credentialId: any, customerId: any) => async (dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>) => {
  console.log(token, credentialId, 'send finerio token')
  try {
    // dispatch({ type: ILoadingActions.LOADING });
    let result = await LightpayApi.post(`/scoring/send-token`,
      { token, credentialId, customerId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        }
      }
    )
    // dispatch({ type: ILoadingActions.LOADED });

    return result
  } catch (error) {
    console.log("Send Error", error)
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    // dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const createUserByFinerio = () => async (dispatch: Dispatch<TrasactionActionsTypes | ILoadingActionsTypes>) => {
  try {
    // dispatch({ type: ILoadingActions.LOADING });
    let result = await LightpayApi.post(`/scoring/create-user-finerio`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        }
      }
    )
    console.log(result,"El resultado del usuario en finerio")
    return result
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

const getBanksFields = async () => {
  let resultBanks = await LightpayApi.get(`/scoring/banks`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      }
    }
  )

  return resultBanks
}

const setFinerioSuccess = () => async (dispatch: Dispatch<AuthActionsTypes | ILoadingActionsTypes>) => {
  dispatch({
    type: AuthActions.SET_SCORING_RESULT, payload: {
      status: 'SUCCESS',
      message: 'El usuario ha sido aprobado exitosamente'
    }
  });
}
const aproveUser = async () => {
  let resultUser = await LightpayApi.get(`/scoring/aproved-user`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      }
    }
  )

  return resultUser
}

const insertLogError = async (body: any) => {
  let resultInsert = await LightpayApi.post(`/logs/insert-log`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${localStorage.getItem('token')}`,
    }
  })

  return resultInsert
}

const checkContinueFinerioWay = async () => {
  try {
    let result = await LightpayApi.post(`/scoring/check-continue-finerio-way`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        }
      }
    )
    console.log(result, 'the response scoring check continue finerio way')

    return result
  } catch (error) {
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    throw formatError;
  }
}

const sendExtraBankData = (bankForm: IBankForm) => async (dispatch: Dispatch<ITransactionPayment | ILoadingActionsTypes>) => {
  try {
    // dispatch({ type: ILoadingActions.LOADING });
    let result = await LightpayApi.post(`/scoring/open-banking/0`,
      bankForm,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${localStorage.getItem('token')}`,
        }
      }
    )
    // dispatch({ type: ILoadingActions.LOADED });

    return result
  } catch (error) {
    console.log("Send Error", error)
    const formatError: IFormatError = LightpayApi.errorHandler(error);
    // dispatch({ type: ILoadingActions.LOADED });
    throw formatError;
  }
}

export const transactionActions = {
  payDue,
  addTransactionSellsData,
  addPaymentsData,
  getPaymentDetails,
  getHomePaymentsDetails,
  paymentsCard,
  purchasesCard,
  getTotalLateFeeAmount,
  payTotalLatePayments,
  getAllLatePayments,
  payAllDebts,
  getPurchaseLatePaymentsAmount,
  payAdvancement,
  payPurchasePendingTotal,
  createSpeiOrPaynetTransaction,
  createCodiTransaction,
  getDappWallets,
  createUserByFinerio,
  sendFinerioToken,
  getBanksFields,
  setFinerioSuccess,
  aproveUser,
  insertLogError,
  checkContinueFinerioWay,
  sendExtraBankData
};
