import styled from 'styled-components';

export const TransactionLayoutStyle = styled.div`
  padding: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  .transaction-left {
    min-width: 300px;
    grid-column: 1/2;
  }
  .transaction-right {
    grid-column: 2/-1;
  }

  .transaction-item {
    margin-bottom: 20px;
    &__wrapper {
      &.card-base-style__CardBase {
        padding: 50px;
      }
    }
  }

  @media (max-width: 800px) {
    display: block;
  }
`;
