import React from 'react';
import { IEStyle } from './IE-style';
import { SVGIcon } from '../../../components/svg-icon/svg-icon';
import { icons, images } from '../../../assets/themes';

const IE: React.FC = () => {
  return (
    <IEStyle>
      <div className="title">
        <SVGIcon icon={icons.slightpayLogo} scale='2.5' color='black'/>
      </div>
      <p>Para una mejor experciencia web, por favor accede a este sitio desde un navegador actual.</p>
      <div className="browser-container">
        <div className="browser-item">
          <a href="https://www.google.es/chrome/" target="_blank" rel="noopener noreferrer">
            <img src={images.chrome} alt="Google Chrome" />
          </a>
        </div>
        <div className="browser-item">
          <a href="https://www.mozilla.org/es-ES/firefox/new/" target="_blank" rel="noopener noreferrer">
            <img src={images.firefox} alt="Mozilla Firefox" />
          </a>
        </div>
        <div className="browser-item">
          <a href="https://support.apple.com/downloads/safari" target="_blank" rel="noopener noreferrer">
            <img src={images.safari} alt="Safari" />
          </a>
        </div>
        <div className="browser-item">
          <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noopener noreferrer">
            <img src={images.edge} alt="Microsoft Edge" />
          </a>
        </div>
        <div className="browser-item">
          <a href="https://www.opera.com/es-419" target="_blank" rel="noopener noreferrer">
            <img src={images.opera} alt="Opera" />
          </a>
        </div>
      </div>
    </IEStyle>
  );
};

export default IE;
